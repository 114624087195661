/* @_flow_ */
/*jshint esversion: 6 */
/*jslint es6*/

/* Contains code from https://developer.mozilla.org/en-US/docs/Archive/Add-ons/Code_snippets/StringView */

/*\
|*|
|*|  :: Number.isInteger() polyfill ::
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
|*|
\*/

/*if (!Number.isInteger) {
  Number.isInteger = function isInteger(nVal) {
    return typeof nVal === "number" && isFinite(nVal) && nVal > -9007199254740992 && nVal < 9007199254740992 && Math.floor(nVal) === nVal;
  };
}*/

/*\
|*|
|*|  StringView - Mozilla Developer Network
|*|
|*|  Revision #12, March 21st, 2017
|*|
|*|  https://developer.mozilla.org/en-US/Add-ons/Code_snippets/StringView
|*|  https://developer.mozilla.org/en-US/docs/User:fusionchess
|*|  https://github.com/madmurphy/stringview.js
|*|
|*|  This framework is released under the GNU Lesser General Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/lgpl-3.0.html
|*|
\*/

export function StringView(vInput, sEncoding /* optional (default: UTF-8) */, nOffset /* optional */, nLength /* optional */) {

  let fTAView, aWhole, aRaw, fPutOutptCode, fGetOutptChrSize, nInptLen, nStartIdx = isFinite(nOffset) ? nOffset : 0, nTranscrType = 15;

  if (sEncoding) { this.encoding = sEncoding.toString(); }

  /*encSwitch: */switch (this.encoding) {
    case "UTF-8":
      fPutOutptCode = StringView.putUTF8CharCode;
      fGetOutptChrSize = StringView.getUTF8CharLength;
      fTAView = Uint8Array;
      break/* encSwitch*/;
    case "UTF-16":
      fPutOutptCode = StringView.putUTF16CharCode;
      fGetOutptChrSize = StringView.getUTF16CharLength;
      fTAView = Uint16Array;
      break/* encSwitch*/;
    case "UTF-32":
      fTAView = Uint32Array;
      nTranscrType &= 14;
      break/* encSwitch*/;
    default:
      /* case "ASCII", or case "BinaryString" or unknown cases */
      fTAView = Uint8Array;
      nTranscrType &= 14;
  }

  /*typeSwitch: */switch (typeof vInput) {
    case "string":
      /* the input argument is a primitive string: a new buffer will be created. */
      nTranscrType &= 7;
      break/* typeSwitch*/;
    case "object":
      /*classSwitch: */switch (vInput.constructor) {
        case StringView:
          /* the input argument is a stringView: a new buffer will be created. */
          nTranscrType &= 3;
          break/* typeSwitch*/;
        case String:
          /* the input argument is an objectified string: a new buffer will be created. */
          nTranscrType &= 7;
          break/* typeSwitch*/;
        case ArrayBuffer:
          /* the input argument is an arrayBuffer: the buffer will be shared. */
          aWhole = new fTAView(vInput);
          nInptLen = this.encoding === "UTF-32" ?
            vInput.byteLength >>> 2
            : this.encoding === "UTF-16" ?
              vInput.byteLength >>> 1
              :
              vInput.byteLength;
          aRaw = nStartIdx === 0 && (!isFinite(nLength) || nLength === nInptLen) ?
            aWhole
            : new fTAView(vInput, nStartIdx, !isFinite(nLength) ? nInptLen - nStartIdx : nLength);

          break/* typeSwitch*/;
        case Uint32Array:
        case Uint16Array:
        case Uint8Array:
          /* the input argument is a typedArray: the buffer, and possibly the array itself, will be shared. */
          fTAView = vInput.constructor;
          nInptLen = vInput.length;
          aWhole = vInput.byteOffset === 0 && vInput.length === (
            fTAView === Uint32Array ?
              vInput.buffer.byteLength >>> 2
              : fTAView === Uint16Array ?
                vInput.buffer.byteLength >>> 1
                :
                vInput.buffer.byteLength
          ) ? vInput : new fTAView(vInput.buffer);
          aRaw = nStartIdx === 0 && (!isFinite(nLength) || nLength === nInptLen) ?
            vInput
            : vInput.subarray(nStartIdx, isFinite(nLength) ? nStartIdx + nLength : nInptLen);

          break/* typeSwitch*/;
        default:
          /* the input argument is an array or another serializable object: a new typedArray will be created. */
          aWhole = new fTAView(vInput);
          nInptLen = aWhole.length;
          aRaw = nStartIdx === 0 && (!isFinite(nLength) || nLength === nInptLen) ?
            aWhole
            : aWhole.subarray(nStartIdx, isFinite(nLength) ? nStartIdx + nLength : nInptLen);
      }
      break/* typeSwitch*/;
    default:
      /* the input argument is a number, a boolean or a function: a new typedArray will be created. */
      aWhole = aRaw = new fTAView(Number(vInput) || 0);

  }

  if (nTranscrType < 8) {

    let vSource, nOutptLen, nCharStart, nCharEnd, nEndIdx, fGetInptChrSize, fGetInptChrCode;

    if (nTranscrType & 4) { /* input is string */

      vSource = vInput;
      nOutptLen = nInptLen = vSource.length;
      nTranscrType ^= this.encoding === "UTF-32" ? 0 : 2;
      /* ...or...: nTranscrType ^= Number(this.encoding !== "UTF-32") << 1; */
      nStartIdx = nCharStart = nOffset ? Math.max((nOutptLen + nOffset) % nOutptLen, 0) : 0;
      nEndIdx = nCharEnd = (Number.isInteger(nLength) ? Math.min(Math.max(nLength, 0) + nStartIdx, nOutptLen) : nOutptLen) - 1;

    } else { /* input is stringView */

      vSource = vInput.rawData;
      nInptLen = vInput.makeIndex();
      nStartIdx = nCharStart = nOffset ? Math.max((nInptLen + nOffset) % nInptLen, 0) : 0;
      nOutptLen = Number.isInteger(nLength) ? Math.min(Math.max(nLength, 0), nInptLen - nCharStart) : nInptLen;
      nEndIdx = nCharEnd = nOutptLen + nCharStart;

      if (vInput.encoding === "UTF-8") {
        fGetInptChrSize = StringView.getUTF8CharLength;
        fGetInptChrCode = StringView.loadUTF8CharCode;
      } else if (vInput.encoding === "UTF-16") {
        fGetInptChrSize = StringView.getUTF16CharLength;
        fGetInptChrCode = StringView.loadUTF16CharCode;
      } else {
        nTranscrType &= 1;
      }

    }

    if (nOutptLen === 0 || nTranscrType < 4 && vSource.encoding === this.encoding && nCharStart === 0 && nOutptLen === nInptLen) {

      /* the encoding is the same, the length too and the offset is 0... or the input is empty! */

      nTranscrType = 7;

    }

    let nChrCode;
    /*conversionSwitch: */switch (nTranscrType) {

      case 0:

        /* both the source and the new StringView have a fixed-length encoding... */

        aWhole = new fTAView(nOutptLen);
        for (let nOutptIdx = 0; nOutptIdx < nOutptLen; aWhole[nOutptIdx] = vSource[nStartIdx + nOutptIdx++]);
        break/* conversionSwitch*/;

      case 1:

        /* the source has a fixed-length encoding but the new StringView has a variable-length encoding... */

        /* mapping... */

        nOutptLen = 0;

        for (let nInptIdx = nStartIdx; nInptIdx < nEndIdx; nInptIdx++) {
          nOutptLen += fGetOutptChrSize(vSource[nInptIdx]);
        }

        aWhole = new fTAView(nOutptLen);

        /* transcription of the source... */

        for (let nInptIdx = nStartIdx, nOutptIdx = 0; nOutptIdx < nOutptLen; nInptIdx++) {
          nOutptIdx = fPutOutptCode(aWhole, vSource[nInptIdx], nOutptIdx);
        }

        break/* conversionSwitch*/;

      case 2:

        /* the source has a variable-length encoding but the new StringView has a fixed-length encoding... */

        /* mapping... */

        nStartIdx = 0;


        for (let nChrIdx = 0; nChrIdx < nCharStart; nChrIdx++) {
          nChrCode = fGetInptChrCode(vSource, nStartIdx);
          nStartIdx += fGetInptChrSize(nChrCode);
        }

        aWhole = new fTAView(nOutptLen);

        /* transcription of the source... */

        for (let nInptIdx = nStartIdx, nOutptIdx = 0; nOutptIdx < nOutptLen; nInptIdx += fGetInptChrSize(nChrCode), nOutptIdx++) {
          nChrCode = fGetInptChrCode(vSource, nInptIdx);
          aWhole[nOutptIdx] = nChrCode;
        }

        break/* conversionSwitch*/;

      case 3:

        /* both the source and the new StringView have a variable-length encoding... */

        /* mapping... */

        nOutptLen = 0;



        for (let nChrIdx = 0, nInptIdx = 0; nChrIdx < nCharEnd; nInptIdx += fGetInptChrSize(nChrCode)) {
          nChrCode = fGetInptChrCode(vSource, nInptIdx);
          if (nChrIdx === nCharStart) { nStartIdx = nInptIdx; }
          if (++nChrIdx > nCharStart) { nOutptLen += fGetOutptChrSize(nChrCode); }
        }

        aWhole = new fTAView(nOutptLen);

        /* transcription... */

        for (let nInptIdx = nStartIdx, nOutptIdx = 0; nOutptIdx < nOutptLen; nInptIdx += fGetInptChrSize(nChrCode)) {
          nChrCode = fGetInptChrCode(vSource, nInptIdx);
          nOutptIdx = fPutOutptCode(aWhole, nChrCode, nOutptIdx);
        }

        break/* conversionSwitch*/;

      case 4:

        /* DOMString to ASCII or BinaryString or other unknown encodings */

        aWhole = new fTAView(nOutptLen);

        /* transcription... */

        for (let nIdx = 0; nIdx < nOutptLen; nIdx++) {
          aWhole[nIdx] = vSource.charCodeAt(nIdx) & 0xff;
        }

        break/* conversionSwitch*/;

      case 5:

        /* DOMString to UTF-8 or to UTF-16 */

        /* mapping... */

        nOutptLen = 0;

        for (let nMapIdx = 0; nMapIdx < nInptLen; nMapIdx++) {
          if (nMapIdx === nCharStart) { nStartIdx = nOutptLen; }
          nOutptLen += fGetOutptChrSize(vSource.charCodeAt(nMapIdx));
          if (nMapIdx === nCharEnd) { nEndIdx = nOutptLen; }
        }

        aWhole = new fTAView(nOutptLen);

        /* transcription... */

        for (let nOutptIdx = 0, nChrIdx = 0; nOutptIdx < nOutptLen; nChrIdx++) {
          nOutptIdx = fPutOutptCode(aWhole, vSource.charCodeAt(nChrIdx), nOutptIdx);
        }

        break/* conversionSwitch*/;

      case 6:

        /* DOMString to UTF-32 */

        aWhole = new fTAView(nOutptLen);

        /* transcription... */

        for (let nIdx = 0; nIdx < nOutptLen; nIdx++) {
          aWhole[nIdx] = vSource.charCodeAt(nIdx);
        }

        break/* conversionSwitch*/;

      case 7:
      default:

        aWhole = new fTAView(nOutptLen ? vSource : 0);
        break/* conversionSwitch*/;

    }

    aRaw = nTranscrType > 3 && (nStartIdx > 0 || nEndIdx < aWhole.length - 1) ? aWhole.subarray(nStartIdx, nEndIdx) : aWhole;

  }

  this.buffer = aWhole.buffer;
  this.bufferView = aWhole;
  this.rawData = aRaw;

  Object.freeze(this);

}

/* CONSTRUCTOR'S METHODS */

StringView.loadUTF8CharCode = function (aChars, nIdx) {
  /* The ISO 10646 view of UTF-8 considers valid codepoints encoded by 1-6 bytes,
   * while the Unicode view of UTF-8 in 2003 has limited them to 1-4 bytes in order to
   * match UTF-16's codepoints. In front of a 5/6-byte sequence StringView tries to
   * encode it in any case.
   */
  let nLen = aChars.length, nPart = aChars[nIdx];
  return nPart > 251 && nPart < 254 && nIdx + 5 < nLen ?
      /* (nPart - 252 << 30) may be not safe in ECMAScript! So...: */
      /* six bytes */ (nPart - 252) * 1073741824 + (aChars[nIdx + 1] - 128 << 24) + (aChars[nIdx + 2] - 128 << 18) + (aChars[nIdx + 3] - 128 << 12) + (aChars[nIdx + 4] - 128 << 6) + aChars[nIdx + 5] - 128
    : nPart > 247 && nPart < 252 && nIdx + 4 < nLen ?
      /* five bytes */ (nPart - 248 << 24) + (aChars[nIdx + 1] - 128 << 18) + (aChars[nIdx + 2] - 128 << 12) + (aChars[nIdx + 3] - 128 << 6) + aChars[nIdx + 4] - 128
      : nPart > 239 && nPart < 248 && nIdx + 3 < nLen ?
      /* four bytes */(nPart - 240 << 18) + (aChars[nIdx + 1] - 128 << 12) + (aChars[nIdx + 2] - 128 << 6) + aChars[nIdx + 3] - 128
        : nPart > 223 && nPart < 240 && nIdx + 2 < nLen ?
      /* three bytes */ (nPart - 224 << 12) + (aChars[nIdx + 1] - 128 << 6) + aChars[nIdx + 2] - 128
          : nPart > 191 && nPart < 224 && nIdx + 1 < nLen ?
      /* two bytes */ (nPart - 192 << 6) + aChars[nIdx + 1] - 128
            :
      /* one byte */ nPart;

};

StringView.putUTF8CharCode = function (aTarget, nChar, nPutAt) {

  let nIdx = nPutAt;

  if (nChar < 0x80 /* 128 */) {
    /* one byte */
    aTarget[nIdx++] = nChar;
  } else if (nChar < 0x800 /* 2048 */) {
    /* two bytes */
    aTarget[nIdx++] = 0xc0 /* 192 */ + (nChar >>> 6);
    aTarget[nIdx++] = 0x80 /* 128 */ + (nChar & 0x3f /* 63 */);
  } else if (nChar < 0x10000 /* 65536 */) {
    /* three bytes */
    aTarget[nIdx++] = 0xe0 /* 224 */ + (nChar >>> 12);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 6) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + (nChar & 0x3f /* 63 */);
  } else if (nChar < 0x200000 /* 2097152 */) {
    /* four bytes */
    aTarget[nIdx++] = 0xf0 /* 240 */ + (nChar >>> 18);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 12) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 6) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + (nChar & 0x3f /* 63 */);
  } else if (nChar < 0x4000000 /* 67108864 */) {
    /* five bytes */
    aTarget[nIdx++] = 0xf8 /* 248 */ + (nChar >>> 24);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 18) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 12) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 6) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + (nChar & 0x3f /* 63 */);
  } else /* if (nChar <= 0x7fffffff) */ { /* 2147483647 */
    /* six bytes */
    aTarget[nIdx++] = 0xfc /* 252 */ + /* (nChar >>> 30) may be not safe in ECMAScript! So...: */ (nChar / 1073741824);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 24) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 18) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 12) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + ((nChar >>> 6) & 0x3f /* 63 */);
    aTarget[nIdx++] = 0x80 /* 128 */ + (nChar & 0x3f /* 63 */);
  }

  return nIdx;

};

StringView.getUTF8CharLength = function (nChar) {
  return nChar < 0x80 ? 1 : nChar < 0x800 ? 2 : nChar < 0x10000 ? 3 : nChar < 0x200000 ? 4 : nChar < 0x4000000 ? 5 : 6;
};

StringView.loadUTF16CharCode = function (aChars, nIdx) {

  /* UTF-16 to DOMString decoding algorithm */
  let nFrstChr = aChars[nIdx];

  return nFrstChr > 0xD7BF /* 55231 */ && nIdx + 1 < aChars.length ?
    (nFrstChr - 0xD800 /* 55296 */ << 10) + aChars[nIdx + 1] + 0x2400 /* 9216 */
    : nFrstChr;

};

StringView.putUTF16CharCode = function (aTarget, nChar, nPutAt) {

  let nIdx = nPutAt;

  if (nChar < 0x10000 /* 65536 */) {
    /* one element */
    aTarget[nIdx++] = nChar;
  } else {
    /* two elements */
    aTarget[nIdx++] = 0xD7C0 /* 55232 */ + (nChar >>> 10);
    aTarget[nIdx++] = 0xDC00 /* 56320 */ + (nChar & 0x3FF /* 1023 */);
  }

  return nIdx;

};

StringView.getUTF16CharLength = function (nChar) {
  return nChar < 0x10000 ? 1 : 2;
};

/* Array of bytes to base64 string decoding */

StringView.b64ToUint6 = function (nChr) {

  return nChr > 64 && nChr < 91 ?
    nChr - 65
    : nChr > 96 && nChr < 123 ?
      nChr - 71
      : nChr > 47 && nChr < 58 ?
        nChr + 4
        : nChr === 43 ?
          62
          : nChr === 47 ?
            63
            :
            0;

};

StringView.uint6ToB64 = function (nUint6) {

  return nUint6 < 26 ?
    nUint6 + 65
    : nUint6 < 52 ?
      nUint6 + 71
      : nUint6 < 62 ?
        nUint6 - 4
        : nUint6 === 62 ?
          43
          : nUint6 === 63 ?
            47
            :
            65;

};

/* Base64 string to array encoding */

StringView.bytesToBase64 = function (aBytes) {

  let eqLen = (3 - (aBytes.length % 3)) % 3, sB64Enc = "";

  for (let nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3;
    /* Uncomment the following line in order to split the output in lines 76-character long: */
    /*
    if (nIdx > 0 && (nIdx * 4 / 3) % 76 === 0) { sB64Enc += "\r\n"; }
    */
    nUint24 |= aBytes[nIdx] << (16 >>> nMod3 & 24);
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCharCode(StringView.uint6ToB64(nUint24 >>> 18 & 63), StringView.uint6ToB64(nUint24 >>> 12 & 63), StringView.uint6ToB64(nUint24 >>> 6 & 63), StringView.uint6ToB64(nUint24 & 63));
      nUint24 = 0;
    }
  }

  return eqLen === 0 ?
    sB64Enc
    :
    sB64Enc.substring(0, sB64Enc.length - eqLen) + (eqLen === 1 ? "=" : "==");


};


StringView.base64ToBytes = function (sBase64, nBlockBytes) {

  let
    sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ""), nInLen = sB64Enc.length,
    nOutLen = nBlockBytes ? Math.ceil((nInLen * 3 + 1 >>> 2) / nBlockBytes) * nBlockBytes : nInLen * 3 + 1 >>> 2, aBytes = new Uint8Array(nOutLen);

  for (let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= StringView.b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        aBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
      }
      nUint24 = 0;
    }
  }

  return aBytes;

};

StringView.makeFromBase64 = function (sB64Inpt, sEncoding, nByteOffset, nLength) {

  return new StringView(sEncoding === "UTF-16" || sEncoding === "UTF-32" ? StringView.base64ToBytes(sB64Inpt, sEncoding === "UTF-16" ? 2 : 4).buffer : StringView.base64ToBytes(sB64Inpt), sEncoding, nByteOffset, nLength);

};

/* DEFAULT VALUES */

StringView.prototype.encoding = "UTF-8"; /* Default encoding... */

/* INSTANCES' METHODS */

StringView.prototype.makeIndex = function (nChrLength, nStartFrom) {

  let

    aTarget = this.rawData, nChrEnd, nRawLength = aTarget.length,
    nStartIdx = nStartFrom || 0, nIdxEnd = nStartIdx, nStopAtChr = isNaN(nChrLength) ? Infinity : nChrLength;

  if (nChrLength + 1 > aTarget.length) { throw new RangeError("StringView.prototype.makeIndex - The offset can't be major than the length of the array - 1."); }

  switch (this.encoding) {

    case "UTF-8":

      let nPart;

      for (nChrEnd = 0; nIdxEnd < nRawLength && nChrEnd < nStopAtChr; nChrEnd++) {
        nPart = aTarget[nIdxEnd];
        nIdxEnd += nPart > 251 && nPart < 254 && nIdxEnd + 5 < nRawLength ? 6
          : nPart > 247 && nPart < 252 && nIdxEnd + 4 < nRawLength ? 5
            : nPart > 239 && nPart < 248 && nIdxEnd + 3 < nRawLength ? 4
              : nPart > 223 && nPart < 240 && nIdxEnd + 2 < nRawLength ? 3
                : nPart > 191 && nPart < 224 && nIdxEnd + 1 < nRawLength ? 2
                  : 1;
      }

      break;

    case "UTF-16":

      for (nChrEnd = nStartIdx; nIdxEnd < nRawLength && nChrEnd < nStopAtChr; nChrEnd++) {
        nIdxEnd += aTarget[nIdxEnd] > 0xD7BF /* 55231 */ && nIdxEnd + 1 < aTarget.length ? 2 : 1;
      }

      break;

    default:

      nIdxEnd = nChrEnd = isFinite(nChrLength) ? nChrLength : nRawLength - 1;

  }

  if (nChrLength) { return nIdxEnd; }

  return nChrEnd;

};

StringView.prototype.toBase64 = function (bWholeBuffer) {

  return StringView.bytesToBase64(
    bWholeBuffer ?
      (
        this.bufferView.constructor === Uint8Array ?
          this.bufferView
          :
          new Uint8Array(this.buffer)
      )
      : this.rawData.constructor === Uint8Array ?
        this.rawData
        :
        new Uint8Array(this.buffer, this.rawData.byteOffset, this.rawData.length << (this.rawData.constructor === Uint16Array ? 1 : 2))
  );

};

StringView.prototype.subview = function (nCharOffset /* optional */, nCharLength /* optional */) {

  let

    nRawSubLen, nRawSubOffset, nSubOffset, nSubLen, bVariableLen = this.encoding === "UTF-8" || this.encoding === "UTF-16",
    nThisLen, nRawLen = this.rawData.length;

  if (nRawLen === 0) {
    return new StringView(this.buffer, this.encoding);
  }

  nThisLen = bVariableLen ? this.makeIndex() : nRawLen;
  nSubOffset = nCharOffset ? nCharOffset + 1 > nThisLen ? nThisLen : Math.max((nThisLen + nCharOffset) % nThisLen, 0) : 0;
  nSubLen = Number.isInteger(nCharLength) ? Math.max(nCharLength, 0) + nSubOffset > nThisLen ? nThisLen - nSubOffset : nCharLength : nThisLen - nSubOffset;

  if (nSubOffset === 0 && nSubLen === nThisLen) { return this; }

  if (bVariableLen) {
    nRawSubOffset = nSubOffset < nThisLen ? this.makeIndex(nSubOffset) : nThisLen;
    nRawSubLen = nSubLen ? this.makeIndex(nSubLen, nRawSubOffset) - nRawSubOffset : 0;
  } else {
    nRawSubOffset = nSubOffset;
    nRawSubLen = nSubLen;
  }

  if (this.encoding === "UTF-16") {
    nRawSubOffset <<= 1;
  } else if (this.encoding === "UTF-32") {
    nRawSubOffset <<= 2;
  }

  return new StringView(this.buffer, this.encoding, this.rawData.byteOffset + nRawSubOffset, nRawSubLen);

};

StringView.prototype.forEachChar = function (fCallback, oThat, nChrOffset, nChrLen) {

  let aSource = this.rawData, nRawEnd, nRawIdx;

  if (this.encoding === "UTF-8" || this.encoding === "UTF-16") {

    let fGetInptChrSize, fGetInptChrCode;

    if (this.encoding === "UTF-8") {
      fGetInptChrSize = StringView.getUTF8CharLength;
      fGetInptChrCode = StringView.loadUTF8CharCode;
    } else if (this.encoding === "UTF-16") {
      fGetInptChrSize = StringView.getUTF16CharLength;
      fGetInptChrCode = StringView.loadUTF16CharCode;
    }

    nRawIdx = isFinite(nChrOffset) ? this.makeIndex(nChrOffset) : 0;
    nRawEnd = isFinite(nChrLen) ? this.makeIndex(nChrLen, nRawIdx) : aSource.length;

    for (let nChrCode, nChrIdx = 0; nRawIdx < nRawEnd; nChrIdx++) {
      nChrCode = fGetInptChrCode(aSource, nRawIdx);
      if (!oThat) {
        fCallback(nChrCode, nChrIdx, nRawIdx, aSource);
      } else {
        fCallback.call(oThat, nChrCode, nChrIdx, nRawIdx, aSource);
      }
      nRawIdx += fGetInptChrSize(nChrCode);
    }

  } else {

    nRawIdx = isFinite(nChrOffset) ? nChrOffset : 0;
    nRawEnd = isFinite(nChrLen) ? nChrLen + nRawIdx : aSource.length;

    for (nRawIdx; nRawIdx < nRawEnd; nRawIdx++) {
      if (!oThat) {
        fCallback(aSource[nRawIdx], nRawIdx, nRawIdx, aSource);
      } else {
        fCallback.call(oThat, aSource[nRawIdx], nRawIdx, nRawIdx, aSource);
      }
    }

  }

};

StringView.prototype.valueOf = StringView.prototype.toString = function () {

  if (this.encoding !== "UTF-8" && this.encoding !== "UTF-16") {
    /* ASCII, UTF-32 or BinaryString to DOMString */
    return String.fromCharCode.apply(null, this.rawData);
  }

  let fGetCode, fGetIncr, sView = "";

  if (this.encoding === "UTF-8") {
    fGetIncr = StringView.getUTF8CharLength;
    fGetCode = StringView.loadUTF8CharCode;
  } else if (this.encoding === "UTF-16") {
    fGetIncr = StringView.getUTF16CharLength;
    fGetCode = StringView.loadUTF16CharCode;
  }

  for (let nChr, nLen = this.rawData.length, nIdx = 0; nIdx < nLen; nIdx += fGetIncr(nChr)) {
    nChr = fGetCode(this.rawData, nIdx);
    sView += String.fromCharCode(nChr);
  }

  return sView;

};


/* Code from HackerRank.com */
const MD5 = function (string) {

  const RotateLeft = function (lValue, iShiftBits) {
    return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
  };

  const AddUnsigned = function (lX, lY) {
    let lX4, lY4, lX8, lY8, lResult;
    lX8 = (lX & 0x80000000);
    lY8 = (lY & 0x80000000);
    lX4 = (lX & 0x40000000);
    lY4 = (lY & 0x40000000);
    lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
    if (lX4 & lY4) {
      return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
    }
    if (lX4 | lY4) {
      if (lResult & 0x40000000) {
        return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
      } else {
        return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
      }
    } else {
      return (lResult ^ lX8 ^ lY8);
    }
  };

  const F = function (x, y, z) {
    return (x & y) | ((~x) & z);
  };

  const G = function (x, y, z) {
    return (x & z) | (y & (~z));
  };

  const H = function (x, y, z) {
    return (x ^ y ^ z);
  };

  const I = function (x, y, z) {
    return (y ^ (x | (~z)));
  };

  const FF = function (a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const GG = function (a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const HH = function (a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const II = function (a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  };

  const ConvertToWordArray = function (string) {
    let lWordCount;
    let lMessageLength = string.length;
    let lNumberOfWords_temp1 = lMessageLength + 8;
    let lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
    let lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
    let lWordArray = Array(lNumberOfWords - 1);
    let lBytePosition = 0;
    let lByteCount = 0;
    while (lByteCount < lMessageLength) {
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
      lByteCount++;
    }
    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
    lBytePosition = (lByteCount % 4) * 8;
    lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
    lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
    lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
    return lWordArray;
  };

  const WordToHex = function (lValue) {
    let WordToHexValue = "",
      WordToHexValue_temp = "",
      lByte, lCount;
    for (lCount = 0; lCount <= 3; lCount++) {
      lByte = (lValue >>> (lCount * 8)) & 255;
      WordToHexValue_temp = "0" + lByte.toString(16);
      WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
    }
    return WordToHexValue;
  };

  const Utf8Encode = function (string) {
    string = string.replace(/\r\n/g, "\n");
    let utftext = "";

    for (let n = 0; n < string.length; n++) {

      let c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }

    }

    return utftext;
  };

  let x = [];
  let k, AA, BB, CC, DD, a, b, c, d;
  let S11 = 7,
    S12 = 12,
    S13 = 17,
    S14 = 22;
  let S21 = 5,
    S22 = 9,
    S23 = 14,
    S24 = 20;
  let S31 = 4,
    S32 = 11,
    S33 = 16,
    S34 = 23;
  let S41 = 6,
    S42 = 10,
    S43 = 15,
    S44 = 21;

  string = Utf8Encode(string);

  x = ConvertToWordArray(string);

  a = 0x67452301;
  b = 0xEFCDAB89;
  c = 0x98BADCFE;
  d = 0x10325476;

  for (k = 0; k < x.length; k += 16) {
    AA = a;
    BB = b;
    CC = c;
    DD = d;
    a = FF(a, b, c, d, x[k], S11, 0xD76AA478);
    d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
    c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
    b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
    a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
    d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
    c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
    b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
    a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
    d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
    c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
    b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
    a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
    d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
    c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
    b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
    a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
    d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
    c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
    b = GG(b, c, d, a, x[k], S24, 0xE9B6C7AA);
    a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
    d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
    c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
    b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
    a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
    d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
    c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
    b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
    a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
    d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
    c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
    b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
    a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
    d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
    c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
    b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
    a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
    d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
    c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
    b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
    a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
    d = HH(d, a, b, c, x[k], S32, 0xEAA127FA);
    c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
    b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
    a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
    d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
    c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
    b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
    a = II(a, b, c, d, x[k], S41, 0xF4292244);
    d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
    c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
    b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
    a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
    d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
    c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
    b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
    a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
    d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
    c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
    b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
    a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
    d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
    c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
    b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
    a = AddUnsigned(a, AA);
    b = AddUnsigned(b, BB);
    c = AddUnsigned(c, CC);
    d = AddUnsigned(d, DD);
  }

  let temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

  return temp.toLowerCase();
};


/* Sha256 Hashing, from : HackerRank.com - https://www.bing.com/search?q=sha256+javascript&form=OPRTSD&pc=OPER */
const SHA256 = function (str) {
  let hexcase = 0; /* hex output format. 0 - lowercase; 1 - uppercase    */
  let b64pad = ""; /* base-64 pad character. "=" for strict RFC compliance   */

  /*
  * These are the functions you'll usually want to call
  * They take string arguments and return either hex or base-64 encoded strings
  */
  function hex_sha256(s) {
    return rstr2hex(rstr_sha256(str2rstr_utf8(s)));
  }

  function b64_sha256(s) {
    return rstr2b64(rstr_sha256(str2rstr_utf8(s)));
  }

  function any_sha256(s, e) {
    return rstr2any(rstr_sha256(str2rstr_utf8(s)), e);
  }

  function hex_hmac_sha256(k, d) {
    return rstr2hex(rstr_hmac_sha256(str2rstr_utf8(k), str2rstr_utf8(d)));
  }

  function b64_hmac_sha256(k, d) {
    return rstr2b64(rstr_hmac_sha256(str2rstr_utf8(k), str2rstr_utf8(d)));
  }

  function any_hmac_sha256(k, d, e) {
    return rstr2any(rstr_hmac_sha256(str2rstr_utf8(k), str2rstr_utf8(d)), e);
  }

  /*
  * Perform a simple self-test to see if the VM is working
  */
  function sha256_vm_test() {
    return hex_sha256("abc").toLowerCase() ===
      "ba7816bf8f01cfea414140de5dae2223b00361a396177a9cb410ff61f20015ad";
  }

  /*
  * Calculate the sha256 of a raw string
  */
  function rstr_sha256(s) {
    return binb2rstr(binb_sha256(rstr2binb(s), s.length * 8));
  }

  /*
  * Calculate the HMAC-sha256 of a key and some data (raw strings)
  */
  function rstr_hmac_sha256(key, data) {
    let bkey = rstr2binb(key);
    if (bkey.length > 16) bkey = binb_sha256(bkey, key.length * 8);

    let ipad = Array(16),
      opad = Array(16);
    for (let i = 0; i < 16; i++) {
      ipad[i] = bkey[i] ^ 0x36363636;
      opad[i] = bkey[i] ^ 0x5C5C5C5C;
    }

    let hash = binb_sha256(ipad.concat(rstr2binb(data)), 512 + data.length * 8);
    return binb2rstr(binb_sha256(opad.concat(hash), 512 + 256));
  }

  /*
  * Convert a raw string to a hex string
  */
  function rstr2hex(input) {
    /*
    try {
      hexcase // jshint ignore:line
    } catch (e) {
      hexcase = 0;
    }
    */
    if (hexcase) hexcase = 0;

    let hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
    let output = "";
    let x;
    for (let i = 0; i < input.length; i++) {
      x = input.charCodeAt(i);
      output += hex_tab.charAt((x >>> 4) & 0x0F) + hex_tab.charAt(x & 0x0F);
    }
    return output;
  }

  /*
  * Convert a raw string to a base-64 string
  */
  function rstr2b64(input) {
    /*
      try {
        b64pad // jshint ignore:line
      } catch (e) {
        b64pad = "";
      }
      */
    if (b64pad) b64pad = "";

    let tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    let output = "";
    let len = input.length;
    for (let i = 0; i < len; i += 3) {
      let triplet = (input.charCodeAt(i) << 16) |
        (i + 1 < len ? input.charCodeAt(i + 1) << 8 : 0) |
        (i + 2 < len ? input.charCodeAt(i + 2) : 0);
      for (let j = 0; j < 4; j++) {
        if (i * 8 + j * 6 > input.length * 8) output += b64pad;
        else output += tab.charAt((triplet >>> 6 * (3 - j)) & 0x3F);
      }
    }
    return output;
  }

  /*
  * Convert a raw string to an arbitrary string encoding
  */
  function rstr2any(input, encoding) {
    let divisor = encoding.length;
    let remainders = [];
    let i, q, x, quotient;

    /* Convert to an array of 16-bit big-endian values, forming the dividend */
    let dividend = Array(Math.ceil(input.length / 2));
    for (i = 0; i < dividend.length; i++) {
      dividend[i] = (input.charCodeAt(i * 2) << 8) | input.charCodeAt(i * 2 + 1);
    }

    /*
    * Repeatedly perform a long division. The binary array forms the dividend,
    * the length of the encoding is the divisor. Once computed, the quotient
    * forms the dividend for the next step. We stop when the dividend is zero.
    * All remainders are stored for later use.
    */
    while (dividend.length > 0) {
      quotient = [];
      x = 0;
      for (i = 0; i < dividend.length; i++) {
        x = (x << 16) + dividend[i];
        q = Math.floor(x / divisor);
        x -= q * divisor;
        if (quotient.length > 0 || q > 0)
          quotient[quotient.length] = q;
      }
      remainders[remainders.length] = x;
      dividend = quotient;
    }

    /* Convert the remainders to the output string */
    let output = "";
    for (i = remainders.length - 1; i >= 0; i--)
      output += encoding.charAt(remainders[i]);

    /* Append leading zero equivalents */
    let full_length = Math.ceil(input.length * 8 /
      (Math.log(encoding.length) / Math.log(2)));
    for (i = output.length; i < full_length; i++)
      output = encoding[0] + output;

    return output;
  }

  /*
  * Encode a string as utf-8.
  * For efficiency, this assumes the input is valid utf-16.
  */
  function str2rstr_utf8(input) {
    let output = "";
    let i = -1;
    let x, y;

    while (++i < input.length) {
      /* Decode utf-16 surrogate pairs */
      x = input.charCodeAt(i);
      y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
      if (0xD800 <= x && x <= 0xDBFF && 0xDC00 <= y && y <= 0xDFFF) {
        x = 0x10000 + ((x & 0x03FF) << 10) + (y & 0x03FF);
        i++;
      }

      /* Encode output as utf-8 */
      if (x <= 0x7F)
        output += String.fromCharCode(x);
      else if (x <= 0x7FF)
        output += String.fromCharCode(0xC0 | ((x >>> 6) & 0x1F),
          0x80 | (x & 0x3F));
      else if (x <= 0xFFFF)
        output += String.fromCharCode(0xE0 | ((x >>> 12) & 0x0F),
          0x80 | ((x >>> 6) & 0x3F),
          0x80 | (x & 0x3F));
      else if (x <= 0x1FFFFF)
        output += String.fromCharCode(0xF0 | ((x >>> 18) & 0x07),
          0x80 | ((x >>> 12) & 0x3F),
          0x80 | ((x >>> 6) & 0x3F),
          0x80 | (x & 0x3F));
    }
    return output;
  }

  /*
  * Encode a string as utf-16
  */
  function str2rstr_utf16le(input) {
    let output = "";
    for (let i = 0; i < input.length; i++)
      output += String.fromCharCode(input.charCodeAt(i) & 0xFF,
        (input.charCodeAt(i) >>> 8) & 0xFF);
    return output;
  }

  function str2rstr_utf16be(input) {
    let output = "";
    for (let i = 0; i < input.length; i++)
      output += String.fromCharCode((input.charCodeAt(i) >>> 8) & 0xFF,
        input.charCodeAt(i) & 0xFF);
    return output;
  }

  /*
  * Convert a raw string to an array of big-endian words
  * Characters >255 have their high-byte silently ignored.
  */
  function rstr2binb(input) {
    let output = Array(input.length >> 2);
    for (let i = 0; i < output.length; i++)
      output[i] = 0;
    for (let i = 0; i < input.length * 8; i += 8)
      output[i >> 5] |= (input.charCodeAt(i / 8) & 0xFF) << (24 - i % 32);
    return output;
  }

  /*
  * Convert an array of big-endian words to a string
  */
  function binb2rstr(input) {
    let output = "";
    for (let i = 0; i < input.length * 32; i += 8)
      output += String.fromCharCode((input[i >> 5] >>> (24 - i % 32)) & 0xFF);
    return output;
  }

  /*
  * Main sha256 function, with its support functions
  */
  function sha256_S(X, n) {
    return (X >>> n) | (X << (32 - n));
  }

  function sha256_R(X, n) {
    return (X >>> n);
  }

  function sha256_Ch(x, y, z) {
    return ((x & y) ^ ((~x) & z));
  }

  function sha256_Maj(x, y, z) {
    return ((x & y) ^ (x & z) ^ (y & z));
  }

  function sha256_Sigma0256(x) {
    return (sha256_S(x, 2) ^ sha256_S(x, 13) ^ sha256_S(x, 22));
  }

  function sha256_Sigma1256(x) {
    return (sha256_S(x, 6) ^ sha256_S(x, 11) ^ sha256_S(x, 25));
  }

  function sha256_Gamma0256(x) {
    return (sha256_S(x, 7) ^ sha256_S(x, 18) ^ sha256_R(x, 3));
  }

  function sha256_Gamma1256(x) {
    return (sha256_S(x, 17) ^ sha256_S(x, 19) ^ sha256_R(x, 10));
  }

  function sha256_Sigma0512(x) {
    return (sha256_S(x, 28) ^ sha256_S(x, 34) ^ sha256_S(x, 39));
  }

  function sha256_Sigma1512(x) {
    return (sha256_S(x, 14) ^ sha256_S(x, 18) ^ sha256_S(x, 41));
  }

  function sha256_Gamma0512(x) {
    return (sha256_S(x, 1) ^ sha256_S(x, 8) ^ sha256_R(x, 7));
  }

  function sha256_Gamma1512(x) {
    return (sha256_S(x, 19) ^ sha256_S(x, 61) ^ sha256_R(x, 6));
  }

  const sha256_K = [1116352408, 1899447441, -1245643825, -373957723, 961987163, 1508970993, -1841331548, -1424204075, -670586216, 310598401, 607225278, 1426881987,
    1925078388, -2132889090, -1680079193, -1046744716, -459576895, -272742522,
    264347078, 604807628, 770255983, 1249150122, 1555081692, 1996064986, -1740746414, -1473132947, -1341970488, -1084653625, -958395405, -710438585,
    113926993, 338241895, 666307205, 773529912, 1294757372, 1396182291,
    1695183700, 1986661051, -2117940946, -1838011259, -1564481375, -1474664885, -1035236496, -949202525, -778901479, -694614492, -200395387, 275423344,
    430227734, 506948616, 659060556, 883997877, 958139571, 1322822218,
    1537002063, 1747873779, 1955562222, 2024104815, -2067236844, -1933114872, -1866530822, -1538233109, -1090935817, -965641998
  ];

  function binb_sha256(m, l) {
    var HASH = [1779033703, -1150833019, 1013904242, -1521486534,
      1359893119, -1694144372, 528734635, 1541459225
    ];
    var W = new Array(64);
    var a, b, c, d, e, f, g, h;
    var i, j, T1, T2;

    /* append padding */
    m[l >> 5] |= 0x80 << (24 - l % 32);
    m[((l + 64 >> 9) << 4) + 15] = l;

    for (i = 0; i < m.length; i += 16) {
      a = HASH[0];
      b = HASH[1];
      c = HASH[2];
      d = HASH[3];
      e = HASH[4];
      f = HASH[5];
      g = HASH[6];
      h = HASH[7];

      for (j = 0; j < 64; j++) {
        if (j < 16) W[j] = m[j + i];
        else W[j] = safe_add(safe_add(safe_add(sha256_Gamma1256(W[j - 2]), W[j - 7]),
          sha256_Gamma0256(W[j - 15])), W[j - 16]);

        T1 = safe_add(safe_add(safe_add(safe_add(h, sha256_Sigma1256(e)), sha256_Ch(e, f, g)),
          sha256_K[j]), W[j]);
        T2 = safe_add(sha256_Sigma0256(a), sha256_Maj(a, b, c));
        h = g;
        g = f;
        f = e;
        e = safe_add(d, T1);
        d = c;
        c = b;
        b = a;
        a = safe_add(T1, T2);
      }

      HASH[0] = safe_add(a, HASH[0]);
      HASH[1] = safe_add(b, HASH[1]);
      HASH[2] = safe_add(c, HASH[2]);
      HASH[3] = safe_add(d, HASH[3]);
      HASH[4] = safe_add(e, HASH[4]);
      HASH[5] = safe_add(f, HASH[5]);
      HASH[6] = safe_add(g, HASH[6]);
      HASH[7] = safe_add(h, HASH[7]);
    }
    return HASH;
  }

  function safe_add(x, y) {
    var lsw = (x & 0xFFFF) + (y & 0xFFFF);
    var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
  }

  return hex_sha256(str);
}

/*
 *  Secure Hash Algorithm (SHA512)
 *  http://www.happycode.info/
 */

function SHA512(str) {
  function int64(msint_32, lsint_32) {
    this.highOrder = msint_32;
    this.lowOrder = lsint_32;
  }

  var H = [new int64(0x6a09e667, 0xf3bcc908), new int64(0xbb67ae85, 0x84caa73b),
  new int64(0x3c6ef372, 0xfe94f82b), new int64(0xa54ff53a, 0x5f1d36f1),
  new int64(0x510e527f, 0xade682d1), new int64(0x9b05688c, 0x2b3e6c1f),
  new int64(0x1f83d9ab, 0xfb41bd6b), new int64(0x5be0cd19, 0x137e2179)
  ];

  var K = [new int64(0x428a2f98, 0xd728ae22), new int64(0x71374491, 0x23ef65cd),
  new int64(0xb5c0fbcf, 0xec4d3b2f), new int64(0xe9b5dba5, 0x8189dbbc),
  new int64(0x3956c25b, 0xf348b538), new int64(0x59f111f1, 0xb605d019),
  new int64(0x923f82a4, 0xaf194f9b), new int64(0xab1c5ed5, 0xda6d8118),
  new int64(0xd807aa98, 0xa3030242), new int64(0x12835b01, 0x45706fbe),
  new int64(0x243185be, 0x4ee4b28c), new int64(0x550c7dc3, 0xd5ffb4e2),
  new int64(0x72be5d74, 0xf27b896f), new int64(0x80deb1fe, 0x3b1696b1),
  new int64(0x9bdc06a7, 0x25c71235), new int64(0xc19bf174, 0xcf692694),
  new int64(0xe49b69c1, 0x9ef14ad2), new int64(0xefbe4786, 0x384f25e3),
  new int64(0x0fc19dc6, 0x8b8cd5b5), new int64(0x240ca1cc, 0x77ac9c65),
  new int64(0x2de92c6f, 0x592b0275), new int64(0x4a7484aa, 0x6ea6e483),
  new int64(0x5cb0a9dc, 0xbd41fbd4), new int64(0x76f988da, 0x831153b5),
  new int64(0x983e5152, 0xee66dfab), new int64(0xa831c66d, 0x2db43210),
  new int64(0xb00327c8, 0x98fb213f), new int64(0xbf597fc7, 0xbeef0ee4),
  new int64(0xc6e00bf3, 0x3da88fc2), new int64(0xd5a79147, 0x930aa725),
  new int64(0x06ca6351, 0xe003826f), new int64(0x14292967, 0x0a0e6e70),
  new int64(0x27b70a85, 0x46d22ffc), new int64(0x2e1b2138, 0x5c26c926),
  new int64(0x4d2c6dfc, 0x5ac42aed), new int64(0x53380d13, 0x9d95b3df),
  new int64(0x650a7354, 0x8baf63de), new int64(0x766a0abb, 0x3c77b2a8),
  new int64(0x81c2c92e, 0x47edaee6), new int64(0x92722c85, 0x1482353b),
  new int64(0xa2bfe8a1, 0x4cf10364), new int64(0xa81a664b, 0xbc423001),
  new int64(0xc24b8b70, 0xd0f89791), new int64(0xc76c51a3, 0x0654be30),
  new int64(0xd192e819, 0xd6ef5218), new int64(0xd6990624, 0x5565a910),
  new int64(0xf40e3585, 0x5771202a), new int64(0x106aa070, 0x32bbd1b8),
  new int64(0x19a4c116, 0xb8d2d0c8), new int64(0x1e376c08, 0x5141ab53),
  new int64(0x2748774c, 0xdf8eeb99), new int64(0x34b0bcb5, 0xe19b48a8),
  new int64(0x391c0cb3, 0xc5c95a63), new int64(0x4ed8aa4a, 0xe3418acb),
  new int64(0x5b9cca4f, 0x7763e373), new int64(0x682e6ff3, 0xd6b2b8a3),
  new int64(0x748f82ee, 0x5defb2fc), new int64(0x78a5636f, 0x43172f60),
  new int64(0x84c87814, 0xa1f0ab72), new int64(0x8cc70208, 0x1a6439ec),
  new int64(0x90befffa, 0x23631e28), new int64(0xa4506ceb, 0xde82bde9),
  new int64(0xbef9a3f7, 0xb2c67915), new int64(0xc67178f2, 0xe372532b),
  new int64(0xca273ece, 0xea26619c), new int64(0xd186b8c7, 0x21c0c207),
  new int64(0xeada7dd6, 0xcde0eb1e), new int64(0xf57d4f7f, 0xee6ed178),
  new int64(0x06f067aa, 0x72176fba), new int64(0x0a637dc5, 0xa2c898a6),
  new int64(0x113f9804, 0xbef90dae), new int64(0x1b710b35, 0x131c471b),
  new int64(0x28db77f5, 0x23047d84), new int64(0x32caab7b, 0x40c72493),
  new int64(0x3c9ebe0a, 0x15c9bebc), new int64(0x431d67c4, 0x9c100d4c),
  new int64(0x4cc5d4be, 0xcb3e42b6), new int64(0x597f299c, 0xfc657e2a),
  new int64(0x5fcb6fab, 0x3ad6faec), new int64(0x6c44198c, 0x4a475817)
  ];

  var W = new Array(64);
  var a, b, c, d, e, f, g, h/*, i, j*/;
  var T1, T2;
  var charsize = 8;

  function utf8_encode(str) {
    return unescape(encodeURI(str));
  }

  function str2binb(str) {
    var bin = [];
    var mask = (1 << charsize) - 1;
    var len = str.length * charsize;

    for (let i = 0; i < len; i += charsize) {
      bin[i >> 5] |= (str.charCodeAt(i / charsize) & mask) << (32 - charsize - (i % 32));
    }

    return bin;
  }

  function binb2hex(binarray) {
    var hex_tab = "0123456789abcdef";
    var str = "";
    var length = binarray.length * 4;
    var srcByte;

    for (let i = 0; i < length; i += 1) {
      srcByte = binarray[i >> 2] >> ((3 - (i % 4)) * 8);
      str += hex_tab.charAt((srcByte >> 4) & 0xF) + hex_tab.charAt(srcByte & 0xF);
    }

    return str;
  }

  function safe_add_2(x, y) {
    var lsw, msw, lowOrder, highOrder;

    lsw = (x.lowOrder & 0xFFFF) + (y.lowOrder & 0xFFFF);
    msw = (x.lowOrder >>> 16) + (y.lowOrder >>> 16) + (lsw >>> 16);
    lowOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

    lsw = (x.highOrder & 0xFFFF) + (y.highOrder & 0xFFFF) + (msw >>> 16);
    msw = (x.highOrder >>> 16) + (y.highOrder >>> 16) + (lsw >>> 16);
    highOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

    return new int64(highOrder, lowOrder);
  }

  function safe_add_4(a, b, c, d) {
    var lsw, msw, lowOrder, highOrder;

    lsw = (a.lowOrder & 0xFFFF) + (b.lowOrder & 0xFFFF) + (c.lowOrder & 0xFFFF) + (d.lowOrder & 0xFFFF);
    msw = (a.lowOrder >>> 16) + (b.lowOrder >>> 16) + (c.lowOrder >>> 16) + (d.lowOrder >>> 16) + (lsw >>> 16);
    lowOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

    lsw = (a.highOrder & 0xFFFF) + (b.highOrder & 0xFFFF) + (c.highOrder & 0xFFFF) + (d.highOrder & 0xFFFF) + (msw >>> 16);
    msw = (a.highOrder >>> 16) + (b.highOrder >>> 16) + (c.highOrder >>> 16) + (d.highOrder >>> 16) + (lsw >>> 16);
    highOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

    return new int64(highOrder, lowOrder);
  }

  function safe_add_5(a, b, c, d, e) {
    var lsw, msw, lowOrder, highOrder;

    lsw = (a.lowOrder & 0xFFFF) + (b.lowOrder & 0xFFFF) + (c.lowOrder & 0xFFFF) + (d.lowOrder & 0xFFFF) + (e.lowOrder & 0xFFFF);
    msw = (a.lowOrder >>> 16) + (b.lowOrder >>> 16) + (c.lowOrder >>> 16) + (d.lowOrder >>> 16) + (e.lowOrder >>> 16) + (lsw >>> 16);
    lowOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

    lsw = (a.highOrder & 0xFFFF) + (b.highOrder & 0xFFFF) + (c.highOrder & 0xFFFF) + (d.highOrder & 0xFFFF) + (e.highOrder & 0xFFFF) + (msw >>> 16);
    msw = (a.highOrder >>> 16) + (b.highOrder >>> 16) + (c.highOrder >>> 16) + (d.highOrder >>> 16) + (e.highOrder >>> 16) + (lsw >>> 16);
    highOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

    return new int64(highOrder, lowOrder);
  }

  function maj(x, y, z) {
    return new int64(
      (x.highOrder & y.highOrder) ^ (x.highOrder & z.highOrder) ^ (y.highOrder & z.highOrder),
      (x.lowOrder & y.lowOrder) ^ (x.lowOrder & z.lowOrder) ^ (y.lowOrder & z.lowOrder)
    );
  }

  function ch(x, y, z) {
    return new int64(
      (x.highOrder & y.highOrder) ^ (~x.highOrder & z.highOrder),
      (x.lowOrder & y.lowOrder) ^ (~x.lowOrder & z.lowOrder)
    );
  }

  function rotr(x, n) {
    if (n <= 32) {
      return new int64(
        (x.highOrder >>> n) | (x.lowOrder << (32 - n)),
        (x.lowOrder >>> n) | (x.highOrder << (32 - n))
      );
    } else {
      return new int64(
        (x.lowOrder >>> n) | (x.highOrder << (32 - n)),
        (x.highOrder >>> n) | (x.lowOrder << (32 - n))
      );
    }
  }

  function sigma0(x) {
    var rotr28 = rotr(x, 28);
    var rotr34 = rotr(x, 34);
    var rotr39 = rotr(x, 39);

    return new int64(
      rotr28.highOrder ^ rotr34.highOrder ^ rotr39.highOrder,
      rotr28.lowOrder ^ rotr34.lowOrder ^ rotr39.lowOrder
    );
  }

  function sigma1(x) {
    var rotr14 = rotr(x, 14);
    var rotr18 = rotr(x, 18);
    var rotr41 = rotr(x, 41);

    return new int64(
      rotr14.highOrder ^ rotr18.highOrder ^ rotr41.highOrder,
      rotr14.lowOrder ^ rotr18.lowOrder ^ rotr41.lowOrder
    );
  }

  function gamma0(x) {
    var rotr1 = rotr(x, 1),
      rotr8 = rotr(x, 8),
      shr7 = shr(x, 7);

    return new int64(
      rotr1.highOrder ^ rotr8.highOrder ^ shr7.highOrder,
      rotr1.lowOrder ^ rotr8.lowOrder ^ shr7.lowOrder
    );
  }

  function gamma1(x) {
    var rotr19 = rotr(x, 19);
    var rotr61 = rotr(x, 61);
    var shr6 = shr(x, 6);

    return new int64(
      rotr19.highOrder ^ rotr61.highOrder ^ shr6.highOrder,
      rotr19.lowOrder ^ rotr61.lowOrder ^ shr6.lowOrder
    );
  }

  function shr(x, n) {
    if (n <= 32) {
      return new int64(
        x.highOrder >>> n,
        x.lowOrder >>> n | (x.highOrder << (32 - n))
      );
    } else {
      return new int64(
        0,
        x.highOrder << (32 - n)
      );
    }
  }

  str = utf8_encode(str);
  let strlen = str.length * charsize;
  str = str2binb(str);

  str[strlen >> 5] |= 0x80 << (24 - strlen % 32);
  str[(((strlen + 128) >> 10) << 5) + 31] = strlen;

  for (let i = 0; i < str.length; i += 32) {
    a = H[0];
    b = H[1];
    c = H[2];
    d = H[3];
    e = H[4];
    f = H[5];
    g = H[6];
    h = H[7];

    for (let j = 0; j < 80; j++) {
      if (j < 16) {
        W[j] = new int64(str[j * 2 + i], str[j * 2 + i + 1]);
      } else {
        W[j] = safe_add_4(gamma1(W[j - 2]), W[j - 7], gamma0(W[j - 15]), W[j - 16]);
      }

      T1 = safe_add_5(h, sigma1(e), ch(e, f, g), K[j], W[j]);
      T2 = safe_add_2(sigma0(a), maj(a, b, c));
      h = g;
      g = f;
      f = e;
      e = safe_add_2(d, T1);
      d = c;
      c = b;
      b = a;
      a = safe_add_2(T1, T2);
    }

    H[0] = safe_add_2(a, H[0]);
    H[1] = safe_add_2(b, H[1]);
    H[2] = safe_add_2(c, H[2]);
    H[3] = safe_add_2(d, H[3]);
    H[4] = safe_add_2(e, H[4]);
    H[5] = safe_add_2(f, H[5]);
    H[6] = safe_add_2(g, H[6]);
    H[7] = safe_add_2(h, H[7]);
  }

  let binarray = [];
  for (let i = 0; i < H.length; i++) {
    binarray.push(H[i].highOrder);
    binarray.push(H[i].lowOrder);
  }
  return binb2hex(binarray);
}

DataView.prototype.setString = function (offset, string, max) {
  let stringView = new StringView(string);
  let length = stringView.buffer.byteLength;
  let i = 0;

  if (max && max < length) {
    length = max;
  }

  for (; i < length; i++) {
    this.setUint8(offset + i, stringView.rawData[i]);
  }

};

type OutputTypeEnum = 0 | 1 | 2 | 3 | 4;
export const OutputType = {
  OUTPUT_TYPE_DATA: 0,
  OUTPUT_TYPE_REPORT_80: 1,
  OUTPUT_TYPE_REPORT_A4: 2,
  OUTPUT_TYPE_CSV: 3,
  OUTPUT_TYPE_BLOB: 4
};

type ProcessTypeEnum = 0 | 1;
export const ProcessType = {
  PROCESS_TYPE_ASYNC: 0,
  PROCESS_TYPE_SYNC: 1
};

let jobCount = 1;

export class User {
  token: string;
  company: string;
  constructor(token: string, company: string) {
    this.token = token;
    this.company = company;
  }

}

export class JobSection {
  code: string;
  data: string;
  dataView: StringViewl
  length: number;

  constructor(code: string, data: string) {
    this.code = code;
    this.data = data;
    this.dataView = new StringView(this.data);
    this.length = this.dataView.buffer.byteLength;
  }
}

export class BlobResponse {
  rawData: DataView;
  size: Uint32;
  data: DataView;

  constructor(raw: ArrayBuffer) {
    this.rawData = new DataView(raw);
    this.size = this.rawData.getUint32(0);
    if (this.size) {
      this.data = new DataView(raw, 4, this.size);
    }
  }
}

export class ReportBlob {
  rawData: ArrayBuffer;
  data: DataView;
  blob: Blob;

  constructor(raw: ArrayBuffer) {
    this.rawData = raw;
    this.data = new DataView(raw);
    this.blob = new Blob([this.data], { type: "octet/stream" });
  }
}

export class JobResponse {
  rawData: DataView;
  size: Uint32;
  hash: string;
  success: number;
  type: ProcessTypeEnum;
  output: OutputTypeEnum;
  errorCode: Uint32;
  errorMessage: string;
  data: string;

  constructor(raw: ArrayBuffer) {

    let offset = 0;
    this.rawData = new DataView(raw);
    let actualSize = this.rawData.buffer.byteLength;
    if (actualSize === 0) {
      return;
    }
    this.size = this.rawData.getUint32(offset);
    offset += 4;
    let hash = new StringView(this.rawData.buffer, "UTF-8", offset, 40);
    this.hash = hash.toString();
    offset += 40;
    this.success = this.rawData.getUint8(offset);
    offset++;
    let type = this.rawData.getUint8(offset);
    if (type === 0) {
      this.type = ProcessType.PROCESS_TYPE_ASYNC;
    } else {
      this.type = ProcessType.PROCESS_TYPE_SYNC;
    }
    offset++;
    let output = this.rawData.getUint8(offset);
    switch (output) {
      case 0:
        this.output = OutputType.OUTPUT_TYPE_DATA;
        break;
      case 1:
        this.output = OutputType.OUTPUT_TYPE_REPORT_80;
        break;
      case 2:
        this.output = OutputType.OUTPUT_TYPE_REPORT_A4;
        break;
      case 3:
        this.output = OutputType.OUTPUT_TYPE_CSV;
        break;
      case 4:
      default:
        this.output = OutputType.OUTPUT_TYPE_BLOB;
        break;
    }
    offset++;
    this.errorCode = this.rawData.getUint32(offset);
    offset += 4;
    let errorMessage = new StringView(this.rawData.buffer, "UTF-8", offset, 1024);
    this.errorMessage = errorMessage.toString();
    let end = this.errorMessage.indexOf(String.fromCharCode(0));
    this.errorMessage = this.errorMessage.substring(0, end);
    offset += 1024;
    let data = actualSize > offset ? new StringView(this.rawData.buffer, "UTF-8", offset) : "";
    this.data = data.toString();
  }
}

export class Job {
  user: User;
  token: string;
  companyCode: string;
  script: string;
  outputType: OutputTypeEnum;
  processType: ProcessTypeEnum;
  hash: string;
  version: number;
  strict: boolean;
  cotVersionCode: string;
  cotVersionName: string;

  constructor(user: User, script: string, outputType: OutputTypeEnum, processType: ProcessTypeEnum, strict: boolean) {
    this.user = user;
    this.token = this.user.token;
    this.companyCode = this.user.company;
    this.script = script;
    this.outputType = outputType;
    this.processType = processType;
    this.hash = this.generateHash();
    this.version = 2;
    this.strict = strict;
    this.cotVersionCode = ""
    this.cotVersionName = "";
    this.setPosVersion();
  }

  input: string = "";
  sections: Array<JobSection> = [];

  tableColumns: Array<string> = [];
  tableValues: Array<string> = [];

  oThat = this;

  setPosVersion = function () {
    if (process) {
      this.cotVersionCode = process?.env?.REACT_APP_VERSION;
      this.cotVersionName = "REACT_APP_VERSION";
    }
  }

  setInput = function (data: string) {
    this.input = data;
  };

  setSection = function (code: string, data: string) {
    if (code.length > 0) {
      this.sections.push(new JobSection(code, data));
      this.input += data;
    }
  };

  setColumn = function (name: string, data: string) {
    this.tableColumns.push(name);
    this.tableValues.push(data);
  };

  generateHash = function (): string {
    let now = new Date();
    let temp = now.toISOString() + "." + this.oThat.token + jobCount++;
    return MD5(temp);
  };

  setHash = function (_hash: string) {
    this.hash = _hash;
  };

  getRawData = function (): DataView {
    if (this.tableColumns.length > 0) {
      this.input = this.tableColumns.join("\f") + "\r" + this.tableValues.join("\f");
    }

    let inputView = new StringView(this.input);
    let length = 32 /* token */ + 10 /* company code */ + 40 /* job hash */ + 1 /* output type */ +
      1 /* process type */ + 128 /* script name */ + 14 * (this.sections.length + 1) /* sections */ +
      inputView.buffer.byteLength;

    let binaryData = new DataView(new ArrayBuffer(length + 4));
    let i, offset = 0;


    binaryData.setInt32(0, length);
    offset += 4;

    binaryData.setString(offset, this.token, 32);
    offset += 32;
    binaryData.setString(offset, this.companyCode, 10);
    offset += 10;
    binaryData.setString(offset, this.hash, 40);
    offset += 40;
    binaryData.setUint8(offset, this.outputType);
    offset++;
    binaryData.setUint8(offset, this.processType);
    offset++;
    binaryData.setString(offset, this.script, 128);
    offset += 128;

    for (i = 0; i < this.sections.length; i++) {
      binaryData.setString(offset, this.sections[i].code, 10);
      offset += 10;
      binaryData.setInt32(offset, this.sections[i].length);
      offset += 4;
    }
    offset += 14;

    binaryData.setString(offset, this.input);
    //console.log("JOB getRawData out: ", binaryData);
    return binaryData;
  };

  getJsonData() {
    let raw = {};
    raw.version = this.version;
    raw.token = this.token;
    raw.companyCode = this.companyCode;
    raw.hash = this.hash;
    raw.scriptName = this.script;
    raw.outputType = this.outputType;
    raw.processType = this.processType;
    raw.humanId = 0;
    raw.cotVersionCode = this.cotVersionCode;
    raw.cotVersionName = this.cotVersionName;

    if (this.tableColumns.length > 0) {
      this.input = this.tableColumns.join("\f") + "\r" + this.tableValues.join("\f");
    }

    raw.input = [];
    if (this.sections.length > 0) {
      for (let i = 0, j = this.sections.length; i < j; i++) {
        raw.input.push({
          code: this.sections[i].code,
          value: this.sections[i].data
        })
      }
    }
    else {
      raw.input.push({ value: this.input });
    }

    //console.log("JOB getJSONData out: ", raw);
    return raw;
  };

  // Inspired by: https://codereview.stackexchange.com/a/37552
  static getByteLength(text: string): string {
    //console.log("JOB getByteLength in: ", text);
    let byteLen = 0;
    for (let i = 0; i < text.length; i++) {
      let c = text.charCodeAt(i);
      byteLen += c < (1 << 7) ? 1 :
        c < (1 << 11) ? 2 :
          c < (1 << 16) ? 3 :
            c < (1 << 21) ? 4 :
              c < (1 << 26) ? 5 :
                c < (1 << 31) ? 6 : Number.NaN;
    }
    //console.log("JOB getByteLength out: ", byteLen);
    return byteLen;
  };

  static encodeLength(text: string): string {
    let string: string = String(text);
    let length = Job.getByteLength(string);
    let bytes = [0, 0, 0, 0];
    const size = 256;
    bytes[0] = parseInt(length / (size * size * size), 10);
    length = length % (size * size * size);
    bytes[1] = parseInt(length / (size * size), 10);
    length = length % (size * size);
    bytes[2] = parseInt(length / size, 10);
    bytes[3] = length % size;
    //console.log("JOB encoded arr: ", bytes[0], bytes[1], bytes[2], bytes[3]);
    //console.log("JOB encodeLength: |", String.fromCharCode(bytes[0], bytes[1], bytes[2], bytes[3]), '|');
    return String.fromCharCode(bytes[0], bytes[1], bytes[2], bytes[3]);
  };

  static writeLengthAsString(text: string): string {
    let string: string = String(text);
    let length = Job.getByteLength(string);
    return length.toString();
  }

  send = function (url: string, successCallback: Function, errorCallback: Function) {
    //console.log("JOB SEND ##################");
    // let binaryData:DataView = this.getRawData();
    let data: Object = this.getJsonData();
    let that: Object = this;

    /*var saveData = (function () {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          return function (data, fileName) {
              var blob = new Blob([data], {type: "octet/stream"}),
                  url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
          };
      }());
      saveData(binaryData, "job.bin");*/

    let oAjax = new XMLHttpRequest();
    let oJob = this;
    oAjax.open("POST", url, true);
    oAjax.responseType = "arraybuffer";
    oAjax.onerror = function (oEvent) {
     if(errorCallback) errorCallback(oAjax.statusText, oAjax.status);
    };
    oAjax.onload = function (oEvent) {
      if (oAjax.status === 200) {
        if (oJob.outputType === OutputType.OUTPUT_TYPE_BLOB) {
          successCallback(new BlobResponse(oAjax.response));
        } else if (oJob.outputType === OutputType.OUTPUT_TYPE_REPORT_A4) {
          successCallback(new ReportBlob(oAjax.response));
        } else {
          successCallback(new JobResponse(oAjax.response));
        }
      } else {
        oAjax.onerror(oEvent);
      }
    };
    // oAjax.send(binaryData);
    let strData: string = JSON.stringify(data);
    let length: string;
    //console.log("OZZ", that)
    //console.log("OZZ", that.strict)
    if (that.strict) {
      //console.log("OZZ true")
      length = Job.encodeLength(strData);
    } else {
      //console.log("OZZ false")
      length = Job.writeLengthAsString(strData) + ';';
    }
    //console.log("JOB send out len: ", length);
    //console.log("JOB send out strData: ", strData);
    oAjax.send(length + strData);
  };
}

const hashLoginInformation = function (username: string, password: string): string {
  let data = username + password;
  let weird_salt = [];
  let i, j;
  let ws_index = 0;

  for (i = 0, j = data.length; i < j; i++) {
    weird_salt[ws_index] = data[i];
    ws_index++;
    if (ws_index === 16) {
      ws_index = 0;
    }
  }

  data = weird_salt.join("") + data;
  return SHA256(data);
};

///////////////////////////////////////////
export function hashLoginInformationTest(username, password) {
  return hashLoginInformation(username, password);
}
///////////////////////////////////////////

export function Login(username: string, password: string, companyCode: string, loginUrl: string, confirmation: string,
  successCallback: Function, errorCallback: Function) {
  let loginString = hashLoginInformation(username, password);
  let user = new User("", companyCode);
  let job = new Job(user, "login", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  //job.setInput(loginString);
  job.setInput(loginString + "\f" + confirmation);
  job.send(loginUrl, successCallback, errorCallback);
  //console.log('yuda params login ',username, password, companyCode, loginUrl, successCallback, errorCallback)
}

export function LoginWithPassword(username: string, password: string, loginUrl: string, successCallback: Function, errorCallback: Function) {
  let user = new User("", "");
  let job = new Job(user, "login", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  job.setInput(username + "\f" + password);
  job.send(loginUrl, successCallback, errorCallback);
};

// auth | confirmation | user_name | user_display | phone | email | psw1 | psw2
// USED auth|confirmation
// NOT USED = user_name|user_display|phone|email|psw1|psw2
// /cgi-bin/CotRenew
export function Get_User_For_Renew(username: string, password: string, companyCode: string, loginUrl: string, confirmation: string,
  successCallback: Function, errorCallback: Function) {
  //console.log("Get_User_For_Renew: ", username, password. companyCode, loginUrl, confirmation);
  let loginString = hashLoginInformation(username, password);
  //console.log("Get_User_For_Renew: loginString: ", loginString);
  let user = new User("", companyCode);
  let job = new Job(user, "get_user_for_renew", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  job.setInput(loginString + "\f" + confirmation + "\f\f\f\f\f\f");
  job.send(loginUrl, successCallback, errorCallback);
}

//// auth | confirmation | user_name | user_display | phone | email | psw1 | psw2
export function Renew(username: string, password: string, companyCode: string, loginUrl: string, confirmation: string,
  user_name: string, user_display: string, phone: string, email: string, psw1: string, psw2: string,
  successCallback: Function, errorCallback: Function) {
  let loginString = hashLoginInformation(username, password);
  let newLoginString1 = psw1; // hashLoginInformation(user_name, psw1);
  let newLoginString2 = psw2; //hashLoginInformation(user_name, psw2);
  let user = new User("", companyCode);
  let job = new Job(user, "renew", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  job.setInput(loginString + "\f" + confirmation + "\f" + user_name + "\f" + user_display + "\f" + phone + "\f" + email + "\f" + newLoginString1 + "\f" + newLoginString2);
  job.send(loginUrl, successCallback, errorCallback);
}

//// auth | confirmation | user_name | user_display | phone | email | psw1 | psw2
export function Resend(username: string, password: string, companyCode: string, loginUrl: string,  
  successCallback: Function, errorCallback: Function) {
  let loginString = hashLoginInformation(username, password);
  let user = new User("", companyCode);
  let job = new Job(user, "resend", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  job.setInput(loginString + "\f\f\f\f\f\f\f");
  job.send(loginUrl, successCallback, errorCallback);
}

export function RequestForget(username: string, companyCode: string, loginUrl: string,  
  successCallback: Function, errorCallback: Function) {
  let user = new User("", companyCode);
  let job = new Job(user, "request_forget", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  job.setInput(username + "\f");
  job.send(loginUrl, successCallback, errorCallback);
}

export function ValidateForget(username: string, confirmation: string, companyCode: string, loginUrl: string,  
  successCallback: Function, errorCallback: Function) {
  let user = new User("", companyCode);
  let job = new Job(user, "validate_forget", OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC, true);
  job.setInput(username + "\f" + confirmation);
  job.send(loginUrl, successCallback, errorCallback);
}

