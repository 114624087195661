/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import { Button, Input, Modal, message } from "antd";
import tel_icon from "../../skins/index/tel_icon.png";
import icon_tara from "../../skins/index/icon_tara.png";
import logo_autosoft from "../../skins/index/logo_autosoft.png";

// const recaptchaRef = React.createRef();

type Props = {
    companyCode: String,
    companyName: String,
    telephone: String,
    menuId: Number,
    companyData: Object,
    siteSettings: Object,
    getClassNameStyle: Function,
    getText: Function,
}

class FoodOrdersFooter extends Component<Props, State> {

    state = {}
    mobile = window.innerWidth <= 700 ? true : false;
    getText = this.props.getText;

    componentDidUpdate() {
        if (this.props.setCssValues){ 
            console.log("aaasss")
            this.props.setCssValues()}
    }

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    render() {
        const { siteSettings, telephone, companyName, menuId, getClassNameStyle, companyData, footerNavbar, changeScreen, ownerSave } = this.props;
        const isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        const isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        const isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        const isStyle6 = siteSettings.pDesktopCategoriesView == '6';
        const color = isStyle3 || isStyle4 || isStyle5 ? "white" : isStyle6 ? "black" : siteSettings.rFonts;
        const background = isStyle6 ? null : isStyle3 ? "#6d6e71" : isStyle4 ? "black" : isStyle5 ? "#fff5e9" : siteSettings.rTitle ? siteSettings.rTitle : '#404041';

        const footerCompNameStyle = {
            marginTop: this.mobile ? -67 : -47,
            marginRight: 31,
            marginLeft: -12,
        }

        const autosoftLinkStyle = {
            width: 150,
            marginTop: isStyle3 ? 20 : isStyle6 ? 46 : 50,
            float: "left",
            textAlign: "center",
        }

        const footerLogoStyle = isStyle3 ? {
            width: 150,
            marginTop: -40,
        } : isStyle6 ? {
            width: 200,
        } : {}


        const addressStyle = {
            height: 25,
            lineHeight: 1,
            fontSize: 18
        }

        let footerCompName = getClassNameStyle("logo_text", footerCompNameStyle, companyName);

        let footerLogo = siteSettings.pLogoHeader ?
            (<img src={this.imgUrl(siteSettings.pLogoHeader)} alt={'Logo'} title={companyName} className={"agala_footer_2"} style={footerLogoStyle} />)
            : (<div className={"agala_footer"}></div>);

        let telValue = (<a href={'tel:' + telephone}>
            <div id={"telephone_footer"} style={{ color }}>
                <b>{telephone}</b>
            </div>
        </a>);

        let telIcon = (<div id="tel_icon_footer"></div>)

        let autosoftLink = (<div style={autosoftLinkStyle} className="cotw_autosoft_link">
            <a href="https://www.autosoft.co.il/" target="_blank">
                <img style={{ width: 100, backgroundColor: isStyle4 || isStyle5 ? "white" : "auto" }} src={logo_autosoft} />
                <div style={{ color: isStyle6 ? "white" : color, fontSize: 8 }}>
                    {this.getText(14141)}
                </div>
            </a>
        </div>)

        // דני ביקש לבטל בתאריך 02.07.2023
        // מתועד במייל

        // if (this.mobile && isStyle4) {
        //     return ""
        // } else

        const percentN = n => this.mobile ? "100%" : n + "%"

        if (isStyle3 || isStyle4 || isStyle5 || isStyle6) {
            let fStyle = { display: "inline-block", verticalAlign: "text-top" }
            return (<div style={{ background, color, fontFamily: "FbTipograf" }}>
                <div style={{ ...fStyle, width: percentN(isStyle3 || isStyle6 ? 30 : 10) }}>
                    {!isStyle6 ?
                        <div style={{ ...fStyle, width: 150, marginRight: 20 }}>
                            <img src={this.imgUrl(siteSettings.pLogoHeader)} alt={'Logo'} title={companyName} style={footerLogoStyle} />
                        </div>
                        : ""}
                    {isStyle3 ?
                        <div style={{ ...fStyle, width: 150, marginRight: 50 }}>
                            <div style={addressStyle}>{companyData.address1}</div>
                            <div style={addressStyle}>{companyData.address2}</div>
                            <div style={addressStyle}>{companyData.address3}</div>
                            <div style={addressStyle}>
                                <img src={tel_icon} style={{ width: 20, height: 20 }} />
                                <a href={'tel:' + telephone}>
                                    <div style={{ ...fStyle, color, marginRight: 15 }}>
                                        {telephone}
                                    </div>
                                </a>
                            </div>
                        </div>
                        : ""}
                </div>
                <div style={{ ...fStyle, width: percentN(isStyle3 || isStyle6 ? 40 : 60), textAlign: "center", marginTop: 25 }}>
                    {footerNavbar.map((x, i) => {
                        let footerNavbarStyle = isStyle3 ? {
                            ...fStyle,
                            width: 90,
                            fontSize: 18,
                            lineHeight: 1,
                            cursor: "pointer",
                            color: x.screen == menuId ? "yellow" : "white",
                            borderRight: i > 0 ? "2px solid white" : "auto"
                        } : {
                            ...fStyle,
                            width: this.mobile ? 150 : 200,
                            fontSize: 22,
                            lineHeight: 1,
                            cursor: "pointer",
                            color: x.screen == menuId ? "#FFC300" : isStyle5 ? "#443325" : isStyle6 ? "black" : "white",
                            background: isStyle6 ? "white" : null,
                            marginRight: isStyle6 ? 20 : null,
                            borderRadius: isStyle6 ? 10 : null,
                        }
                        return (<div style={footerNavbarStyle} onClick={() => { changeScreen(x.screen) }}>{this.getText(x.text)}</div>)
                    })}
                </div>
                <div style={{ ...fStyle, width: percentN(30) }}>
                    {isStyle4 ? <img src={icon_tara} style={{ width: 100 }} /> : ""}
                    {isStyle6 && siteSettings.pCustomerSupportSMS ? <b style={{
                        position: "fixed",
                        bottom: 25,
                        left: 160,
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        cursor: "pointer",
                        background: "#9ACD32",
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                        paddingTop: 25,
                        fontSize: 30
                    }} onClick={() => {
                        this.setState({ modalHelp: true, modalHelpName: "", modalHelpMobile: "", modalHelpRemarks: "" })
                    }}>{this.getText(19904)}</b> : ""}
                    {autosoftLink}
                </div>
                <Modal
                    className="padding_0"
                    title={getClassNameStyle("title_block", { background: "#ddc268" }, this.getText(19907))}
                    zIndex={10000}
                    visible={this.state.modalHelp}
                    onCancel={() => { this.setState({ modalHelp: false }) }}
                    footer={[
                        <Button style={{ margin: 10, width: 140, height: 50, background: "#ddc268" }} onClick={() => {
                            let mobileRegexp = new RegExp(/^05\d([-]{0,1})\d{7}$/);
                            const { modalHelpName, modalHelpMobile, modalHelpRemarks } = this.state;
                            if (!modalHelpName) {
                                message.error(this.getText(19977))
                            } else if (!modalHelpMobile || !mobileRegexp.test(modalHelpMobile)) {
                                message.error(this.getText(19911))
                            } else if (!modalHelpRemarks) {
                                message.error(this.getText(19912))
                            } else {
                                let custData = this.getText(19979) + ": " + modalHelpName + ", "
                                    + this.getText(19980) + ": " + modalHelpMobile + ", "
                                    + (ownerSave ? this.getText(19981) + ": " + ownerSave : "")

                                let dataSend2 = "_custId\f_custName\f_custDestination\f_messageId\f_prm1\f_prm2\f_prm3\f_prm4\f_prm5\f_messageText\f_messageType\r"
                                    + "0\f\f"
                                    + siteSettings.pCustomerSupportSMS + "\f"
                                    + siteSettings.pCustomerSupportMessageId + "\f"
                                    + custData + "\f"
                                    + modalHelpRemarks + "\f\f\f\f\f2";
                                this.props.sendAPI("ac_insert_message", dataSend2, () => {
                                    this.setState({ modalHelp: false, modalHelpName: "", modalHelpMobile: "", modalHelpRemarks: "" }, () => {
                                        message.success(this.getText(19913))
                                    })
                                }, (e) => { console.error(e) })
                            }
                        }}><span className={"text_item"} style={{ color: "black" }}>{this.getText(19905)}</span></Button>,
                        <Button style={{ margin: 10, width: 140, height: 50, background: "#ddc268" }} onClick={() => {
                            this.setState({ modalHelp: false })
                        }}><span className={"text_item"} style={{ color: "black" }}>{this.getText(19906)}</span></Button>
                    ]
                    }
                    width={window.innerWidth > 600 ? 600 : '100%'}
                >
                    <Input style={{ margin: 10, width: window.innerWidth > 600 ? 580 : "90%" }} placeholder={this.getText(19978)} value={this.state.modalHelpName} onChange={e => { this.setState({ modalHelpName: e.target.value }) }} />
                    <Input style={{ margin: 10, width: window.innerWidth > 600 ? 580 : "90%" }} placeholder={this.getText(19908)} value={this.state.modalHelpMobile} onChange={e => { this.setState({ modalHelpMobile: e.target.value }) }} />
                    <Input.TextArea style={{ margin: 10, width: window.innerWidth > 600 ? 580 : "90%" }} rows={4} placeholder={this.getText(19909)} value={this.state.modalHelpRemarks} onChange={e => { this.setState({ modalHelpRemarks: e.target.value }) }} />

                    <h2 style={{ margin: 10, width: window.innerWidth > 600 ? 580 : "90%" }} >{siteSettings.pCustomerSupportRemarks?.split('\n').map(x => [x, <br />])}</h2>
                </Modal >
            </div >)
        } else {
            return (<div id="footer" style={{ background }}>
                <div style={this.mobile || menuId != 1 ? {} : { paddingLeft: "30%", paddingRight: 365 }}>
                    <div id="logo_footer">
                        {footerCompName}
                        {footerLogo}
                    </div>
                    {telValue}
                    {telIcon}
                    {autosoftLink}
                </div>
            </div>)
        }
    }
}

export default FoodOrdersFooter;