/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Modal } from "antd";

class FoodFormatMessage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // this.api = "get_value_virtual_shop_messages"
    }

    componentDidMount() {
        if (this.props.content) {
            this.innerHtml(this.props.content);
        } else {
            setTimeout(() => {
                this.innerHtml(this.props.content);
            }, 500);
        }
    }

    componentDidUpdate(p) {
        if (p.content !== this.props.content) {
            this.innerHtml(this.props.content);
        }
    }

    innerHtml = (data) => {
        let id = document.getElementById(this.props.idStaticElement ? this.props.idStaticElement : 'food-format-message');

        if (id) {
            id.innerHTML = data;
            if (window.innerWidth < 1000) this.widthImgToMobile()
        } else {
            setTimeout(() => { this.innerHtml(data) }, 1000);
        }
    }

    widthImgToMobile = () => {
        let mElement = document.getElementById(this.props.idStaticElement ? this.props.idStaticElement : 'food-format-message')
        let imgElements = mElement.getElementsByTagName('img');
        let eCount = imgElements.length;
        for (let x = 0; x < eCount; x++) {
            imgElements[x].style.width = "95%";
            imgElements[x].style.height = "auto";
        }
        console.log('mElement', mElement, imgElements.length)
    }

    render() {
        if (this.props.content) {
            if (this.props.idStaticElement) {
                return (<div id={this.props.idStaticElement} />)
            } else {

                return (<Modal footer={false} width={650} className={"padding_0"} visible={this.props.visible} onCancel={this.props.onCancel}>
                    <div id="food-format-message" />
                </Modal>)
            }
        } else {
            return ''
        }
    }

}
export default FoodFormatMessage;
