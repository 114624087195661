/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Select, InputNumber, Checkbox, Popconfirm, Button, Input } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ModalCreatePriceList from "../../components/ModalCreatePriceList";
import ModalCreatePrice from "../../components/ModalCreatePrice";
import { fixCurrentPage } from "../../utils/utils";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import ModalAdvanceSearch from "../../components/ModalAdvanceSearch";

const { Option, OptGroup } = Select;

const topHeight = 400; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class PricesTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "PRICES_BY_PRICELIST";
        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,

            filterActive: false,
            valueSearch: '',
        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[TableIDs.genericSelectorPrices] === undefined) {
            props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        }


        this.api = {
            get: "get_prices_table",
            create: "create_price",
            edit: "edit_price",
            delete: "delete_price",
            selector: "get_prices",
            modalPriceListGet: "Prices_List_v2",
        }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();

        //setTimeout(this.handleWindowSizeChange);
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10172);

        let curFilter = this.props.data[this.id].filters ? this.props.data[this.id].filters._filter : "";
        let prevFilter = prev.data[this.id].filters ? prev.data[this.id].filters._filter : "";
        if (prevFilter !== curFilter) {
            this.setState({ valueSearch: curFilter })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _filter: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_1',
            // filters
            _tagList: '',
            _isActive: '',
            _isWeightable: '',
            _isFixedPrice: '',
            _isForSale: '',
            _isDiscount: '',
            _saleAlert: 0,
            _supplierList: '',
            _isForInventory: '',
            _isSerialize: '',
            _isMatrixReverse: '',
            _connectedItems: '',
            _taxType: '',
            _searchColFilter: '1',
            _notAssociatedTag: '0',
            _notAssociatedSupp: '0',
            _rulerList: '',
            _notAssociatedRuler: '0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })

    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 7;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mId": col--;
            case "mPriceListId": col--;
            case "mPriceListName": col--;
            case "mItemCode": col--;
            case "mShortName": col--;
            case "mPriceVal": col--;
            case "mFixedProfit": break; // 7
            default:
                col = 0;
        }

        console.log("input paging: ", pagination, "sorter: ", sorter, "\ncol: ", col);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _filter: '',

            // forced sorting
            //sortTypeRaw: sorter.order,
            //sortColumnRaw: sorter.field,
        };

        console.log("request", request);

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        console.log("render: pagination: ",
            this.props.data[this.id].pagination,
            "\nfilters: ", this.props.data[this.id].filters)

        //let paging = this.props.data[this.id].pagination;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mId: y[0],
                mPriceListId: y[1],
                mPriceListName: y[2],
                mItemCode: y[3],
                mShortName: y[4],
                mPriceVal: y[5],
                mFixedProfit: y[6],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(10358),
                dataIndex: "mId",
                key: "mId",
                sorter: true,
                //sortOrder: paging.sortColumnRaw === 'mId' && paging.sortTypeRaw,
                width: '7%',
                render: (text, record) => text,
            }, {
                title: this.getText(10359),
                dataIndex: "mPriceListId",
                key: "mPriceListId",
                width: '9%',
                sorter: true,
                //sortOrder: paging.sortColumnRaw === 'mPriceListId' && paging.sortTypeRaw,
                render: (text, record) => text,
            }, {
                title: this.getText(10360),
                dataIndex: "mPriceListName",
                key: "mPriceListName",
                width: '20%',
                sorter: true,
                //sortOrder: paging.sortColumnRaw === 'mPriceListName' && paging.sortTypeRaw,
                render: (text, record) => text,
            }, {
                title: this.getText(10361),
                dataIndex: "mItemCode",
                key: "mItemCode",
                width: '12%',
                sorter: true,
                //sortOrder: paging.sortColumnRaw === 'mItemCode' && paging.sortTypeRaw,
                render: (text, record) => text,
            }, {
                title: this.getText(10362),
                dataIndex: "mShortName",
                key: "mShortName",
                width: '20%',
                sorter: true,
                //sortOrder: paging.sortColumnRaw === 'mShortName' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key && this.props.data[this.id].editing == 0) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mShortName: value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: this.getText(10363),
                dataIndex: "mPriceVal",
                key: "mPriceVal",
                width: '12%',
                sorter: true,
                //sortOrder: paging.sortColumnRaw === 'mPriceVal' && paging.sortTypeRaw,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} step={0.01}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mPriceVal: value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            }, {
                title: this.getText(16252),
                dataIndex: "mFixedProfit",
                key: "mFixedProfit",
                width: '12%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} max={100}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mFixedProfit: value });
                                }} />);
                    } else {
                        return text ? text + "%" : "";
                    }
                },
            }, {
                title: this.getText(10364),
                width: '8%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing !== record.key) {
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                }} />
                                <Popconfirm title={this.getText(10365)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['mId'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.delete,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(10366)} cancelText={this.getText(10367)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];

        //window.console.log(TableIDs.genericSelectorPrices);
        //window.console.log(this.props.data);
        let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        });
        //let list = (this.props.data[this.id])[].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});

        return (
            <div/* style={divStyle}*/>
                <PageToolbar
                    title={this.getText(10368)}
                    actionButtons={[
                        {
                            buttonType: 'add',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreatePrice, 0) }
                        }, {
                            buttonIsClicked: false,
                            buttonType: 'filter',
                            buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalSearchItem); },
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        },
                    ]}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _filter: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <Select
                    //disabled={!(this.props.data[this.id].editing === record.key)}
                    showsearch
                    filterOption={true}
                    optionFilterProp="valuefilter"
                    value={this.props.data[this.id].filters['_id']}
                    defaultValue='none'
                    style={{ width: '256px', padding: '4px' }}
                    onChange={(e) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            { _id: e }, this.api.get)
                    }}>
                    <Option key={0} value={0}>-{this.getText(10369)}-</Option>
                    {list}
                </Select>

                {/*<Button key="showModal" type="primary"
                    onClick={() => { this.props.uiActions.showModal(TableIDs.modalCreatePriceList) }}>הוספת מחירון</Button>

                <Table dataSource={dataSource}
                    columns={this.columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />*/}

                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    tableOnly={true}
                    percentageToMobile={[false, 20, false, false, 40, 40]}
                    columns={columns}
                    bordered
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ModalCreatePriceList
                    id={TableIDs.modalCreatePriceList}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreatePriceList)}
                    title={this.getText(10370)}
                />

                <ModalCreatePrice
                    id={TableIDs.modalCreatePrice}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreatePrice)}
                    title={this.getText(10371)}
                />

                <ModalAdvanceSearch
                    id={TableIDs.modalSearchItem}
                    getApi={this.api.get} context={this.id}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchItem)}
                    title={this.getText(10242)}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                />
            </div>
        );
    }
}

export default PricesTable;

