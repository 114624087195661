import React, { Component } from "react";
import { Icon, message, Tooltip } from 'antd';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext"
import { Job, User, OutputType, ProcessType } from '../JmReact';
import GenericModal from "./GenericModal";

class HelpView extends Component<Props, State> {

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    state = {
        modalOpen: false
    }

    render() {
        let mobile = window.innerWidth < 600;
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        let fullUrl = baseUrl + "/uploads/shared/" + this.props.helpFile
        return (<>
            {mobile ?
                (<div style={{ display: "inline-block", width: 100, textAlign: "center", verticalAlign: "text-top" }}>
                    <div style={{ height: 60, marginTop: -20 }}>
                        <Icon
                            type={'question-circle'}
                            style={{ marginLeft: '40px', color: '#fff', fontSize: 20 }}
                            onClick={() => { this.setState({ modalOpen: true }) }}
                        />
                    </div>
                </div>) :
                (<Tooltip title={this.getText(20504)}>
                    <Icon
                        type={'question-circle'}
                        style={{ marginLeft: '40px', color: '#fff' }}
                        onClick={() => { this.setState({ modalOpen: true }) }}
                    />
                </Tooltip>)}
            <GenericModal
                visible={this.state.modalOpen}
                onCancel={() => { this.setState({ modalOpen: false }) }}
                title={this.props.helpTitle}
            >
                <iframe src={fullUrl} style={{width: '100%', height: window.innerHeight - 200}}></iframe>
            </GenericModal>
        </>)
    }

}

export default HelpView;