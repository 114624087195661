/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import { Button, Checkbox, Form, Icon, Input, InputNumber, Radio, Select, Row, Col } from "antd";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ResponsiveTable from "../../components/ResponsiveTable";
import PictureView from "../../components/PictureView";
import TableLineIcons from "../../components/TableLineIcons";
import { isBODev } from "../../utils/utils";

class VirtualShopCSSValues extends Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            groupView: null,
            compView: null,
            compViewDesc: null,
        }

        this.id = "VIRTUAL_SHOP_CSS_KEYS_VALUES";
        this.groups_id = "VIRTUAL_SHOP_CSS_GROUPS";
        this.css_id = "VIRTUAL_SHOP_CSS";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.groups_id] === undefined) props.data[this.groups_id] = { ...props.data.genericSelector };
        if (props.data[this.css_id] === undefined) props.data[this.css_id] = { ...props.data.genericData };

        this.api = {
            get: "get_virtual_shop_css_keys_values",
            get_css: "get_virtual_shop_css",
            get_groups: "get_virtual_shop_css_groups",

            set: "set_virtual_shop_css_values",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.apiGroups()
    }

    apiGroups = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.groups_id, this.props.user.companyCode, this.props.user.token, this.api.get_groups);
    }

    apiCss = (id) => {
        this.setState({ groupView: id }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.css_id, this.props.user.companyCode, this.props.user.token, { _group: id }, this.api.get_css);
        })
    }

    apiKeysValues = (id, desc) => {
        this.setState({ compView: id, compViewDesc: desc }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { _comp: id }, this.api.get);
        })
    }

    renderEditValue = (text, record) => {
        const { editing } = this.props.data[this.id];
        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.set, this.api.get, { _comp: this.state.compView })
                }}
                onClose={() => { this.props.dataActions.genericDataCancelEditing(this.id) }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            />)
        }
    }

    renderValue = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        const { CSSKeyType, CSSKeyOptions } = record
        const onChange = v => { this.props.dataActions.genericDataSetEdit(this.id, { CSSKeyValue: v }) }

        let val = editedRecord.CSSKeyValue
        switch (CSSKeyType) {
            case '1': case '2':
                if (editing == record.key) {
                    let numberType = val?.indexOf("%") > -1 ? "%" : "px"
                    return (<>
                        <InputNumber value={val?.replace(numberType, "")} onChange={e => { onChange(e ? e + numberType : "") }} />
                        <Radio.Group value={numberType} onChange={e => { onChange(val.replace(numberType, e.target.value)) }} >
                            <Radio value="px">px</Radio>
                            {CSSKeyType == '1' ? <Radio value="%">%</Radio> : ""}
                        </Radio.Group>
                    </>)
                } else {
                    return text
                }

            case '3': case '4':
                let fullValue = editing == record.key ? val : text
                let isUrl = fullValue?.indexOf("url") > -1
                let srcUrl = fullValue?.replace("url(", "")?.replace(")", "")
                let companyCode = this.props.user.companyCode;
                let origin = window.location.origin;
                const baseUrl = (!isBODev() ? origin : "http://147.235.163.248") + "/uploads/" + companyCode + "/";
                if (editing == record.key) {
                    return (<>
                        {isUrl ?
                            (<PictureView   {...this.props} url={srcUrl?.replace(baseUrl, "")} onRemove={() => { onChange('url()') }}
                                onUploadedSuccess={() => { }} onUploadedFailed={() => { }} imageType={'V'}
                                onChange={e => { onChange(e ? 'url(' + baseUrl + e + ')' : 'url()') }} />)

                            : <>
                                <input type="color" value={val} onChange={e => { onChange(e.target.value) }} />
                                <Icon type="delete" onClick={() => { onChange("") }} />
                            </>}
                        {CSSKeyType == '4' ?
                            <Radio.Group value={isUrl ? '1' : '0'} onChange={e => { onChange(e.target.value == '1' ? 'url()' : '') }} >
                                <Radio value="0">color</Radio>
                                <Radio value="1">url</Radio>
                            </Radio.Group> : ""
                        }
                    </>)
                } else {
                    return !text ? "" : isUrl ? <img height={50} src={srcUrl} /> : <input type="color" value={text} disabled />
                }

            case '5':
                return (<Select style={{ width: "100%" }} value={editing != record.key ? text : val} disabled={editing != record.key} onChange={onChange}>
                    <Select.Option value={""}> - </Select.Option>
                    {CSSKeyOptions?.split(",").map(x => (<Select.Option value={x}>{x}</Select.Option>))}
                </Select>)

            default: return text;
        }

    }


    render() {

        const gt = this.getText;
        let groupsData = this.props.data[this.groups_id].dataset

        return (<GenericModal
            visible={this.props.visible}
            onCancel={this.props.onCancel}
            width={800}
            title={gt(18446)}
            actionButtons={[{
                buttonType: 'refresh',
                buttonFunction: this.apiGroups
            }]}>
            {this.state.groupView ?
                <>
                    <div>
                        <Icon type="home" onClick={() => { this.setState({ groupView: null }) }} />{" > "}
                        <span style={{ cursor: "pointer" }} onClick={() => { this.setState({ compView: null, compViewDesc: null }) }}>
                            <Icon type="folder" /> {groupsData.find(f => f.code == this.state.groupView)?.name}
                        </span>
                        {this.state.compView ? [" > ", <Icon type="code" />, " ", this.state.compViewDesc] : ""}
                    </div>
                    {this.state.compView ?
                        <ResponsiveTable
                            tableOnly
                            idTable={this.props.data[this.id]}
                            columns={[
                                { title: gt(20537), key: "CSSKeyDscription", dataIndex: "CSSKeyDscription", render: t => t },
                                { title: gt(20538), key: "CSSKeyValue", dataIndex: "CSSKeyValue", render: this.renderValue },
                                { title: gt(20539), render: this.renderEditValue },
                            ]}
                            pagination={{ pageSize: 10 }}
                        />
                        : <ResponsiveTable
                            tableOnly
                            idTable={this.props.data[this.css_id]}
                            columns={[
                                { title: gt(20532), key: "CSSID", dataIndex: "CSSID", render: t => t },
                                { title: gt(20533), key: "CSSName", dataIndex: "CSSName", render: t => t },
                                { title: gt(20534), key: "CSSType", dataIndex: "CSSType", render: t => t == 1 ? "class" : t == 2 ? "id" : "" },
                                { title: gt(20535), key: "CSSDescription", dataIndex: "CSSDescription", render: t => t },
                                { title: gt(20536), render: (t, r) => (<Icon type="table" onClick={() => { this.apiKeysValues(r.CSSID, r.CSSDescription) }} />) },
                            ]}
                            pagination={{ pageSize: 10 }}
                        />}
                </>
                : groupsData.map(x => {
                    return (<div
                        style={{ display: "inline-block", width: 150, padding: 10, textAlign: "center", cursor: "pointer" }}
                        onClick={() => { this.apiCss(x.code) }}>
                        <Icon type="folder" style={{ fontSize: 60 }} />
                        <p>{x.name}</p>
                    </div>)
                })}


        </GenericModal>)
    }
}

export default VirtualShopCSSValues;