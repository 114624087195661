/* @flow */
/*jshint esversion: 6 */

import React from "react";
import { Input, Icon, Modal, message, Button, Tooltip, Checkbox, InputNumber } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import LangContext from "../contextProvider/LangContext";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import SelectorItems from "./SelectorItems";
import { Job, User, OutputType, ProcessType } from '../JmReact';
// import ModalCreateEditItem from "./ModalCreateEditItem";

class ModalCreateEditFoods extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            visible: false,
            newItemCode: "",
        }

        this.api = {
            get: "get_foods_table",
            create_edit: "create_edit_foods_table",
        }

        this.main_id = this.props.main_id;
        this.items_id = "ITEMS_TABLE";

        if (props.data[this.items_id] === undefined) props.data[this.items_id] = { ...props.data.genericData };

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    // componentDidUpdate(p) {
    //     let data = this.props.data[this.main_id];
    //     if (p.data[this.main_id].editing !== data.editing && data.editedRecord)
    //         this.setState({ newItemCode: data.editedRecord['ItemCode'] });
    // }

    handleOk = () => {
        this.setState({ visible: false }, () => {
            this.props.uiActions.hideModal(TableIDs.modalCreateEditFoods);
            this.props.dataActions.genericDataSaveEdit(
                this.main_id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
        });
    }

    handleClear = () => {
        this.props.dataActions.genericDataSetEdit(this.main_id, { fName: '', fRemarks: '', fItemCode: '', fItemName: '' })
    }

    handleCancel = () => {
        this.setState({ visible: false }, () => {
            this.props.uiActions.hideModal(TableIDs.modalCreateEditFoods);
            if (this.props.data[this.main_id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.main_id);
            else this.props.dataActions.genericDataCancelEditing(this.main_id);
        })
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemData = itemsRows[0].split('\f');
                    let _ItemCode = _ItemData[1];
                    let _ItemName = _ItemData[2];

                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data });
                    } else {
                        this.onChangeItemCode(_ItemCode, _ItemName)
                    }
                },
                (error) => {
                    message.error(this.getText(11253));
                }
            );
        }
    }

    keyboardListener(e) {
        let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                } else {
                    bufferUpdate(e.key)
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default:
                bufferUpdate(e.key)
                break;
        }
    }


    onChangeItemCode = (code, name) => {

        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.props.dataActions.genericDataSetEdit(this.main_id, { fItemCode: code, fItemName: name });
        }
    }

    render() {

        let fData = this.props.data[this.main_id].editedRecord;

        let value = (f) => fData && fData[f] ? fData[f] : "";
        let onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.main_id, { [f]: v }) }

        let mTitle = fData && fData.fID ? this.getText(12654) + " " + fData.fID : this.getText(12653);

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={mTitle}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let mobile = window.innerWidth <= 600 ? true : false;

        return (
            <Modal
                destroyOnClose={true} width={mobile ? '95%' : "50%"} visible={false || this.props.toggle}
                title={titleBar} onCancel={this.handleCancel}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataAction={this.props.dataAction}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12664)}</Button>,
                    <Button key="clear" onClick={this.handleClear}>{this.getText(12665)}</Button>,
                    <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(12666)}</Button>,
                ]}>
                <FormItem label={this.getText(12659)}>
                    <Input value={value("fName")} onChange={(v) => {
                        let res = v.target.value;
                        if (!res || res.length <= 45) onChange("fName", res)
                        else message.error(this.getText(19430))
                    }} />
                </FormItem>
                <FormItem label={this.getText(12660)}>
                    <TextArea rows={5} value={value('fRemarks')} onChange={(v) => { onChange('fRemarks', v.target.value) }} />
                </FormItem>
                <FormItem label={this.getText(12661)}>
                    <SelectorItems
                        user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                        dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                        ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} itemType={1}
                        value={value('fItemCode')}
                        onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName) }}
                        onChange={(s) => { onChange('fItemCode', s.target.value) }}
                        onBlur={(s) => { this.checkIfItemExist(value('fItemCode')) }}
                        onKeyDown={this.keyboardListener.bind(this)}
                        onSaveNewItem={(s) => {
                            this.onChangeItemCode(s.mCode, s.mName);
                            onChange('fItemCode', s.mCode);
                        }}
                    />
                    <h4>{value('fItemName') || this.getText(12667)}</h4>
                </FormItem>
                <FormItem label={this.getText(20468)}>
                    <InputNumber min={1} max={9} value={value("fColumns")} onChange={(v) => { onChange("fColumns", v) }} />
                </FormItem>
                <FormItem>
                    <Checkbox
                        checked={value('fRemarkRequierd') == '1'}
                        onChange={e => { onChange('fRemarkRequierd', e.target.checked ? '1' : '0') }}>
                        {this.getText(16337)}
                    </Checkbox>
                </FormItem>


                {/* <ModalCreateEditItem
                    id={TableIDs.modalCreateEditItem}
                    context={this.items_id} context_api_get={"get_items_list"}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                    title={this.getText(10241)}
                /> */}
            </Modal >)
    }
}

export default ModalCreateEditFoods;
