/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import moment from "moment";
import GenericModal from "../../components/GenericModal";
import { Button, Col, Form, Icon, Input, InputNumber, Popover, Radio, Tooltip, message } from "antd";
import { Row } from "antd/es/grid";
import DateTimePicker from "../../components/DateTimePicker";
import CustPicker from "../../components/CustPicker";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ModalDocumentLines from "../../components/ModalDocumentLines";
import ResponsiveTable from "../../components/ResponsiveTable";
import ViewDoc from "../../components/ViewDoc";
import ReportShowButton from "../../components/ReportShowButton";
import TableLineIcons from "../../components/TableLineIcons";
import ModalProgress from "../../components/ModalProgress";
import TableIDs from "../../data/TableIDs";


class K100 extends Component {
    constructor(props: Props) {
        super(props);

        this.firstPrevMonth = moment().add(-1, "month").format("YYYY-MM-01");
        this.firstCurMonth = moment().format("YYYY-MM-01");
        this.firstNextMonth = moment().add(1, "month").format("YYYY-MM-01");
        this.lastPrevMonth = moment(this.firstCurMonth).add(-1, "day").format("YYYY-MM-DD")
        this.lastCurMonth = moment(this.firstNextMonth).add(-1, "day").format("YYYY-MM-DD")

        this.state = {
            _fromDate: this.firstPrevMonth,
            _toDate: this.lastPrevMonth,
            _custId: null,
            _docNum: null,
            _ref2: null,

            modalLines: null,
            modalStamping: null,

            modalPrintK100Percent: 0,
            modalPrintK100Status: null,
            modalPrintK100Message: null,
            modalPrintK100Type: null,
            modalPrintK100MoreErrors: [],
        };

        this.id = "K100_DOCS_LIST";
        this.st_id = "K100_DOC_STAMPING";

        this.api = {
            get: "get_k100_docs_list",

            get_st: "get_k100_doc_stamping",
            set_st: "set_k100_doc_stamping",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.st_id] === undefined) props.data[this.st_id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19778);
    }

    initFilter = () => {
        const { _fromDate, _toDate, _custId, _docNum, _ref2 } = this.state;

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { _fromDate, _toDate, _custId, _docNum, _ref2 }, this.api.get);
    }

    cancelFilter = () => {
        this.setState({ modalFilter: false })
    }

    clearFilter = () => {
        this.setState({
            _fromDate: null,
            _toDate: null,
            _custId: null,
            _docNum: null,
            _ref2: null,
        }, this.initFilter)
    }

    setFilter = () => {
        this.initFilter()
        this.cancelFilter()
    }

    printDoc = (type, record) => {
        const { docNum, docType, docPos } = record;
        const { userId, displayName, companyCode, token } = this.props.user;

        const params = {
            DocumentNumber: docNum,
            DocumentTypeID: docType,
            DocumentPOSID: docPos,
            userID: userId,
            userName: encodeURIComponent(displayName),
            CompanyCode: companyCode,
            isOriginal: false,
            CentralFatherItem: 0,
            PrintFormat: this.state.docFormat,
            docIsWithSigns: 0,
            reportName: "reports/DocumentCopyCentral." + type,
            REPORT_LOCALE: this.getText(101)
        }

        this.setState({ viewPopPrint: null }, () => {
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [companyCode, token, params] });
        })

    }

    getPrintsPopBtn = (type, icon, label, record) => {
        return (<Tooltip title={label}>
            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={icon} onClick={() => { this.printDoc(type, record) }} />
        </Tooltip>)
    }


    getEditCol = (t, record) => {
        const printsPop = (<div>
            {this.getPrintsPopBtn("pdf", "file-pdf", this.getText(11179), record)}
            {this.getPrintsPopBtn("xls", "file-excel", this.getText(11180), record)}
            {this.getPrintsPopBtn("docs", "file-word", this.getText(11179), record)}
            <br />
            <Radio.Group
                style={{ direction: "rtl", textAlign: "right" }}
                value={this.state.docFormat}
                onChange={e => { this.setState({ docFormat: e.target.value }) }}>
                <Radio value={'0'}>{this.getText(18983)}</Radio><br />
                <Radio value={'8'}>{this.getText(20520)}</Radio><br />
                <Radio value={'1'}>{this.getText(18984)}</Radio><br />
                <Radio value={'6'}>{this.getText(19360)}</Radio><br />
                <Radio value={'2'}>{this.getText(18985)}</Radio><br />
                <Radio value={'3'}>{this.getText(18986)}</Radio><br />
                <Radio value={'5'}>{this.getText(19359)}</Radio><br />
                <Radio value={'7'}>{this.getText(19361)}</Radio><br />
                <Radio value={'4'}>{this.getText(19105)}</Radio>
            </Radio.Group>
        </div>);

        return (<span>

            {record.isRef2Req == '1' ?
                <Tooltip title={this.getText(19821)}>
                    <Icon
                        className={"clickable margin-4"} style={{ fontSize: 16 }} type="book" theme="outlined"
                        onClick={() => {
                            this.setState({ modalStamping: record }, () => {
                                const { docNum, docType, docPos } = record
                                this.props.dataActions.genericDataSetFilter(
                                    this.st_id, this.props.user.companyCode, this.props.user.token, { docNum, docType, docPos }, this.api.get_st);

                            })
                        }}
                    />
                </Tooltip>
                : ""}

            <Tooltip title={this.getText(19767)}>
                <Popover content={printsPop} title={this.getText(11183)} trigger="click" visible={this.state.viewPopPrint == record.key} >
                    <Icon
                        className={"clickable margin-4"} style={{ fontSize: 16 }} type="printer" theme="outlined"
                        onClick={() => { this.setState({ docFormat: record.docFormat, viewPopPrint: this.state.viewPopPrint ? null : record.key }) }}
                    />
                </Popover>
            </Tooltip>

            <Tooltip title={this.getText(17083)}>
                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="eye" theme="outlined" onClick={() => { this.setState({ modalLines: record }) }} />
            </Tooltip>

            {this.getReportShowBtn(record.docNum, record.docType, record.docPos, record.docFormat)}

            <Tooltip title={this.getText(19822)}>
                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="file" theme="outlined" onClick={() => { this.printK100Step1(record) }} />
            </Tooltip>

        </span>)
    }

    renderStoreNum = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.st_id]
        if (record.key === editing) {
            return (<InputNumber value={editedRecord['_store_num']} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.st_id, { _store_num: e })
            }} />)
        } else {
            return text;
        }
    }

    renderRef2 = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.st_id]
        if (record.key === editing) {
            return (<InputNumber value={editedRecord['_ref2']} onChange={e => {
                this.props.dataActions.genericDataSetEdit(this.st_id, { _ref2: e })
            }} />)
        } else {
            return text;
        }
    }

    getReportShowBtn = (num, type, pos, format) => {
        return (<ReportShowButton
            {...this.props}
            iconButton
            validSubmit
            params={{
                DocumentNumber: num,
                DocumentTypeID: type,
                DocumentPOSID: pos,
                userID: this.props.user.userId,
                userName: encodeURIComponent(this.props.user.displayName),
                CompanyCode: this.props.user.companyCode,
                isOriginal: false,
                CentralFatherItem: 0,
                PrintFormat: format,
                docIsWithSigns: 0,
                reportName: type == 5 ? "reports/DocumentCopyCentral.pdf" : "reports/DocumentCopy.pdf",
                REPORT_LOCALE: this.getText(101)
            }} />)
    }

    renderEditSt = (text, record) => {
        const { editing } = this.props.data[this.st_id]

        if (record.key === editing) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEdit(
                        this.st_id, this.props.user.companyCode, this.props.user.token, this.api.set_st, this.api.get_st);
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.st_id);
                    else this.props.dataActions.genericDataCancelEditing(this.st_id);
                }} />)
        } else {

            const { _doc_num, _doc_type, _doc_pos } = record;

            return (<span>
                {this.getReportShowBtn(_doc_num, _doc_type, _doc_pos, null)}
                {/* <ReportShowButton
                    {...this.props}
                    iconButton
                    validSubmit
                    params={{
                        DocumentNumber: _doc_num,
                        DocumentTypeID: _doc_type,
                        DocumentPOSID: _doc_pos,
                        userID: this.props.user.userId,
                        userName: encodeURIComponent(this.props.user.displayName),
                        CompanyCode: this.props.user.companyCode,
                        isOriginal: false,
                        CentralFatherItem: 0,
                        PrintFormat: null,
                        docIsWithSigns: 0,
                        reportName: "reports/DocumentCopy.pdf",
                        REPORT_LOCALE: this.getText(101)
                    }} /> */}

                <Tooltip title={this.getText(17083)}>
                    <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="eye" theme="outlined" onClick={() => {
                        this.setState({ modalLines: { ...record, docNum: _doc_num, docType: _doc_type, docPos: _doc_pos, isStTable: true } })
                    }} />
                </Tooltip>

                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="edit" theme="outlined" onClick={() => {
                    this.props.dataActions.genericDataStartEditing(this.st_id, record.key, record.index)
                }} />
            </span>)
        }
    }

    printK100Step1 = (record) => {
        this.setState({
            modalPrintK100Percent: 20,
            modalPrintK100Status: "active",
            modalPrintK100Message: this.getText(19878),
            modalProgressType: "info"
        }, () => {
            this.props.uiActions.showModal(TableIDs.modalProgress);
            this.sendAPI("get_customer_k100", "custID\r" + record.custID, ob => {
                let dataSplit = ob.data ? ob.data.split("\f") : []
                if (dataSplit[0]) {
                    this.printK100Step2({ ...record, isRef2Req: dataSplit[2] == '1' })
                } else {
                    this.setState({
                        modalPrintK100Percent: 20,
                        modalPrintK100Status: "exception",
                        modalPrintK100Message: this.getText(19879) + " " + record.custName,
                        modalProgressType: "error"
                    })
                }
            })
        })
    }

    printK100Step2 = (record) => {
        this.setState({
            modalPrintK100Percent: 40,
            modalPrintK100Status: "active",
            modalPrintK100Message: this.getText(19880),
            modalProgressType: "info"
        }, () => {
            const { docNum, docType, docPos } = record
            this.sendAPI(this.api.get_st, "docNum\fdocType\fdocPos\r" + docNum + "\f" + docType + "\f" + docPos, ob => {
                let data = ob.data ? ob.data.split("\r").slice(1, -1) : []
                let dataObj = data.map(x => {
                    let y = x.split("\f");
                    return { cID: y[0], cName: y[1], storeNum: y[2], docNum: y[5], ref2: y[6] }
                })
                let notStoreNum = dataObj.filter(f => !f.storeNum)

                if (notStoreNum.length) this.setState({ modalPrintK100MoreErrors: notStoreNum.map(x => this.getText(19881) + " " + x.cName) })

                this.printK100Step3({ ...record, dataObj })
            })
        })
    }

    printK100Step3 = (record) => {
        this.setState({
            modalPrintK100Percent: 60,
            modalPrintK100Status: "active",
            modalPrintK100Message: this.getText(19882),
            modalProgressType: "info"
        }, () => {
            if (record.isRef2Req) {
                let notRef2 = record.dataObj.filter(f => !f.ref2)
                if (notRef2.length || this.state.modalPrintK100MoreErrors.length) {
                    this.setState({
                        modalPrintK100Percent: 60,
                        modalPrintK100Status: "exception",
                        modalPrintK100Message: "",
                        modalProgressType: "error",
                        modalPrintK100MoreErrors: notRef2.length ? [...this.state.modalPrintK100MoreErrors, ...notRef2.map(x => this.getText(19883) + " " + x.docNum)] : this.state.modalPrintK100MoreErrors
                    })
                } else {
                    this.printK100Step4(record)
                }
            }
        })
    }

    printK100Step4 = (record) => {
        this.setState({
            modalPrintK100Percent: 80,
            modalPrintK100Status: "active",
            modalPrintK100Message: this.getText(19884),
            modalProgressType: "info",
            modalPrintK100MoreErrors: ['בפיתוח... נא לסגור את החלון']
        })
    }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: "auto",
            paddingRight: 4
        };

        const { _fromDate, _toDate, _custId, _docNum, _ref2 } = this.state;

        const render = t => (<div style={{ textAlign: "right" }}>{t}</div>);
        const renderNum = t => (<div style={{ textAlign: "right", direction: "ltr" }}>{parseFloat(t)?.toFixed(2)}</div>);
        const sorter = true;

        const mSt = this.state.modalStamping

        const columns = [
            { title: gt(19780), key: "custID", dataIndex: "custID", width: "10%", sorter, render },
            { title: gt(19781), key: "custName", dataIndex: "custName", width: "26%", sorter, render },
            { title: gt(19782), key: "docDateTime", dataIndex: "docDateTime", width: "12%", sorter, render: t => render(moment(t).format("DD/MM/YYYY")) },
            { title: gt(19783), key: "docNum", dataIndex: "docNum", width: "12%", sorter, render },
            { title: gt(19784), key: "docTypeName", dataIndex: "docTypeName", width: "20%", sorter, render },
            { title: gt(19785), key: "docSum", dataIndex: "docSum", width: "10%", sorter, render: renderNum },
            { width: "10%", render: this.getEditCol },
        ]

        const stColumns = [
            { title: gt(19823), key: "_cust_id", dataIndex: "_cust_id", width: "10%", render },
            { title: gt(19824), key: "_cust_name", dataIndex: "_cust_name", width: "10%", render },
            { title: gt(19825), key: "_store_num", dataIndex: "_store_num", width: "15%", render: this.renderStoreNum },
            { title: gt(19826), key: "_doc_type_name", dataIndex: "_doc_type_name", width: "10%", render },
            { title: gt(19827), key: "_doc_date", dataIndex: "_doc_date", width: "10%", render },
            { title: gt(19828), key: "_doc_num", dataIndex: "_doc_num", width: "10%", render },
            { title: gt(19829), key: "_ref2", dataIndex: "_ref2", width: "15%", render: this.renderRef2 },
            { title: gt(19830), key: "_sum", dataIndex: "_sum", width: "10%", render },
            { width: "10%", render: this.renderEditSt },
        ]

        const modalLinesColumns = this.state.modalLines ? this.state.modalLines.isStTable ? stColumns.slice(0, -1) : columns.slice(0, -1) : []


        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                title={gt(19779)}
                id={this.id}
                getApi={this.api.get}
                searchBox
                actionButtons={[{
                    buttonType: 'filter',
                    buttonFunction: () => { this.setState({ modalFilter: true }) }
                }]}
                columns={columns}
                filters={{ _fromDate, _toDate, _custId, _docNum, _ref2 }}
            />
            <GenericModal visible={this.state.modalFilter}
                onCancel={this.cancelFilter}
                width={1000}
                title={gt(19786)}
                footer={[
                    <Button key="filter" type="primary" onClick={this.setFilter}>{gt(19787)}</Button>,
                    <Button key="clear" onClick={this.clearFilter}>{gt(19788)}</Button>,
                    <Button key="cancel" onClick={this.cancelFilter}>{gt(19789)}</Button>,
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Form.Item label={gt(19790)}>
                            <DateTimePicker
                                format="DD/MM/YYYY"
                                value={_fromDate}
                                maxDate={_toDate}
                                onChange={e => { this.setState({ _fromDate: e }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(19791)}>
                            <DateTimePicker
                                format="DD/MM/YYYY"
                                value={_toDate}
                                maxDate={_fromDate}
                                onChange={e => { this.setState({ _toDate: e }) }}
                            />
                        </Form.Item>
                        <Button
                            style={{ margin: 5 }}
                            type={_fromDate == this.firstCurMonth && _toDate == this.lastCurMonth ? "primary" : ""}
                            onClick={() => { this.setState({ _fromDate: this.firstCurMonth, _toDate: this.lastCurMonth }) }}
                        >{gt(19792)}</Button>
                        <Button
                            style={{ margin: 5 }}
                            type={_fromDate == this.firstPrevMonth && _toDate == this.lastPrevMonth ? "primary" : ""}
                            onClick={() => { this.setState({ _fromDate: this.firstPrevMonth, _toDate: this.lastPrevMonth }) }}
                        >{gt(19793)}</Button>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Form.Item label={gt(19794)}>
                            <CustPicker
                                {...this.props}
                                value={_custId}
                                onChange={(value) => { this.setState({ _custId: value }) }}
                                defaultView={this.getText(12121)}
                            />
                        </Form.Item>
                        <Form.Item label={gt(19795)}>
                            <Input
                                value={_docNum}
                                onChange={(e) => { this.setState({ _docNum: e.target.value }) }}
                            />
                        </Form.Item>
                        <Form.Item label={gt(19796)}>
                            <Input
                                value={_ref2}
                                onChange={(e) => { this.setState({ _ref2: e.target.value }) }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </GenericModal>

            <GenericModal visible={mSt != null && !this.state.modalLines}
                onCancel={() => { this.setState({ modalStamping: null }) }}
                width={1000}
                title={mSt?.docTypeName + " " + mSt?.docNum + " " + mSt?.custName}>
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.st_id]}
                    columns={stColumns}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>

            <ModalDocumentLines
                user={this.props.user}
                visible={this.state.modalLines !== null ? true : false}
                onCancel={() => { this.setState({ modalLines: null }) }}
                DocumentNumber={this.state.modalLines ? this.state.modalLines.docNum : 0}
                DocumentPOSID={this.state.modalLines ? this.state.modalLines.docPos : 0}
                DocumentTypeId={this.state.modalLines ? this.state.modalLines.docType : 0}
                docTitles={modalLinesColumns.map(x => { return { label: x.title, value: x.render(this.state.modalLines[x.dataIndex], this.state.modalLines) } })}
            />

            <ModalProgress {...this.props}
                id={TableIDs.modalProgress}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalProgress)}
                title={this.getText(19877)}
                handleCancel={() => {
                    this.setState({
                        modalPrintK100Percent: 0,
                        modalPrintK100Status: null,
                        modalPrintK100Message: null,
                        modalPrintK100Type: null,
                        modalPrintK100MoreErrors: []
                    }, () => {
                        this.props.uiActions.hideModal(TableIDs.modalProgress)
                    })
                }}
                // handleRefrash={}
                // handleIgnore={}
                percent={this.state.modalPrintK100Percent}
                status={this.state.modalPrintK100Status}
                message={this.state.modalPrintK100Message}
                type={this.state.modalPrintK100Type}
                moreErrors={this.state.modalPrintK100MoreErrors}
            />
        </div>)
    }

}

export default K100;