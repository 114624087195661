/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Popconfirm, Button, Tooltip, Modal, Spin, message, Form, Checkbox, Radio, Input, Row, Col } from "antd";
import TableIDs from "../../data/TableIDs";
import ModalCreateEditCustomer from "../../components/ModalCreateEditCustomer";
import ResponsiveTable from "../../components/ResponsiveTable";
import { fixCurrentPage } from "../../utils/utils";
import ModalCustomersDebtReport from "../../components/ModalCustomersDebtReport";
import ModalCustomerDebtDetailedReport from "../../components/ModalCustomerDebtDetailedReport";
import ModalSendLinkSelfCustomer from "../../components/ModalSendLinkSelfCustomer";
import LangContext from "../../contextProvider/LangContext";
import CustomerFilter from './CustomerFilter';
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import RecyclingBin from "../../components/RecyclingBin";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import CustomerPicker from "../../components/CustomerPicker";
import TableLineIcons from "../../components/TableLineIcons";
import moment, { suppressDeprecationWarnings } from "moment";
import QuesIdentityEntity from "../../components/QuesIdentityEntityType";
import { escapeRegExp } from "../../utils/utils";
import ModalMatchDocs from "../../components/ModalMatchDocs";
import ModalDocumentPrintOptions from "../../components/ModalDocumentPrintOptions";
import ModalContacts from "../../components/ModalContacts";
import CopyDocumentReport from "../reports/PaymentsSearchReport";
import CustomerBusinessBranches from "../Crm/CustomerBusinessBranches";
import UsersTableColumns from "../../components/UsersTableColumns";

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class CustomerTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            modalFilter: false,
            debtsCalc: false,
            height: window.innerHeight,
            modalCustBranch: null,
            modalConnectedCustomers: null,
            lastCulcTime: "",

            filterActive: false,
            valueSearch: '',

            // modalMatchDocs: null,
            // matchDocsList: [],
            // matchDocsIsAll: false,

            // modalFilterMatchNum: null,
            // modalFilterMatchNumList: []

            modalLinkPersonalInformation: null,

            modalDocumentPrintOptions: null,
            modalDocumentPrintOptionsIsBO: '0',

            modalContacts: null,
            modalK100Settings: null,

            userColumnsList: [],

            filterRequest: null,

        };

        // calc page height
        this.doOnResize = null;

        this.id = "CUSTOMER_TABLE";
        this.recycling_id = "CUST_DELETION_RECYCLING";
        this.connected_id = "CONNECTED_CUSTOMERS";
        // this.match_id = "MATCH_DOCS_CUSTOMERS";

        this.branchSelector = { id: TableIDs.genericSelectorBranches, api: "get_branch_list" }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };
        if (props.data[this.branchSelector.id] === undefined) props.data[this.branchSelector.id] = { ...props.data.genericSelector };
        if (props.data[this.connected_id] === undefined) props.data[this.connected_id] = { ...props.data.genericData };
        // if (props.data[this.match_id] === undefined) props.data[this.match_id] = { ...props.data.genericData };

        this.api = {
            get: "get_customers",
            delete: "delete_customer",
            debts_calc: "calc_debts_customers",

            get_recycling: "get_cust_deletion_recycling",
            restoration: "restoration_cust",

            cust_branch: "save_customers_branches",

            get_connected: "get_connected_customers",
            create_edit_connected: "create_edit_connected_customers",
            delete_connected: "delete_connected_customers",

            // get_match: "get_customer_match_docs",
            // save_match: "save_customer_match_docs",
            // delete_match: "delete_customer_match_docs",

            get_filter: "get_filter_favorite_data",
        };


        this.loopCulc = null;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    componentDidMount() {
        this.sendAPI(this.api.get_filter, "_url\r" + window.location.pathname, (ob) => {
            if (ob.data) {
                let params = {}
                ob.data.split('\r').forEach(x => {
                    let y = x.split('\f');
                    params = { ...params, [y[0]]: y[1] === 'null' || y[1] === 'undefined' ? null : y[1] }
                })
                this.setState({ filterRequest: params, filterActive: true }, () => {

                    this.setFilter(params);
                })
            } else {
                this.initFilters();
            }
        }, e => { console.error(e) })

        this.debtsCalculation();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10152);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            let p = this.props.ui.favoriteParams;
            if (p && p.reportName && p.reportName.indexOf("CustomersDebt") > -1) {
                setTimeout(() => {
                    this.props.uiActions.showModal(TableIDs.modalCustomersDebtReport)
                }, 1000);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        if (window.innerWidth < 600) {
            this.setState({ height: window.innerHeight }, () => {
                let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
                    ? (this.state.height - topHeight) / rowHeight
                    : 1);

                this.setFilter({ _rows_num: numOfRowsByHeight })
            });
        }
    };

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mId": col--;
            case "mFirstName": col--;
            case "mLastName": col--;
            case "mContactPersonName": col--;
            case "mContactPersonMobile": col--;
            case "mDebtBalance": col--;
            case "mObligoBalance": col--;
            case "mIsPrimary": break; // 8
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0,
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            customerTags: '',
            birthDayLower: '',
            birthDayUpper: '',
            clubJoinLower: '',
            clubJoinUpper: '',
            lastClubJoinLower: '',
            lastClubJoinUpper: '',
            updateDateLower: '',
            updateDateUpper: '',
            creationPos: '',
            salesView: '0',
            custCredit: '0',
            custMailingIsActive: '0',
            notActiveView: '0',
            custEmployeeId: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    setFilter = (filter) => {
        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
            _words: '',

            ...filter,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    }

    debtsCalculation = () => {
        this.setState({ debtsCalc: true }, () => {
            let nextApi = () => {
                this.setState({ debtsCalc: false, lastCulcTime: moment().format("HH:mm:ss") }, () => {
                    this.refreshData()
                    // clearTimeout(this.loopCulc);
                    // this.loopCulc = setTimeout(this.debtsCalculation, 30000);
                    // בוטל בתאריך 26.07.2023
                })
            }
            this.sendAPI(this.api.debts_calc, "", nextApi, nextApi)
        })
    }

    renderText = (text, record) => {
        let style = { textAlign: "right" }
        if (record['mIsActive'] != '1') style = { ...style, color: 'red', textDecoration: 'line-through' }
        return (<div style={style}>{text}</div>)
    }

    renderBalance = (text, record) => {

        const formatter = new Intl.NumberFormat('he-IL', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });


        let sumText = text && parseFloat(text) ? formatter.format(text) : '0.00'
        let bg = 'auto';
        if (parseFloat(text) > 0) bg = "#7CFC00";
        if (parseFloat(text) < 0) bg = "pink";
        return (<div style={{ textAlign: "right", backgroundColor: bg, width: '100%', height: '24px', direction: "ltr" }}>{sumText}</div>)
    }

    renderIsPrimary = (text, record) => {
        if (text == '1') {
            return (<Tooltip title={this.getText(17002)}>
                <Icon type="table" onClick={() => { this.setState({ modalConnectedCustomers: record }, () => { this.getConnectedCustomers(record.key) }) }} />
            </Tooltip>)
        } else if (record.mPrimaryID) {
            return (<Tooltip title={this.getText(17003) + " " + record.mPrimaryName}>
                <Icon type="tag" onClick={() => {
                    this.setState({
                        modalConnectedCustomers: {
                            mId: record.mPrimaryID,
                            mContactPersonName: record.mPrimaryName
                        }
                    }, () => {
                        this.getConnectedCustomers(record.mPrimaryID)
                    })
                }} />
            </Tooltip>)
        }
    }

    modalLinkPersonalInformationOpen = (record) => {
        const { mContactPersonMobile, mEmail } = record;
        let sendMobile = this.mobileValidate(mContactPersonMobile) ? mContactPersonMobile : ""
        let sendEmail = this.emailValidate(mEmail) ? mEmail : ""
        let sendType = null;
        if (sendMobile) sendType = '2';
        else if (sendEmail) sendType = '1';

        this.setState({
            modalLinkPersonalInformation: { ...record, sendType, sendMobile, sendEmail },
        })
    }

    getK100Settings = (record) => {
        this.sendAPI("get_customer_k100", "custID\r" + record['mId'], ob => {
            let res = ob.data ? ob.data.split("\f") : ["", "", "0"]
            this.setState({
                modalK100Settings: {
                    code: record['mId'],
                    name: record['mContactPersonName'],
                    isPrimary: record.mIsPrimary == 1,
                    isSecondary: record.mPrimaryID > 0,
                    suppCode: res[0],
                    storeCode: res[1],
                    isRef2Req: res[2],
                }
            })
        })
    }

    saveK100Settings = () => {
        const { code, suppCode, storeCode, isRef2Req } = this.state.modalK100Settings;
        this.sendAPI("set_customer_k100", "custID\fsuppCode\fstoreCode\fisRef2Req\r" + code + "\f" + suppCode + "\f" + storeCode + "\f" + isRef2Req, () => {
            this.setState({ modalK100Settings: null })
        })
    }

    renderEdit = (text, record) => {
        if (this.props.data[this.id].editing !== record.key) {
            return (
                <span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                        this.props.uiActions.showModal(TableIDs.modalCreateEditCustomer, record['mId']);
                    }} />
                    <Popconfirm title={this.getText(10730)}
                        onConfirm={() => {
                            this.sendAPI(this.api.delete, record['mId'], (ob) => {
                                this.refreshData();
                                if (ob.data === 'not deleted') {
                                    setTimeout(() => { message.error(this.getText(15383)) }, 1000);
                                }
                            }, (e) => { console.error(e) });
                        }
                        }
                        okText={this.getText(10731)} cancelText={this.getText(10732)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                    <Tooltip title={this.getText(10733)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file"} onClick={() => {
                            //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                            this.props.uiActions.showModal(TableIDs.modalCustomerDebtDetailedReport, record['mId']);
                        }} />
                    </Tooltip>
                    <Tooltip title={this.getText(15609)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"tag"} onClick={() => {
                            console.log("modalCustBranch 2", record)
                            this.setState({ modalCustBranch: record });
                        }} />
                    </Tooltip>
                    <Tooltip title={this.getText(16008)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"mail"} onClick={() => { this.modalLinkPersonalInformationOpen(record) }} />
                    </Tooltip>
                    <QuesIdentityEntity
                        key={"QuesIdentityEntity-1-" + record['mId']}
                        {...this.props}
                        entityType={1}
                        entityValue={record['mId']}
                        mobileToLink={record['mContactPersonMobile']}
                    />

                    <ModalMatchDocs {...this.props} key={record.key} record={record} mId={record.mId} mName={record.mContactPersonName} />
                    <Tooltip title={this.getText(17397)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"printer"} onClick={() => { this.setState({ modalDocumentPrintOptions: record['mId'] }) }} />
                    </Tooltip>
                    <Tooltip title={this.getText(19663)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => { this.setState({ modalContacts: { code: record['mId'], name: record['mContactPersonName'] } }) }} />
                    </Tooltip>
                    {this.props.data.PSEUDO_SELECTOR_UI_COMPONENTS_MODULES.dataset.find(f => f.code === '524') ?
                        <Tooltip title={this.getText(19769)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"setting"} onClick={() => { this.getK100Settings(record) }} />
                        </Tooltip>
                        : ""}
                    <Tooltip title={this.getText(19914)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file"} onClick={() => { this.setState({ modalDocuments: record['mId'] }) }} />
                    </Tooltip>
                    {this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '531') ?
                        <Tooltip title={this.getText(20223)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"environment"} onClick={() => { this.setState({ modalBusinessBranches: record }) }} />
                        </Tooltip>
                        : ''}
                </span >);
        } else {
            let isCreate = (this.props.data[this.id].editing === 0) ? true : false;
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        this.props.dataActions.genericDataSaveEdit(
                            this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        if (isCreate)
                            this.props.dataActions.genericDataCancelInlineCreate(this.id);
                        else
                            this.props.dataActions.genericDataCancelEditing(this.id);
                    }} />
            </span>)
        }
    }

    saveCustBranches = () => {
        const { mId, mBranchList } = this.state.modalCustBranch;
        this.props.dataActions.genericDataSaveEditWithParams(this.id, this.props.user.companyCode, this.props.user.token, this.api.cust_branch, this.api.get, { mId, mBranchList });
        this.setState({ modalCustBranch: null });
    }
    mobileValidate = (mobile) => {
        var patt = new RegExp(/^05\d([-]{0,1})\d{7}$/);
        return patt.test(mobile);
    }

    emailValidate = (email) => {
        var patt = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        return patt.test(email);
    }

    getConnectedCustomers = (mId) => {
        this.props.dataActions.genericDataSetFilter(
            this.connected_id, this.props.user.companyCode, this.props.user.token, { mId }, this.api.get_connected);

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                { script: "data_get", params: { dataObject: "customerList" } },
                "customerList",
                { loading: "", failed: "Error loading", success: "" },
                false,
                { action: null, args: [] },
                null
            ]
        });
    }

    renderSecondaryCust = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.connected_id];
        if (editing == record._id) {
            return (<CustomerPicker
                defaultCustomerId={editedRecord._secondaryCust}
                onChange={(value) => {
                    this.props.dataActions.genericDataSetEdit(this.connected_id, { _secondaryCust: value.customerId, _custName: value.customerName })
                }}
                label={editedRecord._secondaryCust + ' - ' + editedRecord._custName}
                data={(this.props.data.customerList) ? this.props.data.customerList.data : []}
                showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
            />)
        } else {
            return (<p style={{ textAlign: "right" }}>{text + ' - ' + record._custName}</p>)
        }
    }

    renderSummerySeparately = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.connected_id];
        return (<Checkbox
            disabled={editing != record._id}
            checked={editing == record._id ? editedRecord._summerySeparately == '1' : text == '1'}
            onChange={(value) => {
                this.props.dataActions.genericDataSetEdit(this.connected_id, { _summerySeparately: value.target.checked ? "1" : "0" })
            }} />)
    }

    renderEditConnected = (text, record) => {
        const { editing } = this.props.data[this.connected_id];
        if (editing == record._id) {
            return (<TableLineIcons
                onCheck={() => {
                    const { mId } = this.state.modalConnectedCustomers;
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.connected_id,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.create_edit_connected,
                        this.api.get_connected,
                        { _primaryCust: mId });
                }}
                onClose={() => {
                    if (editing > 0) this.props.dataActions.genericDataCancelEditing(this.connected_id);
                    else this.props.dataActions.genericDataCancelInlineCreate(this.connected_id);
                }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => {
                    this.props.dataActions.genericDataStartEditing(this.connected_id, text, record.index);
                }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.connected_id, text, this.props.user.companyCode, this.props.user.token, this.api.delete_connected, this.api.get_connected);
                }}
                deleteQuetion={{ title: this.getText(17007), okText: this.getText(17008), cancelText: this.getText(17009) }}
            />)
        }
    }



    getSumFormat = (num) => {
        let res = ""
        if (num) {
            let x = parseFloat(num).toFixed(2);
            let y = x.split(".")
            let intNum = y[0];
            if (intNum[0] == "-") {
                res += "-";
                intNum = intNum.slice(1, intNum.length);
            }
            let s = intNum.split("")
            s.forEach((z, i) => {
                if (s.length > 3 && i && i % 3 == s.length % 3) res += ",";
                res += z;
            })

            res += "." + y[1];
        }

        return res;
    }

    createLinkPersonalInformation = () => {
        let issetModule = this.props.data.PSEUDO_SELECTOR_UI_COMPONENTS_MODULES.dataset.find(f => f.name === '505' || f.name === '485');
        const mLPI = this.state.modalLinkPersonalInformation;
        if (issetModule && mLPI) {
            const { sendType, sendMobile, sendEmail, key } = mLPI;
            if ((sendType == 1 && this.emailValidate(sendEmail)) || (sendType == 2 && this.mobileValidate(sendMobile))) {
                let dataSend = "_type\f_params\f_mobile\f_lang\f_messageType\r6\fcustID="
                    + key + "\f" + (sendType == 1 ? sendEmail : sendMobile) + "\f" + this.getText(100) + "\f" + (sendType == 1 ? '3' : '2');
                this.sendAPI("create_random_connection_token_v2", dataSend, (ob) => {
                    if (ob?.errorCode) {
                        message.error(ob.errorMessage);
                    } else {
                        message.success(this.getText(16010))
                        this.setState({ modalLinkPersonalInformation: null })
                    }
                }, (e) => { console.error(e) })
            } else {
                message.error(this.getText(16011))
            }
        } else {
            message.error(this.getText(16009))
        }
    }


    render() {
        let gt = this.getText;
        let sorter = true;



        let columns = [
            { title: gt(10724), dataIndex: "mId", key: "mId", sorter, width: '6%', render: this.renderText },
            { title: gt(10725), dataIndex: "mFirstName", key: "mFirstName", sorter, width: '10%', render: this.renderText },
            { title: gt(10726), dataIndex: "mLastName", key: "mLastName", sorter, width: '10%', render: this.renderText },
            { title: gt(10727), dataIndex: "mContactPersonName", key: "mContactPersonName", sorter, width: '16%', render: this.renderText },
            { title: gt(10728), dataIndex: "mContactPersonMobile", key: "mContactPersonMobile", sorter, width: '10%', render: this.renderText },
            { title: gt(14264), dataIndex: "mDebtBalance", key: "mDebtBalance", sorter, width: '8%', render: this.renderBalance },
            { title: gt(17000), dataIndex: "mObligoBalance", key: "mObligoBalance", sorter, width: '8%', render: this.renderBalance },
            { title: gt(17001), dataIndex: "mIsPrimary", key: "mIsPrimary", sorter, width: '6%', render: this.renderIsPrimary },
            { title: gt(10729), width: '26%', render: this.renderEdit }
        ];

        let gButton = (textCode, iconType, key, modal) => {
            return (<Tooltip title={this.getText(textCode)}>
                <Button key={key} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => this.props.uiActions.showModal(TableIDs[modal])}
                ><Icon style={{ fontSize: 16 }} type={iconType} theme="outlined" /></Button>
            </Tooltip>)
        }

        let genericActionButtons = [
            gButton(10736, "file", "report_debt_summary", "modalCustomersDebtReport"),
            gButton(12613, "mail", "link_to_self", "modalSendLinkSelfCustomer"),
            <Tooltip title={this.getText(14265)}>
                <Button key={"debtsCalc"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.debtsCalculation}
                ><Icon style={{ fontSize: 16 }} type={"calculator"} theme="outlined" /></Button>
            </Tooltip>,
            <Tooltip title={this.getText(14044)}>
                <Button key={"report"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                    onClick={() => {
                        this.debtsCalculation();
                        let f = this.props.data[this.id].filters;
                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.generateReport,
                            args: [this.props.user.companyCode, this.props.user.token, {
                                _words: encodeURIComponent(f._words),
                                _isAsc: f._isAsc,
                                _sort_by: f._sort_by,
                                customerTags: f.customerTags,
                                birthDayLower: f.birthDayLower,
                                birthDayUpper: f.birthDayUpper,
                                clubJoinLower: f.clubJoinLower,
                                clubJoinUpper: f.clubJoinUpper,
                                lastClubJoinLower: f.lastClubJoinLower,
                                lastClubJoinUpper: f.lastClubJoinUpper,
                                updateDateLower: f.updateDateLower,
                                updateDateUpper: f.updateDateUpper,
                                creationPos: f.creationPos,
                                custCredit: f.custCredit,
                                custMailingIsActive: f.custMailingIsActive,
                                notActiveView: f.notActiveView,
                                custEmployeeId: f.custEmployeeId,
                                reportName: "reports/CustomersExcel.xls",
                                REPORT_LOCALE: this.getText(101),
                            }]
                        });
                    }}
                ><Icon style={{ fontSize: 16 }} type={"file-excel"} theme="outlined" /></Button>
            </Tooltip>,
            <RecyclingBin
                id={this.recycling_id}
                getApi={this.api.get_recycling}
                restorationApi={this.api.restoration}
                title={this.getText(15413)}
                columnTitles={[this.getText(15414), this.getText(15415), this.getText(15416), this.getText(15422), this.getText(15417)]}
                popconfirmText={{ title: this.getText(15418), okText: this.getText(15419), cancelText: this.getText(15420) }}
                onClose={this.refreshData}
                {...this.props}
            />,
            <UsersTableColumns {...this.props}
                title={this.getText(20400)}
                table={"Customers"}
                viewList={this.state.userColumnsList}
                setViewList={v => { this.setState({ userColumnsList: v }) }}
                list={columns.slice(0, -1).map((x, i) => { return { key: i + 1, name: x.title } })}
            />
        ];

        const mLPI = this.state.modalLinkPersonalInformation;
        const mk100 = this.state.modalK100Settings;

        let columnsView = this.state.userColumnsList ? [...this.state.userColumnsList.map(x => columns[x - 1]), columns.slice(-1)[0]] : columns



        return (
            <div/* style={divStyle}*/>

                <h4 style={{ textAlign: "left" }}>
                    {this.state.debtsCalc ? [this.getText(14266), <Spin />]
                        : this.state.lastCulcTime ? this.getText(17010) + " " + this.state.lastCulcTime : ""}
                </h4>

                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columnsView}
                    rowClassName={(record) => record.markCustNotSales == 1 ? "row_yellow" : ""}
                    percentageToMobile={[30, 35, 35]}
                    title={this.getText(10723)}
                    actionButtons={[
                        {
                            buttonType: 'add',
                            buttonFunction: () => {
                                this.props.uiActions.showModal(TableIDs.modalCreateEditCustomer, 0);
                            }
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        }, {
                            buttonType: 'filter',
                            buttonFunction: () => {
                                this.setState({ modalFilter: true });
                            }
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: this.initFilters,
                        },
                    ]}
                    genericActionButtons={genericActionButtons}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }
                        let filter = {
                            _words: escapeRegExp(val),
                            page: 1,
                            _page_num: 1,
                        }
                        this.setFilter(filter);
                        // this.props.dataActions.genericDataSetFilter(
                        //     this.id, this.props.user.companyCode, this.props.user.token,
                        //     { ...request, _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />

                <ModalCreateEditCustomer
                    id={TableIDs.modalCreateEditCustomer}
                    roleId={2}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditCustomer)}
                    ActionQueue={this.props.ActionQueue}
                    isRefreshParentTable={true}
                />

                <ModalCustomersDebtReport
                    id={TableIDs.modalCustomersDebtReport}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCustomersDebtReport)}
                    title={this.getText(10734)}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalCustomerDebtDetailedReport
                    id={TableIDs.modalCustomerDebtDetailedReport}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCustomerDebtDetailedReport)}
                    title={this.getText(10735)}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalSendLinkSelfCustomer
                    id={TableIDs.modalSendLinkSelfCustomer}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSendLinkSelfCustomer)}
                    title={this.getText(12614)} type={1} params={""}
                />

                <CustomerFilter
                    mainId={this.id}
                    visible={this.state.modalFilter}
                    onClose={() => { this.setState({ modalFilter: false }) }}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.initFilters()
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    filterRequest={this.state.filterRequest}
                />

                <GenericModal
                    width={500}
                    title={this.getText(15610) + " - " + (this.state.modalCustBranch ? this.state.modalCustBranch.mContactPersonName : "")}
                    visible={this.state.modalCustBranch !== null}
                    onCancel={() => { this.setState({ modalCustBranch: null }) }}
                    footer={[
                        <Button type="primary" onClick={this.saveCustBranches}>{this.getText(15611)}</Button>,
                        <Button onClick={() => { this.setState({ modalCustBranch: null }) }}>{this.getText(15612)}</Button>,
                    ]}>
                    <Form.Item label={this.getText(15613)}>
                        <GenericSelector
                            {...this.branchSelector} {...this.props} multi
                            value={this.state.modalCustBranch && this.state.modalCustBranch.mBranchList ? this.state.modalCustBranch.mBranchList.split(',') : []}
                            onChange={(e) => { this.setState({ modalCustBranch: { ...this.state.modalCustBranch, mBranchList: e ? e.join(',') : '' } }) }} />
                    </Form.Item>
                </GenericModal>
                <GenericModal
                    visible={this.state.modalConnectedCustomers !== null}
                    onCancel={() => { this.setState({ modalConnectedCustomers: null }, this.refreshData) }}
                    title={this.state.modalConnectedCustomers ? gt(17002) + " " + this.state.modalConnectedCustomers.mContactPersonName : ""}
                    width={600}
                    actionButtons={[
                        {
                            buttonType: 'add',
                            buttonFunction: () => {
                                this.props.dataActions.genericDataStartInlineCreate(this.connected_id);
                            }
                        }, {
                            buttonType: 'refresh',
                            buttonFunction: () => {
                                this.getConnectedCustomers(this.state.modalConnectedCustomers.mId)
                            }
                        }
                    ]}>
                    <ResponsiveTable
                        idTable={this.props.data[this.connected_id]}
                        columns={[
                            { title: gt(17004), key: "_secondaryCust", dataIndex: "_secondaryCust", width: "60%", render: this.renderSecondaryCust },
                            { title: gt(17005), key: "_summerySeparately", dataIndex: "_summerySeparately", width: "20%", render: this.renderSummerySeparately },
                            { title: gt(17006), key: "_id", dataIndex: "_id", width: "20%", render: this.renderEditConnected },
                        ]}
                        tableOnly
                        pagination={{ pageSize: 10 }}
                    />
                </GenericModal>
                <GenericModal
                    width={500}
                    title={this.getText(19165) + " - " + (mLPI ? mLPI.mContactPersonName : "")}
                    visible={mLPI !== null}
                    onCancel={() => { this.setState({ modalLinkPersonalInformation: null }) }}
                    footer={[
                        <Button type="primary" disabled={(!mLPI || !mLPI.sendType)} onClick={this.createLinkPersonalInformation}>{this.getText(19166)}</Button>,
                        <Button onClick={() => { this.setState({ modalLinkPersonalInformation: null }) }}>{this.getText(19167)}</Button>,
                    ]}>
                    {mLPI ?
                        <Radio.Group
                            value={mLPI.sendType}
                            onChange={e => { this.setState({ modalLinkPersonalInformation: { ...mLPI, sendType: e.target.value } }) }}>
                            <Radio value={'1'} disabled={!this.emailValidate(mLPI.sendEmail)}>
                                {gt(19168) + " - "}
                                <Input
                                    style={{ width: 300 }}
                                    value={mLPI.sendEmail}
                                    onChange={e => {
                                        this.setState({ modalLinkPersonalInformation: { ...mLPI, sendEmail: e.target.value, sendType: this.emailValidate(e.target.value) ? '1' : null } })
                                    }} />
                            </Radio>
                            <br />
                            <Radio value={'2'} disabled={!this.mobileValidate(mLPI.sendMobile)}>
                                {gt(19169) + " - "}
                                <Input
                                    style={{ width: 300 }}
                                    value={mLPI.sendMobile}
                                    onChange={e => {
                                        this.setState({ modalLinkPersonalInformation: { ...mLPI, sendMobile: e.target.value, sendType: this.mobileValidate(e.target.value) ? '2' : null } })
                                    }} />
                            </Radio>
                        </Radio.Group>
                        : ""}
                </GenericModal>
                <ModalDocumentPrintOptions
                    {...this.props}
                    visible={this.state.modalDocumentPrintOptions}
                    onCancel={() => { this.setState({ modalDocumentPrintOptions: null }) }}
                    isBO={this.state.modalDocumentPrintOptionsIsBO}
                    onChangeIsBo={(e, n) => { this.setState({ modalDocumentPrintOptionsIsBO: e }, n) }}
                    isCustSetting
                />
                <ModalContacts
                    {...this.props}
                    visible={this.state.modalContacts?.code ?? null}
                    onCancel={() => { this.setState({ modalContacts: null }) }}
                    title={this.getText(19664) + " " + this.state.modalContacts?.code + " - " + this.state.modalContacts?.name}
                    ownerType={"0"}
                />
                <GenericModal
                    visible={mk100 != null}
                    onCancel={() => { this.setState({ modalK100Settings: null }) }}
                    title={this.getText(19770) + " " + mk100?.code + " - " + mk100?.name}
                    width={400}
                    footer={[
                        <Button type="primary" onClick={this.saveK100Settings}>{this.getText(19771)}</Button>,
                        <Button onClick={() => { this.setState({ modalK100Settings: null }) }}>{this.getText(19772)}</Button>,
                    ]}>
                    {mk100?.isSecondary ? "" :
                        <Form.Item label={this.getText(19773)}>
                            <Input
                                value={mk100?.suppCode}
                                onChange={e => {
                                    if (e.target.value.length > 20) message.error(this.getText(19777))
                                    else this.setState({ modalK100Settings: { ...mk100, suppCode: e.target.value } })
                                }} />
                        </Form.Item>}
                    {mk100?.isPrimary ? "" :
                        <Form.Item label={this.getText(19774)}>
                            <Input
                                value={mk100?.storeCode}
                                onChange={e => {
                                    if (e.target.value.length > 20) message.error(this.getText(19777))
                                    else this.setState({ modalK100Settings: { ...mk100, storeCode: e.target.value } })
                                }} />
                        </Form.Item>}
                    {mk100?.isSecondary ? "" :
                        <Form.Item>
                            <Checkbox
                                checked={mk100?.isRef2Req == "1"}
                                onChange={e => {
                                    this.setState({ modalK100Settings: { ...mk100, isRef2Req: e.target.checked ? "1" : "0" } })
                                }}
                            >{this.getText(19775)}</Checkbox>
                        </Form.Item>}

                    <h3> * {this.getText(19776)}</h3>

                </GenericModal>
                <CopyDocumentReport
                    {...this.props}
                    isModalFromDashboard
                    visible={this.state.modalDocuments > 0}
                    onCancel={() => { this.setState({ modalDocuments: null }) }}
                    custId={this.state.modalDocuments}
                />
                <CustomerBusinessBranches
                    {...this.props}
                    visible={this.state.modalBusinessBranches}
                    onCancel={() => { this.setState({ modalBusinessBranches: null }) }}
                />
            </div>)
    }
}
export default CustomerTable;
