/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Tooltip, Popover } from "antd";
import Jax from "../../Jax/jax.es6.module";

import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import ModalSearchFixLabDocuments from "../../components/ModalSearchFixLabDocuments";
import ModalCreateEditFixLabDocuments from "../../components/ModalCreateEditFixLabDocuments";
import LangContext from "../../contextProvider/LangContext";
import { escapeRegExp } from "../../utils/utils";
import moment from "moment";
import ReportShowButton from "../../components/ReportShowButton";

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class FixLabDocuments extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "FIX_LAB_DOCUMENTS";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            printFixLab: {
                DocumentNumber: 0,
                DocumentTypeID: 0,
                DocumentPOSID: 0,
                CompanyCode: this.props.user.companyCode,
                reportName: "reports/DocumentFixLab.pdf"
            },

            filterActive: false,
            valueSearch: '',

        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };



        this.api = {
            get: "get_fixLabDocuments_table_bo",

        }


        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetWithParams, args: ["customerList", this.props.user.companyCode, this.props.user.token, {
                dataObject: "customerList"
            }, "data_get"]
        });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setReportParametersAndDo(report: string, params: Object, func) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState, func);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    printerPicker = (record) => {
        const handleClick = (type: String) => {
            this.setReportParametersAndDo("printFixLab", {
                DocumentNumber: record.key,
                DocumentTypeID: 12,
                DocumentPOSID: record.fPosId,
                CompanyCode: this.props.user.companyCode,
                reportName: "reports/DocumentFixLab." + type
            }, () => { this.generateReport.apply(this, ["printFixLab"]) })
            //this.setReportParameters("PaymentsSearchReport", { reportName: "reports/paymentsSearch." + this.state.type, type: value });         
        }
        const content = (<div>
            <Tooltip title={this.getText(11217)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-pdf"} onClick={() => { handleClick("pdf") }} />
            </Tooltip>
            <Tooltip title={this.getText(11218)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-excel"} onClick={() => { handleClick("xls") }} />
            </Tooltip>
            <Tooltip title={this.getText(11219)}>
                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                    type={"file-word"} onClick={() => { handleClick("docx") }} />
            </Tooltip>
        </div>);

        return (
            <span>
                <Popover content={content} title={this.getText(11220)} trigger="click">
                    <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="printer" theme="outlined" />
                </Popover>
            </span>
        )
    }


    componentDidMount() {
        //  document.title = "Cash On Tab - תיקונים";
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        let curdate = moment().format("YYYY-MM-DD")
        let days60Age = moment().add(-60, "days").format("YYYY-MM-DD")
        this.initFilters(days60Age, curdate);


        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10164);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, () => {
            let curdate = moment().format("YYYY-MM-DD")
            let days60Age = moment().add(-60, "days").format("YYYY-MM-DD")
            this.initFilters(days60Age, curdate);
        });
    };

    initFilters = (_fromDate, _toDate) => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 0,
            _sort_by: 'col_0',
            _statusFilter: '',
            _statusDateStart: _fromDate ?? null,
            _statusDateEnd: _toDate ?? null,
            _storageList: '',
            _custFields: '',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);

        this.setState({ filterActive: false, valueSearch: "" })

    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }


    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 11;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "fId": col--;
            case "fPosId": col--;
            case "fReceivingTime": col--;
            case "fReceivingEmployee": col--;
            case "fEstimatedTime": col--;
            case "fActualTime": col--;
            case "fActualEmployee": col--;
            case "fStatus": col--;
            case "fStatusColor": col--;
            case "fCustomerId": col--;
            case "fCustomerName": break; // 11
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    estimatedTimeRender = (text, record) => {
        let timeOver = new Date(text) - Date.now();
        let hour = 60 * 60 * 1000;
        return (timeOver > (hour * 3)) ? text :
            (<span style={{ backgroundColor: (timeOver > hour) ? 'pink' : 'red' }}>{text}</span>);
    }

    statusRender = (text, record) => {
        return (<div style={{ backgroundColor: record.fStatusColor }}>{text}</div>);
    }

    editColRender = (text, record) => {
        return (<span>
            <Tooltip title={this.getText(11216)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                    this.setState({ fixId: record['fId'] },
                        this.props.uiActions.showModal(TableIDs.modalCreateEditFixLabDocuments,
                            { fixId: record['fId'], fixIndex: record.index }));
                    this.props.dataActions.genericDataSetEdit("customer.editedRecord.customers",
                        { customerId: record['fCustomerId'], customerName: record['fCustomerName'] });
                }} />
            </Tooltip>
            {this.printerPicker(record)}
            <ReportShowButton
                {...this.props}
                iconButton
                validSubmit
                params={{
                    DocumentNumber: record.key,
                    DocumentTypeID: 12,
                    DocumentPOSID: record.fPosId,
                    CompanyCode: this.props.user.companyCode,
                    reportName: "reports/DocumentFixLab.pdf"
                }}
            />
        </span>);
    }


    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let gt = this.getText;
        let render = t => t;
        let sorter = true;

        let columns = [
            { title: gt(11208), dataIndex: "fId", key: "fId", width: '8%', sorter, render },
            { title: gt(11209), dataIndex: "fCustomerId", key: "fCustomerId", width: '8%', sorter, render },
            { title: gt(11210), dataIndex: "fCustomerName", key: "fCustomerName", width: '15%', sorter, render },
            { title: gt(11211), dataIndex: "fReceivingTime", key: "fReceivingTime", width: '15%', sorter, render },
            { title: gt(11212), dataIndex: "fEstimatedTime", key: "fEstimatedTime", width: '15%', sorter, render: this.estimatedTimeRender },
            { title: gt(11213), dataIndex: "fActualTime", key: "fActualTime", width: '15%', sorter, render },
            { title: gt(11214), dataIndex: "fStatus", key: "fStatus", width: '15%', sorter, render: this.statusRender },
            { title: gt(11215), width: '8%', render: this.editColRender },
        ];

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    percentageToMobile={[false, false, 30, 35, 35]}
                    title={this.getText(11207)}
                    actionButtons={[
                        {
                            buttonType: 'filter',
                            buttonFunction: () => {
                                this.setState({ fixId: 0 },
                                    this.props.uiActions.showModal(TableIDs.modalSearchFixLabDocuments,
                                        { fixId: 0, fixIndex: 0 }));
                            },
                        }, {
                            buttonDisabled: !this.state.filterActive,
                            buttonType: 'clear',
                            buttonFunction: () => { this.initFilters() },
                        }, {
                            buttonType: 'add',
                            buttonFunction: () => {
                                this.setState({ fixId: 0 },
                                    this.props.uiActions.showModal(TableIDs.modalCreateEditFixLabDocuments,
                                        { fixId: 0, fixIndex: 0, fCustomerId: 0 }));
                                this.props.dataActions.genericDataSetEdit("customer.editedRecord.customers",
                                    { customerId: 0, customerName: '' });
                            }
                        },
                        {
                            buttonType: 'refresh',
                            buttonFunction: this.refreshData
                        },
                    ]}
                    //genericActionButtons={this.genericActionButtons}
                    showsearch={(val) => {
                        if (val) { this.setState({ filterActive: true }) }

                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                _words: escapeRegExp(val),
                                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}

                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />

                <ModalSearchFixLabDocuments
                    id={TableIDs.modalSearchFixLabDocuments}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchFixLabDocuments)}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => {
                        if (e) this.setState({ filterActive: true })
                        else this.initFilters()
                    }}
                //  title={"סינון לפי סטטוס"}
                />

                <ModalCreateEditFixLabDocuments
                    id={TableIDs.modalCreateEditFixLabDocuments}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditFixLabDocuments)}
                //  title={"טופס תיקון"}
                />

            </div>
        );
    }
}

export default FixLabDocuments;

