/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, Col, Row, Form, Select, Checkbox, InputNumber, //InputNumber 
} from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils"
import CustPicker from "../../components/CustPicker";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentsChecks extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PaymentsChecks: {
                LowerSelectionDate: moment().add(-1, "month").format("YYYY-MM-01"),
                UpperSelectionDate: moment().format("YYYY-MM-DD"),
                LowerSelectionTime: "00:00:00",
                UpperSelectionTime: "23:59:59",
                RepaymentLowerSelectionDate: null,
                RepaymentLowerSelectionTime: "00:00:00",
                RepaymentUpperSelectionDate: null,
                RepaymentUpperSelectionTime: "23:59:59",

                LowerSum: null,
                UpperSum: null,
                CustId: null,

                PosList: '',
                StorageList: '',
                reportName: "reports/paymentsChecks.pdf",
            }
        };
        this.state.type = "pdf";

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_storages: "get_storage_list",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        //this.setReportParameters("PaymentsChecks", { RequestedReport: 0 });
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
        //const list = this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',');
        //this.setReportParameters("PaymentsChecks", { mPosList: list });
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10194);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("PaymentsChecks", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
    }
    disabledStartDate = (date) => {
        const FromDate = moment(date).format("YYYY-MM-DD");
        const ToDate = this.state.PaymentsChecks.UpperSelectionDate;

        if (ToDate) console.log("FromDate", FromDate.valueOf(), ToDate.valueOf(), FromDate.valueOf() > ToDate.valueOf());
        return ToDate && FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (date) => {
        const FromDate = this.state.PaymentsChecks.LowerSelectionDate;
        const ToDate = moment(date).format("YYYY-MM-DD");

        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    /*
        onChange = (field, value) => {
            this.setReportParameters("PaymentsChecks", { [field]: value })
            this.setState({
                [field]: value,
            });
        }*/

    // onChange = (field, field2, value) => {
    //     let arr = (value ? value.format("YYYY-MM-DD#HH:mm:ss") : '').split('#');;

    //     console.log("f ", field, "v ", value);
    //     this.setReportParameters("PaymentsChecks", { [field]: arr[0], [field2]: arr[1] })
    // }

    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentsChecks", { [field]: data });
                    else
                        this.setReportParameters("PaymentsChecks", { [field]: '' });
                }} />);
    }

    render() {
        const { PaymentsChecks, endOpen } = this.state;
        const { LowerSelectionDate, UpperSelectionDate, RepaymentLowerSelectionDate, RepaymentUpperSelectionDate, LowerSum, UpperSum, CustId } = PaymentsChecks;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { PaymentsChecks } = this.state;

        const isDocsShownStyle = { display: (PaymentsChecks.RequestedReport === 2) ? 'block' : 'none' }
        //const isEndDateShownStyle = { display: (PaymentsChecks.RequestedReport === 2) ? 'block' : 'none'}

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        //this.setReportParameters("PaymentsChecks", {
        //    mPosList: this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',')
        //});

        let mobile = window.innerWidth > 600 ? false : true;

        const styleCol12 = (label, content) => (<Col span={mobile ? 24 : 12}><FormItem label={this.getText(label)}>{content}</FormItem></Col>)


        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(12225)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>


                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <Row>
                            {styleCol12(12226, <DatePicker
                                // disabledDate={d => disabledDatePicker(d, null, UpperSelectionDate)}
                                // disabledDate={this.disabledStartDate}
                                disabledDate={d => disabledDatePicker(d,
                                    UpperSelectionDate ? moment(UpperSelectionDate).add(-370, "days").format("YYYY-MM-DD") : null,
                                    UpperSelectionDate)}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={LowerSelectionDate ? moment(LowerSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12237)}
                                // onChange={(value) => this.onChange('LowerSelectionDate', 'LowerSelectionTime', value)}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { LowerSelectionDate: value ? value.format("YYYY-MM-DD") : '' }) }}
                            //onOpenChange={this.handleStartOpenChange}
                            />)}

                            {styleCol12(12227, <DatePicker
                                // disabledDate={d => disabledDatePicker(d, LowerSelectionDate, null)}
                                // disabledDate={this.disabledEndDate}
                                disabledDate={d => disabledDatePicker(d,
                                    LowerSelectionDate,
                                    LowerSelectionDate ? moment(LowerSelectionDate).add(370, "days").format("YYYY-MM-DD") : null)}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={UpperSelectionDate ? moment(UpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12238)}
                                // onChange={(value) => this.onChange('UpperSelectionDate', 'UpperSelectionTime', value)}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { UpperSelectionDate: value ? value.format("YYYY-MM-DD") : '' }) }}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />)}

                            {styleCol12(12228, <DatePicker
                                disabledDate={d => disabledDatePicker(d, null, RepaymentUpperSelectionDate)}
                                // disabledDate={this.disabledStartDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={RepaymentLowerSelectionDate ? moment(RepaymentLowerSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12237)}
                                // onChange={(value) => this.onChange('RepaymentLowerSelectionDate', 'RepaymentLowerSelectionTime', value)}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { RepaymentLowerSelectionDate: value ? value.format("YYYY-MM-DD") : '' }) }}
                            //onOpenChange={this.handleStartOpenChange}
                            />)}

                            {styleCol12(12229, <DatePicker
                                disabledDate={d => disabledDatePicker(d, RepaymentLowerSelectionDate, null)}
                                // disabledDate={this.disabledEndDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={RepaymentUpperSelectionDate ? moment(RepaymentUpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12238)}
                                // onChange={(value) => this.onChange('RepaymentUpperSelectionDate', 'RepaymentUpperSelectionTime', value)}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { RepaymentUpperSelectionDate: value ? value.format("YYYY-MM-DD") : '' }) }}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />)}

                            {styleCol12(18144, (<InputNumber
                                // min={0}
                                max={UpperSum ? UpperSum : undefined}
                                value={LowerSum}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { LowerSum: value }) }}
                            />))}

                            {styleCol12(18145, (<InputNumber
                                // min={LowerSum ? LowerSum : 0}
                                min={LowerSum ? LowerSum : undefined}
                                value={UpperSum}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { UpperSum: value }) }}
                            />))}

                        </Row>
                        <FormItem label={this.getText(18146)}>
                            <CustPicker {...this.props}
                                value={CustId}
                                onChange={(value) => { this.setReportParameters("PaymentsChecks", { CustId: value }) }}
                                defaultView={this.getText(12121)}
                            />
                        </FormItem>

                        <FormItem label={this.getText(12230)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setReportParameters("PaymentsChecks", { PosList: value.join(','), StorageList: typeList.join(',') })}
                                value={(this.state.PaymentsChecks.PosList === '') ? [] : this.state.PaymentsChecks.PosList.split(',')}
                            />

                            {/* {this.createCheckBox('PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"PosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentsChecks", { PosList: value.join(',') })}
                                value={(this.state.PaymentsChecks.PosList === '') ? [] : this.state.PaymentsChecks.PosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>

                        {/* <FormItem label={this.getText(12231)}>
                            {this.createCheckBox('StorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentsChecks", { StorageList: value.join(',') })}
                                value={(this.state.PaymentsChecks.StorageList === '') ? [] : this.state.PaymentsChecks.StorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.PaymentsChecks}
                            posField={'PosList'}
                            storagesField={'StorageList'}
                            datesToFavorites={[
                                { field: 'LowerSelectionDate', label: this.getText(12226) },
                                { field: 'UpperSelectionDate', label: this.getText(12227) },
                                { field: 'RepaymentLowerSelectionDate', label: this.getText(12228) },
                                { field: 'RepaymentUpperSelectionDate', label: this.getText(12229) },
                            ]}
                            favoriteSave
                        />


                        {/* <FormItem key={"reportName"} label={this.getText(12232)}>
                            <Select style={{ width: 290 }}
                                //size={"large"}
                                className={"reports/PaymentsChecks.pdf"}
                                defaultValue={"pdf"}
                                //combobox={null}
                                //defaultValue={"reports/PaymentsChecks.pdf"}
                                onChange={(value) => {
                                    this.setReportParameters("PaymentsChecks",
                                        { reportName: "reports/paymentsChecks." + value, type: value });

                                }}
                            >
                                <Option key={"pdf"}>{this.getText(12233)}</Option>
                                <Option key={"xls"}>{this.getText(12234)}</Option>
                                <Option key={"docx"}>{this.getText(12235)}</Option>
                            </Select>
                        </FormItem>

                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.PaymentsChecks.RequestedReport;
                            this.generateReport.apply(this, ["PaymentsChecks"])
                        }}>{this.getText(12236)}</Button> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default PaymentsChecks;