/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Row, Col, Input, InputNumber, Card, message, Form } from "antd";
import "../App.css";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import GenericModal from "./GenericModal";
import TableLineIcons from "./TableLineIcons";
import DateTimePicker from "./DateTimePicker";
import Jax from "../Jax/jax.es6.module";
//import { Job, User, OutputType, ProcessType } from '../JmReact';
import moment from "moment";
import { isBODev } from "../utils/utils";

const topHeight = 340; // px
const rowHeight = 30;  // px

class CreateDocumentPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentModal: null,
            editCheck: 0,
            checksCount: 1,
        }

        this.mobile = window.innerWidth <= 600;

        this.payTemplete = {
            paymentTypeID: null,
            description: null,
            number: null,
            timestamp: null,

            defaultCurrencyChange: null,
            defaultCurrencySum: null,
            foreignCurrencyChange: null,
            foreignCurrencyID: null,
            foreignCurrencyRate: null,
            foreignCurrencySum: null,

            checkAccountNumber: null,
            checkBankNumber: null,
            checkBranchNumber: null,
            checkNumber: null,

            creditCardCompanyID: '0',
            creditCardConfirmationNumber: null,
            creditCardFirstInstallmentSum: '0',
            creditCardNumOfInstallments: '0',
            creditCardNumber: null,
            creditCardOtherInstallmentsSum: '0',
            creditCardTelOrSign: '0',
            creditCardTypeID: '0',
            creditCardValidThru: '0',
            payerId: null,

            valueDate: '1970-01-01'
        }

        this.checkNumberRegExp = new RegExp(/^[0-9]{1,18}$/)

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getDocumentTotal = (source, lineField) => {
        let itemList = Jax.get(this.props.data, source, []);
        return itemList.reduce(function (a, v) {
            if (v.key === 0) {
                return a;
            }
            return a + parseFloat(v[lineField] ? v[lineField] : 0.0);
        }, 0) || 0.0;
    }

    getPaymentsTotal = () => {
        let totalPay = this.getSumFormat(this.getDocumentTotal("documentPayments.data", "defaultCurrencySum"));
        let totalChange = this.getSumFormat(this.getDocumentTotal("documentPayments.data", "defaultCurrencyChange"));

        return this.getSumFormat(totalPay - totalChange)
    }

    getBalanceTotal = () => {
        let totalSum = this.getDocumentTotal("document2.dataSource", "totalSumWithTax");
        let totalPay = this.getPaymentsTotal()
        return this.getSumFormat(totalSum - totalPay)
    }

    getPayTypeList = () => {
        const { data, headers } = this.props.data.paymentTypes;
        return data.map(x => {
            let y = x.split('\f')
            let res = {}
            headers.forEach((z, i) => {
                res = { ...res, [z]: y[i] }
            });
            return res;
        })
    }

    getPayTypeButton = (x) => {

        let number = this.props.data.documentPayments.data.length + 1;
        let valueDate = x.typeId > 1 ? this.addToDate(new Date()) : '1970-01-01';
        let balance = this.getBalanceTotal()
        let defaultCurrencySum = x.typeId > 1 && this.props.documentType == '1' ? balance : null;
        let onClick = () => { this.setState({ paymentModal: { ...this.payTemplete, paymentTypeID: x.typeId, description: x.name, number, valueDate, defaultCurrencySum } }) }

        return (<Button
            size={'large'} type={'primary'} style={{ width: '100%', margin: 5 }}
            disabled={balance <= 0 && this.props.documentType == '1'} onClick={onClick}
        >{x.name}</Button>)
    }

    createLabelInput = (label, value) => {
        return (<div style={{ width: 300, marginRight: 'auto' }}>
            <Row>
                <Col span={12}>{label}</Col>
                <Col span={12}><Input readOnly value={value} /></Col>
            </Row>
        </div>)
    }

    createLimitInputWithStatus = (props, max) => {
        let regex = new RegExp(/^[0-9]+$/)
        return (<Form.Item required
            validateStatus={!props.value || props.value.length > max ? "error" : "success"}
        >
            <Input {...props}
                onChange={e => { if (!e.target.value || regex.test(e.target.value)) props.onChange(e.target.value) }} />
            {props.value && props.value.length > max ? (<b style={{ color: "red" }}>שדה זו מוגבלת ל{max} תוים בלבד</b>) : ""}
        </Form.Item>
        )
    }
    createLabelInputModal = (label, field, isRequired, moreProps) => {
        let m = this.state.paymentModal;
        if (m) {

            let props = {
                value: m[field],
                onChange: (e) => { this.setModalField(field, e) }
            }
            if (moreProps) props = { ...props, ...moreProps }

            return (<Row style={{ padding: 5 }}>
                <Col span={8}>
                    {isRequired ? (<span style={{ color: 'red' }}> * </span>) : ""}
                    {this.getText(label)}
                </Col>
                <Col span={16}>
                    {field === 'valueDate' ? (<DateTimePicker {...props} format="DD/MM/YYYY" />)
                        : field == "checkNumber" ? this.createLimitInputWithStatus(props, 18)
                            : field == "checkBankNumber" ? this.createLimitInputWithStatus(props, 2)
                                : field == "checkBranchNumber" ? this.createLimitInputWithStatus(props, 5)
                                    : field == "checkAccountNumber" ? this.createLimitInputWithStatus(props, 9)
                                        : (<InputNumber style={{ width: '100%' }} {...props} />)}
                </Col>
            </Row>)
        } else {
            return ""
        }
    }

    getPayImg = (sum, url) => {
        let origin = window.location.origin;
        if (isBODev()) origin += '/cash-on-tab/build';
        
        let src = isBODev() ? "/cash-on-tab/build/img/" + url : "/img/" + url;
        let m = this.state.paymentModal;
        let addSum = m && m.defaultCurrencySum ? m.defaultCurrencySum + sum : sum;
        let balance = this.getBalanceTotal();
        return (<Col span={sum > 10 ? 6 : 4} style={{ padding: 5, cursor: 'pointer' }}>
            <Card onClick={() => {
                this.setModalField('defaultCurrencySum', addSum);
                if (balance - addSum <= 0 && this.props.documentType == '1') setTimeout(this.handleOk, 1000);
            }}><img src={src} alt={'Currency ' + sum} width={'100%'} /></Card>
        </Col>)
    }

    setModalField = (field, value) => {
        this.setState({ paymentModal: { ...this.state.paymentModal, [field]: value } })
    }

    setModalFieldMaxToChange = (field, value, max) => {
        if (value > max) message.error(this.getText(19013) + max)
        else this.setModalField(field, value)
    }

    deletePayment = (num) => {
        let number = 0;
        let data = this.props.data.documentPayments.data
            .filter(f => f.number != num && !this.getSumFormat(f.defaultCurrencyChange))
            .map(x => {
                number++;
                return { ...x, number }
            })
        this.props.dataActions.setJson('documentPayments', { data });
    }

    addToDate = (date, addMonth) => {
        if (!date) return null;
        if (addMonth) return moment(date).add(addMonth, 'M').format('YYYY-MM-DD')
        return moment(date).format('YYYY-MM-DD')


        // let d = new Date(date);
        // let yy = d.getFullYear();
        // let mm = d.getMonth();
        // let dd = d.getDate();
        // if (addMonth) {
        //     if (mm == 11) {
        //         mm = 0;
        //         yy++;
        //     } else {
        //         mm++;
        //     }
        // }
        // let zeros = (n) => n < 10 ? "0" + n : n;
        // return (yy + "-" + zeros(mm + 1) + "-" + zeros(dd))
    }

    copyCheck = (record) => {
        let checksCopyStack = [record];
        let balance = this.getBalanceTotal();
        let firstValueDate = record.valueDate;
        let addMonth = 1;

        for (let x = this.state.checksCount; x > 1; x--) {
            balance -= record.defaultCurrencySum;
            let number = record.number + 1;
            let valueDate = this.addToDate(firstValueDate, addMonth);
            let checkNumber = String(parseInt(record.checkNumber) + 1);
            addMonth++
            if (this.props.documentType == '1' && balance > 0) {
                let defaultCurrencySum = (balance > record.defaultCurrencySum) ? record.defaultCurrencySum : balance;
                record = { ...record, valueDate, checkNumber, number, defaultCurrencySum }
                checksCopyStack.push(record)
            } else if (this.props.documentType != '1') {
                record = { ...record, valueDate, checkNumber, number }
                checksCopyStack.push(record)
            }
        }

        let data = [...this.props.data.documentPayments.data, ...checksCopyStack];
        this.props.dataActions.setJson('documentPayments', { data });
    }

    getSumFormat = (sum) => {
        return sum ? Math.round(sum * 100) / 100 : 0;
    }

    renderDetailet = (text, record) => {
        if (this.getSumFormat(record.defaultCurrencyChange)) {
            return this.getText(51782)
        } else if (record.paymentTypeID == 2) {
            return this.getText(51786) + ": " + record.valueDate + ", " +
                this.getText(51787) + ": " + record.checkNumber + ", " +
                this.getText(51788) + ": " + record.checkBankNumber + ", " +
                this.getText(51789) + ": " + record.checkBranchNumber + ", " +
                this.getText(51790) + ": " + record.checkAccountNumber + ".";
        } else if (record.paymentTypeID > 9) {
            let t1 = record.valueDate ? this.getText(16687) + ": " + record.valueDate : ""
            let t2 = record.checkNumber ? this.getText(16686) + ": " + record.checkNumber : ""
            return t1 && t2 ? t1 + ', ' + t2 : t1 + t2
        } else {
            return ''
        }
    }

    renderEdit = (text, record) => {
        let props = {
            onDelete: () => { this.deletePayment(record.number) },
            deleteQuetion: { title: this.getText(51783), okText: this.getText(51784), cancelText: this.getText(51785) },
            // }
            // if (record.paymentTypeID == 2) {
            //     props = {
            //         ...props, 
            onEdit: () => {
                let toEdit = this.props.data.documentPayments.data[record.number - 1];
                if (this.props.documentType == '23') {
                    toEdit = { ...toEdit, defaultCurrencySum: this.getSumFormat(toEdit.defaultCurrencySum * -1) }
                }
                this.setState({ paymentModal: toEdit, editCheck: toEdit.defaultCurrencySum })
            }
        }

        // if (this.getBalanceTotal() > 0) {
        //     props = { ...props, genericIcons: [{ type: 'copy', onClick: () => { this.copyCheck(record) }, tooltip: this.getText(51792) }] }
        // }
        // }

        return (!this.getSumFormat(record.defaultCurrencyChange)) ? (<TableLineIcons {...props} />) : "";
    }

    handleCancel = () => {
        this.setState({ paymentModal: null, editCheck: 0, checksCount: 1 })
    }

    handleOk = () => {
        let m = this.state.paymentModal;
        let defaultCurrencySum = m && m.defaultCurrencySum ? m.defaultCurrencySum : 0;
        let valid = defaultCurrencySum > 0 || this.props.documentType == 53;
        console.log("this.state.paymentModal",this.state.paymentModal)
        if (m && m.paymentTypeID == 2) {
            valid = valid && m.checkNumber && m.checkBankNumber && m.checkBranchNumber && m.checkAccountNumber && m.valueDate
                && m.checkNumber.length <= 18
                && m.checkBankNumber.length <= 2
                && m.checkBranchNumber.length <= 5
                && m.checkAccountNumber.length <= 9;
        } else if (m && m.paymentTypeID > 9) {
            let dinamicPaymentList = this.getPayTypeList().filter(f => f.typeId > 9 && f.typeId < 100);
            let dChanged = dinamicPaymentList.find(f => f.typeId === m.paymentTypeID)
            if (!dChanged) dChanged = {}
            if (dChanged.saveReference == 1 && dChanged.isReferenceRequired == 1 && (!m.checkNumber || m.checkNumber.length > 18)) valid = false;
            if (dChanged.saveDate == 1 && dChanged.isDateRequired == 1 && !m.valueDate) valid = false;
        }
        

        if (!valid) {
            message.error(this.getText(51793))
        } else {
            let newData = (this.props.documentType == '23') ? { ...m, defaultCurrencySum: this.getSumFormat(m.defaultCurrencySum * -1) } : m
            if (this.state.editCheck) {
                let data = this.props.data.documentPayments.data
                    .filter(f => !this.getSumFormat(f.defaultCurrencyChange))
                    .map(x => (x.number === m.number) ? newData : x);

                this.props.dataActions.setJson('documentPayments', { data });
            } else {
                let data = [...this.props.data.documentPayments.data, newData];

                this.props.dataActions.setJson('documentPayments', { data });
                if (m.paymentTypeID == 2 && this.state.checksCount > 1) { this.copyCheck(newData) }
            }

            setTimeout(this.handleAddRow, 1000);

            this.handleCancel();
        }
    }

    handleAddRow = () => {
        if (this.props.documentType == '1') {
            let source = this.props.data.documentPayments.data;
            let totalBalance = this.getBalanceTotal();
            // let totalBalanceRounded = Math.round(totalBalance / this.props.gp_roundTotal) * this.props.gp_roundTotal;
            let totalBalanceRounded = this.props.calculateRoundSum(totalBalance).totalWithTax;
            let defaultCurrencyChange = totalBalanceRounded < 0 ? Math.round(totalBalanceRounded * -10) / 10 : 0;
            if (defaultCurrencyChange && source.find(f => f.paymentTypeID == 1)) {
                let number = source.length + 1;
                let data = [...source, { ...this.payTemplete, paymentTypeID: 1, description: 'מזומן', number, defaultCurrencyChange, valueDate: '1970-01-01' }]
                this.props.dataActions.setJson('documentPayments', { data });
            }

        }

        // if (this.props.documentType == '3' || this.props.documentType == '23' ) {
        //     let totalWithTax = 0;
        //     this.props.data.documentPayments.data.forEach(x => {
        //         totalWithTax += this.getSumFormat(x.defaultCurrencySum);
        //     });
        //     console.log('totalWithTax aaa: ', totalWithTax)
        //     this.props.dataActions.setJson("document2", { totalWithTax })
        // }
    }

    render() {
        let pModal = this.state.paymentModal;
        let renderSum = (text, record) => this.getSumFormat(this.getSumFormat(text) - this.getSumFormat(record.defaultCurrencyChange))

        let columns = [
            { title: '#', key: "number", dataIndex: "number", width: "5%", render: (t) => t },
            { title: this.getText(15399), key: "description", dataIndex: "description", width: "15%", render: (t) => t },
            { title: this.getText(15400), key: "defaultCurrencySum", dataIndex: "defaultCurrencySum", width: "15%", render: renderSum },
            { title: this.getText(15401), width: "55%", render: this.renderDetailet },
            { title: '', width: "10%", render: this.renderEdit },
        ];

        let curSum = pModal ? this.getSumFormat(pModal.defaultCurrencySum) : 0;

        let totalBalance = this.getBalanceTotal();
        let totalPay = this.getPaymentsTotal();
        let roundSum = this.props.data.documentPayments.data.find(f => f.paymentTypeID == 1) ? this.props.calculateRoundSum(totalBalance).roundSum * -1 : 0
        // let roundSum = totalBalance < (this.props.gp_roundTotal / 2) && this.props.data.documentPayments.data.find(f => f.paymentTypeID == 1) ? totalBalance : 0
        let checksCount = this.state.checksCount;

        let dinamicPaymentList = this.getPayTypeList().filter(f => f.typeId == 1 || f.typeId == 2 || (f.typeId > 9 && f.typeId < 100) || (this.props.documentType == 53 && f.typeId == 5));

        let dinamicPaymentListChanged = dinamicPaymentList.find(f => pModal && f.typeId === pModal.paymentTypeID)
        if (!dinamicPaymentListChanged) dinamicPaymentListChanged = {}

        let footerModalButtons = [
            <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{this.getText(51779)}</Button>,
            <Button key={"create"} type="primary" onClick={this.handleOk} style={{ margin: 5 }}>{this.getText(51780)}</Button>
        ];

        let dinamicPaymentMax = this.props.documentType == '1' ? totalBalance : undefined;


        return (<div style={{ direction: 'rtl' }}>
            <Row>
                <Col span={this.mobile ? 24 : 4}>
                    {dinamicPaymentList.map(x => this.getPayTypeButton(x))}
                </Col>
                <Col span={this.mobile ? 24 : 20} style={{ padding: 5, paddingRight: 25 }}>
                    <ResponsiveTable tableOnly columns={columns} dataSource={this.props.data.documentPayments.data} scroll={{ y: window.innerHeight / 2 }} pagination={false} />
                    {this.props.documentType == '1' ? [
                        this.createLabelInput(this.getText(51774), totalPay),
                        roundSum ? this.createLabelInput(this.props.gp_roundDesc, roundSum * -1) : "",
                        this.createLabelInput(this.getText(51775), totalBalance - roundSum)
                    ] : this.createLabelInput(this.getText(51813), totalPay)}
                </Col>
            </Row>
            <GenericModal
                visible={pModal && pModal.paymentTypeID == 1}
                onCancel={this.handleCancel}
                title={this.getText(15397)}
                footer={footerModalButtons}>
                <Row>
                    <Col span={this.mobile ? 24 : 6}>
                        {this.createLabelInputModal(51781, 'defaultCurrencySum')}
                        {this.props.documentType == '1' ? (
                            <Button type="primary" onClick={() => {
                                const { totalWithTax } = this.props.calculateRoundSum(this.getBalanceTotal())
                                this.setModalField('defaultCurrencySum', totalWithTax);
                                setTimeout(this.handleOk, 1000);
                            }}>{this.getText(51797)}</Button>)
                            : ""}
                    </Col>
                    <Col span={this.mobile ? 24 : 18} style={{ paddingRight: 20 }}>
                        <Row>
                            {this.getPayImg(200, 'b200.jpg')}
                            {this.getPayImg(100, 'b100.jpg')}
                            {this.getPayImg(50, 'b50.jpg')}
                            {this.getPayImg(20, 'b20.jpg')}
                            {this.getPayImg(10, 'b10.png')}
                            {this.getPayImg(5, 'b5.png')}
                            {this.getPayImg(2, 'b2.png')}
                            {this.getPayImg(1, 'b1.png')}
                            {this.getPayImg(0.5, 'b05.png')}
                            {this.getPayImg(0.1, 'b01.png')}
                        </Row>
                    </Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={pModal && pModal.paymentTypeID == 2}
                onCancel={this.handleCancel}
                title={this.getText(15398)}
                width={600}
                footer={footerModalButtons}>

                {this.state.editCheck ? "" :
                    (<Row style={{ padding: 5 }}>
                        <Col span={8}>{this.getText(51832)}</Col>
                        <Col span={16}>
                            <InputNumber
                                style={{ width: '100%' }}
                                value={checksCount}
                                min={this.props.documentType == 53 ? undefined : 1}
                                max={99}
                                precision={0}
                                // max={this.props.documentType == '1' && curSum ? Math.ceil(totalBalance / curSum) : undefined}
                                onChange={(e) => {
                                    if (this.props.documentType == '1' /*&& curSum * e > totalBalance*/) {
                                        this.setState({
                                            checksCount: e,
                                            paymentModal: {
                                                ...pModal,
                                                defaultCurrencySum: this.getSumFormat(totalBalance / e)
                                            }
                                        });
                                    } else {
                                        this.setState({ checksCount: e })
                                    }
                                }}
                            />
                        </Col>
                    </Row>)}

                {this.createLabelInputModal(51786, 'valueDate')}
                {this.createLabelInputModal(51787, 'checkNumber')}
                {this.createLabelInputModal(51788, 'checkBankNumber')}
                {this.createLabelInputModal(51789, 'checkBranchNumber')}
                {this.createLabelInputModal(51790, 'checkAccountNumber')}

                <Row style={{ padding: 5 }}>
                    <Col span={8}>{this.getText(51791)}</Col>
                    <Col span={16}>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={this.props.documentType == 53 ? undefined : 0}
                            max={this.props.documentType == '1' ?
                                checksCount > 1 ?
                                    ((totalBalance + this.state.editCheck - 1) / (checksCount - 1))
                                    : (totalBalance + this.state.editCheck)
                                : undefined}
                            precision={2}
                            value={curSum}
                            onChange={(e) => {
                                let newSum = (pModal && pModal.paymentTypeID == 1) ? Math.round(e * 10) / 10 : e;
                                this.setModalField('defaultCurrencySum', newSum)
                            }}
                        />
                    </Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={dinamicPaymentListChanged.typeId > 2}
                onCancel={this.handleCancel}
                title={this.getText(16684) + dinamicPaymentListChanged.name}
                width={600}
                footer={footerModalButtons}>

                {this.createLabelInputModal(16685, 'defaultCurrencySum', true, { min: this.props.documentType == 53 ? undefined : 0, max: dinamicPaymentMax, precision: 2 })}
                {dinamicPaymentListChanged.saveReference == 1 ?
                    this.createLabelInputModal(16686, 'checkNumber', dinamicPaymentListChanged.isReferenceRequired == 1)
                    : ""}
                {dinamicPaymentListChanged.saveDate == 1 ?
                    this.createLabelInputModal(16687, 'valueDate', dinamicPaymentListChanged.isDateRequired == 1)
                    : ""}

            </GenericModal>
        </div>)
    }
}

export default CreateDocumentPayments;