/* @flow */
/*jshint esversion: 6 */
import { Form, Popover } from "antd";
import React, { Component } from "react";
import "../../App.css";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import LangContext from "../../contextProvider/LangContext";
import { Tooltip, Button, Icon } from "antd";
import GenericModal from "../../components/GenericModal";
import DateTimePicker from "../../components/DateTimePicker";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import moment from "moment";


class Transactions extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: {
                _date_from: moment().add(-30,"days").format("YYYY-MM-DD"),
                _date_to: moment().format("YYYY-MM-DD"),
                _pos_list: '',
                _sort_by: 'col_6',
                _isAsc: '0',
            },
            modalFilter: false,

            filterActive: false,
            valueSearch: '',

        };

        this.id = "TRANSACTIONS_TABLE";

        this.api = {
            get: "get_transactions_table",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI("list_pos", "", (ob) => {

            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { code: y[0], name: y[1] }
            }) : [];

            this.setState({ filters: { ...this.state.filters, _pos_list: dataset.map(x => x.code).join(",") } }, () => {
                this.props.dataActions.setJson(TableIDs.genericSelectorPos, { dataset });
                this.filterOk()
            })
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16909);
    }

    renderReport = (text, record) => {
        let generateReport = (reportName) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [
                    this.props.user.companyCode,
                    this.props.user.token,
                    {
                        _id: record.key,
                        _docNum: record.tDocumentNumber,
                        _docType: encodeURIComponent(record.tDocumentTypeID + " - " + record.tDocumentTypeName),
                        _docPos: encodeURIComponent(record.tPOSID + " - " + record.tPOSName),
                        // _docTimestamp: record.tTimestamp,
                        reportName: "reports/" + reportName,
                        REPORT_LOCALE: this.getText(101),
                    }]
            });
        }

        let subButton = (text, type, reportName) => {
            let onClick = () => { generateReport(reportName) }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = (<div>
            {subButton(16919, "file-pdf", "TransactionFields.pdf")}
            {subButton(16920, "file-excel", "TransactionFields.xls")}
            {subButton(16921, "file-word", "TransactionFields.docs")}
        </div>);

        return (<Popover content={content} title={this.getText(16918)} trigger="click">
            {/* <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}> */}
            <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
            {/* </Button> */}
        </Popover>)
    }

    filterOk = () => {
        const { _date_from, _date_to, _pos_list } = this.state.filters;

        this.setState({ modalFilter: false }, () => {
            let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map(x => x.code).join(",")

            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, { _date_from, _date_to, _pos_list: _pos_list ? _pos_list : fullPosList }, this.api.get);
        })

        this.setState({ filterActive: true })
    }

    clearFilter = () => {
        this.setState({
            filters: {
                ...this.state.filters,
                _date_from: null,
                _date_to: null,
                _pos_list: '',
            },
            filterActive: false,
            valueSearch: '',
        }, () => {
            let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map(x => x.code).join(",")
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, {
                _date_from: null,
                _date_to: null,
                _pos_list: fullPosList,
                _words: '',
            }, this.api.get);
        });
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    reportAll = () => {
        const { _words, _sort_by, _isAsc, _date_from, _date_to, _pos_list } = this.props.data[this.id].filters
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    _words: encodeURIComponent(_words),
                    _sort_by, _isAsc, _date_from, _date_to, _pos_list,
                    reportName: "reports/Transactions.pdf",
                    REPORT_LOCALE: this.getText(101),
                }]
        });
    }

    render() {
        let gt = this.getText;
        let sorter = true;
        let render = t => t;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        let datePickerFilter = (field, label) => {

            return (<Form.Item label={gt(label)}>
                <DateTimePicker
                    format={"DD/MM/YYYY"}
                    value={this.state.filters[field]}
                    onChange={e => { this.setState({ filters: { ...this.state.filters, [field]: e } }) }}
                    minDate={field == '_date_to' ? this.state.filters._date_from : null}
                    maxDate={field == '_date_from' ? this.state.filters._date_to : null}
                />
            </Form.Item>)
        }

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16910)}
                searchBox
                valueSearch={this.state.valueSearch}
                onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                setFilterActive={e => { this.setState({ filterActive: e }) }}

                filters={this.state.filters}
                columns={[
                    { title: gt(16916), key: "tID", dataIndex: "tID", width: "8%", sorter, render },
                    { title: gt(16911), key: "tDocumentNumber", dataIndex: "tDocumentNumber", width: "8%", sorter, render },
                    { title: gt(16912), key: "tDocumentTypeID", dataIndex: "tDocumentTypeID", width: "16%", sorter, render: (t, r) => t + " - " + r.tDocumentTypeName },
                    { title: gt(16913), key: "tPOSID", dataIndex: "tPOSID", width: "8%", sorter, render },
                    { title: gt(16914), key: "tPOSName", dataIndex: "tPOSName", width: "16%", sorter, render },
                    { title: gt(16915), key: "tTimestamp", dataIndex: "tTimestamp", width: "16%", sorter, render },
                    { title: gt(17048), key: "tSum", dataIndex: "tSum", width: "10%", sorter, render },
                    { title: gt(17049), key: "tCardNum", dataIndex: "tCardNum", width: "10%", sorter, render },
                    { title: gt(16917), width: "8%", render: this.renderReport },
                ]}
                actionButtons={[{
                    buttonType: 'filter',
                    buttonFunction: () => { this.setState({ modalFilter: true }) }
                }, {
                    buttonDisabled: !this.state.filterActive,
                    buttonType: 'clear',
                    buttonFunction: this.clearFilter,
                }, {
                    buttonType: 'print',
                    buttonFunction: this.reportAll
                },]}
            />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(16925)}
                width={500}
                footer={[
                    <Button key="Cancel" onClick={() => { this.setState({ modalFilter: false }) }}>{gt(16926)}</Button>,
                    <Button key="Clear" onClick={this.clearFilter}>{gt(16927)}</Button>,
                    <Button key="Filter" type={"primary"} onClick={this.filterOk}>{gt(16928)}</Button>,
                ]}
            >
                {datePickerFilter('_date_from', 16929)}
                {datePickerFilter('_date_to', 16930)}
                <Form.Item label={gt(17585)}>
                    <GenericSelectorPosStorages
                        {...this.props}
                        // id={TableIDs.genericSelectorPos}
                        multi
                        value={this.state.filters._pos_list ? this.state.filters._pos_list.split(",") : []}
                        onChange={e => { this.setState({ filters: { ...this.state.filters, _pos_list: e ? e.join(",") : '' } }) }}
                    />
                </Form.Item>
            </GenericModal>
        </div >)
    }

}

export default Transactions;