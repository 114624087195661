import React, { Component } from 'react';
import { Route } from "react-router-dom";

import DocsMenu from '../menus/DocsMenu';
import CreateDocument from "./documents/CreateDocument";
import CopyDocumentReport from "./reports/PaymentsSearchReport";
import FixLabDocuments from "./documents/FixLabDocuments";
import OrdersFromSupplier from "./reports/OrdersFromSupplier";
import FoodOrders from "./foods/FoodOrders";
import NewOrders from "./foods/NewOrders";
import Inventories from "./documents/Inventories";
import DeliveriesTable from "./Deliveries/DeliveriesTable";
import TransfersPending from "./documents/TransfersPending";
import Questionnaires from "./documents/Questionnaires";
import TableIDs from '../data/TableIDs';
import SumDeliverieDocs from "./documents/SumDeliverieDocs";
import Transactions from "./documents/Transactions";
import Meters from "./documents/Meters";
import DocumentsCancelled from "./documents/DocumentsCancelled";
import FixRemarks from "./documents/FixRemarks";
import K100 from "./documents/K100";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: any,
};

type State = {};

class Documents extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={'11'} path={this.props.baseUrl + "/documents/doc-create"} exact render={() => <CreateDocument {...routeProps} />} />),
            (<Route key={'12'} path={this.props.baseUrl + "/documents/doc-report-copy"} exact render={() => <CopyDocumentReport {...routeProps} />} />),
            (<Route key={'13'} path={this.props.baseUrl + "/documents/doc-fix-lab"} exact render={() => <FixLabDocuments {...routeProps} />} />),
            (<Route key={'14'} path={this.props.baseUrl + "/documents/orders"} exact render={() => <OrdersFromSupplier {...routeProps} />} />),
            (<Route key={'15'} path={this.props.baseUrl + "/documents/food-orders"} exact render={() => <FoodOrders {...routeProps} />} />),
            (<Route key={'17'} path={this.props.baseUrl + "/documents/new-orders"} exact render={() => <NewOrders {...routeProps} />} />),
            (<Route key={'18'} path={this.props.baseUrl + "/documents/deliveries"} exact render={() => <DeliveriesTable {...routeProps} />} />),
            (<Route key={'16'} path={this.props.baseUrl + "/documents/inventories"} exact render={() => <Inventories {...routeProps} />} />),
            (<Route key={'19'} path={this.props.baseUrl + "/documents/transfers-pending"} exact render={() => <TransfersPending {...routeProps} />} />),
            (<Route key={'20'} path={this.props.baseUrl + "/documents/questionnaires"} exact render={() => <Questionnaires {...routeProps} />} />),
            (<Route key={'201'} path={this.props.baseUrl + "/documents/sum-deliverie-docs"} exact render={() => <SumDeliverieDocs {...routeProps} />} />),
            (<Route key={'207'} path={this.props.baseUrl + "/documents/sum-budget-docs"} exact render={() => <SumDeliverieDocs {...routeProps} isByBudget />} />),
            (<Route key={'202'} path={this.props.baseUrl + "/documents/transactions"} exact render={() => <Transactions {...routeProps} />} />),
            (<Route key={'203'} path={this.props.baseUrl + "/documents/meters"} exact render={() => <Meters {...routeProps} />} />),
            (<Route key={'204'} path={this.props.baseUrl + "/documents/documents-cancelled"} exact render={() => <DocumentsCancelled {...routeProps} />} />),
            (<Route key={'205'} path={this.props.baseUrl + "/documents/fix-remarks"} exact render={() => <FixRemarks {...routeProps} />} />),
            (<Route key={'206'} path={this.props.baseUrl + "/documents/k100"} exact render={() => <K100 {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/documents"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites || this.props.ui.MENU_VIEW?.hideMenu ? '' : <DocsMenu baseUrl={this.props.baseUrl} data={this.props.data} ui={this.props.ui} />}
                {homeRoute}
                {routes}
            </div>);
    }
}

export default Documents;
// <Route key={home.key} path={this.props.baseUrl + "/documents"} exact render={home.props.render} />
