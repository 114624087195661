
import React from "react";
import {
    Input, Form, Icon, Button, Popconfirm, Popover, Tooltip, Modal, Table, Select,
    Checkbox, message, InputNumber
} from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import ResponsiveTable from "./ResponsiveTable";
import ModalPricesHistory from "./ModalPricesHistory";
import GenericModal from "./GenericModal";
import LangContext from "../contextProvider/LangContext";
import GenericSelector from "./GenericSelector";
import { Job, OutputType, ProcessType, User } from "../JmReact.js";

const { Option, OptGroup } = Select;


class ModalCreateEditItemPrices extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            modalPricesHistory: false,
            popCopy: null,
            popCopyChecked: [],

            taxPercentage: 0,
        }

        this.id = 'ITEM_PRICE_LIST_CREATE_EDIT';

        this.api = {
            get: "get_prices_by_item",
            selector: "get_prices",
        }


        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }
        if (props.data[TableIDs.genericSelectorPriceList] === undefined) props.data[TableIDs.genericSelectorPriceList] = { ...props.data.genericSelector }

        this.picksPriceList = [];

        this.costPriceUpdated = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        let newItem = this.props.ui.data ? this.props.ui.data.itemId : '';
        let prevItem = p.ui.data ? p.ui.data.itemId : '';
        let isOpen = prevItem !== newItem || (this.props.isFromDoc && !p.visible && this.props.visible)
        if (isOpen) this.refreshData()

        if (newItem && !isOpen && this.props.costPrice !== p.costPrice && !this.costPriceUpdated) {
            this.costPriceUpdated = true;
            setTimeout(() => {
                let dataSourcePriceList = this.dataSourcePriceList()
                dataSourcePriceList.filter(f => parseFloat(f.mFixedProfit)).forEach(x => { this.calculateProfit(x) })
                this.costPriceUpdated = false;
            }, 500);
        }

    }

    refreshData = () => {
        let mItemCode = this.props.ui.data ? this.props.ui.data.itemId : '';
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorPriceList, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handlePriceListCreate = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
        let mItemCode = this.props.ui.data ? this.props.ui.data.itemId : '';
        this.props.dataActions.genericDataSetEdit(this.id, { mPriceVal: '0.00', mPriceNoVat: '0.00', mItemCode });
    }

    generatePriceInTable = (text, record) => {
        let source = this.props.data[this.id];
        let pFormat = (p) => p ? parseFloat(p).toFixed(2) : '0.00';
        if (source.editing == record.key) {
            return (
                <InputNumber
                    min={0} step={0.1} precision={2}
                    style={{ width: '98%', textAlign: 'center' }}
                    defaultValue={text}
                    value={source.editedRecord['mPriceVal']}
                    onChange={(val) => {
                        let value = val ? val : '0.00';
                        let toUpdate = { ['mPriceVal']: value }
                        let selectorData = this.props.data[TableIDs.genericSelectorPriceList].dataset;
                        let taxCalc = source.editedRecord['mTaxPercentageValue'] ? (1 + (source.editedRecord['mTaxPercentageValue'] / 100)).toFixed(2) : 1

                        let noTaxText = '(לא כולל מע"מ)';
                        let plIsNoTax = selectorData.find(f => f.code === source.editedRecord['mPriceListId'] && f.name.indexOf(noTaxText) > -1);
                        let mPriceNoVat = plIsNoTax ? value : parseFloat(value / taxCalc).toFixed(2)
                        toUpdate = { ...toUpdate, mPriceNoVat: mPriceNoVat ? mPriceNoVat : '0.00' }

                        this.props.dataActions.genericDataSetEdit(this.id, toUpdate);
                    }} />);
        } else {
            return (!text || text === '0.00') ? (<div style={{ backgroundColor: 'pink' }}>{'0.00'}</div>) : pFormat(text);
        }
    }

    profitCalculatorValue = (sellingPrice, bottom) => {
        let c = parseFloat(this.props.costPrice) ? parseFloat(this.props.costPrice) : 0;
        let s = parseFloat(sellingPrice) ? parseFloat(sellingPrice) : 0;
        if (s && c) {
            return parseFloat(bottom ? 100 - ((c / s) * 100) : (s / c) * 100 - 100).toFixed(2)
        } else {
            return '0.00';
        }
    }

    profitCalculatorOnChange = (value, priceListId, bottom, mTaxPercentageValue) => {
        let costPrice = parseFloat(this.props.costPrice) ? parseFloat(this.props.costPrice) : 0;
        let mPriceVal = '0.00';
        let mPriceNoVat = '0.00';
        let taxCalc = mTaxPercentageValue ? (1 + (mTaxPercentageValue / 100)).toFixed(2) : 1

        if (costPrice) {
            let selectorData = this.props.data[TableIDs.genericSelectorPriceList].dataset;
            let noTaxText = '(לא כולל מע"מ)';
            let plIsNoTax = selectorData.find(f => f.code === priceListId && f.name.indexOf(noTaxText) > -1);
            let factor = bottom ? 100 / (100 - value) : (value + 100) / 100;
            let priceVal = factor * costPrice * (plIsNoTax ? 1 : taxCalc);
            mPriceVal = parseFloat(priceVal).toFixed(2);
            mPriceNoVat = plIsNoTax ? mPriceVal : parseFloat(priceVal / taxCalc).toFixed(2);
        }
        console.log('profitCalculatorOnChange', value, priceListId, bottom, mPriceVal, mPriceNoVat)
        return { mPriceVal, mPriceNoVat }
    }


    getProfitCalculatorInput = (record, bottom) => {
        // let costPrice = this.props.costPrice;
        let mTaxTypeId = this.props.mTaxTypeId;
        let fieldToCalc = mTaxTypeId == 1 ? "mPriceNoVat" : "mPriceVal";
        let sellingPrice = parseFloat(record[fieldToCalc]);

        if (record.key != this.props.data[this.id].editing) {
            return this.profitCalculatorValue(sellingPrice, bottom) + '%';
        } else {
            let er = this.props.data[this.id].editedRecord;
            // let selectorData = this.props.data[TableIDs.genericSelectorPriceList].dataset;
            sellingPrice = parseFloat(er[fieldToCalc]);

            return (<InputNumber
                step={1} precision={2}
                style={{ width: '98%', textAlign: 'center' }}
                value={this.profitCalculatorValue(sellingPrice, bottom)}
                onChange={(value) => {
                    let toUpdate = this.profitCalculatorOnChange(value, er['mPriceListId'], bottom, record.mTaxPercentageValue);
                    this.props.dataActions.genericDataSetEdit(this.id, toUpdate);
                }} />);
        }

    }

    handleSaveInTable = (record) => {
        let data = this.props.data[this.id];
        let isEdit = data.editedRecord['mPriceListId'];
        let listCheck = data.data[0] ? data.data.map(x => x.split("\f")[1]) : [];
        let test = listCheck.indexOf(isEdit);
        if (test >= 0 && isEdit != data.editing) {
            message.error(this.getText(10289));
        } else {
            this.props.dataActions.dataTableSetRow(this.id, record.index, data.editedRecord, true);
            if (this.props.ui.modalStack.indexOf(this.id + "Editor") > 0) this.props.uiActions.hideModal(this.id + "Editor");
        }
    }

    tooltipButton = (title, type, onClick, disabled) => {
        return (<Tooltip title={this.getText(title)}>
            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={onClick} disabled={disabled}>
                <Icon type={type} style={{ fontSize: "16px" }} />
            </Button>
        </Tooltip>)
    }

    getFixedProfitField = (text, record) => {
        if (this.props.data[this.id].editing != record.key) {
            return parseFloat(text) ?
                (<Tooltip title={this.getText(16254)}>
                    <Button
                        type={'primary'}
                        style={{ width: '98%', textAlign: 'center' }}
                        onClick={() => { this.calculateProfit(record) }}>{text + ' %'}</Button>
                </Tooltip>)
                : ""
        } else {
            return (<InputNumber
                step={1} precision={2}
                style={{ width: '98%', textAlign: 'center' }}
                min={0} max={100}
                value={this.props.data[this.id].editedRecord.mFixedProfit}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mFixedProfit: e }); }} />)
        }
    }

    calculateProfit = (record) => {
        let toUpdate = {
            ...record,
            ...this.profitCalculatorOnChange(parseFloat(record.mFixedProfit), record.mPriceListId, 0, record.mTaxPercentageValue)
        };
        this.props.dataActions.dataTableSetRow(this.id, record.index, toUpdate, true);
    }

    dataSourcePriceList = () => {
        return (this.props.data[this.id]) ?
            this.props.data[this.id].data.map((x, index) => {
                let y = x.split("\f");
                let findPL = this.props.data[TableIDs.genericSelectorPriceList].dataset.find(f => f.code == y[1])
                return ({
                    key: (y[1] === '') ? 0 : y[1], //y[0],//y[1],
                    mId: y[0],
                    mPriceListId: y[1],
                    mPriceListName: findPL ? findPL.name : "",
                    mItemCode: y[2],
                    mPriceVal: y[3],
                    mPriceNoVat: y[4],
                    mFixedProfit: y[5],
                    mTaxPercentageValue: y[6],
                    index: index
                })
            })
            : [];
    }

    updatePricesCopy = () => {
        const { popCopy, popCopyChecked } = this.state;
        const oldData = this.dataSourcePriceList();
        const findSource = oldData.find(f => f.mPriceListId == popCopy)
        const data = this.props.data[this.id].data.map(x => {
            let y = x.split("\f");
            if (popCopyChecked.indexOf(y[1]) > -1 && findSource) {
                y[3] = findSource.mPriceVal;
                y[4] = findSource.mPriceNoVat;
            }
            return y.join("\f");
        })

        console.log("testprices", { popCopy, popCopyChecked, oldData, findSource, data })

        this.setState({ popCopy: null, popCopyChecked: [] }, () => {
            this.props.dataActions.setJson(this.id, { data })
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {

        const dataSourcePriceList = this.dataSourcePriceList()

        const columnsPriceList = [
            {
                title: this.getText(10290),
                dataIndex: "mPriceListId",
                key: "mPriceListId",
                sorter: true,
                width: '30%',
                render: (text, record) => {
                    // let list = this.props.data[TableIDs.genericSelectorPriceList].dataset
                    //     .filter((obj) => !this.picksPriceList.includes(obj['code']))
                    //     .map((obj, index) => {
                    //         return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
                    //     });

                    // return (
                    //     <Select
                    //         disabled={!(this.props.data[this.id].editing == record.key)}
                    //         value={(this.props.data[this.id].editing == record.key) ?
                    //             this.props.data[this.id].editedRecord['mPriceListId'] : text}
                    //         defaultValue=""
                    //         style={{ width: '100%', paddingLeft: '4px' }}
                    //         onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mPriceListId: e }) }}
                    //     >
                    //         {list}
                    //     </Select>
                    // )
                    return (<GenericSelector
                        {...this.props}
                        id={TableIDs.genericSelectorPriceList}
                        disabled={!(this.props.data[this.id].editing == record.key)}
                        value={(this.props.data[this.id].editing == record.key) ? this.props.data[this.id].editedRecord['mPriceListId'] : text}
                        style={{ width: '100%', paddingLeft: '4px' }}
                        onChange={(e) => {
                            let mTaxTypeId = this.props.mTaxTypeId;
                            this.sendAPI("get_tax_values_per_price", "_taxType\f_priceListId\r" + mTaxTypeId + "\f" + e, ob => {
                                let selectorData = this.props.data[TableIDs.genericSelectorPriceList].dataset;
                                let taxCalc = parseFloat(ob.data) ? (1 + (parseFloat(ob.data) / 100)).toFixed(2) : 1
                                let noTaxText = '(לא כולל מע"מ)';
                                let plIsNoTax = selectorData.find(f => f.code === e && f.name.indexOf(noTaxText) > -1);
                                let mPriceNoVat = (plIsNoTax ? record.mPriceVal : parseFloat(record.mPriceVal / taxCalc).toFixed(2))??'0.00'
                               
                                this.props.dataActions.genericDataSetEdit(this.id, { mPriceListId: e, mTaxPercentageValue: ob.data, mPriceNoVat })
                            })
                        }}
                        modalStyle={{
                            title: this.getText(17891),
                            col1Title: this.getText(17892),
                            col2Title: this.getText(17893),
                            cancelText: this.getText(17889),
                            clearText: this.getText(17890),
                        }}
                    />)
                },
            }, {
                title: this.getText(10291),
                dataIndex: "mPriceVal",
                key: "mPriceVal",
                sorter: true,
                width: '15%',
                render: this.generatePriceInTable,
            }, {
                title: this.getText(12931),
                dataIndex: "mProfit",
                key: "mProfit",
                width: '15%',
                render: (text, record) => this.getProfitCalculatorInput(record)
            }, {
                title: this.getText(15907),
                dataIndex: "mProfitBottom",
                key: "mProfitBottom",
                width: '15%',
                render: (text, record) => this.getProfitCalculatorInput(record, true)
            }, {
                title: this.getText(16253),
                dataIndex: "mFixedProfit",
                key: "mFixedProfit",
                width: '15%',
                render: this.getFixedProfitField
            }, {
                title: this.getText(10287),
                width: '10%',
                render: (text, record) => {
                    if (this.props.data[this.id].editing != record.key) {
                        const dataPL = this.dataSourcePriceList();
                        const plChecked = this.state.popCopyChecked;
                        return (
                            <span>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                    const { mTaxTypeId } = this.props;
                                    if (mTaxTypeId && !record.mTaxPercentageValue) {
                                        this.sendAPI("get_tax_values_per_price", "_taxType\f_priceListId\r" + mTaxTypeId + "\f" + record.mPriceListId, ob => {
                                            this.props.dataActions.genericDataSetEdit(this.id, { mTaxPercentageValue: ob.data })
                                        })
                                    }

                                }} />
                                <Popconfirm title={this.getText(10314)}
                                    onConfirm={() => {
                                        this.props.dataActions.dataTableDeleteRow(this.id, record.index);
                                    }}
                                    okText={this.getText(10315)} cancelText={this.getText(10316)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                </Popconfirm>
                                <Popover
                                    title={this.getText(19107)}
                                    content={(<div style={{ textAlign: "right", direction: "rtl" }}>
                                        <p>{this.getText(19108)}</p>
                                        <b>
                                            <Checkbox
                                                checked={dataPL.length - plChecked.length <= 1}
                                                indeterminate={dataPL.length - plChecked.length > 1 && plChecked.length}
                                                onChange={e => {
                                                    this.setState({
                                                        popCopyChecked: (e.target.checked) ? dataPL.map(x => x.mPriceListId).filter(f => f != record.key) : []
                                                    })
                                                }}
                                            >{this.getText(19129)}</Checkbox>
                                        </b>
                                        <br />
                                        {dataPL.filter(f => f.mPriceListId != record.key).map(x => {
                                            return <div>
                                                <Checkbox
                                                    checked={plChecked.find(f => f === x.mPriceListId)}
                                                    onChange={e => {
                                                        this.setState({
                                                            popCopyChecked: (e.target.checked) ? [...plChecked, x.mPriceListId] : plChecked.filter(f => f != x.mPriceListId)
                                                        })
                                                    }}
                                                >{x.mPriceListName}</Checkbox>
                                                <br />
                                            </div>
                                        })}
                                        <br />
                                        <Button size="small" onClick={() => { this.setState({ popCopy: null, popCopyChecked: [] }) }}>{this.getText(19110)}</Button>
                                        <Button size="small" type="primary" onClick={this.updatePricesCopy}>{this.getText(19109)}</Button>
                                    </div>)}
                                    visible={this.state.popCopy == record.key}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"copy"} onClick={() => { this.setState({ popCopy: record.key }) }} />
                                </Popover>
                            </span >);
                    } else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.handleSaveInTable(record);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate) this.props.dataActions.genericDataCancelInlineCreate(this.id, true);
                                    else this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)
                    }
                }
            }
        ];

        let notItem = !this.props.ui.data || !this.props.ui.data.itemId;
        let notAddPrice = (!this.props.isValidItemCode || this.props.ui.workInProgres || this.props.data[this.id].editing != -1);
        let fixedProfitList = dataSourcePriceList.filter(f => parseFloat(f.mFixedProfit))

        let genericActionButtons = [
            this.tooltipButton(10281, 'plus', this.handlePriceListCreate, notAddPrice),
            this.tooltipButton(14287, 'table', () => { this.setState({ modalPricesHistory: true }) }, notItem),
            this.tooltipButton(16255, 'calculator', () => { fixedProfitList.forEach(x => { this.calculateProfit(x) }) }, notItem || !fixedProfitList.length),
        ]

        let cols = (window.innerWidth > 600) ? columnsPriceList :
            columnsPriceList.map((x) => { return { ...x, title: (<span style={{ fontSize: 11 }}>{x.title}</span>) } });

        let baseTable = (<ResponsiveTable
            idTable={this.id}
            tableOnly={true}
            percentageToMobile={[80, 20]}
            dataSource={dataSourcePriceList}
            columns={cols}
            scroll={{ y: 160 }}
            bordered
            pagination={false}
            ui={this.props.ui}
            uiActions={this.props.uiActions} />)

        let modalHistory = (<ModalPricesHistory
            visible={this.state.modalPricesHistory}
            forPrices
            close={() => { this.setState({ modalPricesHistory: false }) }}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataActions={this.props.dataActions}
            user={this.props.user}
        />)

        if (this.props.isFromDoc) {
            return (<GenericModal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                width={800}
                title={this.getText(10278)}
                genericActionButtons={genericActionButtons} >
                {baseTable}
                {modalHistory}
            </GenericModal>)
        } else {
            return (<span>
                <div style={{ padding: '4px' }}>
                    <PageToolbar title={this.getText(10278)}
                        actionButtons={[]} genericActionButtons={genericActionButtons} isModal={false}
                        ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions} />
                </div>
                {baseTable}
                {modalHistory}
            </span>)
        }
    }

}
export default ModalCreateEditItemPrices;
