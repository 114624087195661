/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../App.css";
import { Input, Button, Form } from "antd";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import moment from "moment";


class InvoiceApi extends Component {
   
    static contextType = LangContext;

    state = { isPageGotFocus: true }

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    renewTokens = () => {
        this.addEventListeners();

        this.sendAPI("invoice_il_get_authorization_code", "franchise_id\r" + this.props.franchiseId, (ob) => {
            if (ob.data) { window.open(ob.data, '_blank').focus() }
        })
    }

    onPagefocus = () => {
        if (this.state.isPageGotFocus === false && this.props.onPagefocus) this.props.onPagefocus()
    }

    onPageblur = () => {
        this.setState({ isPageGotFocus: false });
    }

    clearEventListeners = () => {
        window.removeEventListener("focus", this.onPagefocus)
        window.removeEventListener("blur", this.onPageblur)
    }

    addEventListeners = () => {
        window.addEventListener("focus", this.onPagefocus);
        window.addEventListener("blur", this.onPageblur);
        setTimeout(this.clearEventListeners, 5 * 60 * 1000);
    }

    render() {
        const isError = (date) => { return moment(date, "YYYY-MM-DD hh:mm:ss") < moment() }
        const isWarning = (date) => { return moment(date, "YYYY-MM-DD hh:mm:ss") < moment().add(7, 'days') }
        const setValidateStatus = (date) => {
            if (isError(date)) return "error"
            if (isWarning(date)) return "warning"
            return null;
        }
        const setHelp = (date) => {
            if (isError(date)) return this.getText(20518)
            if (isWarning(date)) return this.getText(20519)
            return undefined;
        }

        return (
            <div>
                <Form.Item label={this.getText(19926)} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                    <Input value={this.props.accessToken} readOnly style={{ width: '60%' }} onChange={(e) => { this.props.onChangeAccessToken(e.target.value) }} />
                    <Button onClick={this.renewTokens} style={{ marginRight: 8 }}>{this.getText(19928)}</Button>
                </Form.Item>
                <Form.Item label={this.getText(19927)} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                    <Input value={this.props.refreshToken} readOnly style={{ width: '60%' }} onChange={(e) => { this.props.onChangeRefreshToken(e.target.value) }} />
                </Form.Item>
                <Form.Item label={this.getText(20517)} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} 
                    validateStatus={setValidateStatus(this.props.expiration)} help={setHelp(this.props.expiration)}>
                    <Input value={this.props.expiration} readOnly style={{ width: '60%' }} onChange={(e) => { this.props.onChangeExpiration(e.target.value) }} />
                </Form.Item>
            </div>
        )

    }
}
export default InvoiceApi;