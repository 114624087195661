/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import "../App.css";
import { Layout, Icon, /*Tooltip, Button,*/ Spin, Button } from "antd";
import { /*BrowserRouter as Router,*/ Link, Route /*, Redirect*/ } from "react-router-dom";
import AppMenu from "../menus/AppMenu.js";
/*import FavoriteBar from "./components/FavoriteBar";*/
//import { LoginForm } from "../components/LoginForm";
import LoginFormV2 from "../components/LoginFormV2";
import UserProfile from "../components/UserProfile";
import ModalSelectItem from "../components/ModalSelectItem";
import ModalSelectCustomer from "../components/ModalSelectCustomer";
import ServiceCallButton from "../components/ServiceCallButton";

// Pages
import Customers from "../pages/Customers";
import Dashboard from "../pages/Dashboard";
// import AutoIphone from "../pages/AutoIphone";
import Employees from "../pages/Employees";
import Items from "../pages/Items";
import MatrixItems from "../pages/MatrixItems";
import Settings from "../pages/Settings";
import UserSettings from "../pages/UserSettings";
//import Reports from "./pages/reports/index";
//import Reports from "../pages/Reports";
import Documents from "../pages/Documents";

import ReportsInventory from "../pages/ReportsInventory";
import ReportsPayments from "../pages/ReportsPayments";
import ReportsSales from "../pages/ReportsSales";
import ReportsItems from "../pages/ReportsItems";
import TableIDs from "../data/TableIDs";
//import MailingList from "./pages/mailing/MailingList";
import Mailing from "../pages/Mailing";
import Taxes from "../pages/Taxes";
import Admin from "../pages/Admin";
import UserFavoritesPages from "../pages/UserFavoritesPages";
import HelpDocumentations from "../pages/HelpDocumentations";
import moment from "moment";
import LangContext from "../contextProvider/LangContext"
import TextValuesData from "../components/TextValuesData";
import { LangProvider /*, LangConsumer*/ } from "../contextProvider/LangContext";
// End of pages

//import InitFirebase from '../push-notification';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import AddUserFavoritesButton from '../components/AddUserFavoritesButton';
//import { app } from 'firebase';
import BestClub from '../pages/BestClub';
import SearchPages from '../components/SearchPages';
import Crm from '../pages/Crm.js';
import HelpView from '../components/HelpView.js';

if (window.location.origin.indexOf("cashontab2") > -1) {
    import("../App2.css").then(() => { });
} else if (window.location.origin.indexOf("147.235.163.248") > -1 || window.location.origin.indexOf("auto-club") > -1) {
    import("../App-Dev.css").then(() => { });
} else if (window.location.origin.indexOf("localhost") > -1) {
    import("../App-Local.css").then(() => { });

}

const { Header, Sider, Content, Footer } = Layout;
let dataTextValues = new Map();

class BackOfficeApp extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { dataset: [] };
        }
        this.testTokenTimeout = null;
        this.state = {
            // glassixContainerDisplay: null,
            webViewUpdateCredentialsRun: false,
            loadTaxt: false,
            helpTitle: null,
            helpFile: null,
        };
        // setTimeout(() => {
        //     this.setState({ glassixContainerDisplay: "block" })
        // }, 2000);

        this.urlPage = null;
    }

    static langCode = 2;
    static contextType = LangContext;

    getFirebaseToken = (token) => {
        let data = "uFirebaseToken\r" + token;
        let script = "save_firebase_from_user";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", () => { console.log('yuda uFirebaseToken success') }, () => { console.error('yuda uFirebaseToken error') });
    }

    testTokenApi = () => {
        clearTimeout(this.testTokenTimeout);
        this.testTokenTimeout = setTimeout(() => {
            if (this.props.user.token.length > 0) {
                this.props.dataActions.bargainTypesGetRequested(this.props.user.companyCode, this.props.user.token);
            }
        }, 7500000)
    }

    webViewUpdateCredentials = () => {
        console.log("webViewUpdateCredentials atteching")
        window.webViewUpdateCredentials = (companyCode, username, token) => {
            this.setState({ webViewUpdateCredentialsRun: true }, () => {
                this.props.loginRefresh(companyCode, username, token)
            })
        }
    }

    webViewSetCredentialsStatus = (status) => {
        // 0 = invalid token | 1 = ready to receive from app | 2 = invalid token from logout
        console.log("message application - flag: ", status);
        if (window.Android && window.Android.setCredentialsStatus) {
            console.log("call android function");
            window.Android.setCredentialsStatus(status);
        } else if (window.webkit) {
            console.log("call ios function");
            let ios = window.webkit?.messageHandlers?.CotoNative;
            if (ios) {
                ios.postMessage(status);
                /// ios.setCredentialsStatus(status.toString());
            }
        } else {
            console.log("unknown device. skip function call");
        }
    }

    componentDidMount() {
        if (window.location.search.indexOf("autoIphone=1") > -1) {
            console.log("webViewUpdateCredentials mounting");
            this.webViewUpdateCredentials();
            console.log("webViewUpdateCredentials - ready to receive from app");
            this.webViewSetCredentialsStatus(1);
        }
    }

    componentDidUpdate(prevProps) {
        let url = window.location.pathname
        if (url.indexOf("/cash-on-tab/build") > -1) url = url.split("/cash-on-tab/build")[1]
        if (this.urlPage != url) {
            console.log("componentDidUpdate BO run", this.urlPage, window.location.pathname);
            this.urlPage = url
            this.sendAPI("get_help_doc_to_ui_component", this.urlPage, ob => {
                const [helpTitle, helpFile] = ob.data ? ob.data.split("\f") : [null, null]
                this.setState({ helpTitle, helpFile })
            })
        }
        if (this.props.user.token.length == 0 && prevProps.user.token.length > 0) {
            // token changed from exists to empty. therefore alerting app.
            console.log("webView (ui) ", this.props.ui);
            console.log("webView (isLogOutPressed) ", this.props.ui.isLogOutPressed);

            if (this.props.ui.isLogOutPressed) {
                console.log("webViewSetCredentialsStatus - invalid token because of a logout");
                this.webViewSetCredentialsStatus(5);
            }
            else {
                console.log("webViewSetCredentialsStatus - invalid token");
                this.webViewSetCredentialsStatus(0);
            }
        }

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules] !== prevProps.data[TableIDs.pseudoSelectorUIcomponentsModules]) {
            this.setState({ webViewUpdateCredentialsRun: false })
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {

        let nameStyle = { display: 'inline-block', color: '#ffffff', fontSize: '20px' }
        const baseUrl = this.props.baseUrl;
        const isAutoIphone = window.location.search.indexOf("autoIphone=1") > -1;

        const renderContent = () => {
            const { user, ui, data, uiActions, dataActions, ActionQueue } = this.props;
            let compProps = { baseUrl, user, ui, data, uiActions, dataActions, ActionQueue }

            let menuItemsList = [
                { path: baseUrl + "/dashboard", render: () => <Dashboard  {...compProps} />, compList: ["200"] },
                { path: baseUrl + "/items", render: () => <Items  {...compProps} />, compList: ["31", "34", "32", "33", "35", "37", "112"] },
                { path: baseUrl + "/matrixItems", render: () => <MatrixItems  {...compProps} />, compList: ["111", "112", "113"] },
                { path: baseUrl + '/customers', render: () => <Customers {...compProps} />, compList: ["1", "3", "4", "5", "6", "8", "9", "10", "127", "121", "123", "125", "126", "128"] },
                { path: baseUrl + '/crm', render: () => <Crm {...compProps} />, compList: ["271", "272"] },
                { path: baseUrl + '/best-club', render: () => <BestClub {...compProps} />, compList: ["231"] },
                { path: baseUrl + "/employees", render: () => <Employees {...compProps} />, compList: ["21", "22", "23", "24", "25", "9999"] },
                { path: baseUrl + "/documents", render: () => <Documents  {...compProps} />, compList: ["11", "12", "13", "14", "15", "17", "18", "16", "19", "20"] },
                { path: baseUrl + "/user-settings", render: () => <UserSettings  {...compProps} />, compList: ["132", "81", "83", "84", "85", "86", "87", "88", "89", "131", "133", "142", "134", "135", "136", "137", "138", "139"] },
                { path: baseUrl + "/settings", render: () => <Settings  {...compProps} />, compList: ["91", "82", "93", "94", "95", "96", "98", "92", "144", "145", "147", "148", "150", "182", "183", "184", "185"] },
                { path: baseUrl + "/reports_inventory", render: () => <ReportsInventory  {...compProps} />, compList: ["45", "41", "42", "43", "44"] },
                { path: baseUrl + "/reports_sales", render: () => <ReportsSales  {...compProps} />, compList: ["51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "171"] },
                { path: baseUrl + "/reports_payments", render: () => <ReportsPayments  {...compProps} />, compList: ["61", "62", "63", "64", "65", "66", "67", "69", "70", "161", "162", "163", "164", "165"] },
                { path: baseUrl + "/reports_Items", render: () => <ReportsItems  {...compProps} />, compList: ["71"] },
                { path: baseUrl + "/mailing", render: () => <Mailing  {...compProps} />, compList: ["101", "102", "103", "104", "105", "106"] },
                { path: baseUrl + "/taxes", render: () => <Taxes {...compProps} />, compList: ["122", "151", "152", "153", "154", "155"] },
                { path: baseUrl + "/admin", render: () => <Admin  {...compProps} />, compList: ["191", "192", "97", "99", "181", "149", "141", "143", "146", "193", "194", "195", "196", "197", "198"] },
            ];

            let uiComp = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules];

            let firstMenu = menuItemsList.find(item => uiComp && uiComp.dataset.filter(f => item.compList.indexOf(f.code) > -1).length)
            if (isAutoIphone && (!firstMenu || !firstMenu.path || firstMenu.path.indexOf("/dashboard") === -1) && !this.state.webViewUpdateCredentialsRun)
                firstMenu = { render: () => (<h1>משתמש לא מורשה. נא פנה לשירות</h1>) }

            return (
                <React.Fragment>
                    {(<Route path={baseUrl + "/"} exact render={firstMenu ? firstMenu.render : () => (<div />)} />)}
                    {(<Route path={baseUrl + "/user-favorites"} exact render={() => (<UserFavoritesPages  {...this.props} />)} />)}
                    {menuItemsList.map(x => (<Route {...x} />))}
                    {(<Route path={baseUrl + "/help-documentations"} exact render={() => (<HelpDocumentations  {...compProps} />)} />)}
                    {/* <Redirect exact path='/CotGetReport' to={((window.location.origin.indexOf("cashontab.co.il") === -1)) ? "/" : "/cash-on-tab/build/"} /> */}
                </React.Fragment>
            );
        };

        //console.log("BackOfficeApp ", this.props.user.token.length, this.state.loadTaxt)
        let renderContentOrLogin = this.props.user.token.length > 0 ? this.state.loadTaxt ? renderContent() : <Spin />
            : (
                //<LoginForm
                <LoginFormV2
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}

                    user={this.props.user}
                    toggleRenewModal={this.props.uiActions.toggleRenewModal}
                    isRenewModalShown={this.props.ui.isRenewModalShown}
                    toggleConfirmationModal={this.props.uiActions.toggleConfirmationModal}
                    isConfDiagShown={this.props.ui.isConfDiagShown}

                    renew={this.props.renew}
                    getUser={this.props.getUser}
                    setUser={this.props.setUser}
                    justLogin={this.props.justLogin}

                    login={this.props.login}
                    saveValues={this.props.saveValues}
                    working={this.props.ui.workInProgress}
                    error={this.props.user.error}
                    previousValues={this.props.ui.previousFormValues}
                    language={this.langCode}
                    setLang={(id) => {
                        this.langCode = id;
                        let mCode = ['en', 'he', 'ar'];
                        moment.locale(mCode[id - 1]);
                    }} />);

        let textValues = this.props.user.token.length > 0 ?
            (<span>
                <TextValuesData
                    user={this.props.user} ui={this.props.ui}
                    dataTextValues={dataTextValues}
                    language={this.langCode}
                    onLoadText={() => { this.setState({ loadTaxt: true }) }}
                />
                {/* <InitFirebase getToken={this.getFirebaseToken} /> */}
            </span>)
            : "";

        let userProfileDiv = (<UserProfile
            baseUrl={baseUrl}
            displayName={this.props.user.displayName}
            profilePicture={this.props.user.profilePicture}
            isAutoIphone={isAutoIphone}
            logoutFunction={() => {
                this.props.logoutFunction();
                this.props.dataActions.clearDataStore();
            }} />)

        // let background = "#001529"
        // if (window.location.origin.indexOf("cashontab2") > -1 || window.location.origin.indexOf("localhost") > -1) background = "rgb(0,116,128)"


        // let header = (<Header style={{ background }}>
        let header = (<Header >
            {this.props.user.token.length > 0 ?
                <span>
                    <React.Fragment>
                        <Icon
                            className="trigger"
                            type={/*this.*/this.props.ui.mainMenuCollapsed ? 'menu-fold' : 'menu-unfold'}
                            style={{ color: '#fff' }}
                            onClick={/*this.*/this.props.toggleMainMenu}
                        />
                        {/*
      <OpenOrders
      user={this.props.user}
      data={this.props.data}
      dataActions={this.props.dataActions}
      />
    */}
                        {userProfileDiv}
                    </React.Fragment>
                    {this.props.user.token.length > 0 && window.innerWidth < 600 ? (<AddUserFavoritesButton {...this.props} />) : ""}

                    <div style={nameStyle}>
                        {this.props.user.companyName}
                        {/* {this.props.user.token.length > 0 ? (<SearchPages {...this.props} background={background} />) : ""} */}
                        {this.props.user.token.length > 0 ? (<SearchPages {...this.props} />) : ""}
                    </div>
                </span>
                : ""}

            <div className="logo">
                {this.props.user.token.length > 0 && window.innerWidth >= 600 ?
                    [
                        <ServiceCallButton {...this.props} />,
                        <AddUserFavoritesButton {...this.props} />,
                        this.state.helpFile ? <HelpView {...this.props} helpFile={this.state.helpFile} helpTitle={this.state.helpTitle} /> : "",
                    ]
                    : ""}
                <Link to={baseUrl + "/dashboard"}><img src={baseUrl + "/img/logo.png"} alt="Cash On Tab" /></Link>
                <span style={{ fontSize: 12, marginRight: 4 }}>
                    <span>{"ver: "}</span>
                    <span>{process.env.REACT_APP_VERSION}</span>
                </span>
            </div>
        </Header>);


        // const { glassixContainerDisplay } = this.state;

        return isAutoIphone ?
            (<div className="App" onKeyDown={this.testTokenApi} onMouseDown={this.testTokenApi}>
                <LangProvider value={dataTextValues}>
                    {textValues}

                    <Layout className="ant-layout-wrapper">
                        <span style={{ fontSize: 12, marginRight: 4 }}>
                            <span>{"ver: "}</span>
                            <span>{process.env.REACT_APP_VERSION}</span>
                            {this.props.user.token.length > 0 ?
                                <div style={{
                                    display: "inline-block",
                                    marginRight: 30,
                                    padding: 3,
                                    background: "#555555",
                                    borderRadius: 15,
                                }}>{userProfileDiv}</div>
                                : ""}
                        </span>
                        {renderContentOrLogin}
                    </Layout>
                </LangProvider>
            </div>)
            : this.props.ui.MENU_VIEW?.hideMenu ?
                (<div className="App" onKeyDown={this.testTokenApi} onMouseDown={this.testTokenApi}>
                    <LangProvider value={dataTextValues}>
                        {textValues}
                        <Layout className="ant-layout-container">
                            <Content>
                                {renderContentOrLogin}
                            </Content>
                        </Layout>
                    </LangProvider>
                </div>)
                : (<div className="App" onKeyDown={this.testTokenApi} onMouseDown={this.testTokenApi}>
                    <LangProvider value={dataTextValues}>
                        {textValues}
                        <Layout className="ant-layout-wrapper">
                            {header}
                            <Layout className="ant-layout-container">
                                <Sider trigger={null} collapsible collapsed={/*this.*/this.props.ui.mainMenuCollapsed}>
                                    {this.props.user.token.length > 0 ? <AppMenu baseUrl={baseUrl} data={this.props.data} /> : ""}
                                </Sider>
                                <Content>
                                    {renderContentOrLogin}
                                    {/* {glassixContainerDisplay ?
                                    (<Icon
                                        type={glassixContainerDisplay === "block" ? "left" : "right"}
                                        style={{
                                            position: 'fixed',
                                            left: 2,
                                            bottom: 50,
                                            cursor: 'pointer',
                                            background: '#1B80C4',
                                            borderRadius: '50%',
                                            padding: 5,
                                            color: '#ffffff',
                                            fontSize: 12
                                        }}
                                        onClick={() => {
                                            let display = glassixContainerDisplay === "block" ? "none" : "block";
                                            this.setState({ glassixContainerDisplay: display }, () => {
                                                document.getElementById("glassix-container").style.display = display;
                                            })
                                        }}
                                    />)
                                    : ""} */}
                                </Content>
                            </Layout>
                            <Footer style={{ padding: "0 4px" }}>
                                {this.props.user.token.length > 0 ? <div /> : ""}
                                <small className="footer-copyright">2018&copy;אוטוסופט מערכות מחשבים ותוכנה בע"מ</small>
                            </Footer>


                        </Layout>
                        <ModalSelectItem zIndex={9000} visible={this.props.ui.dialogs.itemPicker.visible} data={this.props.ui.dialogs.itemPicker.data}
                            defaultValue={this.props.ui.dialogs.itemPicker.defaultValue} onChange={this.props.ui.dialogs.itemPicker.onChange} />
                        <ModalSelectCustomer zIndex={9000} visible={this.props.ui.dialogs.customerPicker.visible} data={this.props.ui.dialogs.customerPicker.data}
                            defaultValue={this.props.ui.dialogs.customerPicker.defaultValue} onChange={this.props.ui.dialogs.customerPicker.onChange} />
                    </LangProvider>
                </div>)
    }

}
export default BackOfficeApp;
