/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import SearchBox from "./SearchBox";
import TableIDs from '../../data/TableIDs.js';
import { Button, Select, Icon, Modal, Tooltip, Row, Col } from "antd";

class FoodOrderBranches extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            _words: '',
            _method: '',
            _kosher: '',
            modalBranch: null,
        }

        this.mobile = window.innerWidth <= 600 ? true : false;

        this.id = "FOOD_ORDERS_ALL_BRANCHES";
        this.kosher_id = 'KOSHER_TYPES';

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [] };
        if (props.data[this.kosher_id] === undefined) props.data[this.kosher_id] = { dataset: [] };


        this.api = {
            get: 'get_full_branches_to_site',
            get_kosher: 'kosher_type_list',
        }

        this.sendAPI = this.props.sendAPI;
        this.getText = this.props.getText;

    }

    componentDidMount() {
        this.setFilter()
        this.sendAPI(this.api.get_kosher, "", (ob) => {
            let dataset = ob.data ? ob.data.split('\r').map(x => {
                let y = x.split('\f');
                return { code: y[0], name: y[1], image: y[2] }
            }) : []
            this.props.setJson(this.kosher_id, { dataset })
        }, this.props.errorCallback)
    }

    componentDidUpdate() {
        if (this.props.setCssValues) this.props.setCssValues()
    }

    setFilter = () => {
        const { _words, _method, _kosher } = this.state;
        this.sendAPI(this.api.get, '_words\f_method\f_kosher\r' + _words + '\f' + _method + '\f' + _kosher, (ob) => {
            if (ob.data) {
                let x = ob.data.split('\r');
                let h = x[0] ? x[0].split('\f') : []
                let data = x.slice(1).map(y => {
                    let z = y.split('\f');
                    let obj = {}
                    h.forEach((xx, ii) => { obj = { ...obj, [xx]: z[ii] } });
                    return obj;
                });
                this.props.setJson(this.id, { data })
            }
        }, this.props.errorCallback)
    }

    branchCard = (x) => {
        let cardStyle = {
            display: 'inline-block',
            verticalAlign: 'text-top',
            width: this.mobile ? '90%' : 320,
            margin: this.mobile ? '5%' : 16,
            padding: 16,
            background: '#ffffff',

        }

        return (<div style={cardStyle} className="cotw_branch_box">
            <span className={this.props.getFontStyle2("text_item")}>
                <h2 className="cotw_branch_box_branch_name">{x.mName}</h2>
                {x.mAddress ? (<div className="cotw_branch_box_branch_address"> <Icon type={'environment'} /> {x.mAddress}</div>) : ""}
                {x.mPhones ? (<h4 className="cotw_branch_box_branch_phone"><Icon type={'phone'} /> {x.mPhones}</h4>) : ""}
                {x.mKosherId ? (
                    <div className="cotw_branch_box_kosher">
                        <div style={{ display: 'inline-block', marginLeft: 10 }}>
                            {/* {this.kosherIcon(25)} */}
                            {this.getKosherImage(x.mKosherId, 25)}
                        </div>
                        {this.getKosherName(x.mKosherId)}
                    </div>
                ) : ""}
                <button style={{ cursor: 'pointer' }}
                    onClick={() => { this.setState({ modalBranch: x }) }} className="cotw_branch_box_button">{this.getText(20542)}</button>
            </span>
        </div>)
    }

    wazeIcon = (size) => {
        let svgProps = {
            version: "1.1",
            id: "Capa_1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns: xlink": "http://www.w3.org/1999/xlink",
            x: 0,
            y: 0,
            viewBox: "0 0 512 512",
            style: { enableBackground: "new 0 0 512 512", width: size, height: size, marginLeft: 10 },
            "xml: space": "preserve"
        }

        return (<svg {...svgProps}>
            <path style={{ fill: "#BBDEFB" }} d="M288,416c114.88,0,208-85.952,208-192S402.88,32,288,32S80,117.952,80,224c0,0,15.008,96-64,96 C16,400,160,416,288,416" />
            <path style={{ fill: "#455A64" }} d="M288,432C94.208,432,0,395.36,0,320c0-8.832,7.168-16,16-16c15.872,0,27.456-4.544,35.424-13.824 c18.688-21.856,12.832-63.296,12.768-63.712C64,109.312,164.48,16,288,16c123.488,0,224,93.312,224,208S411.488,432,288,432z M34.432,334.336C49.664,377.376,136.576,400,288,400c105.888,0,192-78.976,192-176c0-97.056-86.112-176-192-176S96,126.944,96,224 l0,0c0.192,0,8.16,53.792-20.256,86.976C65.152,323.328,51.328,331.168,34.432,334.336z" />
            <g>
                <circle style={{ fill: "#37474F" }} cx="352" cy="432" r="64" />
                <circle style={{ fill: "#37474F" }} cx="160" cy="432" r="64" />
            </g>
            <g>
                <path style={{ fill: "#455A64" }} d="M224.64,192c-8.832,0-16.16-7.168-16.16-16s7.008-16,15.84-16h0.32c8.832,0,16,7.168,16,16 S233.472,192,224.64,192z" />
                <path style={{ fill: "#455A64" }} d="M352.64,192c-8.8,0-16.16-7.168-16.16-16s7.008-16,15.84-16h0.32c8.832,0,16,7.168,16,16 S361.472,192,352.64,192z" />
                <path style={{ fill: "#455A64" }} d="M288,336c-46.144,0-85.472-31.424-95.616-76.48c-1.92-8.64,3.488-17.184,12.096-19.136 c8.64-1.856,17.184,3.456,19.136,12.096C230.432,282.816,256.928,304,288,304c31.104,0,57.568-21.184,64.384-51.52 c1.984-8.608,10.432-14.016,19.136-12.096c8.64,1.952,14.048,10.496,12.128,19.136C373.504,304.544,334.176,336,288,336z" />
            </g>
        </svg>)
    }

    kosherIcon = (size) => {
        // let svgProps = {
        //     version: "1.0",
        //     xmlns: "http://www.w3.org/2000/svg",
        //     viewBox: "0 0 496 494",
        //     style: { enableBackground: "new 0 0 496 494", width: size, height: size },
        //     preserveAspectRatio: "xMidYMid meet"
        // }

        // return (<svg {...svgProps}>
        //     <g transform="translate(0.000000,494.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        //         <path d="M2185 4633 c-227 -30 -473 -107 -690 -215 -658 -328 -1116 -964 -1210 -1684 -19 -149 -19 -439 0 -588 64 -486 293 -939 651 -1287 121 -117 198 -179 336 -269 278 -182 600 -302 926 -344 161 -21 443 -21 604 0 632 82 1216 446 1568 980 180 273 303 601 345 920 19 149 19 439 0 588 -94 720 -552 1356 -1211 1685 -220 110 -464 184 -702 215 -155 20 -465 20 -617 -1z m530 -83 c346 -39 690 -161 963 -344 150 -100 237 -173 367 -307 176 -182 278 -330 391 -564 275 -569 272 -1227 -6 -1802 -269 -556 -756 -965 -1350 -1133 -641 -181 -1344 -47 -1880 358 -99 75 -265 235 -355 344 -154 183 -304 453 -379 678 -169 515 -134 1073 98 1555 113 234 215 382 391 564 130 134 217 207 367 307 267 179 616 305 948 343 107 12 339 13 445 1z" />
        //         <path d="M2275 3746 c-124 -40 -202 -104 -245 -200 -90 -197 -257 -772 -399 -1371 -87 -367 -191 -858 -191 -905 0 -48 19 -98 48 -124 69 -61 271 42 398 202 70 89 127 216 220 497 47 143 106 303 132 355 l46 95 8 -110 c17 -243 64 -478 127 -640 73 -190 184 -321 336 -396 l88 -44 131 0 c130 0 131 0 208 37 119 57 238 181 238 249 0 24 -11 36 -73 78 -268 180 -512 656 -584 1137 l-17 117 70 66 c38 37 150 129 249 206 99 76 226 184 282 240 87 86 109 114 140 181 31 67 37 92 41 166 4 83 3 88 -22 113 -26 26 -31 27 -110 24 -73 -4 -92 -9 -167 -46 -184 -92 -390 -299 -682 -687 -99 -132 -177 -231 -173 -220 3 10 43 152 90 314 98 343 126 467 126 550 0 52 -4 64 -29 92 l-29 33 -109 2 c-68 2 -123 -2 -148 -11z" />
        //     </g>
        // </svg>)

        let iconStyle = {
            width: size,
            height: size,
            border: '3px solid #404041',
            borderRadius: '50%',
            fontSize: size / 1.5,
            textAlign: 'center',
        }
        return this.styleType() == 6 ? "" : (<div style={iconStyle}>K</div>)
    }

    wazeRedirect = () => {
        let mData = this.state.modalBranch;
        if (mData && mData.mAltitude && mData.mLongitude) {
            let url = "https://www.waze.com/live-map/directions?to=ll." + mData.mAltitude.slice(0, 10) + "%2C" + mData.mLongitude.slice(0, 10);
            window.open(url, '_blank');
        }
    }

    telRedirect = () => {
        let mData = this.state.modalBranch;
        if (mData && mData.mPhones) {
            let url = "tel:" + mData.mPhones;
            window.open(url, '_blank');
        }
    }

    styleType = () => {
        return this.props.data.FORMAT_SITE_SETTINGS?.pDesktopCategoriesView
    }

    getSelectStyle = (key, dataset) => {
        return this.styleType() == 6 ? "" : (<div className={"width_full"}>
            <Select key={key} value={this.state[key]} onChange={(e) => { this.setState({ [key]: e }, this.setFilter) }}
                suffixIcon={(<span><div className={"collapse_icon_active"} style={{ marginTop: -5 }} /></span>)}>
                {dataset.map(x => (<Select.Option value={x.code}><span className={this.props.getFontStyle2("text_item")} style={{ fontSize: 20 }}>{x.name}</span></Select.Option>))}
            </Select>
        </div>)
    }

    getKosherName = (code) => {
        let data = this.props.data[this.kosher_id].dataset.find(f => f.code === code);
        return data ? data.name : ""
    }

    getKosherImage = (code, size) => {
        let data = this.props.data[this.kosher_id].dataset.find(f => f.code === code);
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return data && data.image ? (<img src={baseUrl + "/uploads/shared/" + data.image} width={size} />) : this.kosherIcon(size)
    }

    getMethodButton = (x) => {
        let mID = this.state.modalBranch ? this.state.modalBranch.mID : null;
        return (<Button
            style={{ margin: 10, width: 140, height: 50, ...this.props.buttonStyle }}
            onClick={() => {
                this.setState({
                    _words: '',
                    _method: '',
                    _kosher: '',
                    modalBranch: null,
                }, () => {
                    this.props.changeModal(x.code, mID)
                    this.setFilter()
                })
            }}>
            <span className={this.props.getFontStyle2("text_item")} style={this.props.buttonStyle}>{x.name}</span>
        </Button>)
    }

    render() {
        let modalBranch = this.state.modalBranch ? this.state.modalBranch : {}
        let btnStyle = {
            marginLeft: 10,
            width: 50,
            height: 50,
            padding: 5,
            background: '#f3b919',
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'inline-block'
        }

        let isStyle6 = this.styleType() == 6;

        return (<div style={isStyle6 ? { height: window.innerHeight - 210, overflowY: "auto", textAlign: "center" } : {}}>
            {/* {isStyle6 ? this.props.getClassNameStyle("sale_title", { background: null, color: "#ddc268", fontWeight: "bold", fontSize: 40 }, this.getText(15582)) */}
            {isStyle6 ? "" : this.props.getClassNameStyle("sale_title", { marginTop: 0 }, this.getText(15582))}
            <Row>
                <Col span={this.mobile ? 24 : 8} style={{ padding: 10 }}>
                    <SearchBox
                        btnText={this.getText(15583)}
                        placeholder={this.getText(15584)}
                        onSearch={this.setFilter}
                        onChange={(e) => { this.setState({ _words: e }) }}
                        style={{ height: 50, width: 350, fontSize: 30 }}
                    />
                </Col>
                <Col span={this.mobile ? 24 : 8} style={{ padding: 18 }}>
                    {this.getSelectStyle('_kosher', [{ code: '', name: ' - ' + this.getText(15586) + ' - ' }, ...this.props.data[this.kosher_id].dataset])}
                </Col>
                <Col span={this.mobile ? 24 : 8} style={{ padding: 18 }}>
                    {this.getSelectStyle('_method', [{ code: '', name: ' - ' + this.getText(15587) + ' - ' }, ...this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset])}
                </Col>
            </Row>
            <div>{this.props.data[this.id].data.map(x => this.branchCard(x))}</div>
            <Modal
                title={this.props.getClassNameStyle("title_block", { marginTop: 0 }, modalBranch.mName)}
                visible={modalBranch.mID}
                onCancel={() => this.setState({ modalBranch: false })} destroyOnClose={true} width={600}
                footer={false}
            >
                <span className={this.props.getFontStyle2("text_item")}>
                    <Tooltip title={this.getText(15585)}>
                        <div style={btnStyle} onClick={this.wazeRedirect}><Icon type={'environment'} style={{ marginRight: 7 }} /></div>
                    </Tooltip>
                    {modalBranch.mAddress}
                    <hr />
                    <div style={{ ...btnStyle, fontSize: 35, cursor: 'auto' }} onClick={this.telRedirect}>
                        <Icon type={'phone'} />
                    </div>
                    {modalBranch.mPhones}
                    <hr />
                    <div style={{ ...btnStyle, fontSize: 35, cursor: 'auto' }}>
                        <Icon type={'clock-circle'} />
                    </div>
                    {modalBranch.mActivityTime ?
                        modalBranch.mActivityTime.split('\n').map(x => {
                            let y = x.split("\t");
                            if (y.length == 2) {
                                return (<Row>
                                    <Col span={8}>{y[0]}</Col>
                                    <Col span={16}>{y[1]}</Col>
                                </Row>)
                            } else {
                                return [<br />, x]
                            }
                        })
                        : ""}
                    <hr />
                    <div style={{ ...btnStyle, cursor: 'auto' }}>
                        {/* {this.kosherIcon(40)} */}
                        {this.getKosherImage(modalBranch.mKosherId, 40)}
                    </div>
                    {this.getKosherName(modalBranch.mKosherId)}
                    <hr />
                    {modalBranch.mMethodList ? modalBranch.mMethodList.split(',').map(x => {
                        let data = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset.find(f => f.code === x);
                        return data ? this.getMethodButton(data) : ''
                    }) : ''}

                </span>
            </Modal>
        </div>)
    }


}

export default FoodOrderBranches;