/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Select, Checkbox, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentsCreditCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PaymentsCreditCard: {
                LowerSelectionDate: moment().add(-1, "month").format("YYYY-MM-01"),
                UpperSelectionDate: moment().format("YYYY-MM-DD"),
                LowerSelectionTime: "00:00:00",
                UpperSelectionTime: "23:59:59",
                PosList: '',
                StorageListIssue: '',
                CreditCardCompaniesList: '',
                sortByCol: '0',
                reportName: "reports/paymentsCreditCard.pdf",
            }
        };
        this.state.type = "pdf";

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorCerditCardCompanies] === undefined) {
            props.data[TableIDs.genericSelectorCerditCardCompanies] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_storages: "get_storage_list",
            selector_credit_companies: "get_credit_card_companies_list",
        }

        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        //this.setReportParameters("PaymentsCreditCard", { RequestedReport: 0 });
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorCerditCardCompanies, this.props.user.companyCode, this.props.user.token, this.api.selector_credit_companies);



    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
        //const list = this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',');
        //this.setReportParameters("PaymentsCreditCard", { mPosList: list });
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10195);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("PaymentsCreditCard", this.props.ui.favoriteParams)
        }

    }


    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorCerditCardCompanies, this.props.user.companyCode, this.props.user.token, this.api.selector_credit_companies]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.PaymentsCreditCard.LowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.PaymentsCreditCard.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    /*
        onChange = (field, value) => {
            this.setReportParameters("PaymentsCreditCard", { [field]: value })
            this.setState({
                [field]: value,
            });
        }*/

    onChange = (field, value, field2, value2) => {
        console.log("f ", field, "v ", value);
        this.setReportParameters("PaymentsCreditCard", { [field]: value, [field2]: value2 })
    }

    onStartChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;
        console.log("start date ", arr);
        //this.onChange('LowerSelectionDate', arr[0], 'LowerSelectionTime', arr[1]);
        this.setReportParameters("PaymentsCreditCard", { LowerSelectionDate: arr[0] })
    }

    onEndChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;
        console.log("end date ", arr);
        //this.onChange('UpperSelectionDate', arr[0], 'UpperSelectionTime', arr[1]);
        this.setReportParameters("PaymentsCreditCard", { UpperSelectionDate: arr[0] })
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentsCreditCard", { [field]: data });
                    else
                        this.setReportParameters("PaymentsCreditCard", { [field]: '' });
                }} />);
    }

    render() {
        const { PaymentsCreditCard, endOpen } = this.state;
        const { LowerSelectionDate, UpperSelectionDate } = PaymentsCreditCard;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { PaymentsCreditCard } = this.state;

        const isDocsShownStyle = { display: (PaymentsCreditCard.RequestedReport === 2) ? 'block' : 'none' }
        //const isEndDateShownStyle = { display: (PaymentsCreditCard.RequestedReport === 2) ? 'block' : 'none'}

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listCreditCardCompanies = Jax.get(this.props.data, TableIDs.genericSelectorCerditCardCompanies + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        //this.setReportParameters("PaymentsCreditCard", {
        //    mPosList: this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',')
        //});

        let mobile = window.innerWidth > 600 ? false : true;


        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(12239)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '60%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '2', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <Row>
                            <Col span={mobile ? 24 : 12}>
                                <FormItem label={this.getText(12240)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                    <DatePicker
                                        // disabledDate={d => disabledDatePicker(d, null, UpperSelectionDate)}
                                        //  disabledDate={this.disabledStartDate}
                                        disabledDate={d => disabledDatePicker(d,
                                            UpperSelectionDate ? moment(UpperSelectionDate).add(-370, "days").format("YYYY-MM-DD") : null,
                                            UpperSelectionDate)}
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        //format="D/M/YY"
                                        value={LowerSelectionDate ? moment(LowerSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(12250)}
                                        onChange={this.onStartChange}
                                    //onOpenChange={this.handleStartOpenChange}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={mobile ? 24 : 12}>
                                <FormItem label={this.getText(12241)}>
                                    <DatePicker
                                        // disabledDate={d => disabledDatePicker(d, LowerSelectionDate, null)}
                                        //  disabledDate={this.disabledEndDate}
                                        disabledDate={d => disabledDatePicker(d,
                                            LowerSelectionDate,
                                            LowerSelectionDate ? moment(LowerSelectionDate).add(370, "days").format("YYYY-MM-DD") : null)}
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        //format="D/M/YY"
                                        value={UpperSelectionDate ? moment(UpperSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(12251)}
                                        onChange={this.onEndChange}
                                    //open={endOpen}
                                    //onOpenChange={this.handleEndOpenChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <FormItem label={this.getText(12242)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setReportParameters("PaymentsCreditCard", { PosList: value.join(','), StorageListIssue: typeList.join(',') })}
                                value={(this.state.PaymentsCreditCard.PosList === '') ? [] : this.state.PaymentsCreditCard.PosList.split(',')}
                            />
                            {/* {this.createCheckBox('PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"PosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentsCreditCard", { PosList: value.join(',') })}
                                value={(this.state.PaymentsCreditCard.PosList === '') ? [] : this.state.PaymentsCreditCard.PosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>

                        {/* <FormItem label={this.getText(12243)}>
                            {this.createCheckBox('StorageListIssue', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageListIssue"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentsCreditCard", { StorageListIssue: value.join(',') })}
                                value={(this.state.PaymentsCreditCard.StorageListIssue === '') ? [] : this.state.PaymentsCreditCard.StorageListIssue.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}

                        <FormItem label={this.getText(12244)}>
                            {this.createCheckBox('CreditCardCompaniesList', Jax.get(this.props.data, TableIDs.genericSelectorCerditCardCompanies + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"CreditCardCompaniesList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("PaymentsCreditCard", { CreditCardCompaniesList: value.join(',') })}
                                value={(this.state.PaymentsCreditCard.CreditCardCompaniesList === '') ? [] : this.state.PaymentsCreditCard.CreditCardCompaniesList.split(',')}
                            >
                                {listCreditCardCompanies}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(20271)}>
                            <Radio.Group
                                onChange={(value) => this.setReportParameters("PaymentsCreditCard", { sortByCol: value.target.value })}
                                value={this.state.PaymentsCreditCard.sortByCol} >
                                <Radio value={'0'}>{this.getText(20272)}</Radio><br />
                                <Radio value={'1'}>{this.getText(20273)}</Radio><br />
                                <Radio value={'2'}>{this.getText(20274)}</Radio><br />
                            </Radio.Group>
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state.PaymentsCreditCard}
                            posField={'PosList'}
                            storagesField={'StorageListIssue'}
                            datesToFavorites={[
                                { field: 'LowerSelectionDate', label: this.getText(12240) },
                                { field: 'UpperSelectionDate', label: this.getText(12241) },
                            ]}
                            favoriteSave
                        />


                        {/* <FormItem key={"reportName"} label={this.getText(12245)}>
                            <Select style={{ width: 290 }}
                                //size={"large"}
                                className={"reports/PaymentsCreditCard.pdf"}
                                defaultValue={"pdf"}
                                //combobox={null}
                                //defaultValue={"reports/PaymentsCreditCard.pdf"}
                                onChange={(value) => {
                                    //const type = this.state.PaymentsCreditCard.RequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("PaymentsCreditCard", { reportName: "reports/PaymentsCreditCard" + str + ".pdf"})}
                                    //this.setState({ ...this.state, type: value });
                                    this.setReportParameters("PaymentsCreditCard", {
                                        reportName: "reports/paymentsCreditCard." + value,
                                        type: value
                                    });

                                }}
                            >
                                <Option key={"pdf"}>{this.getText(12246)}</Option>
                                <Option key={"xls"}>{this.getText(12247)}</Option>
                                <Option key={"docx"}>{this.getText(12248)}</Option>
                            </Select>
                        </FormItem>

                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.PaymentsCreditCard.RequestedReport;
                            this.generateReport.apply(this, ["PaymentsCreditCard"])
                        }}>{this.getText(12249)}</Button> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default PaymentsCreditCard;