/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import TableIDs from '../../data/TableIDs.js';
import { Modal, Popconfirm, Checkbox, Input, DatePicker, TimePicker, Icon, Row, Col, Button, Select } from "antd";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import EditorContainer from "../../components/EditorContainer";
import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { escapeRegExp, disabledDatePicker } from "../../utils/utils";
import GenericSelector from "../../components/GenericSelector.js";

const topHeight = 340; // px
const rowHeight = 30;  // px

class VirtualShopMessages extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            modalCreateEdit: null,
            valueMessage: '',
        }

        this.doOnResize = null;

        this.id = "VIRTUAL_SHOP_MESSAGES";
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };

        this.api = {
            get: "get_virtual_shop_messages",
            get_value: "get_value_virtual_shop_messages",
            delete: "delete_virtual_shop_messages",
            create_edit: "create_edit_virtual_shop_messages",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: this.addRow
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]

        this.messageTypeList = [
            { code: "0", text: 16022 },
            { code: "1", text: 16023 },
            { code: "2", text: 16024 },
            { code: "3", text: 18485 },
            { code: "4", text: 20455 },
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.initFilters();
        // if (this.state.modalCreateEdit) {
        //     let draftElement = document.getElementById('view-draft-html');
        //     if (draftElement) draftElement.innerHTML = this.state.modalCreateEdit.eValue
        // }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,
            _isAsc: 1,
            _sort_by: 'col_0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 9;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "eID": col--;
            case "eDesc": col--;
            case "eDateFrom": col--;
            case "eDateTo": col--;
            case "eTimeFrom": col--;
            case "eTimeTo": col--;
            case "eIsActive": col--;
            case "eIsActiveInPos": col--;
            case "eViewType": break; // 8
            default:
                col = 0;
        }

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    getModalTitle = (title, actions, showsearch) => {
        return (<PageToolbar
            isModal={true}
            title={title}
            actionButtons={actions ? actions : []}
            // genericActionButtons={gActions ? gActions : undefined}
            showsearch={showsearch}
            ui={this.props.ui}
            uiActions={this.props.uiActions}
            data={this.props.data}
            dataActions={this.props.dataActions} />)
    }

    getItemField = (label, field, type) => {
        let ceData = this.state.modalCreateEdit;
        let mobile = window.innerWidth <= 600;
        let onChange = (v) => { this.setState({ modalCreateEdit: { ...ceData, [field]: v } }) }
        let input = '';
        switch (type) {
            case 'checkbox':
                input = (<Checkbox checked={ceData[field] == '1'} onChange={(e) => { onChange(e.target.checked ? '1' : '0') }} />);
                break;
            case 'text':
                input = (<Input value={ceData[field]} onChange={(e) => { onChange(e.target.value) }} />);
                break;
            case 'date':
                let minDate = field == 'eDateTo' ? ceData.eDateFrom : null
                let maxDate = field == 'eDateFrom' ? ceData.eDateTo : null
                input = (<DatePicker format={"DD/MM/YYYY"}
                    disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                    value={ceData[field] ? moment(ceData[field], "YYYY-MM-DD") : null}
                    onChange={(e) => { onChange(e ? e.format("YYYY-MM-DD") : "") }} />);
                break;
            case 'time':
                input = (<TimePicker format={"HH:mm:ss"}
                    value={ceData[field] ? moment(ceData[field], "HH:mm:ss") : null}
                    onChange={(e) => { onChange(e ? e.format("HH:mm:ss") : "") }} />);
                break;
            case 'typeSelect':
                input = (<Select value={ceData[field]} onChange={onChange} style={{ width: 250 }}>
                    {this.messageTypeList.map(x => (<Select.Option value={x.code}>{this.getText(x.text)}</Select.Option>))}
                </Select>);
                break;
            case 'dow':
                input = [
                    this.getDOWCheckbox(20549, 0),
                    this.getDOWCheckbox(20550, 1),
                    this.getDOWCheckbox(20551, 2),
                    this.getDOWCheckbox(20552, 3),
                    this.getDOWCheckbox(20553, 4),
                    this.getDOWCheckbox(20554, 5),
                    this.getDOWCheckbox(20555, 6),
                ]
                break;
            case 'branchSelect':
                input = (<GenericSelector {...this.props} multi
                    id={TableIDs.genericSelectorBranches}
                    api={"get_branch_list"}
                    value={ceData[field] ? ceData[field].split(',') : []}
                    onChange={e => { onChange(e?.join(",")) }}
                />);
                break;
        }

        return (<Row style={{ padding: 10 }}>
            <Col span={mobile ? 24 : 6}>{this.getText(label)}</Col>
            <Col span={mobile ? 24 : 18}>{input}</Col>
        </Row>)
    }

    getDOWCheckbox = (label, index) => {
        let oldVal = (this.state.modalCreateEdit?.eDOW ? this.state.modalCreateEdit.eDOW : '1111111').split('');
        return (<Checkbox checked={oldVal[index] == '1'}
            onChange={(v) => {
                oldVal[index] = v.target.checked ? '1' : '0'
                this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, eDOW: oldVal.join('') } })
            }}>{this.getText(label)}</Checkbox>)
    }

    addRow = () => {
        let curdate = moment(Date.now()).format("YYYY-MM-DD");

        this.setState({
            modalCreateEdit: {
                key: '0',
                eID: '0',
                eDesc: '',
                eDateFrom: curdate,
                eDateTo: curdate,
                eTimeFrom: '00:00:00',
                eTimeTo: '23:59:59',
                eIsActive: '1',
                eIsActiveInPos: '1',
                eViewType: '1',
                eDOW: '1111111',
                eBranches: ''
            },
            valueMessage: ''
        });
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null, valueMessage: '' })
    }

    handleClear = (eID) => {
        let headers = this.props.data[this.id].headers;
        let obj = {}
        headers.forEach((x) => { obj = { ...obj, [x]: '' } })
        this.setState({ modalCreateEdit: { ...obj, eID: eID }, valueMessage: '' })
    }

    handleOk = (data) => {
        this.props.dataActions.genericDataSaveEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, data);
        this.handleCancel();
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        let gt = this.getText;
        let colTxt = (text) => text
        let colCb = (text) => (<Checkbox checked={text == '1'} />)
        let colType = (text) => {
            let f = this.messageTypeList.find(x => x.code == text);
            if (f) return this.getText(f.text)
        }
        let colEdit = (text, record) => {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={'edit'} onClick={() => {
                    this.sendAPI(this.api.get_value, record.key, (ob) => {
                        let data = { ...record, eValue: ob && ob.data ? ob.data : '' }
                        this.setState({ modalCreateEdit: data, valueMessage: data.eValue })
                    })
                }} />
                <Popconfirm title={gt(14370)} okText={gt(14371)} cancelText={gt(14372)} onConfirm={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={'delete'} />
                </Popconfirm>
            </span>)
        }

        let columns = [
            { title: gt(14362), dataIndex: "eID", key: "eID", width: '7%', sorter: true, render: colTxt },
            { title: gt(14363), dataIndex: "eDesc", key: "eDesc", width: '20%', sorter: true, render: colTxt },
            { title: gt(14364), dataIndex: "eDateFrom", key: "eDateFrom", width: '10%', sorter: true, render: colTxt },
            { title: gt(14365), dataIndex: "eDateTo", key: "eDateTo", width: '10%', sorter: true, render: colTxt },
            { title: gt(14366), dataIndex: "eTimeFrom", key: "eTimeFrom", width: '10%', sorter: true, render: colTxt },
            { title: gt(14367), dataIndex: "eTimeTo", key: "eTimeTo", width: '10%', sorter: true, render: colTxt },
            { title: gt(16021), dataIndex: "eViewType", key: "eViewType", width: '12%', sorter: true, render: colType },
            { title: gt(14368), dataIndex: "eIsActive", key: "eIsActive", width: '7%', sorter: true, render: colCb },
            { title: gt(16114), dataIndex: "eIsActiveInPos", key: "eIsActiveInPos", width: '7%', sorter: true, render: colCb },
            { title: gt(14369), width: '7%', render: colEdit },
        ];

        let titleBar = this.getModalTitle(gt(14361), this.actionButtons, (val) => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token,
                {
                    ...this.props.data[this.id].filters,
                    _words: escapeRegExp(val),
                    //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                    page: 1,
                    _page_num: 1,
                },
                this.api.get)
        })

        let ceData = this.state.modalCreateEdit;
        let mobile = window.innerWidth <= 600;
        console.log('ceData', ceData)

        return (<Modal visible={this.props.visible} onCancel={this.props.onCancel} title={titleBar} footer={false} width={'95%'}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[20, 80]}
                tableOnly={true}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
            <Modal
                visible={ceData} onCancel={this.handleCancel} width={'95%'} title={this.getModalTitle(gt(14373))}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{gt(14374)}</Button>,
                    <Button key="clear" onClick={() => { this.handleClear(this.state.modalCreateEdit.eID) }}>{gt(14375)}</Button>,
                    <Button key="create" type="primary" onClick={() => { this.handleOk(this.state.modalCreateEdit) }}>{gt(14376)}</Button>,
                ]}>
                {ceData ?
                    (<Row style={{ backgroundColor: '#cccccc', padding: 5 }}>
                        <Col span={mobile ? 24 : 12}>
                            {this.getItemField(14363, 'eDesc', 'text')}
                            {this.getItemField(14364, 'eDateFrom', 'date')}
                            {this.getItemField(14365, 'eDateTo', 'date')}
                            {this.getItemField(14366, 'eTimeFrom', 'time')}
                            {this.getItemField(14367, 'eTimeTo', 'time')}
                            {this.getItemField(20547, 'eDOW', 'dow')}
                            {this.getItemField(16021, 'eViewType', 'typeSelect')}
                            {this.getItemField(14368, 'eIsActive', 'checkbox')}
                            {this.getItemField(16114, 'eIsActiveInPos', 'checkbox')}
                            {this.getItemField(20548, 'eBranches', 'branchSelect')}
                        </Col>
                        <Col span={mobile ? 24 : 12}>
                            <EditorContainer
                                getText={this.getText}
                                topMenu={this.state.modalCreateEdit && this.state.modalCreateEdit.eViewType == '2'}
                                value={this.state.valueMessage}
                                onChange={(v) => { this.setState({ modalCreateEdit: { ...ceData, eValue: v } }) }}
                            />
                        </Col>
                    </Row>)
                    : ""}

            </Modal>
        </Modal>)
    }//'', '', 'eTimeFrom', '', '';


}
export default VirtualShopMessages;