/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import {
    Modal, Input, InputNumber, Select, Card,
    Row, Col, Button, Icon, Collapse, Tooltip, Checkbox, message,
    Radio, Spin, Form, Table
} from "antd";
import "../App.css";
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import DataTypeOrPick from "./DataTypeOrPick";
import DataSelectModal from "./DataSelectModal";
import TableContainer from "./TableContainer";
//import SelectorItems from "./SelectorItems";
import ModalMatrixQuantities from "./ModalMatrixQuantities";
//import LabelPrinter from "./LabelPrinter";
import { Job, User, OutputType, ProcessType } from '../JmReact';
//import { thisExpression } from "@babel/types";
//import { curryRight } from "lodash-decorators";
import LangContext from "../contextProvider/LangContext";
import { encodeXml, decodeXml, isTrue } from "../utils/utils.js"
import { configByDocType, configObject } from "../utils/utils";
import CreateDocumentPayments from "./CreateDocumentPayments";
import ContentDocsCentral from "./ContentDocsCentral";
import DocumentCreationItemsDiscrepancy from "./DocumentCreationItemsDiscrepancy";
import ResponsiveTable from "./ResponsiveTable";
//import RadioGroup from "antd/lib/radio/group";
import DateTimePicker from "./DateTimePicker";
import GenericModal from "./GenericModal";
import GenericSelector from "./GenericSelector";
import CurrencyRatesModal from "./CurrencyRatesModal";
import GenericSelectorCustomers from "./GenericSelectorCustomers.js";
import ModalProgress from "./ModalProgress.js";
//import GenericTable from "./GenericTable";


const { confirm } = Modal;
// TODO: add this under css ID  
//import "../style/responsiveTable.css";
//const FormItem = Form.Item;
//const RadioGroup = Radio.Group;
const Option = Select.Option;
//const OptGroup = Select.OptGroup;
const Panel = Collapse.Panel;
const TextArea = Input.TextArea;
const curdatetime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");

type DocumentCreationModalProps = {
    visible: boolean,
    zIndex: number,
    title: string,
    workInProgress: boolean,
    data: Object,
    dataActions: Object,
    closeFunction: Function,
    saveFunction: Function,
    updateFunction: Function,
    ActionQueue: Object,
    user: Object,
    posId: number,
    documentType: number,
    documentNumber: number
};

type State = {
    forceReload: number,
    isFieldModalShown: boolean,
    fieldDefaultValue: string,
    isItemModalShown: boolean,
    isCustomerModalShown: boolean,
    isEmployeeModalShown: boolean,
    isStorageModalShown: boolean,
    isStorageModalShown2: boolean,
    itemDefaultValue: string,
    activePanel: string,
    pricesIncludeTax: boolean,
    isCashPayVisible: boolean,
    isCheckPayVisible: boolean,
    isCreditPayVisible: boolean,
    isVoucherPayVisible: boolean,
    isOtherPayVisible: boolean,
    voucherBuilder: {
        voucherSum: number,
        voucherNumber: number,
        voucherIssueDate: string,
        voucherDocument: number,
        voucherPos: number
    },
    vouchers: Array<{
        voucherSum: number,
        voucherNumber: number,
        voucherIssueDate: string,
        voucherDocument: number,
        voucherPos: number,
        id: number
    }>
};

class DocumentCreationModal extends Component<Props, State> {
    constructor(props: DocumentCreationModalProps) {
        super(props);
        //Jax.set({}, "this.props.data.document.final", {})
        if (props.data["document2"] === undefined) {
            props.data["document2"] = { dataSource: [], editedRecord: {} };
        }

        if (props.data.documentPayments === undefined) props.data.documentPayments = { ...props.data.genericData }
        if (props.data.transitStorages === undefined) props.data.transitStorages = { ...props.data.genericSelector }

        this.api = {
            check: "check_all_item_code"
        }
        this.state = {
            activeTab: "tab1",
            loading: false,
            config: {},

            GP_RequireSuppDocNum: "1",
            GP_UniqSuppDocNum: "0",
            GP_VatInclude: "0",
            GP_TotalDialog: "1",
            GP_SalesTotalDialog: "0",
            GP_UpdateCostPrice: "1",
            GP_itemcodequantityunion: "0",

            gp_roundTotal: "2",
            gp_roundDesc: "עיגול מזומן",
            gp_roundSumItemCode: "",

            GP_PrintCustomerCredit: "0",

            GP_DocSendTypeDefault: "1",
            GP_DocLineOrderDefault: "0",

            GP_CostCalcOption: "0",

            GP_MarkChangeOnCost: "0",

            GP_ContinueSearchList: "0",

            GP_AutoMatchOption: "0",

            GP_DocEmployeeIsRequired: "0",
            GP_LastItemSearched: "0",

            GP_FatherItemInDoc: "0",
            GP_PrintFixCustFields: "0",
            GP_ChooseSalePriceOption: "0",
            GP_ChoosePurchasePriceOption: "0",
            GP_ProfitDetailsInDocBO: "1",

            modalItemsDiscrepancy: false,
            modalItemsDiscrepancyData: [],

            isCostWithTax: true,
            isCostNoTax: true,
            hidePrices: false,

            modalCreateItemRound: false,
            modalCreateItemRoundSumDoc: 0,
            modalCreateItemRoundPositive: false,

            PaymentsSearchReport: {
                DocumentNumber: null,
                DocumentTypeID: null,
                DocumentPOSID: null,
                CompanyCode: this.props.user.companyCode,
                reportName: "reports/DocumentCopy.pdf"
            },

            printOriginalDoc: true,
            printOriginalDocType1: true,
            printOriginalDocType2: false,
            printOriginalDocType3: false,
            printOriginalDocType4: false,
            printOriginalDocType5: false,

            printCopyDoc: false,
            printCopyDocType1: false,
            printCopyDocType2: false,
            printCopyDocType3: false,

            printType5051: '0',

            sendDocBySms: false,
            sendDocBySmsTarget: '',
            sendDocBySmsTargetOk: false,


            loadDocumentNumber: false,
            loadCostTax: false,

            modalNaNSum: null,
            modalCreateItemsMulti: null,
            modalUpdateItemsMulti: null,
            printCentralPerFatherItem: false,

            PrintFormat: 1,

            modalCreateTemplete: false,
            modalCreateTempleteDesc: "",

            modalMultiCust: false,
            modalMultiCustList: "",
            modalMultiCustCreate: null,
            modalMultiCustLength: 0,


        };

        this.intervalID = null;
        this.docIsCreated = false;
        this.docIsCreated2 = false;
        this.docIsCreated3 = false;

        this.modalItemsDiscrepancyUse = false;

        this.isSupplierChanged = false;
        this.lastSupplier = null;

        let n = (new Date()).valueOf();
        this.lastDate = n;
        this.updatedDate = n;
        this.timerInterval = 5000;

        // create doc user acceptance flags
        this.userAcceptsZeroPrices = false;
        this.userAcceptsInValidWarning = false;
        this.userAcceptsPreDate = false;

        //create discount item to round
        this.itemRoundCreated = false;

        this.confirmPrintTypeOpen = false;

        this.id_rates = "LAST_CURRENCY_RATES";


        if (props.data[this.id_rates] === undefined) props.data[this.id_rates] = { ...props.data.genericData }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //console.log("zzzzzz: DocumentCreationModalV2 mounted");
        //this.setState({ ...this.state, config: configByDocType(0) }); // default
        //let docNum = this.props.documentNumber; // Jax.get(this.props.data, "document.editedRecord.number", 0);
        let currTime = (new Date()).valueOf();
        let self = this;
        this.intervalID = setInterval(function () {

            // console.log("autoSaveParams ", self.props,
            //     self.props.visible,
            //     self.props.documentNumber,
            //     self.updatedDate,
            //     self.lastDate,
            //     self.updatedDate - self.lastDate);

            if (self.props.visible && self.props.documentNumber && self.updatedDate !== self.lastDate && (self.updatedDate - self.lastDate) > self.timerInterval) {
                self.lastDate = self.updatedDate;
                // console.log("autoSave...");
                self.saveDraft();
            }
        }, self.timerInterval);

    }



    componentWillUnmount() {
        //console.log("zzzzzz: DocumentCreationModalV2 un-mounted");
        clearInterval(this.intervalID);
    }

    componentDidUpdate(old) {


        //if (this.props.posId !== old.posId) {
        if (this.props.posId !== old.posId || this.props.documentType !== old.documentType || (this.props.visible !== old.visible && this.props.visible)) {
            this.getPriceList();
            let documentNumber = Jax.get(this.props.data, "document.editedRecord.number", 0);
            if ((documentNumber === "0" || documentNumber === 0 || documentNumber === null || isNaN(documentNumber)) && this.props.visible) {
                // window.console.log("Issuing a document number...");
                this.setState({ loadDocumentNumber: true }, this.issueDocumentNumber)
            }
            this.props.dataActions.genericSelectorRefreshDataset('transitStorages', this.props.user.companyCode, this.props.user.token, 'transit_storages_list', { posID: this.props.posId });
        }

        // if (this.props.documentNumber !== old.documentNumber && this.props.documentNumber > 0) {
        if (this.props.visible !== old.visible && this.props.visible) {
            // console.log("#### second if")
            //this.setState({ ...this.state, config: configByDocType(this.props.documentType) }); // default
            this.loadDraft(() => {
                //setTimeout(function () {
                if (document.getElementById("search_employee_picker")) {
                    document.getElementById("search_employee_picker").focus();
                }
                // if (document.getElementById("search_storage_picker")) {
                //     document.getElementById("search_storage_picker").focus();
                // }
                // if (document.getElementById("search_storage2_picker")) {
                //     document.getElementById("search_storage2_picker").focus();
                // }

                //}, 150)
            });
        }

        if (this.props.documentType !== old.documentType && (this.props.documentType == 15 || this.props.documentType == 215)) {
            this.sendAPI("get_params_from_doc_type_15", "", (ob) => {
                if (ob.data) {
                    let data = ob.data.split('\f');
                    // console.log('this.state.hidePrices && this.props.documentType', data)
                    this.setState({
                        hidePrices: data[1] != '1',
                        // isCostWithTax: data[0] == '1',
                    })
                }
            })
        }

        if (this.props.posId !== old.posId || this.props.documentType !== old.documentType) {
            this.sendAPI('get_all_gp', this.props.posId, (ob) => {
                let gpList = {}
                if (ob.data) {
                    ob.data.split("\r").forEach(x => {
                        let y = x.split("\f");
                        gpList = { ...gpList, [y[0]]: y[1] }
                    })
                }

                this.setState({
                    GP_RequireSuppDocNum: gpList.GP_InventoryDocsRequireSuppDocNum,
                    GP_UniqSuppDocNum: gpList.GP_InventoryDocsUniqSuppDocNum,
                    GP_VatInclude: gpList.GP_InventoryDocsVatInclude,
                    GP_TotalDialog: gpList.GP_InventoryInConfirmDocumentTotalDialog,
                    GP_SalesTotalDialog: gpList.GP_SalesConfirmDocumentTotalDialog,
                    GP_UpdateCostPrice: gpList.GP_UpdateCostPriceBO,
                    printType5051: gpList.GP_PrintDocType5051,
                    GP_itemcodequantityunion: gpList.GP_itemcodequantityunion,

                    isCostNoTax: gpList.GP_InventoryDocsVatInclude != '2',
                    isCostWithTax: gpList.GP_InventoryDocsVatInclude != '1',

                    gp_roundTotal: gpList.GP_RoundTotal,
                    gp_roundTotalInvoice: gpList.GP_RoundTotalInvoice,
                    gp_roundTotalShipping: gpList.GP_RoundTotalShipping,
                    gp_roundDesc: gpList.GP_RoundDesc,
                    gp_roundSumItemCode: gpList.GP_RoundSumItemCode,

                    GP_PrintCustomerCredit: gpList.GP_PrintCustomerCredit,

                    GP_DocSendTypeDefault: gpList.GP_DocSendTypeDefault,
                    GP_DocLineOrderDefault: gpList.GP_DocLineOrderDefault,
                    GP_CostCalcOption: gpList.GP_CostCalcOption,
                    GP_FinalDiscount: gpList.GP_FinalDiscount,
                    GP_MarkChangeOnCost: gpList.GP_MarkChangeOnCost,
                    GP_ContinueSearchList: gpList.GP_ContinueSearchList,
                    GP_AutoMatchOption: gpList.GP_AutoMatchOption,
                    GP_DocEmployeeIsRequired: gpList.GP_DocEmployeeIsRequired,
                    GP_LastItemSearched: gpList.GP_LastItemSearched,

                    GP_FatherItemInDoc: gpList.GP_FatherItemInDoc,
                    GP_PrintFixCustFields: gpList.GP_PrintFixCustFields,
                    GP_ChooseSalePriceOption: gpList.GP_ChooseSalePriceOption,
                    GP_ChoosePurchasePriceOption: gpList.GP_ChoosePurchasePriceOption,
                    GP_ProfitDetailsInDocBO: gpList.GP_ProfitDetailsInDocBO,
                })
            });


        }

        if (this.props.data.document2.docDateTime && this.props.data.document2.docDateTime !== old.data.document2.docDateTime) {
            this.apiLastRates()
        }

        if (this.props.documentNumber && this.props.documentNumber !== old.documentNumber) {
            setTimeout(() => {
                const { supplierDocIncludeVat } = this.props.data.document2;
                let newState = { loadCostTax: false }
                if (supplierDocIncludeVat) {
                    newState = {
                        ...newState,
                        isCostNoTax: supplierDocIncludeVat != '2',
                        isCostWithTax: supplierDocIncludeVat != '1',
                    }
                }

                this.setState(newState, () => {
                    this.props.dataActions.setJson("document2", { draftNumber: this.props.documentNumber });
                    if (document.getElementById("search_employee_picker")) {
                        document.getElementById("search_employee_picker").focus();
                    }
                })
            }, 2000);
        }

        // state changed
        this.updatedDate = (new Date()).valueOf();


        console.log("priceListID", this.props.data.document2.priceListId)
    }

    roundTotalValue = (sum) => {
        if (!sum) return 0;
        const { gp_roundTotal, gp_roundTotalInvoice, gp_roundTotalShipping } = this.state;
        const { documentType } = this.props;
        const rtPrm = documentType == 1 ? gp_roundTotal
            : documentType == 2 || documentType == 5 || documentType == 22 || documentType == 2005 || documentType == 202 || documentType == 222 ? gp_roundTotalInvoice
                : documentType == 8 || documentType == 9 || documentType == 208 ? gp_roundTotalShipping
                    : 0

        switch (rtPrm) {
            case '1': return Math.round(sum * 20) / 20;
            case '2': return Math.round(sum * 10) / 10;
            case '3': return Math.round(sum * 2) / 2;
            case '4': return Math.round(sum);
            case '5': return Math.floor(sum);
            default: return sum;
        }
    }

    apiLastRates = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id_rates, this.props.user.companyCode, this.props.user.token, { dDate: this.props.data.document2.docDateTime }, "get_last_rates")
    }

    issueDocumentNumber() {
        let documentTypeId = this.props.documentType;
        if (this.props.documentType == 5051) documentTypeId = 50;
        if (this.props.documentType == 45051) documentTypeId = 4;
        if (this.props.documentType == 40418) documentTypeId = 16;
        const onLoadNewDraftNumber = () => { this.setState({ loadCostTax: true, loadDocumentNumber: false }) }

        this.props.dataActions.networkFetch(this.props.user.companyCode, this.props.user.token,
            {
                script: "get_temporary_document_number",
                params: { documentTypeId, posId: this.props.posId }
            },
            "document.editedRecord.number",
            { loading: "", failed: "", success: "" },
            false,
            { action: null, args: [] },
            function (actionName, store, data) {
                onLoadNewDraftNumber()
                return Jax.set(store, "document.editedRecord.number", parseInt(data, 10));
            }.bind(this));

    }

    // onLoadNewDraftNumber = (draftNumber)=>{
    //     const { supplierDocIncludeVat } = this.props.data.document2;
    //     let newState = { loadDocumentNumber: false }
    //     if (supplierDocIncludeVat) {
    //         newState = {
    //             ...newState,
    //             isCostNoTax: supplierDocIncludeVat != '2',
    //             isCostWithTax: supplierDocIncludeVat != '1',
    //         }
    //     }

    //     this.setState(newState, () => {
    //         this.props.dataActions.setJson("document2", { draftNumber });
    //         if (document.getElementById("search_employee_picker")) {
    //             document.getElementById("search_employee_picker").focus();
    //         }
    //     })
    // }

    getPriceList = () => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "get_prices", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => { // console.log("success:", ob.data);
                //onSucc(ob.data);
                let arrStr = ob.data.split("\r");
                let arr = arrStr.map(i => {
                    let _record = i.split("\f");
                    return {
                        id: parseInt(_record[0], 10),
                        name: _record[1],
                        taxRegion: parseInt(_record[2], 10),
                        includesTax: _record[3] === 1 || _record[3] === '1'
                    }
                });
                // console.log("DDDDDDD: ", this, this.props, this.props.dataActions);
                this.props.dataActions.setJson("document2", { priceList: arr });
            },
            (error: any) => { message.error(this.getText(12446)); }
        );
    }

    onAddMultiLineItems_v2 = async (itemCode) => {
        // console.log(itemCode, 'test test onAddMultiLineItems_v2 ')
        let datasetObj = this.props.data[TableIDs.matrixQuantities];
        let matrixItemsArr = this.props.data["MATRIX_BARCODE"].data
            .map(i => {
                let arr = i.split('\f');
                return {
                    itemBarcode: itemCode,
                    itemCode: arr[1],
                    colorId: arr[8],
                    sizeId: arr[5],
                    colorName: arr[9],
                    sizeName: arr[6],
                    barcode2: arr[2]
                }
            });

        //console.log("DEBOZ", datasetObj);
        let entryArr = []
        let ind = (this.props.data.document2.dataSource).length + 1;

        for (var key in datasetObj) {
            if (!datasetObj.hasOwnProperty(key)) continue;
            if (!datasetObj[key]) continue;
            let keyArr = key.split('#');
            let entry = {}
            entry.colorId = keyArr[0];
            entry.sizeId = keyArr[1];

            entry.quantity = datasetObj[key].quantity;
            entry.itemName = datasetObj[key].itemName;

            let arrObj = matrixItemsArr.find(obj => obj['colorId'] == entry.colorId && obj['sizeId'] == entry.sizeId);
            entry.itemBarcode = arrObj.itemBarcode;
            entry.itemCode = arrObj.itemCode;
            entry.colorName = arrObj.colorName;
            entry.sizeName = arrObj.sizeName;
            entry.barcode2 = arrObj.barcode2;

            entry.key = ind;
            entry.keyVal = ind;

            entry.unitQuantity = 1.00;

            //console.log("DEBOZ", entry);

            //this.insertEntryFromQuantitiyGrid(entry);
            //await new Promise(r => setTimeout(r, 200));
            entryArr.push(entry);

            ind++;
        }

        this.insertEntryListFromQuantitiyGrid(entryArr);

    }

    insertEntryListWithKey = (arr, callback) => {
        //let newObj = { ...this.props.data.document2.editedRecord, ...obj, };
        //console.log("DEBOP: ", arr);
        //console.log("DEBOP: ",{ dataSource: [...this.props.data.document2.dataSource].concat(arr), editedRecord: emptyRecord } );

        let emptyRecord: RecordObj = {
            key: 0,
            keyVal: 0,
            itemCode: '',
            description: '',
            quantity: 0.00,
            unitPriceWithTax: 0.00,
            discountPercentage: 0.00,
            totalSumWithTax: 0.00,
        };
        this.props.dataActions.setJson("document2", { dataSource: [...this.props.data.document2.dataSource].concat(arr), editedRecord: emptyRecord });
    }

    insertEntryWithKey = (obj, callback) => {
        let newObj = { ...this.props.data.document2.editedRecord, ...obj, };
        let emptyRecord: RecordObj = {
            key: 0,
            keyVal: 0,
            itemCode: '',
            description: '',
            quantity: 0.00,
            unitPriceWithTax: 0.00,
            discountPercentage: 0.00,
            totalSumWithTax: 0.00,
        };
        this.props.dataActions.setJson("document2", { dataSource: [...this.props.data.document2.dataSource, newObj], editedRecord: emptyRecord });
    }

    insertEntry = (obj, callback) => {
        let len = (this.props.data.document2.dataSource).length + 1;
        let newObj = { ...this.props.data.document2.editedRecord, ...obj, key: len, keyVal: len };
        let emptyRecord: RecordObj = {
            key: 0,
            keyVal: 0,
            itemCode: '',
            description: '',
            quantity: 0.00,
            unitPriceWithTax: 0.00,
            discountPercentage: 0.00,
            totalSumWithTax: 0.00,
        };
        this.props.dataActions.setJson("document2", { dataSource: [...this.props.data.document2.dataSource, newObj], editedRecord: emptyRecord });
    }

    getDecimalNumber = (val) => {
        if (isNaN(val)) return '0.00';
        if (!val) return '0.00';
        if (!parseFloat(val)) return '0.00';
        return parseFloat(val).toFixed(2)
    }

    insertEntryFromQuantitiyGrid = (entry: { colorId: Number, sizeId: Number, quantity: Number, itemName: String, itemCode: String, colorName: String, sizeName: String, barcode2: String }) => {
        // console.log("insertMatrixEntry");
        //for empty string do nothing
        if (!entry.itemCode) return;
        let val = entry.itemCode;
        let isSupp = [4, 16, 17, 18, 20, 21].find(f => f == this.props.documentType) ? true : false
        let dataSend = "_itemCode\f_customerId\f_supplierId\f_priceListId\f_posId\f_docTime\r" +
            [
                val,
                this.props.data.document2.customerCode,
                isSupp ? this.props.data.document2.supplierCode : "", //null, 
                this.props.data.document2.priceListId,
                this.props.posId,
                this.props.data.document2.docDateTime
            ].join("\f");

        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "get_item_line_meta", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob: Object) => {
            let resArr = (ob.data).split('\f');

            // console.log("rrrrrrr resArr: ", resArr);

            let _unitCost = resArr[0] ? resArr[0] : 0;
            let _price = resArr[1] ? resArr[1] : 0;
            let _unitCostNoTax = resArr[2] ? resArr[2] : 0;
            let _priceNoTax = resArr[3] ? resArr[3] : 0;

            let _taxId = resArr[4];
            let _taxPercentageValue = resArr[5] ? resArr[5] : 0;
            let _supplierId = resArr[6];
            let _isWeightable = resArr[8];

            let _config = configByDocType(this.props.documentType);

            if (_config.isUnitCost) {
                if ((this.state.GP_CostCalcOption == '1' || this.state.GP_CostCalcOption == '2') && resArr[20]) {
                    _priceNoTax = resArr[20];
                    _price = _priceNoTax * ((parseFloat(_taxPercentageValue) + 100) / 100);
                } else {
                    _price = _unitCost;
                    _priceNoTax = _unitCostNoTax;
                }

            }


            // console.log('this.state.hidePrices && this.props.documentType', this.state.hidePrices, this.props.documentType)
            if (this.state.hidePrices && (this.props.documentType == '15' || this.props.documentType == '215')) {
                _unitCost = 0;
                _price = 0;
                _unitCostNoTax = 0;
                _priceNoTax = 0;
            }

            let _remarks = '';
            _remarks = encodeXml(_remarks, 'Color', entry.colorName);
            _remarks = encodeXml(_remarks, 'Size1', entry.sizeName);
            if (resArr[24]) _remarks += resArr[24];

            let customerDiscount = resArr[25] > 0 ? resArr[25] : this.props.data.document2.customerDiscount ? this.props.data.document2.customerDiscount : 0;

            let newEntry = {
                key: entry.key,
                keyVal: entry.key,
                itemCode: val,
                description: entry.itemName,
                isValid: 1,
                quantity: parseFloat(entry.quantity).toFixed(4),
                unitPriceWithTax: parseFloat(_price).toFixed(4),//_unitCost,//1,
                unitPriceNoTax: parseFloat(_priceNoTax).toFixed(4),//_unitCost,//1,
                discountPercentage: customerDiscount,
                totalSumWithTax: (parseFloat(_price) * parseFloat(entry.quantity) * (1 - customerDiscount / 100)).toFixed(4),
                totalSumNoTax: (parseFloat(_priceNoTax) * parseFloat(entry.quantity) * (1 - customerDiscount / 100)).toFixed(4),
                taxId: _taxId,
                taxPercentageValue: _taxPercentageValue,
                //Color | Size1 | Barcode
                remarks: _remarks,

                unitQuantity: 1,
                quantityInUnit: 1,

                isWeightable: _isWeightable,

                costCurrencyId: resArr[10],
                costCurrencySymbol: resArr[11],
                costCurrencyValue: resArr[12],
                costCurrencyRate: resArr[13],

                cost: parseFloat(_unitCostNoTax).toFixed(4),
                supplierId: _supplierId,

                bonusCalcData: {
                    mBaseCost: this.getDecimalNumber(resArr[16]),
                    mSupDscnt1: this.getDecimalNumber(resArr[17]),
                    mSupDscnt2: this.getDecimalNumber(resArr[18]),
                    mSupDscnt3: this.getDecimalNumber(resArr[19]),
                    mCostIncludeDscnt: this.getDecimalNumber(resArr[20]),
                    mBonusQty: this.getDecimalNumber(resArr[21]),
                    mQtyForBonusQty: this.getDecimalNumber(resArr[22]),
                    mCostIncludeBonus: this.getDecimalNumber(resArr[23])
                }


            }


            //console.log("rrrrrrr newEntry: ", newEntry);
            this.insertEntryWithKey(newEntry);
        }, (error: any) => {
            message.error(this.getText(12538));
        });

    }

    insertEntryListFromQuantitiyGrid = (entryList: { colorId: Number, sizeId: Number, quantity: Number, itemName: String, itemCode: String, colorName: String, sizeName: String, barcode2: String }[]) => {
        // console.log("insertMatrixEntryList");
        //let val = null;
        //for empty string do nothing

        // console.log('DEBOP 1', entryList);

        let promiseList = [];

        let apiCall = (entry) => {
            if (!entry.itemCode) return;
            let val = entry.itemCode;
            let _resolve = (ob: Object) => {
                let resArr = (ob.data).split('\f');
                let _unitCost = resArr[0] ? resArr[0] : 0;
                let _price = resArr[1] ? resArr[1] : 0;
                let _unitCostNoTax = resArr[2] ? resArr[2] : 0;
                let _priceNoTax = resArr[3] ? resArr[3] : 0;
                let _taxId = resArr[4];
                let _taxPercentageValue = resArr[5] ? resArr[5] : 0;
                let _supplierId = resArr[6];

                let _qty = entry.quantity;

                let _config = configByDocType(this.props.documentType);
                if (_config.isUnitCost) {
                    if ((this.state.GP_CostCalcOption == '1' || this.state.GP_CostCalcOption == '2') && resArr[20]) {
                        _priceNoTax = resArr[20];
                        _price = _priceNoTax * ((parseFloat(_taxPercentageValue) + 100) / 100);
                    } else {
                        _price = _unitCost;
                        _priceNoTax = _unitCostNoTax;
                    }

                }

                // console.log('this.state.hidePrices && this.props.documentType', this.state.hidePrices, this.props.documentType)
                if (this.state.hidePrices && (this.props.documentType == '15' || this.props.documentType == '215')) {
                    _unitCost = 0;
                    _price = 0;
                    _unitCostNoTax = 0;
                    _priceNoTax = 0;
                }



                if (this.props.documentType == '9' || this.props.documentType == '21' || this.props.documentType == '22' || this.props.documentType == '222') {
                    _qty = _qty * -1;
                }

                let _remarks = '';
                _remarks = encodeXml(_remarks, 'Color', entry.colorName);
                _remarks = encodeXml(_remarks, 'Size1', entry.sizeName);
                if (resArr[24]) _remarks += resArr[24];

                let customerDiscount = resArr[25] > 0 ? resArr[25] : this.props.data.document2.customerDiscount ? this.props.data.document2.customerDiscount : 0;

                //console.log("")
                let newEntry = {
                    key: entry.key,
                    keyVal: entry.key,
                    itemCode: val,
                    itemBarcode: entry.itemBarcode,
                    description: entry.itemName,
                    isValid: 1,
                    quantity: parseFloat(_qty).toFixed(4),
                    unitPriceWithTax: parseFloat(_price).toFixed(4),//_unitCost,//1,
                    unitPriceNoTax: parseFloat(_priceNoTax).toFixed(4),//_unitCost,//1,
                    discountPercentage: customerDiscount,
                    totalSumWithTax: (parseFloat(_price) * parseFloat(_qty) * (1 - customerDiscount / 100)).toFixed(4),
                    totalSumNoTax: (parseFloat(_priceNoTax) * parseFloat(_qty) * (1 - customerDiscount / 100)).toFixed(4),
                    taxId: _taxId,
                    taxPercentageValue: _taxPercentageValue,
                    //Color | Size1 | Barcode
                    remarks: _remarks,

                    unitQuantity: 1,
                    quantityInUnit: 1,

                    costCurrencyId: resArr[10],
                    costCurrencySymbol: resArr[11],
                    costCurrencyValue: resArr[12],
                    costCurrencyRate: resArr[13],


                    cost: parseFloat(_unitCostNoTax).toFixed(4),
                    supplierId: _supplierId,

                    bonusCalcData: {
                        mBaseCost: this.getDecimalNumber(resArr[16]),
                        mSupDscnt1: this.getDecimalNumber(resArr[17]),
                        mSupDscnt2: this.getDecimalNumber(resArr[18]),
                        mSupDscnt3: this.getDecimalNumber(resArr[19]),
                        mCostIncludeDscnt: this.getDecimalNumber(resArr[20]),
                        mBonusQty: this.getDecimalNumber(resArr[21]),
                        mQtyForBonusQty: this.getDecimalNumber(resArr[22]),
                        mCostIncludeBonus: this.getDecimalNumber(resArr[23])
                    }

                }
                //console.log("rrrrrrr newEntry: ", newEntry);
                //this.insertEntryWithKey(newEntry);
                return newEntry;
            }

            let _reject = (error: any) => {
                message.error(this.getText(12538));
            }

            let isSupp = [4, 16, 17, 18, 20, 21].find(f => f == this.props.documentType) ? true : false


            return new Promise((resolve, reject) => {
                let dataSend = "_itemCode\f_customerId\f_supplierId\f_priceListId\f_posId\f_docTime\r" +
                    [val,
                        this.props.data.document2.customerCode,
                        isSupp ? this.props.data.document2.supplierCode : "", //null, 
                        this.props.data.document2.priceListId,
                        this.props.posId,
                        this.props.data.document2.docDateTime].join("\f");

                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "get_item_line_meta", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob: Object) => { resolve(_resolve(ob)) },
                    (error: any) => { reject(_reject(error)); }
                )
            }
            )
        }

        for (let entry of entryList) {
            if (!entry.itemCode) continue;
            promiseList.push(apiCall(entry))
        }
        Promise.all(promiseList)
            .then((results) => {
                // console.log("DEBOP All done", results);
                this.insertEntryListWithKey(results);
            })
            .catch((e) => { console.log("DEBOP failed"); /* some one failed */ })
    }

    getNumberValue = (num) => {
        return num && !isNaN(num) ? num : 0;
    }

    loadDraft = (doOnSucc, doOnFail) => {
        // console.log("load draft");
        const { defaultEmployee, defaultStorage } = this.props;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "data_get_json", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        //dataObject number
        //tempDocument 9995000109903
        //job.setSection("dataObject", "tempDocument");
        //job.setSection("json", jsonObj); 
        let dataInput = "dataObject\fnumber\rtempDocument\f" + this.props.documentNumber;
        job.setInput(dataInput);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => {
                // console.log("success:", ob.data);
                // console.log("success:", ob.data);
                let j = JSON.parse(ob.data);
                /*
                console.log("cvb: ", j.storageId);
                console.log("cvb: ", defaultStorage.id);
                console.log("cvb: ", (j.storageId) ? j.storageId : defaultStorage.id);
                */

                const customerRecord = this.props.data.Customers.find(r => r.id == j.customerCode);
                const custPriceList = customerRecord ? customerRecord.priceList : null;

                let _config = configByDocType(this.props.documentType);
                let bonusCalc = _config.isUnitCost && (this.state.GP_CostCalcOption == '1' || this.state.GP_CostCalcOption == '2');


                let json = {
                    draftNumber: j.number,

                    remarks: j.remarks,
                    customerAddress: j.customerAddress,
                    customerCode: j.customerCode,
                    customerCodeLoad: j.customerCode,
                    customerName: j.customerName,
                    customerZipCode: j.customerZipCode,
                    customerPhone: j.customerPhone,
                    supplierCode: j.customerCode,
                    supplierName: j.customerName,
                    employeeID: (j.employeeID && parseInt(j.employeeID, 10) !== 0) ? j.employeeID : defaultEmployee.id,
                    employeeName: (j.employeeID && parseInt(j.employeeID, 10) !== 0) ? j.employeeName : defaultEmployee.name,

                    custPriceList,
                    priceListId: custPriceList ? custPriceList.code : this.props.data.document2.priceListId,

                    // docDateTime: undefined,
                    docDateTime: j.timestamp ? j.timestamp : moment().format('YYYY-MM-DD HH:mm:ss'),
                    docValDate: j.docValDate,
                    docRef2: j.docRef2,
                    reservedNumber: j.reservedNumber,

                    dataSource: j.lines.map(i => {
                        let bonusCalcData = {}
                        let remarks = i.remarks;
                        let bonusCalcDataJson = decodeXml(i.remarks, 'bonusCalcData');
                        console.log("bonusCalcDataJson", bonusCalc, bonusCalcDataJson, i, i.remarks)
                        if (bonusCalcDataJson) {
                            bonusCalcData = JSON.parse(bonusCalcDataJson)
                            let oRemarks = remarks?.split("<bonusCalcData>")[0] ?? ""
                            let eRemarks = remarks?.split("</bonusCalcData>").slice(-1)[0] ?? ""
                            // remarks = remarks.replace("<bonusCalcData>" + bonusCalcDataJson + "</bonusCalcData>", "");
                            remarks = oRemarks + eRemarks
                        }
                        // let mBonusQty = decodeXml(i.remarks, 'BonusQty');
                        // let mQtyForBonusQty = decodeXml(i.remarks, 'QtyForBonusQty');
                        // if (mBonusQty) remarks = remarks.replace("<BonusQty>" + mBonusQty + "</BonusQty>", "");
                        // if (mQtyForBonusQty) remarks = remarks.replace("<QtyForBonusQty>" + mQtyForBonusQty + "</QtyForBonusQty>", "");

                        // if (bonusCalc) {
                        //     bonusCalcData = {
                        //         mBaseCost: 0,
                        //         mSupDscnt1: 0,
                        //         mSupDscnt2: 0,
                        //         mSupDscnt3: 0,
                        //         mCostIncludeDscnt: 0,
                        //         mBonusQty: parseFloat(mBonusQty) ?? 0,
                        //         mQtyForBonusQty: parseFloat(mQtyForBonusQty) ?? 0,
                        //         mCostIncludeBonus: 0
                        //     }
                        //     if (i.dscnt1 || i.dscnt2 || i.dscnt3) {
                        //         bonusCalcData.mBaseCost = this.getNumberValue(i.unitPriceNoTax)
                        //         bonusCalcData.mCostIncludeDscnt = this.getNumberValue(i.unitPriceNoTax)
                        //         bonusCalcData.mCostIncludeBonus = this.getNumberValue(i.unitPriceNoTax);

                        //         if (bonusCalcData.mBonusQty && bonusCalcData.mQtyForBonusQty && i.unitPriceNoTax) {
                        //             bonusCalcData.mCostIncludeBonus = this.getNumberValue(bonusCalcData.mCostIncludeBonus * bonusCalcData.mQtyForBonusQty / (bonusCalcData.mBonusQty + bonusCalcData.mQtyForBonusQty))
                        //         }

                        //         if (i.dscnt3) {
                        //             bonusCalcData.mSupDscnt3 = i.dscnt3;
                        //             bonusCalcData.mBaseCost = this.getNumberValue(bonusCalcData.mBaseCost * (1 - (i.dscnt3 / 100)));
                        //         }

                        //         if (i.dscnt2) {
                        //             bonusCalcData.mSupDscnt2 = i.dscnt2;
                        //             bonusCalcData.mBaseCost = this.getNumberValue(bonusCalcData.mBaseCost * (1 - (i.dscnt2 / 100)));
                        //         }

                        //         if (i.dscnt1) {
                        //             bonusCalcData.mSupDscnt1 = i.dscnt1;
                        //             bonusCalcData.mBaseCost = this.getNumberValue(bonusCalcData.mBaseCost * (1 - (i.dscnt1 / 100)));
                        //         }

                        //     }
                        // }
                        return {
                            description: i.description,
                            discountPercentage: i.discountPercentage,
                            itemCode: i.itemCode,
                            itemBarcode: i.itemBarcode,
                            key: i.number,
                            keyVal: i.number,
                            quantity: i.quantity,
                            //totalWithTax: i.totalSumWithTax,
                            //unitPriceWithTax: i.unitPriceWithTax,
                            totalSumNoTax: this.getNumberValue(i.totalSumNoTax),
                            totalSumWithTax: this.getNumberValue(i.totalSumWithTax),
                            unitPriceNoTax: this.getNumberValue(i.unitPriceNoTax),
                            unitPriceWithTax: this.getNumberValue(i.unitPriceWithTax), //23,
                            taxId: this.getNumberValue(i.taxTypeId),
                            taxPercentageValue: this.getNumberValue(i.taxValue),
                            isValid: parseInt(i.isValid, 10), //GetDocumentLinesIsValidLine

                            remarks,

                            unitID: i.unitID,
                            quantityInUnit: i.quantityInUnit,
                            unitQuantity: i.unitQuantity,
                            unitDescription: i.unitDescription,

                            cost: i.cost,
                            supplierId: i.supplierId,

                            costCurrencyValue: i.costCurrencyValue,
                            costCurrencyId: i.costCurrencyId,
                            costCurrencyRate: i.costCurrencyRate,

                            // changed by oz - 9/3/23
                            serialNumber: i.sn,

                            bonusCalcData
                            //DocumentLinesDocumentUnitDescription
                        }
                    }),
                    // lines
                    editedRecord: {}, // edit
                    //priceList:,
                    // priceListId:,
                    storageId: (j.storageId && parseInt(j.storageId, 10) !== 0) ? j.storageId : defaultStorage.id,
                    storageName: (j.storageId && parseInt(j.storageId, 10) !== 0) ? j.storageName : defaultStorage.name, // null /* parse remark xml */,
                    storageId2: decodeXml(j.remarks, 'TransferInId')/* not found in doument */,
                    storageName2: decodeXml(j.remarks, 'TransferIn') /* parse remark xml */,

                    // supplierDocNum: decodeXml(j.remarks, 'SupplierDocNum') /* parse remark xml */,
                    supplierDocNum: j.docRef2,
                    // supplierDocDate: decodeXml(j.remarks, 'SupplierDocDate') /* parse remark xml */,
                    supplierDocDate: moment(j.timestamp).format('YYYY-MM-DD'),
                    supplierDocIncludeVat: decodeXml(j.remarks, 'SupplierDocIncludeVat') /* parse remark xml */,
                    // "<TransferIn>" + inText + "</TransferIn>"
                    // "<TransferInId>" + inId + "</TransferInId>"
                    // "<TransferOut>" + outText + "</TransferOut>"
                    // "<TransferOutId>" + outId + "</TransferOutId>";
                    taxes: j.taxes,

                    /*
                  [{
                      "taxTypeID" : "2",
                      "taxValue" : "0.00",
                      "timestamp" : "2019-11-27 10:00:38",
                      "totalNoTax" : "23.00",
                      "totalWithTax" : "0.00"
                  }]  
                  */
                }

                // let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051].filter(f => this.props.documentType == f).length > 0

                // if (isSuppDoc && !json.supplierDocDate) {
                //     json = { ...json, supplierDocDate: moment().format("YYYY-MM-DD") }
                // }

                console.log("loadDraft - set json: ", json);
                this.props.dataActions.setJson("document2", json);


                /// pays
                if (j.payments) {
                    this.props.dataActions.setJson('documentPayments', { data: j.payments })
                }

                if (doOnSucc) { doOnSucc(); }

            },
            (error: any) => {
                message.error(this.getText(12446));
                if (doOnFail) { doOnFail(); }
            }
        )

    }

    saveDraft = (callback) => {
        // console.log("save draft");
        let draftNumber = this.props.data.document2 && this.props.data.document2.draftNumber ? this.props.data.document2.draftNumber : null
        // console.log("draftNumber", draftNumber)
        if (draftNumber && !this.docIsCreated3) {
            let isSumException = false;

            if (callback) {
                //console.log("DEBOZ: is create, clear timer flag");
                let n = (new Date()).valueOf();
                this.lastDate = n;
                this.updatedDate = n;
            }
            //script: "data_set_json",
            //params: { dataObject: "tempDocument" /*, Docs: { number: _num }*/ }
            // let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + edited_itemCode + "\f1\f1";
            //let dataSend = "dataObject\f";

            let store = this.props.data.document2;

            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "data_set_json", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            console.log(this.props);
            console.log(this.props.documentNumber);
            let jsonObjLines = store.dataSource.map(i => {
                console.log("data_set_json lines", JSON.stringify(i));

                if (i.totalSumNoTax >= 100000000 || i.totalSumNoTax <= -10000000) isSumException = true;
                if (i.totalSumWithTax >= 100000000 || i.totalSumWithTax <= -10000000) isSumException = true;

                let remarks = i.remarks;

                // if (i.bonusCalcData && i.bonusCalcData.mBonusQty) remarks = encodeXml(remarks, 'BonusQty', i.bonusCalcData.mBonusQty)
                // if (i.bonusCalcData && i.bonusCalcData.mQtyForBonusQty) remarks = encodeXml(remarks, 'QtyForBonusQty', i.bonusCalcData.mQtyForBonusQty)
                if (i.bonusCalcData) remarks = encodeXml('', 'bonusCalcData', JSON.stringify(i.bonusCalcData)) + remarks;


                return {
                    "number": i.key,//"2",
                    "itemCode": i.itemCode, //"111",
                    "itemBarcode": i.itemBarcode, //"111",
                    "description": i.description ? i.description.slice(0, 50) : '', // "אספרגוס",
                    "unitPriceNoTax": this.getNumberValue(i.unitPriceNoTax),
                    "unitPriceWithTax": this.getNumberValue(i.unitPriceWithTax ? i.unitPriceWithTax : i.unitPriceNoTax), //23,
                    "quantity": i.quantity, //1,
                    "discountPercentage": i.discountPercentage, //0,
                    "totalSumNoTax": this.getNumberValue(i.totalSumNoTax),
                    "totalSumWithTax": this.getNumberValue(i.totalSumWithTax ? i.totalSumWithTax : i.totalSumNoTax), //23,
                    "taxTypeId": i.taxId,
                    "taxValue": this.getNumberValue(i.taxPercentageValue),
                    "sellerEmployeeId": "0",
                    "isValid": i.isValid,

                    "remarks": remarks,

                    "unitID": i.unitID,
                    "quantityInUnit": i.quantityInUnit,
                    "unitQuantity": i.unitQuantity,
                    "unitDescription": i.unitDescription,

                    "cost": i.cost,
                    "supplierId": i.supplierId,

                    "costCurrencyId": i.costCurrencyId,
                    "costCurrencyValue": i.costCurrencyValue,
                    "costCurrencyRate": i.costCurrencyRate,
                    // changed by oz - 9/3/23
                    "sn": i.serialNumber ?? '', //(i.serialNumber) ? i.serialNumber : '',


                    "dscnt1": i.bonusCalcData && i.bonusCalcData.mSupDscnt1 ? i.bonusCalcData.mSupDscnt1 : "",
                    "dscnt2": i.bonusCalcData && i.bonusCalcData.mSupDscnt2 ? i.bonusCalcData.mSupDscnt2 : "",
                    "dscnt3": i.bonusCalcData && i.bonusCalcData.mSupDscnt3 ? i.bonusCalcData.mSupDscnt3 : "",

                }
            });

            let totalsObj = this.calculateDocTotal();
            console.log("saveDraft totalsObj", JSON.stringify(totalsObj))

            let vatArray = this.calculateDocVat();
            console.log("saveDraft vatArray", JSON.stringify(vatArray))

            let remarksXml = '';
            if (this.props.documentType === 5051 || this.props.documentType === '5051') {
                remarksXml = encodeXml(remarksXml, 'TransferOutId', store.storageId ?? "");
                remarksXml = encodeXml(remarksXml, 'TransferOut', store.storageName ?? "");
                remarksXml = encodeXml(remarksXml, 'TransferInId', store.storageId2 ?? "");
                remarksXml = encodeXml(remarksXml, 'TransferIn', store.storageName2 ?? "");
            }

            let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051, 40418].filter(f => this.props.documentType == f).length > 0
            if (isSuppDoc) {
                const { isCostNoTax, isCostWithTax } = this.state;
                let SupplierDocIncludeVat = isCostNoTax && isCostWithTax ? "0" : isCostNoTax ? "1" : "2"
                // remarksXml = encodeXml(remarksXml, 'SupplierDocNum', store.supplierDocNum);
                remarksXml = encodeXml(remarksXml, 'SupplierDocDate', moment(store.docDateTime).format("YYYY-MM-DD"));
                remarksXml = encodeXml(remarksXml, 'SupplierDocIncludeVat', SupplierDocIncludeVat);
            }

            if (this.props.documentType == 5) {
                let split1 = store.remarks ? store.remarks.split("<jsonData>") : []
                let split2 = split1[1] ? split1[1].split("</jsonData>") : []
                let jsonData = split2[0] ? split2[0] : null
                if (jsonData) remarksXml = encodeXml(remarksXml, 'jsonData', jsonData);
                console.log("remarksXml-2", { remarksXml, split1, split2, jsonData })
            }

            // let freeRemarks1 = store.remarks ? store.remarks.split('<')[0] : '';
            let freeRemarks = store.remarks ? store.remarks.split('>').slice(-1)[0] : '';

            remarksXml += freeRemarks //+ freeRemarks2;

            console.log("remarksXml", remarksXml)

            let objPays = this.props.data.documentPayments.data.map(x => {
                // begin bug of save_temp_document
                return {
                    ...x,
                    creditCardCompanyID: '0',
                    creditCardCompanyId: '0',
                    creditCardTypeID: '0',
                    creditCardTypeId: '0',
                    checkAccountNumber: x.checkAccountNumber ? x.checkAccountNumber : '0',
                    checkBankNumber: x.checkBankNumber ? x.checkBankNumber : '0',
                    checkBranchNumber: x.checkBranchNumber ? x.checkBranchNumber : '0',
                    checkNumber: x.checkNumber ? x.checkNumber : '0',
                }
            });

            //let config: configObject = configByDocType(parseInt(this.props.documentType));
            let jsonObj = {
                "Docs": {
                    "number": draftNumber, //this.props.documentNumber, //9995000109903,
                    "typeId": parseInt(this.props.documentType), //5051,
                    "posId": parseInt(this.props.posId), //999,
                    "remarks": remarksXml/*store.remarks*/, //"", //"<Text></Text><TransferIn>אנה</TransferIn><TransferInId>1</TransferInId><TransferOut>שירן</TransferOut><TransferOutId>2</TransferOutId>",
                    "customerId": store.customerCode,// "3",
                    "customerName": store.customerName, // "ישראל ישראלי",
                    "customerAddress": store.customerAddress, //"",
                    "customerZipCode": store.customerZipCode, //null,
                    "customerPhone": store.customerPhone, // "0722772222",//customerPhone
                    "storageId": store.storageId, //"2",

                    "roundSum": totalsObj.roundSum.toFixed(2),//0,
                    "totalNoTax": totalsObj.totalNoTax.toFixed(2),//23,
                    "totalTax": totalsObj.totalTax.toFixed(2),//0,
                    "totalWithTax": totalsObj.totalWithTax.toFixed(2),//23,

                    "employeeId": store.employeeID, //"1"
                    "docDateTime": store.docDateTime,
                    "docValDate": store.docValDate,
                    "docRef2": store.supplierDocNum,
                    "reservedNumber": store.reservedNumber,
                },
                "DocsVat": vatArray
                // [
                //     {
                //         "taxTypeId": "2",
                //         "taxValue": 0,
                //         "totalNoTax": 23,
                //         "totalTax": 0
                //     }
                // ]
                ,
                "Lines": jsonObjLines,
                "Pays": objPays
            }

            if (jsonObj.Docs.totalNoTax >= 100000000 || jsonObj.Docs.totalNoTax <= -10000000) isSumException = true;
            if (jsonObj.Docs.totalWithTax >= 100000000 || jsonObj.Docs.totalWithTax <= -10000000) isSumException = true;

            if (isSumException) {
                message.error(this.getText(17540))
            } else {
                console.log("saveDraft json", JSON.stringify(jsonObj))

                job.setSection("dataObject", "tempDocument");
                job.setSection("json", JSON.stringify(jsonObj));
                //job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob: Object) => {
                        console.log("success:", ob.data);
                        if (callback) {
                            console.log("DEBOZ: SAVE DOC callback");
                            callback(ob);
                        }
                    },
                    (error: any) => { message.error(this.getText(12446)); }
                )
            }
        }
    }

    calculateDocTotal = ()/*: { "roundSum": Number, "totalNoTax": Number, "totalTax": Number, "totalWithTax": Number, }*/ => {
        let res = {
            "roundSum": 0.00,
            "totalNoTax": 0.00,
            "totalTax": 0.00,
            "totalWithTax": 0.00,
        }
        let lines = this.props.data.document2.dataSource;
        let pays = this.props.data.documentPayments.data;
        const getSumFormat = (sum) => parseFloat(sum) ? parseFloat(sum) : 0

        if (this.props.documentType == '3' || this.props.documentType == '23' || this.props.documentType == '53' || this.props.documentType == '203') {
            res = pays.reduce((acc, v) => {
                console.log("calc DocTotal iter: ", v);
                console.log("calc DocTotal iter: ", acc);
                //acc.roundSum += 0;
                // quantity | totalWithTax | unitPriceWithTax | discountPercentage | taxTypeId | 
                // acc.totalNoTax += parseFloat(v.totalSumNoTax); // * curr.quantity;
                // acc.totalTax += parseFloat(v.totalSumWithTax) - parseFloat(v.totalSumNoTax);
                acc.totalWithTax += getSumFormat(v.defaultCurrencySum) - getSumFormat(v.defaultCurrencyChange);
                return acc;
            }, {
                "roundSum": 0.00,
                "totalNoTax": 0.00,
                "totalTax": 0.00,
                "totalWithTax": 0.00,
            })

            console.log("res type 3 ", res)
        } else {
            res = lines.reduce((acc, v) => {
                console.log("calc DocTotal iter: ", v);
                console.log("calc DocTotal iter: ", acc);
                //acc.roundSum += 0;
                // quantity | totalWithTax | unitPriceWithTax | discountPercentage | taxTypeId | 
                acc.totalNoTax += getSumFormat(v.totalSumNoTax); // * curr.quantity;
                acc.totalTax += getSumFormat(v.totalSumWithTax) - getSumFormat(v.totalSumNoTax);
                acc.totalWithTax += getSumFormat(v.totalSumWithTax);
                return acc;
            }, {
                "roundSum": 0.00,
                "totalNoTax": 0.00,
                "totalTax": 0.00,
                "totalWithTax": 0.00,
            })
        }

        // let isWithCashPay = pays.length && pays[pays.length - 1].paymentTypeID == 1;

        // if (isWithCashPay) {
        //     res = { ...res, ...this.calculateRoundSum(res.totalWithTax) }
        // }

        return res;
    }

    calculateRoundSum = (totalWithTax) => {
        // let roundedTotal = Math.round(totalWithTax / this.state.gp_roundTotal) * this.state.gp_roundTotal;
        let roundedTotal = this.roundTotalValue(totalWithTax);
        console.log("calculateRoundSum", totalWithTax, roundedTotal, Jax.get(this.props.data, "document2.dataSource", []))
        let roundSum = roundedTotal - totalWithTax;
        return Math.abs(roundSum) >= 0.005 ? {
            roundSum,
            totalWithTax: roundedTotal
        } : {
            totalWithTax,
            roundSum: 0
        }
    }

    calculateDocVat = ()/*: Array<Object<{ "roundSum": Number, "totalNoTax": Number, "totalTax": Number, "totalWithTax": Number, }>>*/ => {
        let lines = this.props.data.document2.dataSource;
        const getSumFormat = (sum) => parseFloat(sum) ? parseFloat(sum) : 0
        //// TODO: /////
        console.log("calculateDocVat1: ", lines)
        console.log("calculateDocVat2: ", lines.reduce((acc, v) => {
            console.log("calculateDocVat3: ", acc, v)
            if (v && v.taxId) {
                console.log("calculateDocVat4: ", acc, v)
                if (acc[v.taxId/*taxType*/] === undefined) {
                    console.log("calculateDocVat5A: ", acc, v)
                    acc[v.taxId/*taxType*/] = {
                        taxTypeId: v.taxId,//taxType,
                        taxValue: getSumFormat(v.taxPercentageValue),//taxValue,
                        totalNoTax: getSumFormat(v.totalSumNoTax),
                        totalTax: getSumFormat(v.totalSumWithTax) - getSumFormat(v.totalSumNoTax)
                    }
                } else {
                    console.log("calculateDocVat5A: ", acc, v)
                    acc[v.taxId/*taxType*/].totalNoTax += getSumFormat(v.totalSumNoTax);
                    acc[v.taxId/*taxType*/].totalTax += getSumFormat(v.totalSumWithTax) - getSumFormat(v.totalSumNoTax);
                }
            }
            return acc;
        }, []))
        /////// TODO: ///////////
        return lines.reduce((acc, v) => {
            if (v && v.taxId) {
                if (acc[v.taxId/*taxType*/] === undefined) {
                    acc[v.taxId/*taxType*/] = {
                        taxTypeId: v.taxId,//taxType,
                        taxValue: getSumFormat(v.taxPercentageValue),//taxValue,
                        totalNoTax: getSumFormat(v.totalSumNoTax),
                        totalTax: getSumFormat(v.totalSumWithTax) - getSumFormat(v.totalSumNoTax)
                    }
                } else {
                    acc[v.taxId/*taxType*/].totalNoTax += getSumFormat(v.totalSumNoTax);
                    acc[v.taxId/*taxType*/].totalTax += getSumFormat(v.totalSumWithTax) - getSumFormat(v.totalSumNoTax);
                }
            }
            return acc;
        }, [])
            .filter(v => v)
            .map(x => { return { ...x, totalNoTax: x.totalNoTax.toFixed(2), totalTax: x.totalTax.toFixed(2) } });
    }

    // closeEditor() {
    closeEditor = () => {

        console.log("saveDocumentMultiCust-10", this.state.modalMultiCustCreate)
        if (this.state.modalMultiCustCreate?.length) {
            let modalMultiCustCreate = [...this.state.modalMultiCustCreate];
            let a = modalMultiCustCreate.splice(0, 1)


            this.props.dataActions.setJson("document.editedRecord", { number: a[0] })
            setTimeout(() => {
                this.setState({ modalMultiCustCreate }, () => {
                    this.saveTempDocument(this.props.documentType)
                })
            }, 1000);
            return;
        }
        // refresh parent
        this.refrashTempDoc()

        // close
        this.props.closeFunction();

        setTimeout(() => { this.loadDraft() }, 1000);
        //this.resetDocument();
        // defaults for when modal re-opens
        this.setState({ activeTab: 'tab1', loading: false });
        this.docIsCreated = false;
        this.docIsCreated2 = false;
        this.docIsCreated3 = false;
    }

    formatFloat(number: number) {
        if (number) {
            return parseFloat(Math.round(number * 10000) / 10000).toFixed(2);
        }
    }

    ///////////// report generation /////////////////////////////////
    setReportParametersAndDo(report: String, params: Object, func) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState, func);
    }

    // getCentralReportName = (printFormat) => {
    //     switch (printFormat) {
    //         case '0': case 0: return "reports/DocumentCopyCentral.pdf";
    //         case '1': case 1: return "reports/DocumentCopyCentralDetails.pdf";
    //         case '2': case 2: return "reports/DocumentCopyCentralPerCust.pdf";
    //         case '3': case 3: return "reports/DocumentCopyCentralPerCustDetails.pdf";
    //         case '4': case 4: return "reports/DocumentCopyCentralSum.pdf";
    //     }
    // }

    getReport = (docTypeId, printFormat) => {
        const { printOriginalDoc, printCopyDoc, printType5051, printCentralPerFatherItem, sendDocBySms } = this.state;
        let docType = docTypeId == 45051 ? 4 : docTypeId == 40418 ? 16 : docTypeId;
        let posId = docTypeId == 45051 || docTypeId == 40418 ? 999 : this.props.posId;
        var dataSend = "_pos\f_docType\r" + posId + '\f' + docType;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_last_doc_number_by_type", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob) => {
            let data = ob.data ? ob.data.split('\f') : []
            let realDocNumber = data[0];
            let issetMatrixItems = data[1];
            let needAllocation = data[2];
            let custTaxIdentity = data[3];
            const { allocationType } = this.props.data.document2;

            // let params = {
            //         DocumentNumber: realDocNumber,
            //         DocumentTypeID: docType,
            //         DocumentPOSID: posId,
            //         CompanyCode: this.props.user.companyCode,
            //         userID: this.props.user.userId,
            //         userName: encodeURIComponent(this.props.user.displayName),
            //         CentralFatherItem: printCentralPerFatherItem ? '1' : '0',
            //         PrintFormat: this.state.PrintFormat,
            //     }

            //     let reportName = docType == '5' ? "reports/DocumentCopyCentral.pdf" : "reports/DocumentCopy.pdf"

            //     if ((docType == 50 && printType5051 == 1) || (docType == 51 && printType5051 == 2)) {
            //         this.updateDocNotPrintOriginal(realDocNumber, docType, posId);
            //     } else {
            //         if (printOriginalDoc) {
            //             this.generateReport({ ...params, isOriginal: true, reportName });
            //             if (parseInt(issetMatrixItems)) {
            //                 this.generateReport({ ...params, isOriginal: true, reportName: "reports/DocumentCopyMatrix.pdf" });
            //             }
            //         } else {
            //             this.updateDocNotPrintOriginal(realDocNumber, docType, posId);
            //         }

            //         if (printCopyDoc) {
            //             this.generateReport({ ...params, isOriginal: false, reportName });
            //             if (parseInt(issetMatrixItems)) {
            //                 this.generateReport({ ...params, isOriginal: false, reportName: "reports/DocumentCopyMatrix.pdf" });
            //             }
            //         }
            //     }

            if (allocationType == '1' && needAllocation > 0) {
                this.props.dataActions.setJson("document2", {
                    modalInsertReservedNumber: {
                        realDocNumber,
                        docType,
                        posId,
                        issetMatrixItems,
                        custTaxIdentity,
                        saveReservedNumber: this.saveReservedNumber
                    }
                })
            } else {
                this.getReport2(realDocNumber, docType, posId, issetMatrixItems);
            }


        }, (e) => e); // callback problem here  
    }

    getReport2 = (realDocNumber, docType, posId, issetMatrixItems) => {
        const {
            printOriginalDoc,
            printOriginalDocType1,
            printOriginalDocType2,
            printOriginalDocType3,
            printOriginalDocType4,
            printOriginalDocType5,
            printCopyDoc,
            printCopyDocType1,
            printCopyDocType2,
            printCopyDocType3,
            printType5051, printCentralPerFatherItem, sendDocBySms, PrintFormat } = this.state;

        let params = {
            DocumentNumber: realDocNumber,
            DocumentTypeID: docType,
            DocumentPOSID: posId,
            CompanyCode: this.props.user.companyCode,
            userID: this.props.user.userId,
            userName: encodeURIComponent(this.props.user.displayName),
            CentralFatherItem: printCentralPerFatherItem ? '1' : '0',
            PrintFormat,
        }

        let reportName = docType == '5' ? "reports/DocumentCopyCentral.pdf" : "reports/DocumentCopy.pdf"


        if ((docType == 50 && printType5051 == 1) || (docType == 51 && printType5051 == 2)) {
            this.updateDocNotPrintOriginal(realDocNumber, docType, posId);
        } else {
            const { customerCode, custPrintData } = this.props.data.document2;
            if (printOriginalDoc) {

                const sendReportToSign = (target, targetType) => {
                    let dataSend = "_type\f_params\f_mobile\f_lang\f_messageType\r7\f"
                        + "entityType=3&entityValue="
                        + realDocNumber + "-" + docType + "-" + posId
                        + "&qId=" + custPrintData?.questionnaireId
                        + "&qName=" + custPrintData?.questionnaireName
                        + "&qIsAllowsDraft=0"
                        + "\f" + target + "\f" + this.getText(100) + "\f" + targetType;
                    this.sendAPI("create_random_connection_token_v2", dataSend, () => { });
                }

                if (printOriginalDocType1) {
                    this.generateReport({ ...params, isOriginal: true, reportName });
                    if (parseInt(issetMatrixItems)) {
                        this.generateReport({ ...params, isOriginal: true, reportName: "reports/DocumentCopyMatrix.pdf" });
                    }
                }

                if (printOriginalDocType2) this.checkTargetBeforeSend({ ...params, isOriginal: true, reportName }, customerCode, custPrintData?.targetMobile, "2")
                if (printOriginalDocType3) this.checkTargetBeforeSend({ ...params, isOriginal: true, reportName }, customerCode, custPrintData?.targetEmail, "3")
                if (printOriginalDocType4) sendReportToSign(custPrintData?.targetMobile, "2")
                if (printOriginalDocType5) sendReportToSign(custPrintData?.targetEmail, "3")

            } else {
                this.updateDocNotPrintOriginal(realDocNumber, docType, posId);
            }

            if (printCopyDoc) {
                if (printCopyDocType1) {
                    this.generateReport({ ...params, isOriginal: false, reportName });
                    if (parseInt(issetMatrixItems)) {
                        this.generateReport({ ...params, isOriginal: false, reportName: "reports/DocumentCopyMatrix.pdf" });
                    }
                }

                if (printCopyDocType2) this.checkTargetBeforeSend({ ...params, isOriginal: false, reportName }, customerCode, custPrintData?.targetMobile, "2")
                if (printCopyDocType3) this.checkTargetBeforeSend({ ...params, isOriginal: false, reportName }, customerCode, custPrintData?.targetEmail, "3")

            }
        }
    }

    updateDocNotPrintOriginal = (docNumber, docType, posId) => {
        this.sendAPI("update_doc_print_original", "docNumber\fdocType\fposId\r" + docNumber + "\f" + docType + "\f" + posId, () => { })
    }

    printDesktop = (params) => {
        this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
    }

    sendToCust = (params, customerId, target, targetType) => {
        console.log("sendToCust", { params, customerId, target, targetType })
        let reportNameAndFormat = params.reportName.split("/")[1].split(".");
        let reportName = reportNameAndFormat[0];
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'send_report', OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
        let metaData = 'targetType\freportName\ftargetSystem\fcustomerId\ftarget\freportFormat\fisDoc\r' +
            targetType + '\f' + reportName + '\f0\f' + customerId + '\f' + target + '\f0\f1';
        let paramsKeys = Object.keys(params);
        let paramsData = paramsKeys.join("\f") + "\r" + paramsKeys.map(x => params[x] ? params[x] : "").join("\f")
        job.setSection('META', metaData);
        job.setSection('PARAMS', paramsData);
        job.send("/cgi-bin/CashOnTab", () => {
            message.success(this.getText(17755))
        }, (e) => { console.error(e) });
    }

    checkTargetBeforeSend = (params, customerId, target, targetType) => {
        const { customerPhone, custPrintData } = this.props.data.document2;
        let isForUserChoice = custPrintData?.defaultPrintType == '5'
        if (isForUserChoice) target = targetType == 2 ? customerPhone : ""
        if (target && !isForUserChoice) {
            this.sendToCust(params, ([4, 15, 16, 17, 18, 20, 21, 53, 215].find(f => f == params.DocumentTypeID)) ? "0" : customerId, target, targetType)
        } else {
            confirm({
                title: this.getText(targetType == '2' ? 18196 : 18197),
                content: (<Input style={{ textAlign: "end" }} defaultValue={target} onChange={e => { target = e.target.value }} />),
                iconType: 'exclamation-circle',
                okText: this.getText(17753),
                cancelText: this.getText(17754),
                onOk: () => {
                    this.props.dataActions.setJson("document2", { custPrintData: { ...custPrintData, defaultPrintType: targetType } })
                    setTimeout(() => {
                        this.checkTargetBeforeSend(params, customerId, target, targetType)
                    }, 500);
                },
                onCancel() { },
            })
        }
    }

    generateReport = (params) => {
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            console.log("sendToCust0", { params }, params.isOriginal, params.isDraft)

            if (params.isOriginal && !params.isDraft && [1, 2, 3, 5, 8, 9, 22, 23, 30, 202, 203, 208, 222, 230].find(f => this.props.documentType == f)) {
                console.log("sendToCust01", { params })
                this.sendAPI("get_doc_cust_target", "_num\f_type\f_pos\r" + params.DocumentNumber + "\f" + params.DocumentTypeID + "\f" + params.DocumentPOSID, ob => {
                    console.log("sendToCust02", { params })
                    let res = ob.data ? ob.data.split("\f") : []
                    let customerId = res[0];
                    let target = res[1];
                    let targetType = res[2];
                    let target1 = res[3];
                    let target2 = res[4];

                    if (!parseInt(customerId)) {
                        target = "";
                        target1 = "";
                        target2 = "";
                        targetType = this.state.GP_DocSendTypeDefault;
                    }

                    switch (targetType) {
                        case '1': this.printDesktop(params); break;
                        case '2': case '3': this.checkTargetBeforeSend(params, customerId, target, targetType); break;
                        default:
                            if (!this.confirmPrintTypeOpen) {
                                this.confirmPrintTypeOpen = true;
                                const confirmPrintTypeClose = () => { this.confirmPrintTypeOpen = false }
                                const checkTargetBeforeSend = this.checkTargetBeforeSend
                                const printDesktop = this.printDesktop
                                confirm({
                                    title: this.getText(18198),
                                    content: (<span>
                                        <Radio.Group onChange={e => {
                                            targetType = e.target.value;
                                            if (targetType > 1) {
                                                target = targetType == '2' ? target1 : target2
                                                let inputId = document.getElementById("target_send_input");
                                                inputId.style.display = "block";
                                                inputId.value = target;
                                            }
                                        }}>
                                            <Radio value="1">{this.getText(18199)}</Radio><br />
                                            <Radio value="2">{this.getText(18200)}</Radio><br />
                                            <Radio value="3">{this.getText(18201)}</Radio>
                                        </Radio.Group>
                                        <Input id="target_send_input" style={{ display: 'none', textAlign: "end" }} onChange={e => { target = e.target.value }} />
                                    </span>),
                                    iconType: 'exclamation-circle',
                                    okText: this.getText(17753),
                                    cancelText: this.getText(17754),
                                    onOk() {
                                        confirmPrintTypeClose()
                                        if (targetType == '1') printDesktop(params);
                                        else checkTargetBeforeSend(params, customerId, target, targetType)
                                    },
                                    onCancel() {
                                        confirmPrintTypeClose()
                                    },
                                })
                            }
                            break;
                    }

                })
            } else {
                this.printDesktop(params);
            }
        }
    }
    // generateReport(report: string) {
    //     let params = Jax.get(this.state || {}, report, null);
    //     if (params !== null && params.reportName) {
    //         window.console.log("Generating report...");
    //         this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
    //     }
    // }
    ///////////////////////////////////////////////////////

    parseCentralDocJson = () => {
        const { remarks } = this.props.data.document2;
        let remarkSplit = remarks ? remarks.split('<jsonData>') : [];
        let remarkSplit2 = remarkSplit[1] ? remarkSplit[1].split('</jsonData>') : ['[]'];
        let jsonData = remarkSplit2[0] ? remarkSplit2[0] : '[]';
        return JSON.parse(jsonData);
    }

    saveTempDocument = (DocTypeID) => {
        if (this.state.modalMultiCustList) {
            this.saveDocumentMultiCust();
            return;
        }


        let DocNum = Jax.get(this.props.data, "document.editedRecord.number", 0);
        console.log("saveDocumentMultiCust-1", DocNum, this.state.modalMultiCustCreate)
        let DocPosID = this.props.posId;
        let isDeleteDraft = DocTypeID == 50 ? 0 : 1;
        let extraText = DocTypeID == 50 || DocTypeID == 51 ? DocTypeID + " " : "";
        let loading = this.getText(51816);
        let failed = this.getText(51817) + " " + extraText + this.getText(51818);
        let success = this.getText(51819) + " " + extraText + this.getText(51820);

        const { customerCode, customerName } = this.props.data.document2;

        this.docIsCreated3 = true;

        this.sendAPI("check_if_exists_numerator", '_type\f_pos\r' + DocTypeID + '\f' + DocPosID, (ob) => {
            if (parseInt(ob.data)) {
                console.log("saveDocumentMultiCust-2", DocNum, this.state.modalMultiCustCreate)
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.networkPost,
                    args: [
                        this.props.user.companyCode,
                        this.props.user.token,
                        { script: "save_temp_document", params: { DocNum, DocTypeID, DocPosID, isDeleteDraft } },
                        "", "",
                        { loading, failed, success },
                        {
                            action: (e) => {
                                if (isDeleteDraft == 1) {
                                    console.log("saveDocumentMultiCust-3", DocNum, this.state.modalMultiCustCreate)

                                    // this.closeEditor.bind(this);
                                    this.closeEditor();
                                    if (DocTypeID == 51) {
                                        this.getReport(50);
                                    }
                                    this.getReport(DocTypeID, DocTypeID == 5 ? 1 : null);

                                    if (this.state.GP_AutoMatchOption == '1' && DocTypeID == 3 && this.props.openModalMatchDocs) {
                                        this.props.openModalMatchDocs({ key: customerCode, mId: customerCode, mName: customerName })
                                    }
                                }


                            }, args: []
                        },
                        null
                    ]
                });
            } else {
                message.error(this.getText(16527))
            }
        })


    }

    saveDocument() {


        const {
            docDateTime, docValDate, customerCode, supplierDocNum, supplierDocDate,
            employeeID, remarks, customerAddress, customerName, customerZipCode, customerPhone,
            storageId, priceListId, customerDiscount, customerCredit, customerObligo
        } = this.props.data.document2;
        console.log("save doc... ");
        const type = parseInt(this.props.documentType, 10);
        console.log("Saving document...  type:", type);
        let DocNum = Jax.get(this.props.data, "document.editedRecord.number", 0);
        let parseData = this.parseCentralDocJson();

        this.setState({ loading: true })
        this.itemRoundCreated = false;

        switch (this.props.documentType) {
            case "5051": case 5051:
                this.saveTempDocument(50);
                this.saveTempDocument(51);
                break;

            case "45051": case 45051:
            case "40418": case 40418:
                if (parseData && parseData.length) {
                    let dataSend = JSON.stringify(
                        parseData.map(x => {
                            return {
                                DocNum: parseInt(x.DocNum),
                                DocTypeId: parseInt(x.DocTypeId),
                                PosId: parseInt(x.PosId),
                                UserDocDate: docDateTime ? docDateTime : curdatetime,
                                SuppId: parseInt(customerCode) ? parseInt(customerCode) : null,
                                SuppDocNum: parseInt(supplierDocNum) ? parseInt(supplierDocNum) : null,
                                SuppDocDate: supplierDocDate ? supplierDocDate : null,
                            }
                        }));

                    this.sendAPI("close_open_purchases", dataSend, () => {
                        this.sendAPI("delete_temp_document", "number\r" + DocNum, () => {
                            // this.closeEditor.bind(this);
                            this.getReport(this.props.documentType);
                        })
                    }, 'OUTPUT_TYPE_BLOB')
                } else {
                    message.error(this.getText(15441));
                }
                break;

            case "5": case 5:
                if (parseData && parseData.length) {
                    let PrintFormat = null;

                    let DocList = parseData.map(x => {
                        PrintFormat = x.PrintFormat;
                        return {
                            DocNum: parseInt(x.DocNum),
                            DocTypeId: parseInt(x.DocTypeId),
                            PosId: parseInt(x.PosId),
                            DocSum: parseInt(x.DocSum),
                        }
                    });

                    let freeRemarks = remarks ? remarks.split('>').slice(-1)[0] : '';

                    let dataSend = JSON.stringify({
                        DocValDate: docValDate,
                        docDateTime,
                        customerCode: customerCode ? parseInt(customerCode) : 0,
                        customerAddress,
                        customerName,
                        customerZipCode,
                        customerPhone,
                        employeeID: employeeID ? parseInt(employeeID) : 0,
                        remarks: freeRemarks,
                        storageId: storageId ? parseInt(storageId) : 0,
                        customerCredit,
                        customerObligo,
                        PrintFormat,
                        DocList
                    });

                    let DocSum = 0;
                    DocList.forEach(x => {
                        if (parseFloat(x.DocSum)) DocSum += parseFloat(x.DocSum);
                    })

                    if (DocSum > 0.005 || DocSum < -0.005) {
                        this.sendAPI("close_open_deliveries_v4", dataSend, () => {
                            this.sendAPI("delete_temp_document", "number\r" + DocNum, () => {
                                this.getReport(5, PrintFormat);
                            })
                        }, 'OUTPUT_TYPE_BLOB')
                    } else {
                        confirm({
                            title: this.getText(19711),
                            content: this.getText(19712),
                            iconType: 'exclamation-circle',
                            okText: this.getText(19713), // עבור ללשונית מסמך
                            cancelText: this.getText(19714), //this.getText(12451), //ביטול
                            onOk: () => {
                                this.sendAPI("close_open_deliveries_v4", dataSend, () => {
                                    this.sendAPI("delete_temp_document", "number\r" + DocNum, () => { })
                                }, 'OUTPUT_TYPE_BLOB')
                            },
                            onCancel: () => {
                                this.sendAPI("delete_temp_document", "number\r" + DocNum, () => { })
                            },
                        })
                    }


                } else {
                    message.error(this.getText(15441));
                }
                break;

            case "2005": case 2005:
                this.sendAPI("update_temp_doc_type", "_typeToUpdate\f_num\r5\f" + DocNum, () => {
                    this.saveTempDocument(5);
                })
                break;

            // case "9": case 9:
            //     this.sendAPI("reverse_amount_in_draft_doc", "number\r" + DocNum, () => {
            //         this.saveTempDocument(9);
            //     })
            //     break;

            default:
                this.saveTempDocument(this.props.documentType);
        }

        // refresh context list
        setTimeout(() => {
            this.refrashTempDoc();
            this.closeEditor();
        }, 1300);
    }

    saveDocumentMultiCust = () => {
        let dataSend = "_num\f_type\f_pos\f_custList\r"
            + Jax.get(this.props.data, "document.editedRecord.number", 0) + "\f"
            + this.props.documentType + "\f"
            + this.props.posId + "\f"
            + this.state.modalMultiCustList

        this.sendAPI("copy_temp_doc_multi_cust", dataSend, ob => {
            let data = ob.data?.split("\r")

            this.setState({
                modalMultiCustList: "",
                modalMultiCustCreate: data,
                modalMultiCustLength: data?.length,
            }, () => {
                this.saveTempDocument(this.props.documentType)
            })
        })
    }

    refrashTempDoc = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token,
            {
                script: "get_documents_for_user",
                params: { docTypeId: this.props.documentType }
            },
                "temporaryDocuments",
            { loading: this.getText(12448), failed: this.getText(12449), success: "" },
                false,
            { action: null, args: [] },
            function (action, store, data) {
                let arr = data.split("\r");
                let json = [];
                let headers = arr.shift().split("\f");
                arr.pop();
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    let obj = {};
                    for (let i = 0, j = headers.length; i < j; i++) {
                        obj[headers[i]] = row[i];
                    }
                    json.push(obj);
                }
                return Jax.set(store, "temporaryDocuments.data", json);
            }]
        });
    }

    checkInValidLinesExists = () => {
        // 4
        const { dataSource } = this.props.data.document2;
        let invalidLineIndex = dataSource.findIndex((i) => { return !parseInt(i.isValid) ? true : false })
        return (invalidLineIndex > -1) ? true : false;
    }
    checkInValidLinesWarning = () => {
        // 4
        const { dataSource } = this.props.data.document2;
        let invalidLineIndex = dataSource.findIndex((i) => { return parseInt(i.isValid) > 1 ? true : false })
        return (invalidLineIndex > -1) ? true : false;
    }
    checkInValidStorages = () => {
        // 3
        const { storageId, storageId2 } = this.props.data.document2;
        if (this.props.documentType === "5051" || this.props.documentType === 5051) {
            if (!storageId || !storageId2 || (storageId === storageId2)) { return true; }
        } else {
            if (!storageId) { return true; }
        }
        return false;
    }
    checkIfLinesIsEmpty = () => {
        // 2
        //const { dataSource, storageId, storageId2 } = this.props.data.document2;

        if (parseInt(this.props.documentType, 10) === 3 || parseInt(this.props.documentType, 10) === 23 || parseInt(this.props.documentType, 10) === 53) {
            return false;
        }

        if (parseInt(this.props.documentType, 10) === 5 && this.parseCentralDocJson().length) {
            return false;
        }

        if (parseInt(this.props.documentType, 10) === 45051 && this.parseCentralDocJson().length) {
            return false;
        }

        if (parseInt(this.props.documentType, 10) === 40418 && this.parseCentralDocJson().length) {
            return false;
        }

        let lines = this.props.data.document2.dataSource;
        if (!lines || ((typeof (lines) === typeof ([]) && lines.length === 0))) {
            return true;
        }
        return false;
    }

    checkIfLinesHaveZeroValueRecords = () => {
        // 1
        let lines = this.props.data.document2.dataSource;
        let recordWithPriceZero = lines.find(i => {
            return i.unitPriceWithTax && (parseFloat(i.unitPriceWithTax) === 0.0);
        });
        if (recordWithPriceZero) { return true; }
        else { return false; }
    }

    checkPaymentsFromType1 = () => {
        let pays = this.props.data.documentPayments;
        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
        let sumMastPays = itemList.reduce(function (a, v) {
            if (v.key === 0) {
                return a;
            }
            return a + parseFloat((v.totalSumWithTax) ? v.totalSumWithTax : 0.0);
        }, 0) || 0.0;

        if (sumMastPays < 0) return 16558
        if (sumMastPays == 0) return 16557
        if (!pays || !pays.data || !pays.data.length) return 16555

        let sumPays = pays.data.reduce(function (a, v) {
            return a + (parseFloat((v.defaultCurrencySum) ? v.defaultCurrencySum : 0.0) - parseFloat((v.defaultCurrencyChange) ? v.defaultCurrencyChange : 0.0));
        }, 0) || 0.0;

        let isWithCashPay = pays.data.length && pays.data[pays.data.length - 1].paymentTypeID == 1;

        if (isWithCashPay) {
            sumMastPays = this.calculateRoundSum(sumMastPays).totalWithTax
        }

        if (Math.round(sumMastPays * 100) > Math.round(sumPays * 100)) return 16556;

        return null;
    }

    checkPlusOrMinusSum = () => {
        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
        let sumNoTax = itemList.reduce((a, v) => {
            if (v.key === 0) return a;
            return a + parseFloat((v.totalSumNoTax) ? v.totalSumNoTax : 0.0);
        }, 0) || 0.0;
        let sumQty = itemList.reduce((a, v) => {
            if (v.key === 0) return a;
            return a + parseFloat((v.quantity) ? v.quantity : 0.0);
        }, 0) || 0.0;

        switch (this.props.documentType) {
            case '2':
                if (sumNoTax == 0) {
                    return { message: 16549, type: sumQty >= 0 ? '8' : '9' }
                } else if (sumNoTax < 0) {
                    return { message: 16550, type: '22' }
                } else {
                    return null;
                }
            case '22':
                if (sumNoTax == 0) {
                    return { message: 16549, type: sumQty >= 0 ? '8' : '9' }
                } else if (sumNoTax > 0) {
                    return { message: 16559, type: '2' }
                } else {
                    return null;
                }

            case '8': return sumQty < 0 ? { message: 16560, type: '9' } : null
            case '9': return sumQty > 0 ? { message: 16561, type: '8' } : null

            case '4': return sumNoTax < 0 ? { message: 17046, type: '18', invertQuantities: true } : null
            case '18': return sumNoTax < 0 ? { message: 17047, type: '4', invertQuantities: true } : null

            default: return null;
        }
    }

    legendItem = (color, text, type) => {
        let data = this.props.data.document2.dataSource.filter(f => f.isValid == type)
        if (data.length) {
            return (<Row dir={"rtl"}>
                <Col span={6} style={{ height: 20, background: color, textAlign: 'center' }}>{data.length} {this.getText(18730)} - </Col>
                <Col span={18} style={{ paddingRight: 20 }}>{this.getText(text)}</Col>
            </Row>)
        }
    }

    getLegends = () => {
        return (<div>
            {this.legendItem('#ff3333', 16256, 0)}
            {this.legendItem('#ffff33', 16257, 2)}
            {this.legendItem('#ff33ff', 16258, 3)}
            {this.legendItem('#c3c3c3', 16259, 4)}
            {this.legendItem('#33ffff', 16260, 5)}
        </div>)
    }

    checkAndSaveDocument() {
        let { dataActions } = this.props;
        //let self = this;
        //console.log("oz3333")
        setTimeout(() => {
            let type1IsValid = this.props.documentType == 1 ? this.checkPaymentsFromType1() : null;
            let plusOrMinusSum = this.checkPlusOrMinusSum();

            if (this.checkInValidStorages()) {
                this.docIsCreated = false;
                confirm({
                    title: this.getText(51823),
                    content: this.getText(51824),
                    iconType: 'exclamation-circle',
                    okText: this.getText(12450), // עבור ללשונית מסמך
                    cancelText: this.getText(51825), //this.getText(12451), //ביטול
                    onOk() {
                        //this.setState({ activeTab: 'tab1' });
                        /*this.save#Draft(this.saveDocument.bind(this));*/
                    },
                    okButtonProps: { style: { display: 'none' } },
                    onCancel() { },
                });
            } else if (this.checkIfLinesIsEmpty()) {
                this.docIsCreated = false;
                let contentTextCode = 51827;
                if (this.props.documentType == 5) contentTextCode = 15441;
                if (this.props.documentType == 45051) contentTextCode = 17054;
                if (this.props.documentType == 40418) contentTextCode = 15441;

                confirm({
                    title: this.getText(51826),
                    content: this.getText(contentTextCode),
                    iconType: 'exclamation-circle',
                    okText: '',//this.getText(12450), // עבור ללשונית פריטים
                    cancelText: this.getText(51825), //this.getText(12451), // ביטול
                    onOk() {
                        //this.setState({ activeTab: 'tab2' });
                        /*this.save#Draft(this.saveDocument.bind(this));*/
                    },
                    okButtonProps: { style: { display: 'none' } },
                    onCancel() { },
                });
            } else if (this.checkInValidLinesExists()) {
                confirm({
                    title: this.getText(51826),
                    content: this.getText(51828),
                    iconType: 'exclamation-circle',
                    okText: this.getText(12450), // מחק שגויים וצור מסמך
                    cancelText: this.getText(51825),//this.getText(12451), // עבור ללשונית פריטים
                    onOk: () => {
                        let dataSourceFiltered = this.props.data.document2.dataSource.filter(i => parseInt(i.isValid));
                        dataActions.setJson("document2", { dataSource: dataSourceFiltered });
                        this.checkAndSaveDocument();
                    },
                    onCancel: () => {
                        this.docIsCreated = false;
                        // this.setState({ activeTab: 'tab2' }); 
                    },
                });
            } else if (this.state.sendDocBySms && !this.state.sendDocBySmsTargetOk) {
                confirm({
                    title: this.getText(17751),
                    content: (<Form.Item label={this.getText(17752)}>
                        <Input style={{ textAlign: "end" }} defaultValue={this.state.sendDocBySmsTarget} onChange={e => {
                            this.setState({ sendDocBySmsTarget: e.target.value })
                        }} />
                    </Form.Item>),
                    iconType: 'exclamation-circle',
                    okText: this.getText(17753),
                    cancelText: this.getText(17754),
                    onOk: () => { this.setState({ sendDocBySmsTargetOk: true }, this.checkAndSaveDocument) },
                    onCancel: () => { this.setState({ sendDocBySms: false }, this.checkAndSaveDocument) },
                });
            } else if (this.userAcceptsZeroPrices === false && this.checkIfLinesHaveZeroValueRecords() === true) {
                confirm({
                    title: this.getText(51826),
                    content: this.getText(51829),
                    iconType: 'exclamation-circle',
                    okText: this.getText(12450),
                    cancelText: this.getText(12451),
                    onOk: () => {
                        this.userAcceptsZeroPrices = true;
                        this.checkAndSaveDocument();
                    },
                    onCancel: () => {
                        this.docIsCreated = false;
                    },
                });
            } else if (!this.userAcceptsInValidWarning && this.checkInValidLinesWarning()) {
                confirm({
                    title: this.getText(51826),
                    content: (<div>
                        {this.getText(16265)}
                        {this.getLegends()}
                        {this.getText(16266)}
                    </div>),
                    iconType: 'exclamation-circle',
                    okText: this.getText(12450),
                    cancelText: this.getText(12451),
                    onOk: () => {
                        this.userAcceptsInValidWarning = true;
                        this.checkAndSaveDocument();
                    },
                    onCancel: () => {
                        this.docIsCreated = false;

                    },
                });
            } else if (!this.userAcceptsPreDate && moment(Jax.get(this.props.data, "document2.docDateTime", null), "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD").valueOf() != moment(Date.now()).format("YYYY-MM-DD").valueOf()) {
                confirm({
                    title: this.getText(18221),
                    content: this.getText(18222),
                    iconType: 'exclamation-circle',
                    okText: this.getText(12450),
                    cancelText: this.getText(12451),
                    onOk: () => {
                        this.userAcceptsPreDate = true;
                        this.checkAndSaveDocument();
                    },
                    onCancel: () => {
                        this.docIsCreated = false;
                    },
                });
            } else if (type1IsValid) {
                message.error(this.getText(type1IsValid));
                this.docIsCreated = false;

            } else if (plusOrMinusSum) {
                confirm({
                    title: this.getText(16548),
                    content: this.getText(plusOrMinusSum.message),
                    iconType: 'exclamation-circle',
                    okText: this.getText(16551),
                    cancelText: this.getText(16552),
                    onOk: () => {
                        this.props.documentTypeChange(plusOrMinusSum.type);
                        if (plusOrMinusSum.invertQuantities) {
                            this.saveDraft(() => {
                                this.sendAPI("invert_quantities_purchase_temp_doc", this.props.documentNumber, () => {
                                    this.loadDraft(() => {
                                        this.checkAndSaveDocument()
                                    })
                                })
                            })
                        } else {
                            setTimeout(() => {
                                this.checkAndSaveDocument()
                            }, 1000);
                        }
                    },
                    onCancel: () => {
                        this.docIsCreated = false;
                    },
                });
            } else if ([4, 16, 17, 18, 20, 21].filter(f => this.props.documentType == f).length > 0) {
                if (!this.itemRoundCreated && this.state.GP_TotalDialog == '1') {
                    this.docIsCreated = false;
                    this.saveDraft(() => {
                        // const { isCostWithTax } = this.state;
                        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
                        let sumDoc = itemList.reduce(function (a, v) {
                            if (v.key === 0) return a;
                            // let sumField = isCostWithTax ? v.totalSumWithTax : v.totalSumNoTax;
                            // return a + parseFloat(sumField ? sumField : 0.0);
                            return a + parseFloat(v.totalSumWithTax ? v.totalSumWithTax : 0.0);
                        }, 0) || 0.0;
                        this.setState({ modalCreateItemRound: true, modalCreateItemRoundSumDoc: sumDoc ? sumDoc.toFixed(2) : '0.00', modalCreateItemRoundPositive: sumDoc > 0 })
                    })
                } else {
                    let DocNum = Jax.get(this.props.data, "document.editedRecord.number", 0);
                    this.saveDraft(() => {
                        if ((this.props.documentType == 4 || this.props.documentType == 16) && !this.modalItemsDiscrepancyUse && this.state.GP_UpdateCostPrice == '1') {
                            this.sendAPI("check_cost_price_before_create_doc", "number\r" + DocNum, (ob) => {
                                let fullData = ob.data ? ob.data.split('\r') : [''];
                                let modalItemsDiscrepancyHeaders = fullData[0].split("\f");
                                let modalItemsDiscrepancyData = fullData.splice(1).map((x, index) => {
                                    let y = x.split('\f');
                                    let obj = { key: y[0], index }
                                    modalItemsDiscrepancyHeaders.forEach((h, hi) => {
                                        obj = { ...obj, [h]: y[hi] }
                                    })
                                    return obj;
                                });
                                if (modalItemsDiscrepancyData.length) {
                                    this.setState({ modalItemsDiscrepancyData, modalItemsDiscrepancy: true, loading: true });
                                    this.modalItemsDiscrepancyUse = true;
                                } else {
                                    this.setState({ loading: true }, this.afterUpdateCostPrices);
                                }
                            })
                        } else {
                            this.setState({ loading: true }, this.afterUpdateCostPrices);
                        }
                    });
                }
            } else {
                if ([1, 2, 22, 8, 9].filter(f => this.props.documentType == f).length > 0 && !this.itemRoundCreated && this.state.GP_SalesTotalDialog == '1') {
                    this.docIsCreated = false;
                    this.saveDraft(() => {
                        // const { isCostWithTax } = this.state;
                        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
                        let sumDoc = itemList.reduce(function (a, v) {
                            if (v.key === 0) return a;
                            // let sumField = isCostWithTax ? v.totalSumWithTax : v.totalSumNoTax;
                            // return a + parseFloat(sumField ? sumField : 0.0);
                            return a
                                + parseFloat(v.totalSumWithTax ? v.totalSumWithTax : 0.0);
                        }, 0) || 0.0;
                        this.setState({ modalCreateItemRound: true, modalCreateItemRoundSumDoc: sumDoc ? sumDoc.toFixed(2) : '0.00', modalCreateItemRoundPositive: sumDoc > 0 })
                    })
                } else {

                    console.log("DEBOZ: save then create doc");
                    const { customerCode, customerCredit, customerObligo, remarks } = this.props.data.document2;
                    const DocTypeID = this.props.documentType
                    const { GP_PrintCustomerCredit, gp_roundSumItemCode, gp_roundDesc } = this.state;

                    let sumTotal = Jax.get(this.props.data, "document2.dataSource", []).reduce(function (a, v) {
                        if (v.key === 0) return a;
                        return a + parseFloat((v.totalSumWithTax) ? v.totalSumWithTax : 0.0);
                    }, 0) || 0.0;

                    //console.log("oz5555555");
                    if (GP_PrintCustomerCredit == '1' && customerCode > 0 && [1, 2, 5, 8, 9, 22, 202, 208, 222, 2005].find(f => f == DocTypeID)) {
                        //console.log("oz6666666");
                        let newRemarks = "";
                        let newCustCredit = parseFloat(customerCredit) ? parseFloat(customerCredit).toFixed(2) : "0.00";
                        let newCustObligo = parseFloat(customerObligo) ? parseFloat(customerObligo).toFixed(2) : "0.00";
                        switch (DocTypeID) {
                            case 1: case '1':
                                this.props.data.documentPayments.data.forEach(x => {
                                    if (x.paymentTypeID == '2' && new Date(x.valueDate) > Date.now()) newCustObligo -= x.defaultCurrencySum
                                })
                                break;
                            case 2: case '2': case 22: case '22':
                                newCustCredit -= sumTotal;
                                newCustObligo -= sumTotal;
                                break;
                            case 5: case '5':
                                newCustCredit -= sumTotal;
                                break;
                            case 8: case '8': case 9: case '9':
                                newCustObligo -= sumTotal;
                                break;
                        }

                        if (remarks) newRemarks += remarks + " \n";

                        newRemarks += this.getText(51800) + ":       " +
                            parseFloat(newCustCredit).toFixed(2) + " \n" +
                            this.getText(16426) + ":    " +
                            parseFloat(newCustObligo).toFixed(2);

                        this.props.dataActions.setJson("document2", { remarks: newRemarks })

                    }
                    setTimeout(() => {
                        this.saveDraft(() => {
                            //console.log("oz99999");
                            let isTypeForRound = [2, 5, 8, 9, 22, 202, 208, 222, 2005].filter(f => f == this.props.documentType).length > 0
                                || (this.props.documentType == 1 && this.props.data.documentPayments.data.find(f => f.paymentTypeID == 1))

                            let issetRoundItem = false;

                            sumTotal = Jax.get(this.props.data, "document2.dataSource", []).reduce(function (a, v) {
                                if (v.key === 0) return a;
                                if (v.description == gp_roundDesc) issetRoundItem = true;
                                return a + parseFloat((v.totalSumWithTax) ? v.totalSumWithTax : 0.0);
                            }, 0) || 0.0;

                            let r = this.calculateRoundSum(sumTotal);
                            // let restRound = ((sumTotal * 100) % (gp_roundTotal * 100)) / 100;
                            // let restRound = ((sumTotal * 100) % (gp_roundTotal * 100)) / 100;
                            // console.log("test not save temp 1", sumTotal, gp_roundTotal, restRound)
                            // if (gp_roundSumItemCode && isTypeForRound && Math.abs(restRound) > 0.005 && (Math.abs(restRound - gp_roundTotal)) > 0.005) {
                            if (isTypeForRound && r.roundSum && !issetRoundItem && r.totalWithTax) {
                                // console.log("test round sum 0 ", { restRound, sumTotal, gp_roundTotal })

                                this.getDiscountRoundItemTempDoc(r.totalWithTax, () => {
                                    this.docIsCreated = false;
                                    this.checkAndSaveDocument()
                                }, gp_roundSumItemCode, gp_roundDesc)

                            } else {
                                this.saveDocument();
                                this.userAcceptsZeroPrices = false;
                                this.userAcceptsPreDate = false;
                            }
                        })
                    }, 500);

                }
            }
        }, 500)
    }

    getDiscountRoundItemTempDoc = (finishSum, callback, otherItemCode, otherDescription) => {

        const { documentNumber, documentType, posId } = this.props;
        let sendData = "docNum\fdocType\fdocPos\ffinishSum\fitemCode\r" +
            documentNumber + '\f' + documentType + '\f' + posId + '\f' + finishSum + "\f" + (otherItemCode ?? "");
        this.sendAPI("get_discount_round_item_temp_doc", sendData, (ob) => {
            let newData = ob.data ? ob.data.split('\r').map(x => {
                let y = x.split('\f');
                let totalSumWithTax = y[6] ? y[6] : 0;
                let totalSumNoTax = y[5] ? y[5] : totalSumWithTax;
                return {
                    key: y[0],
                    keyVal: y[0],
                    itemCode: otherItemCode ? otherItemCode : y[1],
                    description: otherDescription ? otherDescription : y[2],
                    taxId: y[3],
                    taxPercentageValue: y[4] ? y[4] : 0,
                    totalSumNoTax,
                    totalSumWithTax,
                    unitPriceNoTax: totalSumNoTax < 0 ? totalSumNoTax * -1 : totalSumNoTax,
                    unitPriceWithTax: totalSumWithTax < 0 ? totalSumWithTax * -1 : totalSumWithTax,
                    isValid: 1,
                    quantity: totalSumWithTax < 0 ? '-1.00' : '1.00',
                    discountPercentage: 0,
                    quantityInUnit: "1",
                    remarks: "",
                    unitDescription: "",
                    unitID: "0",
                    unitQuantity: "1",
                }
            }) : [];

            let dataSource = [...this.props.data.document2.dataSource, ...newData]

            this.props.dataActions.setJson('document2', { dataSource });

            if (callback) callback()
        })
    }

    onSaveSumType4or18 = () => {
        if (!this.docIsCreated2) {
            this.docIsCreated2 = true;

            const { modalCreateItemRoundSumDoc } = this.state;
            this.getDiscountRoundItemTempDoc(modalCreateItemRoundSumDoc, () => {
                setTimeout(() => {
                    this.setState({ modalCreateItemRound: false, modalCreateItemRoundSumDoc: 0, modalCreateItemRoundPositive: false })
                    this.itemRoundCreated = true;
                    this.checkAndSaveDocument();
                }, 1000)
            })

        }
    }

    sendAPI = (script, data, sCal, outputType) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType[outputType ? outputType : 'OUTPUT_TYPE_DATA'], ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    // sendReportBySms = (params) => {
    //     let customerId = this.props.data.document2.customerCode;
    //     let target = this.state.sendDocBySmsTarget;

    //     let reportNameAndFormat = params.reportName.split("/")[1].split(".");
    //     let reportName = reportNameAndFormat[0];
    //     let user = new User(this.props.user.token, this.props.user.companyCode);
    //     let job = new Job(user, 'send_report', OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
    //     let metaData = 'targetType\freportName\ftargetSystem\fcustomerId\ftarget\freportFormat\fisDoc\r' +
    //         '2\f' + reportName + '\f0\f' + customerId + '\f' + target + '\f0\f1';
    //     let paramsKeys = Object.keys(params);
    //     let paramsData = paramsKeys.join("\f") + "\r" + paramsKeys.map(x => params[x] ? params[x] : "").join("\f")
    //     job.setSection('META', metaData);
    //     job.setSection('PARAMS', paramsData);
    //     job.send("/cgi-bin/CashOnTab", () => {
    //         message.success(this.getText(17755))
    //         this.setState({
    //             sendDocBySmsTarget: '',
    //             sendDocBySmsTargetOk: false,
    //         })

    //     }, (e) => { console.error(e) });
    // }

    getUOMqty = (_IsMatrixReverseFather, _UOM_Qty): Number => {
        return (_IsMatrixReverseFather && (_IsMatrixReverseFather === 1 || _IsMatrixReverseFather === '1')) ? _UOM_Qty * 1.00 : 1.00;
    }

    getSizeDescription = (_sizeID) => {
        // get description of sizeid of the ruler in store
        let obj = Jax.get(this.props.data, "UOM_Ruler.data", [])
            .find(i => (i.sizeID).toString() === _sizeID.toString());
        return (obj) ? obj['sizeDesc'] : '';
    }

    updatePricesPerSupplier = (supplierCode, priceListId, dataSource, next, notAddBonuses, fromIndex) => {
        if (dataSource.length) {
            let isInventory = [4, 11, 16, 17, 18, 20, 21].filter(f => this.props.documentType == f).length > 0
            let isSupp = [4, 16, 17, 18, 20, 21].find(f => f == this.props.documentType) ? true : false

            let dataSend = "_itemCode\f_customerId\f_supplierId\f_priceListId\f_posId\f_docTime";
            let lineSend = '\f' + this.props.data.document2.customerCode
                + '\f' + (isSupp ? supplierCode : "")
                + '\f' + priceListId
                + '\f' + this.props.posId
                + '\f' + this.props.data.document2.docDateTime;
            dataSource.forEach(line => { dataSend += '\r' + line.itemCode + lineSend });

            let costCurrencyId = null;
            let costCurrencySymbol = null;
            let costCurrencyRate = null;

            let isEnglishDoc = [202, 203, 208, 215, 219, 222, 230].find(f => f == this.props.documentType)
            if (isEnglishDoc) {
                costCurrencyId = this.props.data.document2.currencyCode
                costCurrencySymbol = this.props.data.document2.currencySymbol
                costCurrencyRate = this.props.data.document2.currencyRate
            }

            this.sendAPI('get_item_line_meta', dataSend, (ob2) => {
                let fullData = ob2.data.split('\r');
                let bonusRows = []

                fullData.forEach((line, index) => {
                    if (dataSource[index] && (!fromIndex || index >= fromIndex)) {
                        const {
                            excelPriceValue, excelCostValue, discountPercentage, quantity,
                            excelBaseCostNoTaxValue, excelBaseCostWithTaxValue, excelDscnt1Value, excelDscnt2Value, excelBonusQtyValue, excelQtyForBonusQtyValue,
                        } = dataSource[index];
                        let resArr = line.split('\f');
                        let _unitCost = resArr[isInventory ? 0 : 1] ? resArr[isInventory ? 0 : 1] : 0;
                        let _unitCostNoTax = resArr[isInventory ? 2 : 3] ? resArr[isInventory ? 2 : 3] : 0;
                        let _cost = resArr[2] ? resArr[2] : 0;
                        let _quantity = parseFloat(quantity);
                        let _supplierId = resArr[6];
                        let _taxId = resArr[4];
                        let _taxPercentageValue = resArr[5] ? resArr[5] : 0;
                        let _isWeightable = resArr[8];
                        let _isValid = parseInt(resArr[9]);
                        let _discountPercentage = parseFloat(discountPercentage);
                        let isChangeCostOrPrice = false;

                        let _config = configByDocType(this.props.documentType);

                        let taxCulc = 1 + (parseFloat(_taxPercentageValue) ? parseFloat(_taxPercentageValue) / 100 : 0);


                        let bonusCalcData = {
                            mBaseCost: this.getDecimalNumber(resArr[16]),
                            mSupDscnt1: this.getDecimalNumber(resArr[17]),
                            mSupDscnt2: this.getDecimalNumber(resArr[18]),
                            mSupDscnt3: this.getDecimalNumber(resArr[19]),
                            mCostIncludeDscnt: this.getDecimalNumber(resArr[20]),
                            mBonusQty: this.getDecimalNumber(resArr[21]),
                            mQtyForBonusQty: this.getDecimalNumber(resArr[22]),
                            mCostIncludeBonus: this.getDecimalNumber(resArr[23])
                        }

                        if (_config.isUnitCost && (this.state.GP_CostCalcOption == '1' || this.state.GP_CostCalcOption == '2') && resArr[20]) {
                            _unitCostNoTax = resArr[20];
                            _unitCost = _unitCostNoTax * ((parseFloat(_taxPercentageValue) + 100) / 100);
                        }

                        const isCostFieldsNew = parseFloat(excelBaseCostNoTaxValue) || parseFloat(excelBaseCostWithTaxValue);

                        let _excelCostValueNoTax = excelCostValue;
                        let _excelCostValueWithTax = excelCostValue * taxCulc;

                        if (isCostFieldsNew) {
                            _excelCostValueNoTax = parseFloat(excelBaseCostNoTaxValue) ? excelBaseCostNoTaxValue : excelBaseCostWithTaxValue / taxCulc;
                            _excelCostValueWithTax = parseFloat(excelBaseCostWithTaxValue) ? excelBaseCostWithTaxValue : excelBaseCostNoTaxValue * taxCulc;

                        }



                        if (excelPriceValue || excelCostValue) {

                            if (parseFloat(_excelCostValueNoTax)) {
                                _cost = _excelCostValueNoTax;
                            }
                            if (parseFloat(_excelCostValueNoTax) && isInventory) {
                                _unitCostNoTax = _excelCostValueNoTax
                                _unitCost = _excelCostValueWithTax;
                            }
                            if (parseFloat(excelPriceValue) && !isInventory) {
                                _unitCost = excelPriceValue;
                                _unitCostNoTax = excelPriceValue / taxCulc;
                            }

                            if (_isValid > 0 && parseFloat(resArr[2]) != parseFloat(_excelCostValueNoTax) && !isCostFieldsNew) isChangeCostOrPrice = true;
                            if (_isValid > 0 && parseFloat(resArr[1]) != parseFloat(excelPriceValue)) isChangeCostOrPrice = true;
                        }



                        if (_discountPercentage) {
                            _quantity = _quantity * (1 - (_discountPercentage / 100))
                        }
                        if (this.state.hidePrices && (this.props.documentType == '15' || this.props.documentType == '215')) {
                            _unitCost = 0;
                            _unitCostNoTax = 0;
                        }

                        if (isCostFieldsNew) {
                            let mBaseCost = parseFloat(_excelCostValueNoTax);
                            let mSupDscnt1 = parseFloat(excelDscnt1Value) ?? parseFloat(resArr[17] ? resArr[17] : 0)
                            let mSupDscnt2 = parseFloat(excelDscnt2Value) ?? parseFloat(resArr[18] ? resArr[18] : 0)
                            let mSupDscnt3 = parseFloat(resArr[19] ? resArr[19] : 0)
                            let mBonusQty = parseFloat(excelBonusQtyValue) ?? resArr[21]
                            let mQtyForBonusQty = parseFloat(excelQtyForBonusQtyValue) ?? resArr[22]

                            let mCostIncludeDscnt = parseFloat(mBaseCost);
                            if (parseFloat(mSupDscnt1)) mCostIncludeDscnt = mCostIncludeDscnt * (1 - (parseFloat(mSupDscnt1) / 100))
                            if (parseFloat(mSupDscnt2)) mCostIncludeDscnt = mCostIncludeDscnt * (1 - (parseFloat(mSupDscnt2) / 100))
                            if (parseFloat(mSupDscnt3)) mCostIncludeDscnt = mCostIncludeDscnt * (1 - (parseFloat(mSupDscnt3) / 100))

                            let mCostIncludeBonus = mCostIncludeDscnt;

                            if (_isValid > 0 && parseFloat(resArr[2]) != parseFloat(mCostIncludeDscnt)) isChangeCostOrPrice = true;

                            if (parseFloat(mBonusQty) && parseFloat(mQtyForBonusQty)) {
                                mCostIncludeBonus = mCostIncludeBonus / (parseFloat(mBonusQty) + parseFloat(mQtyForBonusQty)) * parseFloat(mQtyForBonusQty);
                                if (_quantity >= mQtyForBonusQty && !notAddBonuses) {
                                    bonusRows.push({
                                        ...dataSource[index],
                                        itemCode: resArr[14],
                                        itemBarcode: resArr[15],
                                        unitPriceWithTax: 0,
                                        unitPriceNoTax: 0,
                                        totalSumWithTax: 0,
                                        totalSumNoTax: 0,
                                        isValid: _isValid,
                                        taxId: _taxId,
                                        taxPercentageValue: _taxPercentageValue,
                                        isWeightable: _isWeightable,
                                        cost: 0,
                                        supplierId: _supplierId,
                                        quantity: parseFloat(parseInt(_quantity / mQtyForBonusQty) * mBonusQty).toFixed(2)
                                    })
                                }
                            }



                            bonusCalcData = {
                                mBaseCost: this.getDecimalNumber(mBaseCost),
                                mSupDscnt1: this.getDecimalNumber(mSupDscnt1),
                                mSupDscnt2: this.getDecimalNumber(mSupDscnt2),
                                mSupDscnt3: this.getDecimalNumber(mSupDscnt3),
                                mCostIncludeDscnt: this.getDecimalNumber(mCostIncludeDscnt),
                                mBonusQty: this.getDecimalNumber(mBonusQty),
                                mQtyForBonusQty: this.getDecimalNumber(mQtyForBonusQty),
                                mCostIncludeBonus: this.getDecimalNumber(mCostIncludeBonus)
                            }


                            _unitCost = mCostIncludeDscnt * taxCulc;
                            _unitCostNoTax = mCostIncludeDscnt;

                        }

                        let costCurrencyValue = null;

                        if (isEnglishDoc) {
                            _unitCost = _unitCostNoTax;
                            costCurrencyValue = parseFloat((_unitCostNoTax ? _unitCostNoTax : 0) / (costCurrencyRate ? costCurrencyRate : 0)).toFixed(2)
                        } else {
                            costCurrencyId = resArr[10]
                            costCurrencySymbol = resArr[11]
                            costCurrencyValue = resArr[12]
                            costCurrencyRate = resArr[13]
                        }




                        dataSource[index] = {
                            ...dataSource[index],

                            itemCode: resArr[14],
                            itemBarcode: isTrue(dataSource[index].itemBarcode) ?? resArr[15],
                            remarks: resArr[24],

                            unitPriceWithTax: parseFloat(_unitCost).toFixed(4),//_unitCost,//1,
                            unitPriceNoTax: parseFloat(_unitCostNoTax).toFixed(4),//_unitCost,//1,
                            totalSumWithTax: (parseFloat(_unitCost) * _quantity).toFixed(4),
                            totalSumNoTax: (parseFloat(_unitCostNoTax) * _quantity).toFixed(4),
                            isValid: _isValid,
                            taxId: _taxId,
                            taxPercentageValue: _taxPercentageValue,
                            isWeightable: _isWeightable,
                            cost: parseFloat(_cost).toFixed(4),
                            supplierId: _supplierId,
                            isChangeCostOrPrice,

                            costCurrencyId,
                            costCurrencySymbol,
                            costCurrencyValue,
                            costCurrencyRate,

                            bonusCalcData,

                            excelCostValue: isCostFieldsNew ? bonusCalcData.mCostIncludeDscnt : excelCostValue

                        }
                    }
                })
                let dataSourceLength = dataSource.length;
                if (bonusRows.length) {
                    bonusRows.forEach((x, i) => {
                        dataSource.push({
                            ...x,
                            key: dataSourceLength + i + 1,
                            keyVal: dataSourceLength + i + 1,
                            isBonusRow: true
                        })
                    })
                }
                console.log("dataSource test ", dataSource)
                this.props.dataActions.setJson('document2', { priceListId, dataSource });
                this.modalCreateItemsMultiOpen({ supplierCode, priceListId, dataSource });
                // this.setState({ isSupplierChanged: false });
                this.isSupplierChanged = false;

                if (next) next();
            })
        } else {
            this.isSupplierChanged = false;
            if (next) next();
        }
    }

    modalCreateItemsMultiOpen = ({ supplierCode, priceListId, dataSource }) => {
        let filterNaNSum = dataSource.filter(f => !f.isBonusRow && (!parseFloat(f.unitPriceWithTax) || !parseFloat(f.unitPriceNoTax) || !parseFloat(f.quantity)))
        let filterNewItems = dataSource.filter(f => f.isValid == 0 && f.itemCode)
        let filterUpdateItems = dataSource.filter(f => f.isValid > 0 && f.isChangeCostOrPrice && !f.isBonusRow)

        this.setState({
            modalNaNSum: filterNaNSum.length ? { supplierCode, priceListId, dataSource } : null,
            modalCreateItemsMulti: filterNewItems.length ? { supplierCode, priceListId, dataSource } : null,
            modalUpdateItemsMulti: filterUpdateItems.length ? { supplierCode, priceListId, dataSource } : null,
        });
        // if (filterNaNSum.length) this.setState({ modalNaNSum: { supplierCode, priceListId, dataSource } });
        // if (filterNewItems.length) this.setState({ modalCreateItemsMulti: { supplierCode, priceListId, dataSource } });
        // if (filterUpdateItems.length) this.setState({ modalUpdateItemsMulti: { supplierCode, priceListId, dataSource } });
    }

    afterUpdateCostPrices = () => {
        this.setState({ modalItemsDiscrepancy: false, modalItemsDiscrepancyData: []/*, modalItemsDiscrepancyChecked: [] */ }, () => {
            console.log("DEBOZ: save then create doc");
            this.saveDocument();
            this.userAcceptsZeroPrices = false;
            this.modalItemsDiscrepancyUse = false;
        })
    }

    closeModal = () => {
        let issetPays = this.props.data.documentPayments.data.length
        if (issetPays) {
            message.error(this.getText(51799));
        } else {
            this.saveDraft();
            this.closeEditor();
        }
    }


    updateCustDiscount = () => {
        let customerCode = parseInt(Jax.get(this.props.data, "document2.customerCode", -1), 10);
        this.sendAPI("get_customer_discount", customerCode, (ob) => {
            let customerDiscount = parseFloat(ob.data) ? parseFloat(ob.data) : 0;
            this.props.dataActions.setJson("document2", { customerDiscount })
        })

    }

    generateDraftDoc = () => {

        this.saveDraft(() => {
            this.generateReport({
                DocumentNumber: this.props.documentNumber,
                DocumentTypeID: this.props.documentType,
                DocumentPOSID: this.props.posId,
                CompanyCode: this.props.user.companyCode,
                userID: this.props.user.userId,
                userName: encodeURIComponent(this.props.user.displayName),
                isDraft: true,
                CentralFatherItem: this.state.printCentralPerFatherItem ? '1' : '0',
                PrintFormat: this.state.PrintFormat,
                reportName: this.props.documentType == '5' ? "reports/DocumentCopyCentral.pdf" : "reports/DocumentCopy.pdf"
            })
        })
    }

    removeNaNSum = (key) => {
        return (<Icon type="delete" onClick={() => {
            const filterData = f => {
                if (key === "all") {
                    return parseFloat(f.unitPriceWithTax) && parseFloat(f.unitPriceNoTax) && parseFloat(f.quantity)
                } else {
                    return f.itemCode != key
                }
            }
            const { supplierCode, priceListId, dataSource } = this.state.modalNaNSum;
            this.modalCreateItemsMultiOpen({ supplierCode, priceListId, dataSource: dataSource.filter(filterData) })
            this.props.dataActions.setJson("document2", { dataSource: this.props.data.document2.dataSource.filter(filterData) })

        }} />)
    }

    renderNaNSum = (num) => {
        return parseFloat(num) ? num : (<div style={{ backgroundColor: 'red' }}>0</div>)
    }

    getCheckboxModalCreateItemsMulti = (text, record, stateName) => {
        // let allData = this.state.modalCreateItemsMulti ? [...this.state.modalCreateItemsMulti.dataSource] : [];
        let allData = this.state[stateName] ? [...this.state[stateName].dataSource] : [];
        let checked = false;
        let indeterminate = false;

        if (text === 'all') {
            let checkedCount = allData.filter(f => f.isChecked).length;
            checked = checkedCount == allData.length;
            indeterminate = checkedCount && checkedCount < allData.length;
        } else {
            checked = record.isChecked;
        }

        let onChange = (e) => {
            let isChecked = e.target.checked;
            let dataSource = allData.map(x => { return (text === 'all' || x.key === record.key) && !x.isBonusRow ? { ...x, isChecked } : x })
            this.setState({ [stateName]: { ...this.state[stateName], dataSource } })
        };

        return (<Checkbox {...{ checked, onChange, indeterminate }} />)
    }

    createItemsMulti = (stateName) => {
        // const { supplierCode, priceListId, dataSource } = this.state.modalCreateItemsMulti ? this.state.modalCreateItemsMulti : {};
        const { supplierCode, priceListId, dataSource } = this.state[stateName] ? this.state[stateName] : {};

        let data = dataSource ? dataSource.filter(f => f.isChecked) : [];
        let dataSend = "_code\f_name\f_cost\f_price\f_qtyInUnit\f_supplier\f_pos\f_priceListId\f_barcode";
        data.forEach(x => {
            dataSend += "\r" + x.itemCode + "\f"
                + x.description + "\f"
                + (x.excelCostValue ? x.excelCostValue : "") + "\f"
                + (x.excelPriceValue ? x.excelPriceValue : "") + "\f"
                + (x.quantityInUnit ? x.quantityInUnit : "") + "\f"
                + (supplierCode ? supplierCode : "") + "\f"
                + this.props.posId + "\f"
                + (priceListId ? priceListId : "") + "\f"
                + (x.itemBarcode ? x.itemBarcode : x.itemCode);
        });
        this.sendAPI("save_items_multi_from_doc", dataSend, (ob) => {
            // this.sendAPI(api, dataSend, (ob) => {
            if (stateName == 'modalCreateItemsMulti') {
                let res = ob.data ? ob.data.split("\r") : []

                let newDataSource = [...dataSource]
                res.forEach(x => {
                    let y = x.split("\f");
                    let f = newDataSource.findIndex(f => f.itemCode === y[0] && !f.isBonusRow);
                    if (f > -1) {
                        newDataSource[f] = { ...newDataSource[f], unitID: y[1] }
                    }
                });

                this.updatePricesPerSupplier(supplierCode, priceListId, newDataSource, () => { this.setState({ [stateName]: null }) }, true)
            } else {
                this.setState({ [stateName]: null })
            }
        })
    }

    getModalItemsMultiColumns = (stateName) => {
        return [
            { title: this.getCheckboxModalCreateItemsMulti("all", null, stateName), width: "5%", render: (t, r) => this.getCheckboxModalCreateItemsMulti(t, r, stateName) },
            { title: this.getText(17706), key: "itemCode", dataIndex: "itemCode", width: "12%", render: t => t },
            { title: this.getText(18095), key: "itemBarcode", dataIndex: "itemBarcode", width: "12%", render: t => t },
            { title: this.getText(17707), key: "description", dataIndex: "description", width: "35%", render: t => t },
            { title: this.getText(17709), key: "excelPriceValue", dataIndex: "excelPriceValue", width: "12%", render: t => t },
            { title: this.getText(17708), key: "excelCostValue", dataIndex: "excelCostValue", width: "12%", render: t => t },
            { title: this.getText(17710), key: "quantityInUnit", dataIndex: "quantityInUnit", width: "12%", render: t => t },
        ]
    }

    saveTemplete = () => {
        const { data, posId } = this.props;

        const dataSend = "pos\fdesc\fblob\fsum\r" +
            posId + "\f" +
            this.state.modalCreateTempleteDesc + "\f" +
            JSON.stringify({ data: data.document2, docType: this.props.documentType }) + "\f" +
            data.document2.dataSource.reduce((a, v) => (v.key === 0) ? a : a + parseFloat((v.totalSumWithTax) ? v.totalSumWithTax : 0.0), 0) || 0.0

        this.sendAPI("save_templete_doc", dataSend, () => {
            this.setState({ modalCreateTemplete: false }, () => {
                message.success(this.getText(18670))
            })
        })
    }

    saveReservedNumber = (reservedNumber) => {
        const { realDocNumber, custTaxIdentity, docType, posId, issetMatrixItems } = this.props.data.document2.modalInsertReservedNumber ?? {}
        const nextReport = () => {
            this.getReport2(realDocNumber, docType, posId, issetMatrixItems);
            this.props.dataActions.setJson("document2", { modalInsertReservedNumber: null })
        }

        if (reservedNumber) {
            let dataSend = "_num\f_type\f_pos\f_value\f_taxIdentity\r" + realDocNumber + "\f" + docType + "\f" + posId + "\f" + reservedNumber + "\f" + custTaxIdentity;
            this.sendAPI("save_reserved_number", dataSend, nextReport)
        } else {
            nextReport()
        }
    }

    getCheckBox = (field, label, style) => {
        return (<Checkbox
            style={style}
            checked={this.state[field]}
            onChange={e => {
                let more = {}
                if (field === "printOriginalDoc") {
                    more = {
                        printOriginalDocType1: false,
                        printOriginalDocType2: false,
                        printOriginalDocType3: false,
                        printOriginalDocType4: false,
                        printOriginalDocType5: false,
                    }
                    if (e.target.checked) {
                        more.printOriginalDocType1 = true
                    }
                } else if (field === "printCopyDoc") {
                    more = {
                        printCopyDocType1: false,
                        printCopyDocType2: false,
                        printCopyDocType3: false,
                    }
                    if (e.target.checked) {
                        more.printCopyDocType1 = true
                    }
                }
                this.setState({ [field]: e.target.checked, ...more })
            }}>
            {this.getText(label)}
        </Checkbox>)
    }

    render() {
        const { ...rest } = this.props;
        const { gp_roundTotal, gp_roundDesc, gp_roundSumItemCode, GP_CostCalcOption, GP_FinalDiscount } = this.state;
        let config: configObject = configByDocType(this.props.documentType);

        let disabledSubmit = false;
        if (config.showItems && !this.props.data.document2.dataSource.length) disabledSubmit = true;
        if (config.showPayments && !this.props.data.documentPayments.data.length) disabledSubmit = true;
        if (config.showTax && !this.calculateDocVat().length) disabledSubmit = true;

        const contentList = {
            tab1: <ContentDoc
                {...rest} test={this.state.activeTab}
                sendAPI={this.sendAPI}
                isCostNoTax={this.state.isCostNoTax}
                isCostWithTax={this.state.isCostWithTax}
                onChangeState={(obj) => { this.setState(obj) }}
                // isCostWithTax={this.state.isCostWithTaxChecked ? this.state.isCostWithTax ? '1' : '0' : null}
                // onChangeTax={(e) => {
                //     this.setState({ isCostWithTaxChecked: true, isCostWithTax: e === '1' })
                // }}
                onChangeSupplier={(e) => {
                    this.isSupplierChanged = e && this.lastSupplier && e !== this.lastSupplier ? true : false;
                    this.lastSupplier = e;
                    // this.setState({ isSupplierChanged: e && this.lastSupplier && e !== this.lastSupplier ? true : false }, () => { this.lastSupplier = e })
                }}
                requireSuppDocNum={this.state.GP_RequireSuppDocNum == '1'}
                uniqSuppDocNum={this.state.GP_UniqSuppDocNum == '1'}
                requireEmployee={this.state.GP_DocEmployeeIsRequired == '1'}
                printFixCustFields={this.state.GP_PrintFixCustFields == '1'}
                setTargets={sendDocBySmsTarget => { this.setState({ sendDocBySmsTarget }) }}
                apiLastRates={this.apiLastRates}
                loadDraft={this.loadDraft}
                setRootState={newState => { this.setState(newState) }}
            />,
            tab2: <ContentLines
                {...rest} config={config} test={this.state.activeTab}
                hidePrices={this.state.hidePrices}
                isCostNoTax={this.state.isCostNoTax}
                isCostWithTax={this.state.isCostWithTax}
                onChangeState={(obj) => { this.setState(obj) }}
                // isCostWithTaxRadio={this.state.isCostWithTaxChecked ? this.state.isCostWithTax ? '1' : '0' : null}
                updatePricesPerSupplier={this.updatePricesPerSupplier}
                // onChangeTax={(e) => {
                //     this.setState({ isCostWithTaxChecked: true, isCostWithTax: e === '1' })
                // }}
                sendAPI={this.sendAPI}
                isSumFlag={this.state.GP_itemcodequantityunion == '1'}
                changeSumFlag={(e) => {
                    this.setState({ GP_itemcodequantityunion: e.target.checked ? '1' : '0' })
                }}
                linesOrderAscDefault={this.state.GP_DocLineOrderDefault == '1'}
                continueSearchList={this.state.GP_ContinueSearchList == '1'}
                costCalcOption={this.state.GP_CostCalcOption}
                addDiscountRoundItem={this.getDiscountRoundItemTempDoc}
                lastItemSearched={this.state.GP_LastItemSearched == '1'}
                fatherItemInDoc={this.state.GP_FatherItemInDoc == '1'}
                chooseSalePriceOption={this.state.GP_ChooseSalePriceOption}
                choosePurchasePriceOption={this.state.GP_ChoosePurchasePriceOption}
            />,
            tab3: <ContentPays
                {...rest}
                test={this.state.activeTab}
                {...{ gp_roundTotal, gp_roundDesc }}
                calculateRoundSum={this.calculateRoundSum}
            />,
            tab4: <ContentDocsCentral
                {...rest}
                sendAPI={this.sendAPI}
                parseCentralDocJson={this.parseCentralDocJson}
                PrintFormat={this.state.PrintFormat}
                setRootState={newState => { this.setState(newState) }}
            />,
        };

        let tabListFull = [
            {
                key: 'tab4',
                tab: this.getText(this.props.documentType == 45051 ? 17055 : 15430),
            }, {
                key: 'tab3',
                tab: this.getText(12443),
            }, {
                key: 'tab2',
                tab: this.getText(12444),
            }, {
                key: 'tab1',
                tab: this.getText(12445),
            }
        ];
        //let config: { showItems: Boolean, showPayments: Boolean, showTax: Boolean, isUnitCost: Boolean } = configByDocType(this.props.documentType);
        let tabList = tabListFull.filter(i => {
            if (i.key === 'tab4' && (this.props.documentType == '5' || this.props.documentType == '45051' || this.props.documentType == '40418')) {
                return true;
            }
            if (i.key === 'tab3' && config.showPayments === true) {
                return true;
            }
            if (i.key === 'tab2' && config.showItems === true) {
                return true;
            }
            if (i.key === 'tab1') {
                return true;
            }
            return false;
        });

        let issetPays = this.props.data.documentPayments.data.length;
        let issetMatrix = this.props.data.document2.dataSource.filter(f => decodeXml(f.remarks, 'Color') || decodeXml(f.remarks, 'Size1')).length;

        const onCreateDoc = () => {
            //console.log("oz11111")
            if (!this.docIsCreated) {
                const onOk = () => {
                    this.docIsCreated = true;
                    this.checkAndSaveDocument();
                }
                //console.log("oz22222")
                if ([2, 5, 8, 2005].filter(f => f == this.props.documentType).length) {

                    this.sendAPI("get_customer_credit_balance_bo", this.props.data.document2.customerCode, (ob) => {
                        let res = ob.data ? ob.data.split('\f') : [];
                        let limitCredit = Math.abs(parseFloat(res[0])) * -1 ?? 0;
                        let limitObligo = Math.abs(parseFloat(res[1])) * -1 ?? 0;
                        let customerCredit = parseFloat(res[2]) ?? 0;
                        let customerObligo = parseFloat(res[3]) ?? 0;
                        let sumDoc = this.calculateDocTotal().totalWithTax;
                        let exceptionCredit = 0;
                        let exceptionObligo = 0;


                        this.props.dataActions.setJson("document2", { customerCredit, customerObligo })


                        if (this.props.documentType != 8 && customerCredit - sumDoc < limitCredit) exceptionCredit = customerCredit - sumDoc;
                        if (customerObligo - sumDoc < limitObligo) exceptionObligo = customerObligo - sumDoc;
                        if (exceptionCredit || exceptionObligo) {
                            confirm({
                                title: this.getText(exceptionCredit ? 19039 : 19040),
                                content: exceptionCredit ?
                                    (<span>
                                        <p>{this.getText(19041)} {customerCredit}</p>
                                        <p>{this.getText(19042)} {limitCredit}</p>
                                        <p>{this.getText(19043)} {exceptionCredit} {this.getText(19044)}</p>
                                    </span>)
                                    : (<span>
                                        <p>{this.getText(19045)} {customerObligo}</p>
                                        <p>{this.getText(19046)} {limitObligo}</p>
                                        <p>{this.getText(19047)} {exceptionObligo} {this.getText(19044)}</p>
                                    </span>),
                                iconType: 'exclamation-circle',
                                okText: this.getText(17753),
                                cancelText: this.getText(17754),
                                onOk,
                                onCancel() { },
                            })
                        } else {
                            onOk()
                        }
                    })
                } else {
                    onOk()
                }
            }
        }

        return <Modal keyboard={false} width={'96%'} align={null} zIndex={this.props.zIndex}
            title={this.props.title}
            visible={this.props.visible}
            closable={true}
            maskClosable={false}
            confirmLoading={this.props.workInProgress /*|| this.props.data.ongoingRequests > 0*/}
            cancelButtonProps={{ loading: this.props.workInProgress /*|| this.props.data.ongoingRequests > 0*/ }}
            footer={[
                <Button key="back" onClick={this.closeModal}>{this.getText(12451)}</Button>,
                // this.props.documentType == '5' || 
                this.props.documentType == '45051' || this.props.documentType == '40418' || this.props.documentNumber == 0 ? "" :
                    <Button key="draft" onClick={this.generateDraftDoc}>{this.getText(17507)}</Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={this.state.loading}
                    disabled={disabledSubmit}
                    onClick={onCreateDoc}>{this.getText(12450)}</Button>,
                this.props.documentNumber == 0 || ![1, 2, 3, 8, 9, 14, 22, 23, 30, 202, 203, 208, 222, 230].find(f => f == this.props.documentType) ? "" :
                    <Button
                        key="multi_cust"
                        disabled={disabledSubmit}
                        onClick={() => {
                            this.setState({
                                modalMultiCust: true,
                                modalMultiCustList: ""
                            })
                        }}>{this.getText(19605)}</Button>,
                this.props.documentNumber == 0 ? "" :
                    <Button key="templete" onClick={() => {
                        this.setState({
                            modalCreateTemplete: true,
                            modalCreateTempleteDesc: ""
                        })
                    }}>
                        {this.getText(18665)}
                    </Button>
            ]}
            onCancel={this.closeModal}
        >
            <Card
                style={{ width: '100%', /*float: 'right',*/ direction: 'ltr' }}
                title={<ContentTitle {...rest} test={this.state.activeTab}
                    activeTabKey={this.state.activeTab}
                    parseCentralDocJson={this.parseCentralDocJson}
                    getLegends={this.getLegends}
                    calculateRoundSum={this.calculateRoundSum}
                    sendAPI={this.sendAPI}
                    profitDetailsInDoc={this.state.GP_ProfitDetailsInDocBO == '1'}
                    getSettings={() => {
                        let res = []

                        if (this.props.documentType == '5' || this.props.documentType == '2005') {
                            res.push(<Radio.Group
                                style={{ marginRight: 10 }}
                                onChange={e => {
                                    this.setState({ PrintFormat: e.target.value })
                                }}
                                value={this.state.PrintFormat}
                            >
                                <Radio value={0}>{this.getText(18983)}</Radio><br />
                                <Radio value={8}>{this.getText(20520)}</Radio><br />
                                <Radio value={1}>{this.getText(18984)}</Radio><br />
                                <Radio value={6}>{this.getText(19360)}</Radio><br />
                                <Radio value={2}>{this.getText(18985)}</Radio><br />
                                <Radio value={3}>{this.getText(18986)}</Radio><br />
                                <Radio value={5}>{this.getText(19359)}</Radio><br />
                                <Radio value={7}>{this.getText(19361)}</Radio><br />
                                <Radio value={4}>{this.getText(19105)}</Radio>
                            </Radio.Group>)
                        }

                        if (this.props.documentType == '5051') {
                            res.push(<Radio.Group value={this.state.printType5051} onChange={e => { this.setState({ printType5051: e.target.value }) }}>
                                <Radio value={'1'}>{this.getText(16562)}</Radio><br />
                                <Radio value={'2'}>{this.getText(16563)}</Radio><br />
                                <Radio value={'0'}>{this.getText(16564)}</Radio>
                            </Radio.Group>)
                        }

                        if (issetMatrix > 0) {
                            res.push(this.getCheckBox("printCentralPerFatherItem", 17713, { marginRight: 10 }))
                        }

                        const { SendToSign } = this.props.data.document2?.custPrintData ?? {}

                        res.push(<span>
                            {this.getCheckBox("printOriginalDoc", 16565, { marginRight: 10 })}<br />
                            {this.state.printOriginalDoc ?
                                (<span>
                                    {this.getCheckBox("printOriginalDocType1", 18199, { marginRight: 30 })}<br />
                                    {this.getCheckBox("printOriginalDocType2", 18200, { marginRight: 30 })}<br />
                                    {this.getCheckBox("printOriginalDocType3", 18201, { marginRight: 30 })}<br />
                                    {SendToSign == '1' ?
                                        (<span>
                                            {this.getCheckBox("printOriginalDocType4", 19680, { marginRight: 30 })}<br />
                                            {this.getCheckBox("printOriginalDocType5", 19681, { marginRight: 30 })}<br />
                                        </span>)
                                        : ""}
                                </span>)
                                : ""}
                            {this.getCheckBox("printCopyDoc", 16566, { marginRight: 10 })}<br />
                            {this.state.printCopyDoc ?
                                (<span>
                                    {this.getCheckBox("printCopyDocType1", 19682, { marginRight: 30 })}<br />
                                    {this.getCheckBox("printCopyDocType2", 19683, { marginRight: 30 })}<br />
                                    {this.getCheckBox("printCopyDocType3", 19684, { marginRight: 30 })}<br />
                                </span>)
                                : ""}
                        </span>)

                        return res.map(x => (<div style={{ border: "1px solid black", borderRadius: 10, paddingTop: 3, marginTop: 3, direction: "rtl" }}>{x}</div>));
                    }}
                />}
                //extra={<a href="#">More</a>}
                tabList={tabList}
                defaultActiveTabKey={"tab1"}
                activeTabKey={this.state.activeTab}
                onTabChange={tabKey => {
                    const { supplierCode, supplierDocNum, employeeID } = this.props.data.document2;
                    let isSuppDoc = [4, 16, 17, 18, 20, 21, 45051, 40418].indexOf(parseInt(this.props.documentType, 10)) > -1;
                    let isSuppDocNumRequired = [4, 16, 17, 18, 45051, 40418].indexOf(parseInt(this.props.documentType, 10)) > -1;
                    let isMastCust = [2, 3, 5, 8, 9, 30, 2005, 202, 203, 208, 230].indexOf(parseInt(this.props.documentType, 10)) > -1;
                    let isMastCustName = [1, 22, 23, 222].indexOf(parseInt(this.props.documentType, 10)) > -1;
                    let suppIsset = this.props.data.Suppliers.find(f => f.ID == supplierCode);
                    let isMastCurrencyCode = [202, 203, 208, 215, 219, 222, 230].indexOf(parseInt(this.props.documentType, 10)) > -1;

                    if (issetPays && tabKey !== 'tab3') {
                        message.error(this.getText(51799));
                    } else if (tabKey !== 'tab1' && isMastCust && !parseInt(this.props.data.document2.customerCode)) {
                        message.error(this.getText(17318))
                    } else if (tabKey !== 'tab1' && isMastCustName && !this.props.data.document2.customerName) {
                        message.error(this.getText(17319))
                    } else if (tabKey !== 'tab1' && !supplierCode && (isSuppDoc || this.props.documentType == 15 || this.props.documentType == '215' || this.props.documentType == 53)) {
                        message.error(this.getText(16042));
                    } else if (tabKey !== 'tab1' && !suppIsset && (isSuppDoc || this.props.documentType == 15 || this.props.documentType == '215')) {
                        message.error(this.getText(16043))
                        this.props.dataActions.setJson("document2", { supplierCode: '', supplierName: '' })
                    } else if (tabKey !== 'tab1' && this.state.GP_DocEmployeeIsRequired == '1' && !employeeID) {
                        message.error(this.getText(19984))
                    } else if (tabKey !== 'tab1' && isMastCurrencyCode && !this.props.data.document2.currencyCode) {
                        message.error(this.getText(20159))
                    } else if (tabKey !== 'tab1' && isSuppDoc) {

                        // if (!this.state.isCostWithTaxChecked && this.props.documentType != 53) {
                        //     message.error(this.getText(16113))
                        // } else 
                        if (isSuppDocNumRequired && !supplierDocNum && this.state.GP_RequireSuppDocNum == '1') {
                            message.error(this.getText(16204))
                        } else {
                            this.setState({ activeTab: tabKey });
                            this.sendAPI('get_price_list_default', this.props.posId, (ob) => {
                                let priceListId = ob.data ? ob.data : this.props.data.document2.priceListId;
                                let dataSource = [...this.props.data.document2.dataSource];
                                if (dataSource.length && this.isSupplierChanged) {
                                    this.updatePricesPerSupplier(supplierCode, priceListId, dataSource, null, true);
                                } else {
                                    this.props.dataActions.setJson('document2', { priceListId, dataSource });
                                }
                            })

                        }
                    } else {
                        this.setState({ activeTab: tabKey });
                        if (tabKey === 'tab2') {
                            this.updateCustDiscount()

                            if (!this.props.data.document2.custPriceList) {
                                this.sendAPI('get_price_list_default', this.props.posId, (ob) => {
                                    if (ob.data) {
                                        this.props.dataActions.setJson('document2', { priceListId: ob.data })
                                    }
                                })
                            }
                        }
                    }
                }}>
                {contentList[this.state.activeTab]}
            </Card>


            <ModalMatrixQuantities
                //context={this.id} 
                //context_api_get={this.api.get} 
                mParentItemCode={(this.props.ui.data) ? this.props.ui.data.mCode : null /* this.state.mParentItemCode*/}
                id={TableIDs.modalMatrixQuantities}
                user={this.props.user}
                uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalMatrixQuantities)}
                title={this.getText(12452)}
                ActionQueue={this.props.ActionQueue}
                isRefreshParentTable={false}
                onAddMatrixItemsQuantities={(itemCode) => { this.onAddMultiLineItems_v2(itemCode); }}
                zIndex={2000}
            />

            <DocumentCreationItemsDiscrepancy
                {...this.props}
                sendAPI={this.sendAPI}
                afterUpdateCostPrices={this.afterUpdateCostPrices}
                modalItemsDiscrepancy={this.state.modalItemsDiscrepancy}
                modalItemsDiscrepancyData={this.state.modalItemsDiscrepancyData}
                markChangeOnCost={this.state.GP_MarkChangeOnCost == '1'}
                onCancel={() => {
                    this.setState({ modalItemsDiscrepancy: false, modalItemsDiscrepancyData: [], loading: false }, () => {
                        this.userAcceptsZeroPrices = false;
                        this.modalItemsDiscrepancyUse = false;
                        this.itemRoundCreated = false;
                        this.docIsCreated2 = false;
                        this.props.dataActions.setJson("document2", { dataSource: this.props.data.document2.dataSource.filter(f => f.itemCode != GP_FinalDiscount) })
                    })
                }}
            />

            <GenericModal
                visible={this.state.modalCreateItemRound}
                onCancel={() => { this.setState({ modalCreateItemRound: false }) }}
                width={300}
                title={this.getText(16328)}
                footer={[<Button type='primary' onClick={this.onSaveSumType4or18}>{this.getText(16330)}</Button>]}>
                <h2>{this.getText(16329)}</h2>
                <InputNumber
                    min={this.state.modalCreateItemRoundPositive ? 0 : -9999999.9999}
                    max={this.state.modalCreateItemRoundPositive ? 99999999.9999 : 0}
                    style={{ width: '100%', textAlign: 'right', direction: "ltr" }}
                    value={this.state.modalCreateItemRoundSumDoc}
                    onChange={e => {
                        if ((!this.state.modalCreateItemRoundPositive && e > 0) || (this.state.modalCreateItemRoundPositive && e < 0)) {
                            message.error(this.getText(19831))
                        } else {
                            this.setState({ modalCreateItemRoundSumDoc: e })
                        }
                    }}
                />
            </GenericModal>

            <GenericModal
                visible={this.state.modalNaNSum}
                onCancel={() => { this.setState({ modalNaNSum: null }) }}
                title={this.getText(17935)}
                width={1000}
            >
                {this.state.modalNaNSum ?
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalNaNSum.dataSource.filter(f => !parseFloat(f.unitPriceWithTax) || !parseFloat(f.unitPriceNoTax) || !parseFloat(f.quantity))}
                        columns={[
                            { title: this.removeNaNSum("all"), width: "5%", render: (t, r) => this.removeNaNSum(r.itemCode) },
                            { title: this.getText(17936), key: "itemCode", dataIndex: "itemCode", width: "15%", render: t => t },
                            { title: this.getText(17937), key: "description", dataIndex: "description", width: "35%", render: t => t },
                            { title: this.getText(17938), key: "unitPriceNoTax", dataIndex: "unitPriceNoTax", width: "15%", render: this.renderNaNSum },
                            { title: this.getText(17939), key: "unitPriceWithTax", dataIndex: "unitPriceWithTax", width: "15%", render: this.renderNaNSum },
                            { title: this.getText(17940), key: "quantity", dataIndex: "quantity", width: "15%", render: this.renderNaNSum },
                        ]} />
                    : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalCreateItemsMulti && !this.state.modalNaNSum}
                onCancel={() => { this.setState({ modalCreateItemsMulti: null }) }}
                title={this.getText(17703)}
                width={1000}
                footer={[
                    <Button
                        type='primary'
                        onClick={() => { this.createItemsMulti('modalCreateItemsMulti') }}
                        disabled={!this.state.modalCreateItemsMulti || !this.state.modalCreateItemsMulti.dataSource.filter(f => f.isChecked).length}
                    >{this.getText(17704)}</Button>,
                    <Button onClick={() => { this.setState({ modalCreateItemsMulti: null }) }}>{this.getText(17705)}</Button>,
                ]}>
                {this.state.modalCreateItemsMulti ? [
                    <Row>
                        <Col span={12}>
                            <Form.Item label={this.getText(17780)} style={{ maxWidth: 300 }}>
                                <GenericSelector
                                    {...this.props}
                                    multi
                                    id={TableIDs.genericSelectorPriceList}
                                    value={this.state.modalCreateItemsMulti.priceListId ? this.state.modalCreateItemsMulti.priceListId.split(',') : []}
                                    onChange={e => { this.setState({ modalCreateItemsMulti: { ...this.state.modalCreateItemsMulti, priceListId: e ? e.join(',') : "" } }) }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <p style={{ border: "1px solid black", width: 250, fontWeight: "bold", padding: 5, textAlign: "center" }}>{this.getText(20299)}<br />{this.getText(20301)}</p>
                        </Col>
                    </Row>,
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalCreateItemsMulti.dataSource.filter(f => f.isValid == 0 && f.itemCode)}
                        columns={this.getModalItemsMultiColumns('modalCreateItemsMulti')}
                    />
                ] : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalUpdateItemsMulti && !this.state.modalCreateItemsMulti && !this.state.modalNaNSum}
                onCancel={() => { this.setState({ modalUpdateItemsMulti: null }) }}
                title={this.getText(17778)}
                width={1000}
                footer={[
                    <Button
                        type='primary'
                        onClick={() => { this.createItemsMulti('modalUpdateItemsMulti') }}
                        disabled={!this.state.modalUpdateItemsMulti || !this.state.modalUpdateItemsMulti.dataSource.filter(f => f.isChecked).length}
                    >{this.getText(17779)}</Button>,
                    <Button onClick={() => { this.setState({ modalUpdateItemsMulti: null }) }}>{this.getText(17705)}</Button>,
                ]}>
                {this.state.modalUpdateItemsMulti ? [
                    <Row>
                        <Col span={12}>
                            <Form.Item label={this.getText(17780)} style={{ maxWidth: 300 }}>
                                <GenericSelector
                                    {...this.props}
                                    multi
                                    id={TableIDs.genericSelectorPriceList}
                                    value={this.state.modalUpdateItemsMulti.priceListId ? this.state.modalUpdateItemsMulti.priceListId.split(',') : []}
                                    onChange={e => { this.setState({ modalUpdateItemsMulti: { ...this.state.modalUpdateItemsMulti, priceListId: e ? e.join(',') : "" } }) }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <p style={{ border: "1px solid black", width: 250, fontWeight: "bold", padding: 5, textAlign: "center" }}>{this.getText(20299)}<br />{this.getText(20300)}</p>
                        </Col>
                    </Row>,
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalUpdateItemsMulti.dataSource.filter(f => f.isValid > 0 && f.isChangeCostOrPrice && !f.isBonusRow)}
                        columns={this.getModalItemsMultiColumns('modalUpdateItemsMulti')}
                    />
                ] : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalCreateTemplete}
                onCancel={() => { this.setState({ modalCreateTemplete: false }) }}
                title={this.getText(18666)}
                width={300}
                footer={[
                    <Button key={"SaveTemplete"} type="primary" onClick={this.saveTemplete}>{this.getText(18668)}</Button>,
                    <Button key={"CancelSaveTemplete"} onClick={() => { this.setState({ modalCreateTemplete: false }) }}>{this.getText(18669)}</Button>,
                ]}>
                <Form.Item label={this.getText(18667)}>
                    <Input
                        value={this.state.modalCreateTempleteDesc}
                        onChange={e => { this.setState({ modalCreateTempleteDesc: e.target.value }) }}
                    />
                </Form.Item>
            </GenericModal>

            <GenericModal
                visible={this.state.modalMultiCust}
                onCancel={() => { this.setState({ modalMultiCust: false }) }}
                title={this.getText(19606)}
                width={500}
                footer={[
                    <Button key={"SaveMultiCust"} type="primary" onClick={() => { this.setState({ modalMultiCust: false }, onCreateDoc) }}>{this.getText(19608)}</Button>,
                    <Button key={"CancelMultiCust"} onClick={() => { this.setState({ modalMultiCust: false }) }}>{this.getText(19609)}</Button>,
                ]}>
                <Form.Item label={this.getText(19607)}>
                    <GenericSelectorCustomers
                        {...this.props}
                        multi
                        onChange={(value) => { this.setState({ modalMultiCustList: value?.join(',') ?? "" }) }}
                        value={this.state.modalMultiCustList ? this.state.modalMultiCustList.split(",") : []}
                    />
                </Form.Item>
            </GenericModal>

            <ModalProgress
                {...this.props}
                // id={TableIDs.modalProgress}
                toggle={this.state.modalMultiCustCreate?.length}
                title={this.getText(19612)}
                handleCancel={() => {
                    this.setState({ modalMultiCustCreate: [] })
                }}
                handleRefrash={() => { }}
                handleIgnore={() => { }}
                percent={Math.ceil(((this.state.modalMultiCustLength - this.state.modalMultiCustCreate?.length + 1) / this.state.modalMultiCustLength) * 100)}
                status={"active"}
                message={this.getText(19610) + " " + (this.state.modalMultiCustLength - this.state.modalMultiCustCreate?.length + 1) + " " + this.getText(19611) + " " + this.state.modalMultiCustLength}
                type={"exception"}
            />

            <Modal
                visible={this.state.loadDocumentNumber || this.state.loadCostTax}
                width={300}
                footer={false}>
                <Spin />
                <br />
                {this.getText(17562)}
            </Modal>

        </Modal >
    }

}
export default React.memo(DocumentCreationModal);


/** #content doc title-summary */
class ContentTitle extends Component<ContentTitleProps, State> {
    constructor(props: ContentTitleProps) {
        super(props);
    }
    /*
    customerCode
    customerName
    customerAddress
    */

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev) {
        let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051, 40418].filter(f => this.props.documentType == f).length > 0;
        if (isSuppDoc) {
            let supplierCode = Jax.get(this.props.data, "document2.supplierCode", "");
            let prevSupplierCode = Jax.get(prev.data, "document2.supplierCode", "");
            if (supplierCode !== prevSupplierCode) {
                this.props.sendAPI("get_supplier_balances", "_supp\r" + supplierCode, (ob) => {
                    this.props.dataActions.setJson("document2", { supplierCredit: ob.data })
                })
            }
        }
    }

    formatFloat(number: number) {
        return number ?
            number.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : "0.00"
        // if (number) {
        //     return parseFloat(Math.round(number * 10000) / 10000).toFixed(2);
        // }
    }

    getDocumentTotalWithTax() {
        if (this.props.documentType == '3' || this.props.documentType == '23' || this.props.documentType == '53' || this.props.documentType == '203') {
            let payList = Jax.get(this.props.data, "documentPayments.data", []);
            let res = payList && payList.length ? payList.reduce(function (a, v) {
                if (v.key === 0) {
                    return a;
                }
                return a + parseFloat((v.defaultCurrencySum) ? v.defaultCurrencySum : 0.0);
            }, 0) : 0.0;
            return
        } else {
            let itemList = Jax.get(this.props.data, "document2.dataSource", []);
            return itemList.reduce(function (a, v) {
                if (v.key === 0) {
                    return a;
                }
                return a + parseFloat((v.totalSumWithTax) ? v.totalSumWithTax : 0.0);
            }, 0) || 0.0;
        }
    }

    getDocumentTotalNoTax() {
        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
        return itemList.reduce(function (a, v) {
            if (v.key === 0) {
                return a;
            }
            return a + parseFloat((v.totalSumNoTax) ? v.totalSumNoTax : 0.0);
        }, 0) || 0.0;
    }

    getDetailSummary() {
        let customerCode = Jax.get(this.props.data, "document2.customerCode", 0);
        let customerName = Jax.get(this.props.data, "document2.customerName", "");
        let supplierCode = Jax.get(this.props.data, "document2.supplierCode", 0);
        let supplierName = Jax.get(this.props.data, "document2.supplierName", "");
        let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051, 40418].filter(f => this.props.documentType == f).length > 0
        let code = isSuppDoc ? supplierCode : customerCode;
        let name = isSuppDoc ? supplierName : customerName;
        let address = Jax.get(this.props.data, "document2.customerAddress", "");

        // let cust_code = parseInt(customerCode.toString(), 10);
        // if (isNaN(cust_code)) {
        //     cust_code = 0;
        // }

        //if (cust_code > 0 ){
        if (code > 0) {
            return <span dir="rtl">{code} - {name}<br />{address}</span>
        } else {
            return "";
        }
    }

    getCreditSummary() {
        let customerCode = Jax.get(this.props.data, "document2.customerCode", 0);
        let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051, 40418].filter(f => this.props.documentType == f).length > 0

        if (customerCode > 0) {
            let customerCredit = Jax.get(this.props.data, "document2." + (isSuppDoc ? "supplierCredit" : "customerCredit"), 0);
            let customerCreditFormat = (Math.round(parseFloat(customerCredit ? customerCredit : 0) * 100) / 100).toFixed(2);
            let style = { color: customerCreditFormat < 0 ? 'red' : 'blue', display: 'inline-block', verticalAlign: "text-top" }
            return (<span>
                <div style={{ ...style, width: 150 }}>{customerCreditFormat}</div>
                <div style={{ ...style, width: 100 }}> :{this.getText(isSuppDoc ? 18907 : 51800)}</div>
            </span>);
        } else {
            return "";
        }
    }

    getObligoSummary() {
        let customerCode = Jax.get(this.props.data, "document2.customerCode", 0);
        if (customerCode > 0 && ![4, 18, 53, 45051, 40418].filter(f => f == this.props.documentType).length) {
            let customerObligo = Jax.get(this.props.data, "document2.customerObligo", 0);
            let customerObligoFormat = (Math.round(parseFloat(customerObligo ? customerObligo : 0) * 100) / 100).toFixed(2);
            let style = { color: customerObligoFormat < 0 ? 'red' : 'blue', display: 'inline-block', verticalAlign: "text-top" }
            return (<span>
                <div style={{ ...style, width: 150 }}>{customerObligoFormat}</div>
                <div style={{ ...style, width: 100 }}> :{this.getText(16426)}</div>
            </span>);
        } else {
            return "";
        }
    }

    getLinesSummary() {
        //console.log(this.props.data.datasetObj);
        //console.log(this.props.data.documentTypeId);
        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
        let count = itemList.length;
        //console.log(this.props.data.count);
        let totalSumNoTax = this.getDocumentTotalNoTax() || 0.0;
        let totalSum = this.getDocumentTotalWithTax() || 0.0;
        let totalTax = totalSum - totalSumNoTax;
        let totalQuantity = itemList.reduce(function (a, v) {
            if (v.key === 0) { return a; }
            return a + parseFloat(v.quantity);
        }, 0.0) || 0.0;

        if ([5, 45051, 40418].filter(f => this.props.documentType == f).length > 0) {
            let parseData = this.props.parseCentralDocJson();

            if (parseData.length) {
                let docCount = parseData.length;
                let docSum = 0;
                parseData.forEach(x => { if (x.DocSum) { docSum += parseFloat(x.DocSum); } })
                let docSumFormat = docSum ? Math.round(docSum * 10000) / 10000 : 0.0000;
                return this.getText(15430) + ": " + docCount + ", " + this.getText(15437) + ": " + docSumFormat
            }

            return this.getText(this.props.documentType == '45051' ? 17056 : 15429);

        } else if (count === 0) {
            return (this.getText(12463));
        } else {
            return (<div style={{ direction: "rtl", lineHeight: 0.5 }}>
                <div style={{ display: "inline-block", paddingLeft: 20 }}>
                    <p>{this.getText(count === 1 ? 12464 : 12467)}</p>
                    <p>{this.getText(12465)}:</p>
                    <p><b>{this.getText(51830)}:</b></p>
                    <p><b>{this.getText(18711)}:</b></p>
                    <p><b>{this.getText(12466)}:</b></p>
                    {this.props.documentType == '1' ? <p>{this.getText(17451)}:</p> : ""}
                </div>
                <div style={{ display: "inline-block" }}>
                    <p>{count === 1 ? " " : count}</p>
                    <p>{this.formatFloat(totalQuantity)}</p>
                    <p><b>{this.formatFloat(totalSumNoTax)}</b></p>
                    <p><b>{this.formatFloat(totalTax)}</b></p>
                    <p><b>{this.formatFloat(totalSum)}</b></p>
                    {this.props.documentType == '1' ? this.formatFloat(this.props.calculateRoundSum(totalSum).totalWithTax) : ""}
                </div>

            </div>)
        }
    }

    getProfitSum = () => {
        let itemList = Jax.get(this.props.data, "document2.dataSource", []);
        let documentTypeList = Jax.get(this.props.data, "DocTypesCategories", []);
        let dataDoc = documentTypeList.find(f => f.id == this.props.documentType);
        let isSaleDoc = dataDoc && dataDoc.category == 1;

        if (isSaleDoc && itemList.length) {
            let _sumCost = 0;
            let _sumSale = 0;

            const sumFormat = (sum) => parseFloat(sum) ? Math.round(sum * 100) / 100 : 0;

            itemList.forEach(i => {
                if (i.cost * i.quantity) _sumCost += sumFormat(i.cost * i.quantity);
                if (i.totalSumNoTax) _sumSale += sumFormat(i.totalSumNoTax);
            });

            let _sumProfit = sumFormat(_sumSale - _sumCost);
            let _percentProfitUp = _sumSale ? sumFormat(_sumProfit / _sumSale * 100) : 0;
            let _percentProfitDown = _sumCost ? sumFormat(_sumProfit / _sumCost * 100) : 0;

            // return this.getText(17191) + ": " + _sumProfit + "₪, " + this.getText(17192) + ": " + _percentProfitUp + "%, " + this.getText(17193) + ": " + _percentProfitDown + "%";
            return (<Row style={{ direction: "rtl" }}>
                <Col span={14}>{this.getText(17191)}:</Col>
                <Col span={10} style={{ direction: "ltr" }}>₪ {this.formatFloat(_sumProfit)}</Col>
                <Col span={14}>{this.getText(17192)}:</Col>
                <Col span={10} style={{ direction: "ltr" }}>% {this.formatFloat(_percentProfitUp)}</Col>
                <Col span={14}>{this.getText(17193)}:</Col>
                <Col span={10} style={{ direction: "ltr" }}>% {this.formatFloat(_percentProfitDown)}</Col>
            </Row>)
        }
    }

    render() {


        const boxStyle = {
            border: "1px solid black",
            borderRadius: 10,
        }
        //let customerCredit = Jax.get(this.props.data, "document2.customerCredit", "");
        //let customerCreditStyle = { color: !customerCredit || parseFloat(customerCredit) < 0 ? 'red' : 'blue' }
        let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051, 40418].filter(f => this.props.documentType == f).length > 0

        return (<Row>
            <Col span={window.innerWidth > 600 ? 5 : 24}>
                <div style={{ fontWight: "bold", fontSize: "1.3em" }}>{this.getText(isSuppDoc ? 18908 : 18360)}</div>
                <div>{this.getDetailSummary()}</div>
                <div>{this.getCreditSummary()}</div>
                <div>{this.getObligoSummary()}</div>
            </Col>
            <Col span={window.innerWidth > 600 ? 5 : 24}>
                <div style={{ fontWight: "bold", fontSize: "1.3em" }}>{this.getText(12468)}</div>
                <div>{this.getLinesSummary()}</div>
            </Col>
            <Col span={window.innerWidth > 600 ? 5 : 24}>
                {this.props.profitDetailsInDoc ? [
                    <div style={{ fontWight: "bold", fontSize: "1.3em" }}>{this.getText(18361)}</div>,
                    <div>{this.getProfitSum()}</div>
                ] : ""}
            </Col>
            <Col span={window.innerWidth > 600 ? 5 : 24}>
                <div style={{ fontWight: "bold", fontSize: "1.3em" }}>{this.getText(18637)}</div>
                {this.props.getSettings()}
            </Col>
            <Col span={window.innerWidth > 600 ? 4 : 24} style={{ display: this.props.activeTabKey == 'tab2' ? 'block' : 'none' }}>
                {this.props.getLegends()}
            </Col>

            {/* {this.props.test} */}

        </Row>)
    }

}

/** #content doc meta */
class ContentDoc extends Component<ContentDocProps, State> {
    constructor(props: ContentDocProps) {
        super(props);
        this.state = {
            modalCurrencyRates: false,
            calcCreditDaysPop: false,
            modalFixRemark: false,
            modalFixRemarksSearch: null,

            modalSearchCustPerDoc: false,
            modalSearchCustPerDocInput: null,
            modalSearchCustPerDocRes: null,
        }
        this.filterConnectedCust = new Set();

        this.id_rates = "LAST_CURRENCY_RATES";
        this.id_fixremarks = "FIX_REMARKS_LIST"

        if (props.data[this.id_rates] === undefined) props.data[this.id_rates] = { ...props.data.genericData }
        if (props.data[this.id_fixremarks] === undefined) props.data[this.id_fixremarks] = { ...props.data.genericSelector }
        if (props.data.CURRENCY_SELECTOR === undefined) props.data.CURRENCY_SELECTOR = { ...props.data.genericSelector };

        this.typingCustId = false;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.apiConnectedCustomers()
        this.props.dataActions.genericSelectorRefreshDataset(
            'CURRENCY_SELECTOR', this.props.user.companyCode, this.props.user.token, 'get_currency_list');

        setTimeout(() => {
            const { dataset } = this.props.data.CURRENCY_SELECTOR
            if (dataset.length == 1) {
                let currencyCode = dataset[0].code;
                let currencyData = this.props.data[this.id_rates].data.map(x => x.split('\f')).find(f => f[0] == currencyCode)
                let currencyRate = currencyData ? currencyData[2] : null;
                let currencySymbol = currencyData ? currencyData[4] : null;
                this.props.dataActions.setJson("document2", { currencyCode, currencyRate, currencySymbol })
            }
        }, 3000);

    }

    componentDidUpdate(p) {

        const { data, documentType } = this.props;
        const { customerCode, customerCodeLoad } = data.document2;
        if (customerCode && p.data.document2.customerCode !== customerCode && customerCode !== customerCodeLoad && !this.typingCustId) {

            // if ([1, 2, 3, 5, 8, 9, 22, 23, 30, 2005, 202, 203, 208, 222, 230].find(f => f == documentType)) {

            this.updateCustData(customerCode)
            // }
            if (documentType == 2 || documentType == 5 || documentType == 22 || documentType == 2005 || documentType == 202 || documentType == 222) {
                this.props.sendAPI("get_cust_credit_days", customerCode, (ob) => {
                    let d = ob.data ? ob.data.split("\f") : [null, null];
                    this.calcCustCreditDays(d[0], d[1]);
                })
            }
        }


    }

    updateSupplierAddress() {
        //let customerCode = parseInt(Jax.get(this.props.data, "document.editedRecord.customerCode", -1), 10);
        let customerCode = parseInt(Jax.get(this.props.data, "document2.supplierCode", -1), 10);
        let record = Jax.findFirst(this.props.data, "Suppliers", function (r) {
            return parseInt(r.ID) === parseInt(customerCode);
        });


        if (record) {
            this.props.dataActions.setJson("document2", {
                customerName: record.Name,
                customerAddress: record.ContactPersonAddress,
                customerPhone: record.ContactPersonPhone.length > 0 ? record.ContactPersonPhone : record.ContactPersonMobile,
                customerZipCode: "",
            })

            if (this.props.setTargets) {
                this.props.setTargets(record.ContactPersonMobile)
            }
        } else {
            this.props.dataActions.setJson("document2", {
                customerName: "",
                customerAddress: "",
                customerPhone: "",
                customerZipCode: "",
            })
        }
    }


    updateCustData = (customerCode) => {

        let documentType = this.props.documentType == '5051' ? '50' : this.props.documentType;
        let documentPos = this.props.posId;
        let customerName = "";
        let customerAddress = "";
        let customerZipCode = "";
        let customerPhone = "";
        let custPriceList = null;
        let priceListId = this.props.data.document2.priceListId;

        let isSuppDoc = [4, 15, 16, 17, 18, 20, 21, 53, 215].find(f => f == documentType)

        if (isSuppDoc) {

            let record = Jax.findFirst(this.props.data, "Suppliers", function (r) {
                return parseInt(r.ID) === parseInt(customerCode);
            });


            if (record) {
                customerName = record.Name
                customerAddress = record.ContactPersonAddress
                customerPhone = record.ContactPersonPhone.length > 0 ? record.ContactPersonPhone : record.ContactPersonMobile

                if (this.props.setTargets) {
                    this.props.setTargets(record.ContactPersonMobile)
                }
            }

        } else {

            let customerRecord = Jax.findFirst(this.props.data, "Customers", function (r) {
                return r.id == customerCode;
            });

            if (customerRecord) {
                customerName = customerRecord.contactPerson
                customerAddress = customerRecord.address
                customerZipCode = customerRecord.zipCode
                customerPhone = customerRecord.phone
                custPriceList = customerRecord.priceList
            }

            if (custPriceList) priceListId = custPriceList.code

            if (this.props.printFixCustFields) {
                console.log("GP_PrintFixCustFields")
                this.props.sendAPI("customer_fields_to_print", "custID\r" + customerCode, ob => {
                    let data = ob.data ? ob.data.split("\r") : []
                    let remarks = data.map(x => {
                        let y = x.split("\f")
                        return y[1] + ": " + y[2]
                    }).join(",")
                    this.props.dataActions.setJson("document2", { remarks })
                })
            }
        }


        this.props.sendAPI("get_customer_credit_balance_bo", customerCode, (ob) => {
            this.props.sendAPI("get_print_data_new_doc", "custID\fdocType\fdocPos\r" + customerCode + "\f" + documentType + "\f" + documentPos, (ob2) => {
                let res = ob.data ? ob.data.split('\f') : [];
                let res2 = ob2.data ? ob2.data.split('\f') : [];
                let customerCredit = res[2];
                let customerObligo = res[3];

                let custPrintData = {
                    defaultPrintType: res2[0],
                    targetMobile: res2[1],
                    targetEmail: res2[2],
                    SendToSign: res2[3],
                    questionnaireId: res2[4],
                    questionnaireName: res2[5],
                }

                if (!isSuppDoc) {

                    let newState = {
                        printOriginalDoc: false,
                        printOriginalDocType1: false,
                        printOriginalDocType2: false,
                        printOriginalDocType3: false,
                        printOriginalDocType4: false,
                        printOriginalDocType5: false,
                    }

                    if ([1, 2, 3].find(f => f == custPrintData.defaultPrintType)) newState.printOriginalDoc = true;
                    if (custPrintData.defaultPrintType == '1') newState.printOriginalDocType1 = true;
                    if (custPrintData.defaultPrintType == '2') newState.printOriginalDocType2 = true;
                    if (custPrintData.defaultPrintType == '3') newState.printOriginalDocType3 = true;
                    if (custPrintData.defaultPrintType == '2' && custPrintData.SendToSign == '1') newState.printOriginalDocType4 = true;
                    if (custPrintData.defaultPrintType == '3' && custPrintData.SendToSign == '1') newState.printOriginalDocType5 = true;

                    this.props.setRootState(newState)
                }

                let dataToUpdate = {
                    customerName,
                    customerAddress,
                    customerZipCode,
                    customerPhone,
                    customerCredit,
                    customerObligo,
                    custPriceList,
                    priceListId,
                    custPrintData
                }

                this.props.dataActions.setJson("document2", dataToUpdate)

                if (this.props.setTargets) this.props.setTargets(customerPhone)


            })
        })



        if (this.props.documentType == 5 || this.props.documentType == 2005) {
            this.props.sendAPI("get_customer_sum_doc_print_format", customerCode, (ob) => {
                this.props.setRootState({ PrintFormat: ob.data ? parseInt(ob.data) : 1 })
            })
        }

    }

    calcCustCreditDays = (_customerCreditDays, _customerIsEndOfMonth) => {
        const { CustomerCreditDays, CustomerIsEndOfMonth, docDateTime } = this.props.data.document2;

        let creditDays = _customerCreditDays ? _customerCreditDays : CustomerCreditDays;
        let isEndOfMonth = _customerIsEndOfMonth ? _customerIsEndOfMonth : CustomerIsEndOfMonth;
        let docValDate = moment(docDateTime).format("YYYY-MM-DD");

        if (docValDate && isEndOfMonth == 1) {
            docValDate = moment(docValDate).add(1, "month").startOf("month").format("YYYY-MM-DD");
        }

        if (docValDate && creditDays > 0) {
            docValDate = moment(docValDate).add(parseInt(creditDays), "days").format("YYYY-MM-DD");
        }

        this.props.dataActions.setJson("document2", { CustomerCreditDays: creditDays, CustomerIsEndOfMonth: isEndOfMonth, docValDate });
    }

    onBlurSupplierDocNum = (supplierDocNum) => {

        let uniqSuppDocNum = this.props.uniqSuppDocNum;
        let _supp = Jax.get(this.props.data, "document2.supplierCode", "");
        let documentType = this.props.documentType == 45051 ? 4 : this.props.documentType == 40418 ? 16 : this.props.documentType;
        let tempNum = this.props.documentNumber;

        if (supplierDocNum && uniqSuppDocNum && tempNum && tempNum != '0' && _supp && !this.docIsCreated) {
            let dataSend = '_supp_num\f_type\f_temp_num\f_supp\r' + supplierDocNum + '\f' + documentType + '\f' + tempNum + '\f' + _supp;

            this.props.sendAPI('check_if_exists_supp_doc_num', dataSend, (ob) => {
                if (parseInt(ob.data)) {
                    this.props.dataActions.setJson('document2', { supplierDocNum: '' })
                    message.error(this.getText(16526))
                }
            })
        }
    }

    getStyleRowSpacingInput = (text, field, type, required, tabIndex, maxLength, text2, field2, maxWidth) => {
        let value = Jax.get(this.props.data, 'document2.' + field, "");
        let value2 = Jax.get(this.props.data, 'document2.' + field2, "");
        let xmlSplit = value && type === 1 ? value.split('>') : [];
        let xmlValue = '';
        if (xmlSplit.length > 1) {
            value = xmlSplit.splice(-1)[0];
            xmlValue = xmlSplit.join('>') + '>';
        }
        const checkLength = (t, l) => !(l && t && t.length > l)

        let onChange = (e) => {
            if (maxLength && typeof maxLength == "object") {
                let valid = true
                e.split("\n").forEach((x, i) => { if (!checkLength(x, maxLength[i]) || !maxLength[i]) valid = false })
                if (valid) this.props.dataActions.setJson('document2', { [field]: e })
            } else {
                if (checkLength(e, maxLength)) this.props.dataActions.setJson('document2', { [field]: e })
                else message.error(this.getText(19013) + maxLength + " " + this.getText(19014))
            }
        }
        let onChangeNum = (e) => { if (RegExp(/^[0-9]+$/).test(e)) onChange(e) }
        let onBlur = field === 'supplierDocNum' ? e => { this.onBlurSupplierDocNum(e.target.value) } : undefined

        console.log("allocationType? - getStyleRow ", type, field, field2)

        switch (type) {
            case 1: return this.getStyleRow(text, (<TextArea tabIndex={tabIndex} style={{ textAlign: "right", maxWidth }} value={value} onChange={(e) => { onChange(xmlValue + e.target.value) }} />), required);
            case 2: return this.getStyleRow(text, (<Input tabIndex={tabIndex} style={{ textAlign: "right", maxWidth }} value={value} onChange={(e) => { onChangeNum(e.target.value) }} onBlur={onBlur} />), required);
            case 3: return this.getStyleRow(text, (<DateTimePicker tabIndex={tabIndex} format="DD/MM/YYYY" value={value} onChange={onChange} style={{ direction: 'rtl' }} />), required);
            case 4: return this.getStyleRow(text, (<Input tabIndex={tabIndex} style={{ textAlign: "right" }} value={value} onChange={(e) => { onChange(e.target.value) }} onBlur={onBlur} />), required,
                text2, (<Input tabIndex={tabIndex} style={{ textAlign: "right" }} value={value2} onChange={(e) => { this.props.dataActions.setJson('document2', { [field2]: e.target.value }) }} />));
            default: return this.getStyleRow(text, (<Input tabIndex={tabIndex} style={{ textAlign: "right", maxWidth }} value={value} onChange={(e) => { onChange(e.target.value) }} />), required);
        }

    }

    getStyleRow = (text, content, required, text2, content2) => {
        let redStern = required ? (<span style={{ color: 'red' }}>* </span>) : ""
        return (<Row style={{ padding: 4 }}>
            <Col span={4}>{redStern}{this.getText(text)}</Col>
            <Col span={text2 ? 8 : 20}>{content}</Col>
            <Col span={text2 ? 4 : 0} style={{ paddingLeft: 10, textAlign: 'left' }}>{this.getText(text2)}</Col>
            <Col span={text2 ? 8 : 0}>{content2}</Col>
        </Row>)
    }

    apiConnectedCustomers = () => {
        this.props.sendAPI("get_connected_customers_list", "", (ob) => {
            if (ob.data) {
                ob.data.split("\r").forEach(r => {
                    let f = r.split("\f");
                    this.filterConnectedCust.add(parseInt(f[0]))
                    this.filterConnectedCust.add(parseInt(f[1]))
                })
            }
        })
    }
    renderCheckedRemark = (text, record) => {
        return (
            <Button onClick={() => {
                let remarks = this.props.data.document2.remarks
                if (remarks) remarks += "\n"
                else remarks = ''

                remarks += record.content

                this.props.dataActions.setJson("document2", { remarks })
                this.setState({ modalFixRemark: false, modalFixRemarksSearch: null })

            }}>
                {this.getText(19243)}
            </Button>
        )
    }



    render() {

        const style_row_spacing = { padding: 4 };

        let isSuppDoc = [4, 16, 17, 18, 20, 21, 31, 53, 45051, 40418].indexOf(parseInt(this.props.documentType, 10)) > -1;
        let isSuppDocNumRequired = [4, 16, 17, 18, 45051, 40418].indexOf(parseInt(this.props.documentType, 10)) > -1;

        let customersDataToSelector = this.props.data.Customers ?
            this.props.data.Customers
                .filter(f => this.props.documentType != '5' || !this.filterConnectedCust.has(f.id))
                .map(v => v.id + "\f" + v.firstName + "\f" + v.lastName + "\f" + v.contactPerson + "\f" + v.phone + "\f" + v.creditBalance + "\f" + v.ObligoBalance)
            : [];

        const colStyle = {
            display: "inline-block",
            verticalAlign: "text-top",
            width: window.innerWidth > 600 ? "48%" : "98%",
            padding: "1%",
        }


        return (<div dir="rtl">
            <div style={colStyle}>
                {isSuppDoc || parseInt(this.props.documentType, 10) === 15 || parseInt(this.props.documentType, 10) === 215 ? (
                    <div style={{ direction: 'ltr' }}>
                        {isSuppDoc && this.props.documentType != 53 ?
                            this.getStyleRow(16110, [
                                <Checkbox
                                    checked={this.props.isCostNoTax}
                                    onChange={e => {
                                        this.props.onChangeState({
                                            isCostNoTax: e.target.checked,
                                            isCostWithTax: !e.target.checked || this.props.isCostWithTax,
                                        })
                                    }}>{this.getText(16112)}</Checkbox>,
                                <Checkbox
                                    checked={this.props.isCostWithTax}
                                    onChange={e => {
                                        this.props.onChangeState({
                                            isCostNoTax: !e.target.checked || this.props.isCostNoTax,
                                            isCostWithTax: e.target.checked,
                                        })
                                    }}>{this.getText(16111)}</Checkbox>
                            ]

                                , true)
                            : ""}

                        {this.getStyleRow(12469,
                            <Row>
                                <Col span={this.props.documentType == 53 ? 2 : 0}>
                                    <Tooltip title={this.getText(19296)}>
                                        <Button key="search_cust_in_doc" className={"btn"}
                                            style={{ height: 40 }}
                                            onClick={() => { this.setState({ modalSearchCustPerDoc: true }) }}>
                                            <Icon style={{ fontSize: 16 }} type="search" theme="outlined" />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={this.props.documentType == 53 ? 22 : 24}>
                                    <DataTypeOrPick
                                        id={"supplier_picker"}
                                        searchId={"search_supplier_picker"}
                                        // doFocus={true}
                                        tabIndex={"5"}
                                        defaultDataId={Jax.get(this.props.data, "document2.supplierCode", "")}
                                        placeholder={this.getText(12470)}
                                        value={{
                                            data: Jax.get(this.props.data, "document2.supplierCode", ""),
                                            dataName: Jax.get(this.props.data, "document2.supplierName", "")
                                        }}
                                        onChange={(selection) => {
                                            this.props.onChangeSupplier(selection.data)
                                            this.props.dataActions.setJson("document2", {
                                                supplierCode: selection.data,
                                                supplierName: selection.dataName,
                                                customerCode: selection.data,
                                                customerName: selection.dataName,
                                            });
                                            this.updateSupplierAddress();
                                            this.onBlurSupplierDocNum(Jax.get(this.props.data, 'document2.supplierDocNum', ""))
                                        }}
                                        showDialog={(defaultValue) => {
                                            let state = Jax.set(this.state, "isSupplierModalShown", true);
                                            this.setState(state);
                                        }}
                                        hideDialog={() => {
                                            this.setState(Jax.set(this.state, "isSupplierModalShown", false));
                                        }}
                                        data={Jax.map(this.props.data, "Suppliers", function (v) { return v.ID + "\f" + v.Name; })} />
                                </Col>
                            </Row>
                            , true)}

                        {this.getStyleRowSpacingInput(12471, 'supplierName', 0, 0, '6', 150)}
                        {isSuppDocNumRequired ?
                            this.getStyleRowSpacingInput(16199, 'supplierDocNum', this.props.data.document2.allocationType == 3 ? 4 : 2, this.props.requireSuppDocNum, '7', null, 19575, "reservedNumber") :
                            ""}
                        {/* {this.props.documentType == 4 || this.props.documentType == 18 ? this.getStyleRowSpacingInput(16200, 'supplierDocDate', 3, 0, '8') : ""} */}
                    </div>)
                    : (parseInt(this.props.documentType, 10) === 11) ? <div />
                        : (<div>
                            <Row style={{ ...style_row_spacing, direction: 'ltr' }}>
                                <Col span={4}>{this.getText(12472)}</Col>
                                <Col span={20}>
                                    <Row style={style_row_spacing}>
                                        <Col span={4}>
                                            <Tooltip title={this.getText(12473)}>
                                                <Button key="create_cust" className={"btn"} /*disabled={(this.props.data[this.id_directdebit].editing > -1)}*/
                                                    style={{ height: 40 }}
                                                    //style={{ marginTop: "5px", marginLeft: "5px" }}
                                                    onClick={(e) => { this.props.uiActions.showModal(TableIDs.modalCreateEditCustomer, 0); }}>
                                                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="plus" theme="outlined" />
                                                </Button>
                                            </Tooltip>
                                            {this.props.documentType == 3 || this.props.documentType == 23 || this.props.documentType == 203 ?
                                                <Tooltip title={this.getText(19295)}>
                                                    <Button key="search_cust_in_doc" className={"btn"}
                                                        style={{ height: 40 }}
                                                        onClick={() => { this.setState({ modalSearchCustPerDoc: true }) }}>
                                                        <Icon style={{ fontSize: 16 }} type="search" theme="outlined" />
                                                    </Button>
                                                </Tooltip>
                                                : ""}
                                        </Col>
                                        <Col span={8}>
                                            <DataTypeOrPick
                                                id={"customer_picker"}
                                                searchId={"search_customer_picker"}
                                                // doFocus={true}
                                                tabIndex="5"
                                                defaultDataId={Jax.get(this.props.data, "document2.customerCode", "")}
                                                placeholder={this.getText(12474)}
                                                value={{
                                                    data: Jax.get(this.props.data, "document2.customerCode", ""),
                                                    dataName: Jax.get(this.props.data, "document2.customerName", "")
                                                }}
                                                onChange={(selection) => {

                                                    this.props.dataActions.setJson("document2", {
                                                        customerCode: selection.data,
                                                        custPrintData: {},
                                                        // customerName: selection.dataName
                                                    })
                                                    // this.updateCustData();
                                                    this.typingCustId = true;
                                                }}
                                                onValidated={(selection) => {
                                                    this.typingCustId = false;
                                                    this.props.dataActions.setJson("document2", {
                                                        customerCode: selection.dataName ? selection.data : "0",
                                                        customerName: selection.dataName ? selection.dataName : ""
                                                    })

                                                    if (parseInt(selection.data)) this.updateCustData(parseInt(selection.data));
                                                }}
                                                showDialog={(defaultValue) => {
                                                    let state = Jax.set(this.state, "isCustomerModalShown", true);
                                                    this.setState(state);
                                                }}
                                                hideDialog={() => {
                                                    this.setState(Jax.set(this.state, "isCustomerModalShown", false));
                                                }} data={Jax.map(this.props.data, "Customers", function (v) { return v.id + "\f" + v.firstName + " " + v.lastName; })} />
                                        </Col>
                                        <Col span={12}>
                                            <Input value={Jax.get(this.props.data, "document2.customerName", "")}
                                                size="large"
                                                tabIndex={'6'}
                                                style={{ textAlign: 'right' }}
                                                onChange={(e) => {
                                                    if (e.target.value?.length > 150) message.error(this.getText(19013) + "150 " + this.getText(19014))
                                                    else this.props.dataActions.setJson('document2', { customerName: e.target.value })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>)}



                {this.getStyleRowSpacingInput(12476, 'customerAddress', 1, 0, '9', [40, 40], 0, 0, 350)}
                {this.getStyleRowSpacingInput(12477, 'customerZipCode', 0, 0, '10', 10, 0, 0, 350)}
                {this.getStyleRowSpacingInput(12478, 'customerPhone', 0, 0, '11', 30, 0, 0, 350)}
                {/* </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}> */}
            </div>
            <div style={colStyle}>
                <Row style={{ ...style_row_spacing, direction: 'rtl' }}>
                    <Col span={4}>{this.getText(51831)/*this.getText(xxxx)*/}</Col>
                    <Col span={20}>
                        {/* DEBOZ */}
                        <DateTimePicker
                            // disabledDate={this.disabledStartDate}
                            showTime
                            format="HH:mm:ss DD/MM/YYYY"//"YYYY-MM-DD"                                
                            value={Jax.get(this.props.data, "document2.docDateTime", null)
                                //  ?
                                //     moment(Jax.get(this.props.data, "document2.docDateTime", null), "YYYY-MM-DD HH:mm:ss") : null
                            }
                            //placeholder="Start"
                            // onChange={(date, dateStr) => {
                            onChange={(dateStr) => {
                                const { documentNumber, documentType, posId, data, sendAPI, loadDraft } = this.props
                                let dataSend = "_docNum\f_docPos\f_docType\f_docDatetime\f_priceListId\r" +
                                    documentNumber + "\f" + posId + "\f" + documentType + "\f" + (dateStr ?? "") + "\f" + (data.document2.priceListId ?? "")
                                sendAPI("calc_get_doc_tax", dataSend, () => {
                                    loadDraft(() => { this.props.dataActions.setJson("document2", { docDateTime: dateStr }) })
                                })
                                if (documentType == 2 || documentType == 5 || documentType == 22 || documentType == 2005 || documentType == 202 || documentType == 222) {
                                    setTimeout(() => {
                                        this.calcCustCreditDays()
                                    }, 1000);
                                }
                            }}
                            maxDate={moment(Date.now()).format("YYYY-MM-DD")}

                        // disabledDate={(d) => {
                        //     const formatDate = moment(d).format("YYYY-MM-DD");
                        //     const nowDate = moment(Date.now()).format("YYYY-MM-DD");
                        //     if (formatDate.valueOf() > nowDate.valueOf()) return true;
                        //     return false;
                        // }}
                        //onOpenChange={this.handleStartOpenChange}
                        />
                    </Col>
                </Row>
                {[2, 5, 16, 17, 20, 21, 22, 2005, 202, 222].filter(f => f == this.props.documentType).length ?
                    <Row style={{ ...style_row_spacing, direction: 'rtl' }}>
                        <Col span={4}>{this.getText(18689)/*this.getText(xxxx)*/}</Col>
                        <Col span={20}>
                            <DateTimePicker
                                format="DD/MM/YYYY"
                                value={Jax.get(this.props.data, "document2.docValDate", null)}
                                onChange={(dateStr) => {
                                    this.props.dataActions.setJson("document2", { docValDate: dateStr })
                                }}
                            />

                            <Button onClick={() => { this.setState({ calcCreditDaysPop: true }) }}>{this.getText(18690)}</Button>

                        </Col>
                    </Row>
                    : ""}
                <Row style={{ ...style_row_spacing, direction: 'ltr' }}>
                    <Col span={4}>
                        {this.props.requireEmployee ? <span style={{ color: "red" }}> * </span> : ""}
                        {this.getText(12479)}
                    </Col>
                    <Col span={8}>
                        <DataTypeOrPick
                            id={"employee_picker"}
                            searchId={"search_employee_picker"}
                            doFocus={true}
                            tabIndex="1"
                            //showDescription={true}
                            defaultDataId={Jax.get(this.props.data, "document2.employeeID", null /*this.props.user.userId*/)}
                            placeholder={this.getText(12480)}
                            value={{
                                data: Jax.get(this.props.data, "document2.employeeID", null /*this.props.user.userId*/),
                                dataName: Jax.get(this.props.data, "document2.employeeName", null /*this.props.user.displayName*/)
                            }} onChange={(selection) => {
                                // this.props.ActionQueue.addToQueue({
                                //     action: this.props.dataActions.genericDataSetEdit,
                                //     args: ["document.editedRecord", {
                                //         employeeID: selection.data,
                                //         employeeName: selection.dataName
                                //     }]
                                // });
                                // //this.autoSave();
                                if (this.props.visible) this.props.dataActions.setJson("document2", { employeeID: selection.data, employeeName: selection.dataName });
                            }} showDialog={(defaultValue) => {
                                let state = Jax.set(this.state, "isEmployeeModalShown", true);
                                this.setState(state);
                            }} hideDialog={() => {
                                this.setState(Jax.set(this.state, "isEmployeeModalShown", false));
                            }} data={Jax.map(this.props.data, "Employees", function (v) { return v.id + "\f" + v.name; })} />


                    </Col>
                    <Col span={12}>
                        <Input readOnly value={Jax.get(this.props.data, "document2.employeeName", null /* TODO: this.props.user.displayName */)}
                            size="large"
                            style={{ textAlign: 'right' }}
                            onChange={(e) => {
                                // this.props.ActionQueue.addToQueue({
                                //     action: this.props.dataActions.genericDataSetEdit,
                                //     args: ["document.editedRecord", {
                                //         employeeName: e.target.value
                                //     }]
                                // });
                                // //this.autoSave();
                            }}
                        />
                    </Col>
                </Row>

                <Row style={{ ...style_row_spacing, direction: 'ltr' }}>
                    <Col span={4}>{this.getText(12481)}</Col>
                    <Col span={8}>
                        {(this.props.documentType == "5") ?
                            (<Input tabIndex="2" readOnly value={Jax.get(this.props.data, "document2.storageId", null)} style={{ textAlign: 'right' }} size="large" />)
                            : (<DataTypeOrPick
                                id={"storage_picker"}
                                searchId={"search_storage_picker"}
                                // doFocus={true}
                                tabIndex="2"
                                //showDescription={true}
                                //showInlineDescription={true}
                                defaultDataId={Jax.get(this.props.data, "document2.storageId", null/*this.props.defaultStorage.id*/)}
                                placeholder={this.getText(12482)}
                                value={{
                                    data: Jax.get(this.props.data, "document2.storageId", null/*this.props.defaultStorage.id*/),
                                    dataName: Jax.get(this.props.data, "document2.storageName", null/*this.props.defaultStorage.name*/)
                                }}
                                onValidated={(selection) => {
                                    const findStorage = this.props.data.Storages.find(f => f.id == selection.data)
                                    if (!findStorage) {
                                        this.props.dataActions.setJson("document2", { storageId: null, storageName: null });
                                        message.error(this.getText(18712))
                                    } else {
                                        this.props.dataActions.setJson("document2", { storageName: findStorage.name });
                                    }
                                }}
                                onChange={(selection) => {
                                    // this.props.ActionQueue.addToQueue({
                                    //     action: this.props.dataActions.genericDataSetEdit,
                                    //     args: ["document.editedRecord", {
                                    //         storageId: selection.data,
                                    //         storageName: selection.dataName
                                    //     }]
                                    // });
                                    // //this.autoSave();
                                    this.props.dataActions.setJson("document2", { storageId: selection.data, storageName: selection.dataName });
                                }} showDialog={(defaultValue) => {
                                    let state = Jax.set(this.state, "isStorageModalShown", true);
                                    this.setState(state);
                                }} hideDialog={() => {
                                    this.setState(Jax.set(this.state, "isStorageModalShown", false));
                                }} data={Jax.map(this.props.data, "Storages", function (v) {
                                    return v.id + "\f" + v.name;
                                })} />)}
                    </Col>
                    <Col span={12}>
                        <Input readOnly value={Jax.get(this.props.data, "document2.storageName", null/*this.props.defaultStorage.name*//* TODO: this.props.user.displayName */)}
                            style={{ textAlign: 'right' }}
                            size="large"
                            onChange={(e) => {
                                // this.props.ActionQueue.addToQueue({
                                //     action: this.props.dataActions.genericDataSetEdit,
                                //     args: ["document.editedRecord", {
                                //         storageName: e.target.value
                                //     }]
                                // });
                                // //this.autoSave();
                            }}
                        />
                    </Col>
                </Row>
                {(this.props.documentType === "5051" || this.props.documentType === 5051) ? (
                    <Row style={{ ...style_row_spacing, direction: 'ltr' }}>
                        <Col span={4}>{this.getText(12483)}</Col>
                        <Col span={8}>
                            <DataTypeOrPick
                                id={"storage2_picker"}
                                searchId={"search_storage2_picker"}
                                // doFocus={true}
                                tabIndex="3"
                                //showDescription={true}
                                defaultDataId={Jax.get(this.props.data, "document2.storageId2", "")}
                                placeholder={this.getText(12484)}
                                value={{
                                    data: Jax.get(this.props.data, "document2.storageId2", ""),
                                    dataName: Jax.get(this.props.data, "document2.storageName2", "")
                                }}
                                onValidated={(selection) => {
                                    const findTransitStorage = this.props.data.transitStorages.dataset.find(f => f.code == selection.data)
                                    if (!findTransitStorage) {
                                        this.props.dataActions.setJson("document2", { storageId2: null, storageName2: null });
                                        message.error(this.getText(18683))
                                    } else {
                                        // console.log("findTransitStorage", findTransitStorage)
                                        this.props.dataActions.setJson("document2", { storageName2: findTransitStorage.name });
                                    }
                                }}
                                onChange={(selection) => {
                                    this.props.dataActions.setJson("document2", { storageId2: selection.data, storageName2: selection.dataName });
                                }}
                                showDialog={(defaultValue) => {
                                    let state = Jax.set(this.state, "isStorageModalShown2", true);
                                    this.setState(state);
                                }}
                                hideDialog={() => {
                                    this.setState(Jax.set(this.state, "isStorageModalShown2", false));
                                }}
                                data={Jax.map(this.props.data, "transitStorages.dataset", function (v) {
                                    return v.code + "\f" + v.name;
                                })} />
                        </Col>
                        <Col span={12}>
                            <Input readOnly value={Jax.get(this.props.data, "document2.storageName2", /* TODO: this.props.user.displayName */ "")}
                                style={{ textAlign: 'right' }}
                                size="large"
                                onChange={(e) => {
                                    /*
                                    this.props.ActionQueue.addToQueue({
                                        action: this.props.dataActions.genericDataSetEdit,
                                        args: ["document.editedRecord", {
                                            storageName2: e.target.value
                                        }]
                                    });
                                    */
                                    //this.autoSave();
                                }}
                            />
                        </Col>
                    </Row>)
                    : <div></div>}
                <Row>
                    <Col span={23}>
                        {this.getStyleRowSpacingInput(15395, 'remarks', 1, false, '4')}
                    </Col>
                    <Col span={1}>
                        <Tooltip title={this.getText(19240)}>
                            <Button type="primary" onClick={() => {

                                this.setState({
                                    modalFixRemark: true,
                                }, () => {
                                    this.props.sendAPI('fix_remarks_list', 'mType\r' + this.props.documentType, (ob) => {
                                        let dataset = ob.data ? ob.data.split("\r").map((x) => {
                                            let y = x.split("\f")
                                            return { code: y[0], name: y[1], content: y[2] }
                                            {/* 2דניאל היה פה */ }

                                        }) : []
                                        this.props.dataActions.setJson(this.id_fixremarks, { dataset })
                                    })
                                })


                            }}>
                                <Icon type="bars" />
                            </Button >
                        </Tooltip>
                    </Col>
                </Row>
                {[202, 203, 208, 215, 219, 222, 230].find(f => f == this.props.documentType) ?
                    this.getStyleRow(20158, (
                        <GenericSelector
                            {...this.props}
                            id={"CURRENCY_SELECTOR"}
                            value={this.props.data.document2.currencyCode}
                            onChange={(e) => {
                                let currencyData = this.props.data[this.id_rates].data.map(x => x.split('\f')).find(f => f[0] == e)
                                let currencyRate = currencyData ? currencyData[2] : null;
                                let currencySymbol = currencyData ? currencyData[4] : null;
                                this.props.dataActions.setJson("document2", { currencyCode: e, currencyRate, currencySymbol })
                            }}
                        />), true)
                    : ""}
                <Button type="primary" onClick={() => { this.setState({ modalCurrencyRates: true }) }}>{this.getText(18186)}</Button>
                <div dir={"rtl"} style={{ width: 400, marginLeft: 'auto' }}>
                    <ResponsiveTable
                        idTable={this.props.data[this.id_rates]}
                        columns={[
                            { title: "", key: "_symbol", dataIndex: "_symbol", render: t => t },
                            { title: this.getText(18240), key: "_cur", dataIndex: "_cur", render: t => t },
                            { title: this.getText(18241), key: "_val", dataIndex: "_val", render: t => t },
                            { title: this.getText(18242), key: "_date", dataIndex: "_date", render: t => t },
                        ]}
                        pagination={false}
                        tableOnly
                    />
                </div>
            </div>
            {/* </Col>
            </Row> */}

            <DataSelectModal keyColumnLabel={this.getText(12486)} nameColumnLabel={this.getText(12487)}
                data={Jax.get(this.props.data, "itemCodes", [])}
                visible={Jax.get(this.state || {}, "isItemModalShown", false)}
                onChange={(data) => {
                    if (data.data) {
                        // console.log('data item code ', data)
                    }
                    this.setState(Jax.set(this.state, "isItemModalShown", data.visible));
                }}
                defaultValue={Jax.get(this.props.data, "document.editedRecord.lines.editedRecord.itemCode", "")}
                zIndex={this.props.zIndex + 2} title={this.getText(12485)}
                searchPlaceholder={this.getText(12488)} tableNotFound={this.getText(12489)} />

            <DataSelectModal //keyColumnLabel={this.getText(12493)} nameColumnLabel={this.getText(12494)}
                data={customersDataToSelector}
                columns={[
                    { title: this.getText(12493), dataIndex: "key", key: "key", sorter: true },
                    { title: this.getText(20059), dataIndex: "firstName", key: "firstName", sorter: true },
                    { title: this.getText(20060), dataIndex: "lastName", key: "lastName", sorter: true },
                    { title: this.getText(20061), dataIndex: "contactPerson", key: "contactPerson", sorter: true },
                    { title: this.getText(20062), dataIndex: "phone", key: "phone", sorter: true },
                    { title: this.getText(20472), dataIndex: "creditBalance", key: "creditBalance", sorter: true },
                    { title: this.getText(20473), dataIndex: "ObligoBalance", key: "ObligoBalance", sorter: true },
                ]}
                width={window.innerWidth > 600 ? 1000 : "95%"}
                dataNameField={"contactPerson"}
                visible={Jax.get(this.state || {}, "isCustomerModalShown", false)}
                onChange={(data) => {
                    this.props.dataActions.setJson("document2", { customerCode: data.data, customerName: data.dataName });
                    this.updateCustData(parseInt(data.data));
                    this.setState(Jax.set(this.state, "isCustomerModalShown", data.visible));
                    // //this.autoSave();
                }}
                defaultValue={this.props.data.document2.customerCode > 0 ? this.props.data.document2.customerCode : ""}
                zIndex={this.props.zIndex + 2} title={this.getText(12495)}
                searchPlaceholder={this.getText(12496)} tableNotFound={this.getText(12497)} />

            <DataSelectModal keyColumnLabel={this.getText(12498)} nameColumnLabel={this.getText(12499)}
                data={Jax.map(this.props.data, "Suppliers", function (v) {
                    return v.ID + "\f" + v.Name;
                })}
                visible={Jax.get(this.state || {}, "isSupplierModalShown", false)}
                onChange={(data) => {
                    //this.props.dataActions.setJson("document2", { supplierCode: data.data, supplierName: data.dataName });
                    this.props.dataActions.setJson("document2", { supplierCode: data.data, supplierName: data.dataName, /* because of stupid doc column */ customerCode: data.data, customerName: data.dataName });
                    this.updateSupplierAddress();
                    this.setState(Jax.set(this.state, "isSupplierModalShown", data.visible));
                }}
                defaultValue={this.props.data.document2.customerCode > 0 ? this.props.data.document2.customerCode : ""}
                zIndex={this.props.zIndex + 2} title={this.getText(12500)}
                searchPlaceholder={this.getText(12501)} tableNotFound={this.getText(12502)} />

            <DataSelectModal keyColumnLabel={this.getText(12503)} nameColumnLabel={this.getText(12504)}
                data={Jax.map(this.props.data, "Employees", function (v) {
                    return v.id + "\f" + v.name;
                })}
                visible={Jax.get(this.state || {}, "isEmployeeModalShown", false)}
                onChange={(data) => {
                    // if (data.data) {
                    //     this.props.ActionQueue.addToQueue({
                    //         action: this.props.dataActions.genericDataSetEdit,
                    //         args: ["document.editedRecord", {
                    //             employeeID: data.data,
                    //             employeeName: data.dataName
                    //         }]
                    //     });
                    // }
                    this.props.dataActions.setJson("document2", { employeeID: data.data, employeeName: data.dataName });
                    this.setState(Jax.set(this.state, "isEmployeeModalShown", data.visible));
                    // //this.autoSave();
                }}
                defaultValue={Jax.get(this.props.data, "document2.employeeID", "")}
                zIndex={this.props.zIndex + 2} title={this.getText(12505)}
                searchPlaceholder={this.getText(12506)} tableNotFound={this.getText(12507)} />

            <DataSelectModal keyColumnLabel={this.getText(12508)} nameColumnLabel={this.getText(12509)}
                data={Jax.map(this.props.data, "Storages", function (v) {
                    return v.id + "\f" + v.name;
                })}
                visible={Jax.get(this.state || {}, "isStorageModalShown", false)}
                onChange={(data) => {
                    // if (data.data) {
                    //     this.props.ActionQueue.addToQueue({
                    //         action: this.props.dataActions.genericDataSetEdit,
                    //         args: ["document.editedRecord", {
                    //             storageId: data.data,
                    //             storageName: data.dataName
                    //         }]
                    //     });
                    // }
                    this.props.dataActions.setJson("document2", { storageId: data.data, storageName: data.dataName });
                    this.setState(Jax.set(this.state, "isStorageModalShown", data.visible));
                    // //this.autoSave();
                }}
                defaultValue={Jax.get(this.props.data, "document.editedRecord.storageId", "")}
                zIndex={this.props.zIndex + 2} title={this.getText(12510)}
                searchPlaceholder={this.getText(12511)} tableNotFound={this.getText(12512)} />

            <DataSelectModal keyColumnLabel={this.getText(16078)} nameColumnLabel={this.getText(12509)}
                data={Jax.map(this.props.data, "transitStorages.dataset", function (v) {
                    return v.code + "\f" + v.name;
                })}
                visible={Jax.get(this.state || {}, "isStorageModalShown2", false)}
                onChange={(data) => {
                    // if (data.data) {
                    //     this.props.ActionQueue.addToQueue({
                    //         action: this.props.dataActions.genericDataSetEdit,
                    //         args: ["document.editedRecord", {
                    //             storageId2: data.data,
                    //             storageName2: data.dataName
                    //         }]
                    //     });
                    // }
                    this.props.dataActions.setJson("document2", { storageId2: data.data, storageName2: data.dataName });
                    this.setState(Jax.set(this.state, "isStorageModalShown2", data.visible));
                    // //this.autoSave();
                }}
                defaultValue={Jax.get(this.props.data, "document.editedRecord.storageId2", "")}
                zIndex={this.props.zIndex + 2} title={this.getText(12510)}
                searchPlaceholder={this.getText(12511)} tableNotFound={this.getText(12512)} />
            <CurrencyRatesModal
                {...this.props}
                visible={this.state.modalCurrencyRates}
                onCancel={() => { this.setState({ modalCurrencyRates: false }, this.props.apiLastRates) }} />
            <GenericModal
                title={this.getText(18691)}
                visible={this.state.calcCreditDaysPop}
                onCancel={() => { this.setState({ calcCreditDaysPop: false }) }}
                width={400}
                footer={[
                    <Button type="primary" onClick={() => { this.setState({ calcCreditDaysPop: false }, this.calcCustCreditDays) }}>{this.getText(18694)}</Button>,
                    <Button onClick={() => { this.setState({ calcCreditDaysPop: false }) }}>{this.getText(18695)}</Button>
                ]}>
                <div>
                    <Row style={{ width: 300, marginBottom: 10 }}>
                        <Col span={8}>
                            <Checkbox
                                checked={this.props.data.document2.CustomerIsEndOfMonth == '1'}
                                onChange={e => { this.props.dataActions.setJson("document2", { CustomerIsEndOfMonth: e.target.checked ? "1" : "0" }) }}
                            >{this.getText(18692)}</Checkbox>
                        </Col>
                        <Col span={16}>
                            <span>{this.getText(18693)}: </span>
                            <InputNumber
                                value={this.props.data.document2.CustomerCreditDays}
                                onChange={e => { this.props.dataActions.setJson("document2", { CustomerCreditDays: e }) }}
                            />
                        </Col>
                    </Row>

                </div>


            </GenericModal>

            <GenericModal
                title={this.getText(19239)}
                visible={this.state.modalFixRemark}
                onCancel={() => { this.setState({ modalFixRemark: false }) }}
                width={400}
            >
                <Input.Search
                    value={this.state.modalFixRemarksSearch}
                    onChange={(e) => { this.setState({ modalFixRemarksSearch: e.target.value }) }}
                />
                <Table
                    dataSource={this.props.data[this.id_fixremarks].dataset.filter(f => {
                        let g = this.state.modalFixRemarksSearch
                        if (!g) return true
                        return f.code.indexOf(g) > -1 || f.name.indexOf(g) > -1

                    })}
                    columns={[
                        { title: this.getText(19241), key: 'code', dataIndex: 'code', width: '25%' },
                        { title: this.getText(19242), key: 'name', dataIndex: 'name', width: '50%' },
                        { title: "", width: '25%', render: this.renderCheckedRemark },
                    ]}

                />
            </GenericModal>
            <GenericModal
                visible={this.state.modalSearchCustPerDoc}
                onCancel={() => { this.setState({ modalSearchCustPerDoc: false }) }}
                width={300}
                title={this.getText(this.props.documentType == 53 ? 19296 : 19295)}
                footer={[
                    <Button type="primary" onClick={() => {
                        let number = this.state.modalSearchCustPerDocInput;
                        let isSupp = this.props.documentType == 53 ? "1" : "0";
                        let dataSend = "_number\f_isSupp\r" + number + "\f" + isSupp;
                        this.props.sendAPI("get_customer_from_doc", dataSend, (ob) => {
                            if (!ob.data) {
                                message.error(this.getText(isSupp == '1' ? 19301 : 19298))
                            } else {
                                let res = ob.data.split("\r").map(x => {
                                    let y = x.split("\f");
                                    return { id: y[0], name: y[1] }
                                }).filter(f => f.id > 0);
                                if (!res.length) {
                                    message.error(this.getText(isSupp == '1' ? 19301 : 19298))
                                } else if (res.length > 1) {
                                    this.setState({ modalSearchCustPerDocRes: res })
                                } else {
                                    let data = res[0];
                                    if (isSupp == '1') {
                                        this.props.dataActions.setJson("document2", { supplierCode: data.id, supplierName: data.name, customerCode: data.id, customerName: data.name });
                                        this.updateSupplierAddress();
                                    } else {
                                        this.props.dataActions.setJson("document2", { customerCode: data.id, customerName: data.name });
                                    }
                                    this.updateCustData(parseInt(data.id));
                                    this.setState({ modalSearchCustPerDoc: false, modalSearchCustPerDocInput: null })
                                }
                            }
                        })
                    }}>{this.getText(19297)}</Button>
                ]}>
                <Form.Item label={this.getText(19299)}>
                    <Input value={this.state.modalSearchCustPerDocInput} onChange={e => { this.setState({ modalSearchCustPerDocInput: e.target.value }) }} />
                </Form.Item>
                {this.state.modalSearchCustPerDocRes ?
                    <Form.Item label={this.getText(19300)}>
                        <Radio.Group onChange={e => {
                            let data = this.state.modalSearchCustPerDocRes.find(f => f.id == e.target.value);
                            let isSupp = this.props.documentType == 53;
                            if (isSupp) {
                                this.props.dataActions.setJson("document2", { supplierCode: data.id, supplierName: data.name, customerCode: data.id, customerName: data.name });
                                this.updateSupplierAddress();
                            } else {
                                this.props.dataActions.setJson("document2", { customerCode: data.id, customerName: data.name });
                            }
                            this.updateCustData(parseInt(data.id));
                            this.setState({ modalSearchCustPerDoc: false, modalSearchCustPerDocInput: null, modalSearchCustPerDocRes: null })
                        }}>
                            {this.state.modalSearchCustPerDocRes.map(x => [<Radio value={x.id}>{x.id} - {x.name}</Radio>, <br />])}
                        </Radio.Group>
                    </Form.Item>
                    : ""}
            </GenericModal >




        </div >)
    }


}

/** #content doc lines */
class ContentLines extends Component<ContentLinesProps, State> {
    constructor(props: ContentLinesProps) {
        super(props);
    }

    render() {
        // console.log("Render: ContentLines")
        return (<div style={{ direction: 'rtl' }}>
            <TableContainer {...this.props} />
        </div>)
    }

}

/** #content doc payments */
class ContentPays extends Component<ContentPaysProps, State> {
    constructor(props: ContentPaysProps) {
        super(props);
    }
    render() {
        // console.log("Render: ContentPays")
        return (<CreateDocumentPayments {...this.props} />)
    }

}

/*
    setJson(who: string, obj: Object) {
        Dispatcher.dispatch({
            type: ActionTypes.SET_JSON,
            who,
            obj,
        })
    },

    overrideJson(who: string, obj: Object){
        Dispatcher.dispatch({
            type: ActionTypes.OVERRIDE_JSON,
            who,
            obj,
        })
*/