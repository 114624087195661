/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

//import "../App.css";
import "../../App.css";
import { Card, Col, Row, Button, Form, Select, List, Icon, Popconfirm, message, Checkbox, Modal } from "antd";

import Jax from "../../Jax/jax.es6.module";
//import { Job, User, OutputType, ProcessType } from '../JmReact';
import { Job, User, OutputType, ProcessType } from '../../JmReact';

// TODO: point too new create doc modal
//import DocumentCreationModal from "../../components/DocumentCreationModal";
import DocumentCreationModal from "../../components/DocumentCreationModalV2";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ModalCreateEditCustomer from "../../components/ModalCreateEditCustomer";
import LangContext from "../../contextProvider/LangContext";
import GenericSelector from "../../components/GenericSelector";
import GenericModal from "../../components/GenericModal";
import ResponsiveTable from "../../components/ResponsiveTable";
import moment from "moment";
import GenericSelectorCustomers from "../../components/GenericSelectorCustomers";
import AllocationModal from "../../components/AllocationModal";
import ModalMatchDocs from "../../components/ModalMatchDocs";


const FormItem = Form.Item;
const Option = Select.Option;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = { documentType: number, documentTypeLabel: string, isEditorOpen: boolean };

class Documents extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            posId: "",
            posName: "",
            documentType: "",
            documentTypeLabel: "",
            issetPurchaseDocFromTransfers: "",
            isEditorOpen: "",
            defaultEmployee: "",
            defaultStorage: "",

            modalTemplateSelect: false,
            modalTemplateSelectStatus: '0',
            modalTemplateSelectItemsOnly: null,

            // createToMultiCust: false,
            // createToMultiCustList: [],

            modalMatchDocs: null,
        }

        this.selectors = [
            { id: "POS_LIST_BO_USER", api: "get_pos_list_bo_user" },
            { id: "TEMPLETE_DOCS_LIST", api: "get_templete_docs_list" },
        ]

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } } })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }



    componentDidMount() {
        //console.log("zzzzz createDocument page mounted");
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isForCoto) document.title = "Cash On Tab - " + this.getText(10162);
        /*
        if (this.props.data.temporaryDocuments !== prevProps.data.temporaryDocuments) {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.networkFetchData,
                args: [this.props.user.companyCode, this.props.user.token, { script: "get_documents_for_user", params: { docTypeId: (this.state) ? this.state.documentType : 0 } }, "temporaryDocuments", { loading: "טוען טיוטות מסמכים...", failed: this.getText(12422), success: "" }, false, { action: null, args: [] }, function (action, store, data) {
                    let arr = data.split("\r");
                    let json = [];
                    let headers = arr.shift().split("\f");
                    arr.pop();
                    for (let i = 0, j = arr.length; i < j; i++) {
                        let row = arr[i].split("\f");
                        let obj = {};
                        for (let i = 0, j = headers.length; i < j; i++) {
                            obj[headers[i]] = row[i];
                        }
                        json.push(obj);
                    }
                    return Jax.set(store, "temporaryDocuments.data", json);
                }]
            });
        }
        */
    }

    sendAPI = (script, dataSend, onSuccess, onError) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", onSuccess, onError ? onError : e => { console.error(e) });
    }

    getDefaultEmployee = () => {
        //let dataSend = Object.keys(this.state.request).join('\f') + '\r' + Object.values(this.state.request).join('\f');
        // let dataSend = '';
        // //let dataSend = "mWorkerId\fmIsPunchIn\r" + this.state.employeeID + '\f' + ;
        // let user = new User(this.props.user.token, this.props.user.companyCode);
        // let job = new Job(user, "get_employee_by_user", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        // job.setInput(dataSend);
        // job.send("/cgi-bin/CashOnTab",
        this.sendAPI("get_employee_by_user", "",
            (ob: Object) => {
                console.log("get_employee_by_user: ", ob.data);
                const resultArr = ob.data.split("\f");
                if (resultArr.length === 2) {
                    this.setState({ defaultEmployee: { id: resultArr[0], name: resultArr[1] } })
                }
            },
            (error: any) => {
                console.log(this.getText(12423));
            });

    }
    getDefaultStorage = () => {
        //let dataSend = Object.keys(this.state.request).join('\f') + '\r' + Object.values(this.state.request).join('\f');
        // let dataSend = '';
        // //let dataSend = "mWorkerId\fmIsPunchIn\r" + this.state.employeeID + '\f' + ;
        // let user = new User(this.props.user.token, this.props.user.companyCode);
        // let job = new Job(user, "get_storage_by_user_default", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        // job.setInput(dataSend);
        // job.send("/cgi-bin/CashOnTab",

        this.sendAPI("get_storage_by_user_default", "",
            (ob: Object) => {
                console.log("get_storage_by_user_default: ", ob.data);
                const resultArr = ob.data.split("\f");
                if (resultArr.length === 2) {
                    this.setState({ defaultStorage: { id: resultArr[0], name: resultArr[1] } })

                }
            },
            (error: any) => {
                console.log(this.getText(12423));
            });
    }

    loadData = () => {
        this.getDefaultStorage();
        this.getDefaultEmployee();

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    script: "get_documents_for_user",
                    params: { docTypeId: (this.state) ? this.state.documentType : 0 }
                },
                "temporaryDocuments",
                {
                    loading: this.getText(12421),
                    failed: this.getText(12422),
                    success: ""
                },
                false,
                { action: null, args: [] },
                function (action, store, data) {
                    let arr = data.split("\r");
                    let json = [];
                    let headers = arr.shift().split("\f");
                    arr.pop();
                    for (let i = 0, j = arr.length; i < j; i++) {
                        let row = arr[i].split("\f");
                        let obj = {};
                        for (let i = 0, j = headers.length; i < j; i++) {
                            obj[headers[i]] = row[i];
                        }
                        json.push(obj);
                    }
                    return Jax.set(store, "temporaryDocuments.data", json);
                }
            ]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                { script: "get_document_types", params: {} },
                "documentTypes",
                { loading: "", failed: this.getText(12422), success: "" },
                false,
                { action: null, args: [] },
                null
            ]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_payment_types", params: {} }, "paymentTypes", { loading: "", failed: this.getText(12422), success: "" }, false, { action: null, args: [] }, null]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_tax_values", params: {} }, "taxes", { loading: "", failed: this.getText(12422), success: "" }, false, { action: null, args: [] }, null
            ]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_tax_values", params: {} }, "TaxValues", { loading: "", failed: this.getText(12422), success: "" }, false, null, null]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token, { script: "data_get", params: { dataObject: "customerList" } }, "customerList", { loading: "", failed: this.getText(12422), success: "" }, false, { action: null, args: [] }, null
            ]
        });


        this.props.ActionQueue.addToQueue({ action: this.props.dataActions.getItemCodes, args: [this.props.user.companyCode, this.props.user.token] });


        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            //args: [this.props.user.companyCode, this.props.user.token, { script: "data_get", params: { dataObject: "posList" } }, "PointsOfSale", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_pos_by_user_list", params: { /*dataObject: "posList"*/ } }, "PointsOfSaleByUser", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                //arr.pop();
                //arr.shift();
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    json.push({
                        id: parseInt(row[0], 10),
                        name: row[1],
                        taxRegion: parseInt(row[2], 10)
                    });
                }
                //return Jax.set(store, "PointsOfSale", json);
                return Jax.set(store, "PointsOfSaleByUser", json);
            }]
        });

        this.loadDataCustomers();

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, {
                script: "get_suppliers_table_list", params: {
                    _id: 0,
                    _words: "",
                    _page_num: 1,
                    _rows_num: 99999999,
                    _sort_by: "col_1",
                    _isAsc: 1
                }
            }, "Suppliers", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let headers = arr.shift().split("\f");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    let obj = {};
                    for (let i = 0, j = headers.length; i < j; i++) {
                        obj[headers[i]] = row[i];
                    }
                    json.push(obj);
                }
                return Jax.set(store, "Suppliers", json);
            }]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_document_types_withCategory_list", params: {} }, "DocTypesCategories", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    json.push({
                        id: parseInt(row[0], 10),
                        name: row[1],
                        category: row[2],
                        allocationType: row[3],
                        signFactor: row[4],
                    });
                }
                return Jax.set(store, "DocTypesCategories", json);
            }]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_employee_list", params: {} }, "Employees", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    json.push({
                        id: parseInt(row[0], 10),
                        name: row[1]
                    });
                }
                return Jax.set(store, "Employees", json);
            }]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_storage_list", params: {} }, "Storages", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    json.push({
                        id: parseInt(row[0], 10),
                        name: row[1]
                    });
                }
                return Jax.set(store, "Storages", json);
            }]
        });

        this.getDefaultPosByUser();
    }

    loadDataCustomers = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetch,
            args: [this.props.user.companyCode, this.props.user.token, { script: "get_all_customers", params: { mCreatingBranchList: "" } }, "Customers", { loading: "", failed: this.getText(12422), success: "" }, false, null, function (actionName, store, data) {
                let arr = data.split("\r");
                let json = [];
                for (let i = 0, j = arr.length; i < j; i++) {
                    let row = arr[i].split("\f");
                    let id = parseInt(row[0], 10);
                    if (id) {
                        json.push({
                            id,
                            firstName: row[1],
                            lastName: row[2],
                            contactPerson: row[3],
                            address: row[4],
                            // zipCode: parseInt(row[5], 10),
                            zipCode: row[5],
                            phone: row[6].length > 0 ? row[6] : row[7],
                            priceList: parseInt(row[8]) ? { code: row[8], name: row[9] } : null,

                            creditBalance: row[10],
                            ObligoBalance: row[11],
                        });
                    }
                }
                return Jax.set(store, "Customers", json);
            }]
        });
    }

    getDefaultPosByUser = () => {
        this.sendAPI("get_pos_by_user_default", "",
            // var successCallback =
            (ob) => {
                console.log("get success selector" + ob.data);
                let rowsRaw = ob.data;
                let rows = rowsRaw.split("\r");

                if (rows.length > 0) {
                    let row = rows[0].split("\f");
                    this.setState(Jax.mutate(this.state, "", { posId: row[0], posName: row[1], issetPurchaseDocFromTransfers: row[2] }));
                }
            })

        // var dataSend = "";
        // var user = new User(this.props.user.token, this.props.user.companyCode);
        // var job = new Job(user, "get_pos_by_user_default", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        // job.setInput(dataSend);
        // job.send("/cgi-bin/CashOnTab", successCallback, (e) => (e)); // callback problem here  
    }
    /*
        isDocTypesIncluded = (listFlag, docTypeId) => {
            // listFlag 1-sales , 2-inventory , 4-purchase
            switch (parseInt(docTypeId, 10)) {
                case 1: return listFlag & 1     //  "1\fחשבונית מס-קבלה"
                case 2: return listFlag & 1     //  "2\fחשבונית מס"
                case 3: return listFlag & 4     //  "3\fקבלה"
                case 4: return listFlag & 2     //  "4\fכניסת רכש"
                case 8: return listFlag & 1     //  "8\fתעודת משלוח"
                case 9: return listFlag & 1     //  "9\fהחזרת משלוח"
                case 10: return listFlag & 4    //  "10\fשובר זיכוי"
                case 11: return listFlag & 2    //  "11\fספירת מלאי"
                case 12: return listFlag & 4    //  "12\fתעודת תיקון"
                case 13: return listFlag & 2    //  "13\fהפרשי מלאי"
                case 14: return listFlag & 1    //  "14\fהזמנת לקוח"
                case 18: return listFlag & 2    //  "18\fהחזרת רכש"
                case 22: return listFlag & 1    //  "22\fחשבונית מס זיכוי"
                case 23: return listFlag & 4    //   "23\fהחזרת כסף"
                case 40: return listFlag & 4        //   "40\fהכנסת כסף"
                case 41: return listFlag & 4        //   "41\fהוצאת כסף"
                case 50: return listFlag & 2        //   "50\fהעברה בין מחסנים - יציאה "
                case 51: return listFlag & 2        //   "51\fהעברה בין מחסנים - כניסה"
                case 90: return listFlag & 4        //   "90\fרשומת לקוח"
                case 99: return listFlag & 4        //   "99\fמיספור Z
                default: return false;
            }
        }
    */

    issueDocumentNumber() {
        this.props.dataActions.networkFetch(this.props.user.companyCode, this.props.user.token,
            {
                script: "get_temporary_document_number",
                params: {
                    documentTypeId: (this.props.documentType === 5051 || this.props.documentType === '5051') ? 50 : this.props.documentType,
                    //documentTypeId: this.props.documentType,
                    posId: this.props.posId
                }
            },
            "document.editedRecord.number",
            { loading: "", failed: "", success: "" },
            false,
            { action: null, args: [] },
            function (actionName, store, data) {
                return Jax.set(store, "document.editedRecord.number", parseInt(data, 10));
            }.bind(this));
    }

    openSelectorTemplete = (type, onSelect) => {
        const { id, api } = this.selectors[1]
        this.setState({
            modalTemplateSelect: true,
            modalTemplateSelectStatus: '0',
            modalTemplateSelectItemsOnly: type === 'itemsOnly' ? onSelect : null,
        }, () => {
            this.sendAPI(api, "", (ob) => {
                const dataset = ob.data ? ob.data.split("\r").map(x => {
                    let y = x.split("\f");
                    return {
                        id: y[0],
                        name: y[1],
                        status: y[2],
                        data: y[3] ? JSON.parse(y[3]) : {},
                        pos: y[4],
                        isCheced: '0',
                    }
                }) : []
                this.props.dataActions.setJson(id, { dataset })
            })
        })
    }

    statusTemplete = (status) => {
        const findChecked = this.props.data[this.selectors[1].id].dataset.find(f => f.isCheced == '1');
        if (findChecked) this.sendAPI("update_templete_doc_status", "_id\f_status\r" + findChecked.id + "\f" + status, this.openSelectorTemplete)
    }

    loadTemplete = () => {
        const findChecked = this.props.data[this.selectors[1].id].dataset.find(f => f.isCheced == '1');
        if (findChecked) {
            if (this.state.modalTemplateSelectItemsOnly) {
                this.setState({ modalTemplateSelect: false }, () => {
                    let dataSource = [...this.props.data.document2.dataSource]
                    let dataSourceLength = dataSource.length;
                    findChecked.data.data.dataSource.forEach(x => {
                        dataSourceLength++
                        dataSource.push({ ...x, key: dataSourceLength, keyVal: dataSourceLength })
                    })

                    this.props.dataActions.setJson("document2", { dataSource })
                    this.state.modalTemplateSelectItemsOnly();
                })
            } else {
                const typeData = this.props.data.DocTypesCategories.find(f => f.id == findChecked.data.docType);
                this.setState({
                    posId: findChecked.pos,
                    isEditorOpen: true,
                    modalTemplateSelect: false,
                    documentType: findChecked.data.docType,
                    documentTypeLabel: typeData ? typeData.name : ""
                }, () => {

                    setTimeout(() => {
                        this.props.dataActions.setJson("document2", { ...findChecked.data.data })
                    }, 2000);
                })
            }
        }
    }



    render() {

        console.log("documentTypes-data", this.props.data.documentTypes)

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            display: this.props.isForCoto ? "none" : "block"
        };

        let documentTypes = Jax.get(this.props.data, "documentTypes.data", []).map((obj) => {
            let node = obj.split("\f");
            return (<Option key={parseInt(node[0], 10)}>{node[1]}</Option>)
        });

        const templeteDataset = this.props.data[this.selectors[1].id].dataset;

        const notTemplateSelect = !(templeteDataset.find(f => f.isCheced == '1'))

        const onSelectTemplete = (isCheced, record) => {
            return (<Checkbox
                checked={isCheced == '1'}
                onChange={e => {
                    const dataset = templeteDataset.map(x => {
                        // console.log("onSelectTemplete", x.id, record.id, e.target.checked)
                        return { ...x, isCheced: x.id == record.id && e.target.checked ? '1' : '0' }
                    })
                    this.props.dataActions.setJson(this.selectors[1].id, { dataset })
                }}
            />)
        }

        const mts1 = this.state.modalTemplateSelectStatus == '1'
        const mtsItemsOnly = this.state.modalTemplateSelectItemsOnly != null;


        ///////////////////////////
        let docTypeSelectorButton = (node) => {
            let buttonStyle = { width: '98%', marginLeft: 4, marginRight: 4 }

            return (
                <Button style={buttonStyle}
                    key={parseInt(node[0], 10)}
                    value={node.join("#")}

                    type={(this.state)
                        ? (this.state.documentType)
                            ? (this.state.documentType != parseInt(node[0], 10)) ? null : "primary"
                            : null
                        : null
                    }

                    onClick={(e) => {
                        let value = e.target.value;
                        let documentType = value.split("#")[0]
                        let documentTypeLabel = value.split("#")[1]
                        this.setState(Jax.mutate(this.state, "", { documentType, documentTypeLabel }), () => {
                            this.props.ActionQueue.addToQueue({
                                action: this.props.dataActions.networkFetchData,
                                args: [this.props.user.companyCode, this.props.user.token, { script: "get_documents_for_user", params: { docTypeId: documentType ? documentType : 0 } }, "temporaryDocuments", { loading: this.getText(12421), failed: this.getText(12422), success: "" }, false, { action: null, args: [] }, function (action, store, data) {
                                    let arr = data.split("\r");
                                    let json = [];
                                    let headers = arr.shift().split("\f");
                                    arr.pop();
                                    for (let i = 0, j = arr.length; i < j; i++) {
                                        let row = arr[i].split("\f");
                                        let obj = {};
                                        for (let i = 0, j = headers.length; i < j; i++) {
                                            obj[headers[i]] = row[i];
                                        }
                                        json.push(obj);
                                    }
                                    return Jax.set(store, "temporaryDocuments.data", json);
                                }]
                            });
                        }
                        )
                    }}>{node[1]}</Button>
            )
        }


        /*
        let documentTypesSale = Jax.get(this.props.data, "documentTypes.data", [])
            .filter((i) => { return this.isDocTypesIncluded(1, i.split("\f")[0]); })
            .map((obj) => {
                let node = obj.split("\f");
                return (docTypeSelectorButton(node))
            });
        let documentTypesInventory = Jax.get(this.props.data, "documentTypes.data", [])
            .filter((i) => { return this.isDocTypesIncluded(2, i.split("\f")[0]); })
            .map((obj) => {
                let node = obj.split("\f");
                return (docTypeSelectorButton(node))
            });
        let documentTypesPurchase = Jax.get(this.props.data, "documentTypes.data", [])
            .filter((i) => { return this.isDocTypesIncluded(4, i.split("\f")[0]); })
            .map((obj) => {
                let node = obj.split("\f");
                return (docTypeSelectorButton(node))
            });

        */
        let documentTypeList = Jax.get(this.props.data, "DocTypesCategories", []);
        let documentTypesSale = documentTypeList.filter(i => i.category == 1).map(i => docTypeSelectorButton([i.id, i.name]));
        let documentTypesInventory = documentTypeList.filter(i => i.category == 2).map(i => docTypeSelectorButton([i.id, i.name]));
        let documentTypesPurchase = documentTypeList.filter(i => i.category == 3).map(i => docTypeSelectorButton([i.id, i.name]));
        let documentTypesEn = documentTypeList.filter(i => i.category == 4).map(i => docTypeSelectorButton([i.id, i.name]));


        if (this.state.issetPurchaseDocFromTransfers == '1' && documentTypeList.find(f => f.id == 4)) {
            documentTypesInventory.push(docTypeSelectorButton([45051, this.getText(17053)]))
        }

        if (this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code == 529) && documentTypeList.find(f => f.id == 2)) {
            documentTypesSale.push(docTypeSelectorButton([2005, this.getText(20073)]))
        }

        // if (documentTypeList.find(f => f.id == 16)) {
        //     documentTypesInventory.push(docTypeSelectorButton([40418, this.getText(19838)]))
        // }
        /////////////////////

        //const posList = Jax(this.props.data).path("PointsOfSale").list().map((node: Object) => {
        const posList = Jax(this.props.data).path("PointsOfSaleByUser").list().map((node: Object) => {
            return (<Option key={parseInt(node.id, 10)}>{node.id + " - " + node.name}</Option>);
        }
        );

        const toolbar = (
            <PageToolbar
                title={"".concat(
                    this.getText(12424), ":", " ",
                    //Jax.get(this.state || {}, "documentTypeLabel", ""),
                    Jax.get(this.state, "documentTypeLabel", ""),
                    "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", this.getText(12425), ":", " ",
                    Jax.get(this.state, "posName", -1),
                    "\xa0\xa0\xa0\xa0\xa0\xa0\xa0", this.getText(12426), ":", " ",
                    Jax.get(this.state, "posId", -1)
                )
                }
                actionButtons={[]}
                genericActionButtons={[]}
                /*
                showsearch={(val) => {
                    this.props.dataActions.genericDataSetFilter(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        { ...request, _words: val
                            .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                         }, this.api.get)
                }}*/
                ui={this.props.ui}
                uiActions={this.props.uiActions} />
        );

        console.log("KKKKKKKKKKKK: ", this.props.data.document, this.props.data.document2);

        return (
            <div>
                <div style={divStyle}>
                    <PageToolbar
                        title={this.getText(12427)}
                        actionButtons={[]}
                        /*
                        showsearch={(val) => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token,
                                { ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                                 }, this.api.get)
                        }}*/
                        ui={this.props.ui}
                        uiActions={this.props.uiActions} />

                    <Row gutter={16} type="flex" justify="center">
                        <Col span={24} order={1}>
                            <Card title={this.getText(12428)} bordered={true}>

                                <FormItem label={this.getText(12429)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
                                >
                                    {/* <h2>{this.state && this.state.posId ? this.state.posId + " - " + this.state.posName : ""}</h2> */}
                                    <GenericSelector
                                        {...this.props}
                                        {...this.selectors[0]}
                                        style={{ width: 220 }}
                                        value={this.state.posId}
                                        onChange={e => {
                                            let findPos = this.props.data[this.selectors[0].id].dataset.find(f => f.code === e)
                                            this.setState({ posId: findPos ? findPos.code : "", posName: findPos ? findPos.name : "" })
                                        }}
                                    />
                                    {/* <Select key={"pos"} style={{ width: 220 }}
                                    size={"large"}
                                    className={""}
                                    combobox={null}

                                    value={(this.state)
                                        ? (this.state.posId && this.state.posName)
                                            ? { key: this.state.posId, label: this.state.posName }
                                            : undefined
                                        : undefined
                                    }

                                    onChange={(value: { key: number, label: string }) => this.setState(Jax.mutate(this.state, "", {
                                        posId: value.key,
                                        posName: value.label
                                    }))}
                                    notFoundContent={this.getText(12430)}
                                    labelInValue={true}
                                >
                                    {posList}
                                </Select> */}
                                </FormItem>

                                <FormItem label={this.getText(12431)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} >
                                    <Row type="flex">
                                        <Col lg={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }} /*span={8}*/ order={1} >{documentTypesSale}</Col>
                                        <Col lg={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }} /*span={8}*/ order={2} >{documentTypesInventory}</Col>
                                        <Col lg={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }} /*span={8}*/ order={3} >{documentTypesPurchase}</Col>
                                        <Col lg={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }} /*span={8}*/ order={4} >{documentTypesEn}</Col>
                                    </Row>

                                    {/*
                                <Select key={"DocumentType"} style={{ width: 250 }}
                                    size={"large"}
                                    className={""}
                                    combobox={null}
                                    onChange={(value: { key: number, label: string }) => this.setState(Jax.mutate(this.state, "", {
                                        documentType: value.key,
                                        documentTypeLabel: value.label
                                    }))}
                                    notFoundContent={"לא נמצאו סוגי מסמכים"}
                                    labelInValue={true}
                                >
                                    {documentTypes}
                                </Select>
                                */}

                                </FormItem>

                                <Button
                                    type={"primary"} style={{ float: 'left', marginLeft: 16 }} onClick={() => {
                                        if (this.state.posId) {
                                            let documentType = Jax.get(this.state, "documentType", -1);
                                            let posId = Jax.get(this.state, "posId", -1);

                                            if (!posId || !(posId > -1)) {
                                                message.warning(this.getText(12437), 3, null); return;
                                            }
                                            if (!documentType || !(documentType > -1)) {
                                                message.warning(this.getText(12438), 3, null); return;
                                            }
                                            if (documentType && documentType > -1 && posId && posId > -1) {
                                                //this.issueDocumentNumber();
                                                this.setState({ ...this.state, isEditorOpen: true }, () => {
                                                    const { allocationType } = this.props.data.DocTypesCategories.find(f => f.id == documentType) ?? []
                                                    const { documentTypeLabel } = this.state;
                                                    this.props.dataActions.setJson("document2", { allocationType, documentTypeLabel })
                                                    if (documentType == 2005) {
                                                        Modal.confirm({
                                                            content: this.getText(20076),
                                                            iconType: 'exclamation-circle',
                                                            okButtonProps: { style: { display: 'none' } },
                                                            cancelText: this.getText(20064),
                                                            onOk() { },
                                                            onCancel() { },
                                                        })
                                                    }
                                                });
                                            }
                                        } else {
                                            message.error(this.getText(17045))
                                        }
                                    }}>{this.getText(12439)}</Button>
                                <Button style={{ float: 'left', marginLeft: 16 }} onClick={this.openSelectorTemplete}>{this.getText(18671)}</Button>

                            </Card>
                        </Col>


                    </Row>
                    <Row style={{ marginTop: 16 }}>
                        <PageToolbar
                            title={this.getText(12432)}
                            actionButtons={[]}
                            genericActionButtons={this.state.documentType ? [
                                <Popconfirm
                                    title={this.getText(19278)}
                                    onConfirm={() => {
                                        this.sendAPI("delete_temp_document_all", "docTypeId\r" + this.state.documentType, this.loadData, e => { console.error(e) })
                                    }}
                                    okText={this.getText(19279)}
                                    cancelText={this.getText(19280)}
                                >
                                    <Button className={"btn"} style={{ marginTop: 5, marginLeft: 5 }} >
                                        <Icon type={"delete"} style={{ fontSize: 16 }} />
                                    </Button>
                                </Popconfirm>
                            ] : []}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                        />

                        <List
                            grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }}
                            dataSource={Jax.get(this.props.data, "temporaryDocuments.data", [])}
                            locale={{ emptyText: this.getText(12433) }}
                            renderItem={item => (
                                <List.Item>
                                    <Card title={item.DocumentTypeName + " " + item.DocumentNumber} style={{ borderRadius: 8 }} >
                                        {item.DocumentCustomerID + " - "}
                                        {item.DocumentCustomerName + ", "}
                                        {item.DocumentCustomerAddress}<br />
                                        {moment(item.DocumentTimestamp).format("DD/MM/YYYY HH:mm")} {item.linesCount ? "(" + item.linesCount + " " + this.getText(19277) + ")" : ""} <br />

                                        <span>
                                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"}
                                                onClick={() => {
                                                    this.props.ActionQueue.addToQueue({
                                                        action: this.props.dataActions.genericDataSetEdit,
                                                        args: [
                                                            "document.editedRecord",
                                                            { number: parseInt(item.DocumentNumber, 10) }
                                                        ]
                                                    });
                                                    this.setState(Jax.mutate(this.state, "", {
                                                        documentType: item.DocumentTypeID,
                                                        documentTypeLabel: item.DocumentTypeName,
                                                        posId: item.DocumentPOSID,
                                                        posName: item.POSName,
                                                        isEditorOpen: true
                                                    }));

                                                    const { allocationType } = this.props.data.DocTypesCategories.find(f => f.id == item.DocumentTypeID) ?? []
                                                    console.log("allocationType?", allocationType)
                                                    this.props.dataActions.setJson("document2", { allocationType, documentTypeLabel: item.DocumentTypeName })
                                                }}
                                            />

                                            <Popconfirm title={this.getText(12434)}
                                                documentNumber={item.DocumentNumber}
                                                onConfirm={() => {
                                                    window.console.log(item);
                                                    this.props.ActionQueue.addToQueue({
                                                        action: this.props.dataActions.networkPost,
                                                        args: [
                                                            this.props.user.companyCode,
                                                            this.props.user.token,
                                                            {
                                                                script: "delete_temp_document",
                                                                params: { number: item.DocumentNumber }
                                                            },
                                                            "",
                                                            "",
                                                            {
                                                                loading: this.getText(12440),
                                                                failed: this.getText(12441),
                                                                success: this.getText(12442)
                                                            },
                                                            {
                                                                action: this.props.dataActions.networkFetchData,
                                                                args: [
                                                                    this.props.user.companyCode,
                                                                    this.props.user.token,
                                                                    { script: "get_documents_for_user", params: { docTypeId: (this.state) ? this.state.documentType : 0 } },
                                                                    "temporaryDocuments",
                                                                    {
                                                                        loading: this.getText(12421),
                                                                        failed: this.getText(12422),
                                                                        success: ""
                                                                    },
                                                                    false,
                                                                    { action: null, args: [] },
                                                                    function (action, store, data) {
                                                                        let arr = data.split("\r");
                                                                        let json = [];
                                                                        let headers = arr.shift().split("\f");
                                                                        arr.pop();
                                                                        for (let i = 0, j = arr.length; i < j; i++) {
                                                                            let row = arr[i].split("\f");
                                                                            let obj = {};
                                                                            for (let i = 0, j = headers.length; i < j; i++) {
                                                                                obj[headers[i]] = row[i];
                                                                            }
                                                                            json.push(obj);
                                                                        }
                                                                        return Jax.set(store, "temporaryDocuments.data", json);
                                                                    }
                                                                ]
                                                            },
                                                            null
                                                        ]
                                                    });
                                                }}
                                                okText={this.getText(12435)} cancelText={this.getText(12436)}>
                                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                                            </Popconfirm>
                                        </span>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Row>
                </div>

                <DocumentCreationModal zIndex={60} data={this.props.data}
                    key={"DocumentCreationModal"}
                    title={toolbar}
                    visible={Jax.get(this.state || {}, "isEditorOpen", false)}

                    dataActions={this.props.dataActions} ActionQueue={this.props.ActionQueue} uiActions={this.props.uiActions} ui={this.props.ui} user={this.props.user}
                    defaultEmployee={Jax.get(this.state, "defaultEmployee", { id: null, name: null })}
                    defaultStorage={Jax.get(this.state, "defaultStorage", { id: null, name: null })}
                    posId={Jax.get(this.state, "posId", -1)}
                    documentType={Jax.get(this.state, "documentType", -1)}
                    documentTypeChange={documentType => {
                        let typeData = documentTypeList.find(f => f.id == documentType);
                        let documentTypeLabel = typeData ? typeData.name : "";
                        this.setState({ documentType, documentTypeLabel })
                    }}
                    documentNumber={parseInt(Jax.get(this.props.data, "document.editedRecord.number", 0), 10)}
                    workInProgress={this.props.ui.workInProgress}
                    closeFunction={() => {
                        this.setState(Jax.mutate(this.state, "", { "isEditorOpen": false }));
                        // clear document number from store // 
                        this.props.ActionQueue.addToQueue({
                            action: this.props.dataActions.genericDataCancelEditing,
                            args: ["document"]
                        });

                        this.props.dataActions.setJson("document2", { customerDiscount: 0 })

                    }}
                    updateFunction={(data) => {
                        window.console.log(data);
                    }}
                    saveFunction={() => {
                        console.log("save function");
                    }}
                    openSelectorTemplete={this.openSelectorTemplete}
                    openModalMatchDocs={data => { this.setState({ modalMatchDocs: { ...data, record: data } }) }}
                />

                <ModalCreateEditCustomer
                    id={TableIDs.modalCreateEditCustomer}
                    roleId={2}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditCustomer)}
                    ActionQueue={this.props.ActionQueue}
                    isRefreshParentTable={false}
                    onSave={e => {
                        this.loadDataCustomers()
                        this.props.dataActions.setJson("document2", {
                            customerCode: e.code,
                            customerName: e.name
                        })
                        let d = document.getElementById("search_customer_picker")
                        if (d) d.focus()
                    }}
                />

                <GenericModal
                    visible={this.state.modalTemplateSelect}
                    onCancel={() => { this.setState({ modalTemplateSelect: false }) }}
                    title={(mts1 ? this.getText(18673) + " " : "") + this.getText(18672)}
                    width={500}
                    footer={[
                        <Button onClick={() => {
                            this.setState({ modalTemplateSelect: false })
                        }}>{this.getText(18674)}</Button>,
                        <Button type="primary" disabled={notTemplateSelect} style={{ marginRight: 10 }} onClick={() => {
                            if (mts1) this.statusTemplete('0')
                            else this.loadTemplete()
                        }} >{this.getText(18675)}</Button>,
                        mts1 || mtsItemsOnly ? "" :
                            <Button disabled={notTemplateSelect} onClick={() => {
                                this.statusTemplete('1')
                            }} >{this.getText(18676)}</Button>,
                        mtsItemsOnly ? "" :
                            <Button onClick={() => {
                                this.setState({ modalTemplateSelectStatus: mts1 ? '0' : '1' })
                            }}>{this.getText(mts1 ? 18678 : 18677)}</Button>,
                    ]}>
                    {/* <FormItem>
                        <Checkbox
                            checked={this.state.createToMultiCust}
                            onChange={e => { this.setState({ createToMultiCust: e.target.checked }) }}
                        >{this.getText(19567)}</Checkbox>
                        {this.state.createToMultiCust ? [
                            <p>{this.getText(19568)}:</p>,
                            <GenericSelectorCustomers
                                {...this.props} multi
                                value={this.state.createToMultiCustList}
                                onChange={e => { this.setState({ createToMultiCustList: e }) }}
                            />
                        ] : ""}
                    </FormItem> */}
                    <ResponsiveTable
                        tableOnly
                        dataSource={templeteDataset.filter(f => f.status == this.state.modalTemplateSelectStatus)}
                        columns={[
                            { title: "", key: "isCheced", dataIndex: "isCheced", width: "10%", render: onSelectTemplete },
                            { title: this.getText(18679), key: "name", dataIndex: "name", width: "90%", render: t => t },
                        ]}
                    />

                </GenericModal>
                <AllocationModal {...this.props} />

                <ModalMatchDocs {...this.props}
                    {...this.state.modalMatchDocs}
                    autoOpen={this.state.modalMatchDocs != null}
                    onClose={() => { this.setState({ modalMatchDocs: null }) }}
                />

            </div>


        );
    }
}

export default Documents;