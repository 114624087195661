/* @flow */
/*jshint esversion: 6 */

import React from "react";
import { Icon, Modal, message, Popconfirm, Tooltip, Input, InputNumber, Select, Popover, Button, Form, Checkbox } from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import SelectorItems from "./SelectorItems";
import ModalPriceListItemView from "./ModalPriceListItemView";
import GenericModal from "./GenericModal";
import SelectorBonTags from "./SelectorBonTags";
import GenericSelectorItemTags from "./GenericSelectorItemTags";

const topHeight = 300; // px
const rowHeight = 40;  // px
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class ModalCreateEditFoodLevels extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            visible: false,
            hierarchy: null,
            levelId: null,
            levelName: null,
            optionNum: null,
            optionName: null,
            addPerTagButton: false,
            addPerTagButtonValue: null,
            addPerTagButtonType: null,
            addPerTagButtonBonsData: null,
            selectorBonTags: null,
            selectorBonTagsData: null,
        }

        this.id = this.props.id;
        this.main_id = this.props.main_id;
        this.options_id = "FOOD_DETAILS_TABLE";
        this.extras_id = "FOOD_DETAILS_EXTRAS";
        this.items_id = "ITEMS_TABLE";

        // this.extras_payment_id = "FOOD_DETAILS_EXTRAS_PAYMENT";
        // this.extras_no_payment_id = "FOOD_DETAILS_EXTRAS_NO_PAYMENT";
        // this.extras_auto_id = "FOOD_DETAILS_EXTRAS_AUTO";

        this.api = {
            get: "get_food_levels_table",
            order: "order_food_levels_table",
            create_edit: "create_edit_food_levels_table",
            delete: "delete_food_levels_table",

            get_options: "get_food_details_table",
            order_options: "order_food_details_table",
            create_edit_options: "create_edit_food_details_table",
            delete_options: "delete_food_details_table",

            get_extra: "get_food_details_extra",
            create_edit_extra: "create_edit_food_details_extra",
            delete_extra: "delete_food_details_extra",

            selector_tags: "get_tags_with_types",
            create_extra_tag: "create_food_details_extra_per_tag",

            edit_bons: "edit_food_dish_details_bon_tags",
        }

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.options_id] === undefined) props.data[this.options_id] = { ...props.data.genericData };
        if (props.data[this.extras_id] === undefined) props.data[this.extras_id] = { ...props.data.genericData };
        if (props.data[this.items_id] === undefined) props.data[this.items_id] = { ...props.data.genericData };
        // if (props.data[this.extras_payment_id] === undefined) props.data[this.extras_payment_id] = { ...props.data.genericData };
        // if (props.data[this.extras_no_payment_id] === undefined) props.data[this.extras_no_payment_id] = { ...props.data.genericData };
        // if (props.data[this.extras_auto_id] === undefined) props.data[this.extras_auto_id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    componentDidMount() {
        this.initFilters();
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selector_tags);
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        let request = { fID: this.props.ui.data ? this.props.ui.data['fID'] : 0 }
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    componentDidUpdate(prevProps) {
        let d = this.props.ui.data;
        let pd = prevProps.ui.data;
        if ((d && pd && d.fID !== pd.fID) || (prevProps.toggle === false && this.props.toggle === true)) {
            this.setState({ visible: true, hierarchy: this.id }, this.initFilters);
        }

        if (this.state.hierarchy === this.id) this.hierarchyChange(this.id, this.api.order, this.api.get, 3);
        if (this.state.hierarchy === this.options_id) this.hierarchyChange(this.options_id, this.api.order_options, this.api.get_options, 0);
    }

    refreshData = () => {
        let getScript = this.api.get;
        let h = this.state.hierarchy;
        switch (h) {
            case this.id:
                getScript = this.api.get; break;
            case this.options_id:
                getScript = this.api.get_options; break;
            // case this.extras_payment_id:
            // case this.extras_no_payment_id:
            // case this.extras_auto_id:
            case this.extras_id:
                getScript = this.api.get_extra; break;
        }

        this.props.dataActions.genericDataGetRequested(h, this.props.user.companyCode, this.props.user.token, getScript);

    }

    handleCancel = () => {
        this.setState({
            visible: false,
            hierarchy: null,
            levelId: null,
            levelName: null,
            optionNum: null,
            optionName: null,
            addPerTagButton: false,
            addPerTagButtonValue: null,
            addPerTagButtonType: null,
        }, () => {
            this.props.uiActions.hideModal(TableIDs.modalCreateEditFoodLevels);
        })
    }

    getTable = (id, columns) => {

        return (<ResponsiveTable
            id={id}
            idTable={this.props.data[id]}
            columns={columns}
            tableOnly={true}
            pagination={false}
            scroll={{ y: topHeight }}
            ui={this.props.ui}
            uiActions={this.props.uiActions}
        />)
    }

    hierarchyChange = (_id, _api, _get, _index) => {
        let tableElement = document.getElementById(_id);
        let lineElement = tableElement ? tableElement.getElementsByClassName('ant-table-row-level-0') : [];
        let data = this.props.data[_id].data;
        if (tableElement && lineElement[0]) {
            data.map((record, index) => {
                let line = lineElement[index];
                if (line) {
                    line.draggable = true;
                    line.ondragstart = (e) => {
                        e.dataTransfer.setData("keyStart", e.target.dataset.rowKey);
                    }
                    line.ondragover = (e) => { e.preventDefault() }
                    line.ondrop = (e) => {
                        let recordData = record.split("\f");
                        this.props.dataActions.genericDataSaveEditWithParams(
                            _id, this.props.user.companyCode, this.props.user.token, _api, _get,
                            { orderKey: e.dataTransfer.getData("keyStart"), order2: recordData[_index] });
                    }
                }
            });
        }
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";

            // let user = new User(this.props.user.token, this.props.user.companyCode);
            // let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            // job.setInput(dataSend);
            // job.send("/cgi-bin/CashOnTab",

            this.sendAPI("check_all_item_code", dataSend,
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemData = itemsRows[0].split('\f');
                    let _ItemCode = _ItemData[1];
                    let _ItemName = _ItemData[2];

                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data });
                    } else {
                        this.onChangeItemCode(_ItemCode, _ItemName)
                    }
                },
                (error) => {
                    message.error(this.getText(11253));
                }
            );
        }
    }

    keyboardListener(e) {
        let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                } else {
                    bufferUpdate(e.key)
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default:
                bufferUpdate(e.key)
                break;
        }
    }


    onChangeItemCode = (code, name) => {

        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            let hierarchy = this.state.hierarchy;
            let obj = hierarchy === this.options_id ? { oItemCode: code, oItemName: name } : { eItemCode: code, eItemName: name }
            this.props.dataActions.genericDataSetEdit(hierarchy, obj);
        }
    }

    checkAndCloseButtons = (id, edit, get, params) => {
        return (<span>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"} onClick={() => {
                let editedRecord = this.props.data[id].editedRecord;
                console.log('checkAndCloseButtons', this.state, this.props.data[id]);
                if (id === this.props.id
                    || (editedRecord['oItemCode'] && !this.state.optionNum)
                    || (editedRecord['eItemCode'] && editedRecord['eType'])
                ) {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        id,
                        this.props.user.companyCode,
                        this.props.user.token,
                        edit,
                        get,
                        params)
                } else {
                    message.error(this.getText(14071))
                }
            }} />
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"} onClick={() => {
                if (this.props.data[id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(id);
                else this.props.dataActions.genericDataCancelEditing(id)
            }} />
        </span >)
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    editButton = (id, record) => {
        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
            this.props.dataActions.genericDataStartEditing(id, record.key, record.index);
        }} />)
    }

    deleteButton = (text, id, record, sDelete, sGet) => {
        return (<Popconfirm title={this.getText(text[0])}
            onConfirm={() => { this.props.dataActions.genericDataDeleteRow(id, record.key, this.props.user.companyCode, this.props.user.token, sDelete, sGet) }}
            okText={this.getText(text[1])} cancelText={this.getText(text[2])}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
        </Popconfirm>)
    }

    tooltipButton = (titleText, icon, onClick) => {
        return (<Tooltip title={this.getText(titleText)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={icon} onClick={onClick} />
        </Tooltip>)
    }

    //     addPerTagButton = (oType) => {
    //         let buttonTitle = this.getText(12726);
    //         let tagList = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map((node) => {
    //             return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
    //                 {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
    //             </OptGroup>)
    //         });

    //         let typeNames = [
    //             this.getText(12681),
    //             this.getText(12680),
    //             this.getText(12728)
    //         ].map((x, i) => (<Option key={i} value={i + 2}>{x}</Option>));

    //         let content = (<span>
    //             <FormItem label={this.getText(14072)}>
    //                 <Select showSearch showArrow filterOption={true} optionFilterProp="valuefilter" style={{ width: 300 }}
    //                     placeholder={this.getText(12727)} value={this.state.addPerTagButtonValue} onChange={(e) => {
    //                         console.log("yuda success", e)
    //                         this.setState({ addPerTagButtonValue: e })
    //                     }}>{tagList}</Select>
    //             </FormItem>
    //             {oType > 1 ? (<FormItem label={this.getText(14073)}>
    //                 <Select showSearch showArrow style={{ width: 300 }} value={this.state.addPerTagButtonType} onChange={(e) => {
    //                     console.log("yuda success", e)
    //                     this.setState({ addPerTagButtonType: e })
    //                 }}>{typeNames}</Select>
    //             </FormItem>) : ""}
    //             <Button key="addPerTagButtonClose" onClick={() => {
    //                 this.setState({ addPerTagButton: false })
    //             }}>{this.getText(14075)}</Button>
    //             <Button key="addPerTagButtonAdd" type="primary" onClick={() => {
    //                 let type = oType === 1 ? 1 : this.state.addPerTagButtonType;
    //                 if (type && this.state.addPerTagButtonValue) {
    //                     this.setState({ addPerTagButton: false }, () => {
    //                         let data = "lID\foNum\foType\feTagId\r"
    //                             + this.state.levelId + "\f"
    //                             + this.state.optionNum + "\f"
    //                             + type + "\f"
    //                             + this.state.addPerTagButtonValue;
    //                         this.sendAPI(this.api.create_extra_tag, data, this.refreshData, (e) => { message.error(e) });
    //                     })
    //                 } else {
    //                     message.error(this.getText(14074))
    //                 }
    //             }}>{this.getText(14076)}</Button>
    //         </span>);
    // /////////// todoz:
    //         return [
    //             (<Tooltip title={buttonTitle}>
    //                 <Popover content={content} title={buttonTitle} placement="bottomLeft" trigger="click" visible={this.state.addPerTagButton}>
    //                     <Button key={"addPerTagButton"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
    //                         onClick={() => { this.setState({ addPerTagButton: true }) }}>
    //                         <Icon style={{ fontSize: 16 }} type={"gift"} theme="outlined" />
    //                     </Button>
    //                 </Popover>
    //             </Tooltip >)
    //         ];
    //     }

    addPerTagButton = (oType) => {
        return (<Tooltip title={this.getText(12726)}>
            <Button key={"addPerTagButton"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}
                onClick={() => { this.setState({ addPerTagButton: oType }) }}>
                <Icon style={{ fontSize: 16 }} type={"gift"} theme="outlined" />
            </Button>
        </Tooltip >)
    }

    addPerTagButtonModal = () => {
        let oType = this.state.addPerTagButton;
        let buttonTitle = this.getText(12726);
        let tagList = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map((node) => {
            return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
            </OptGroup>)
        });

        let typeNames = [
            this.getText(12681),
            this.getText(12680),
            this.getText(12728)
        ].map((x, i) => (<Option key={i} value={i + 2}>{x}</Option>));

        let content = (<span>
            <FormItem label={this.getText(14072)}>
                {/* <Select showSearch showArrow filterOption={true} optionFilterProp="valuefilter" style={{ width: '100%' }} */}
                <GenericSelectorItemTags {...this.props} key={"item_tags_" + (this.state.addPerTagButton === 1 ? 'l' : 'e')}
                    notReport
                    placeholder={this.getText(12727)} value={this.state.addPerTagButtonValue} onChange={(e) => {
                        console.log("yuda success", e)
                        this.setState({ addPerTagButtonValue: e })
                    }} />
                {/* }}>{tagList}</Select> */}
            </FormItem>
            {oType > 1 ? (<FormItem label={this.getText(14073)}>
                <Select showSearch showArrow style={{ width: '100%' }} value={this.state.addPerTagButtonType} onChange={(e) => {
                    console.log("yuda success", e)
                    this.setState({ addPerTagButtonType: e })
                }}>{typeNames}</Select>
            </FormItem>) : ""}

            <Button type="primary" icon="setting" onClick={() => {
                this.setState({ selectorBonTags: "forImportTag", selectorBonTagsData: null })
            }}><span style={{ marginRight: 5 }}>{this.getText(18965)}</span></Button>

        </span>);

        return [
            (<GenericModal
                title={buttonTitle}
                visible={this.state.addPerTagButton}
                onCancel={() => { this.setState({ addPerTagButton: false }) }}
                width={300}
                footer={[
                    <Button key="addPerTagButtonClose" onClick={() => {
                        this.setState({ addPerTagButton: false, addPerTagButtonValue: null })
                    }}>{this.getText(14075)}</Button>,
                    <Button key="addPerTagButtonAdd" type="primary" onClick={() => {
                        let type = oType === 1 ? 1 : this.state.addPerTagButtonType;
                        let tagId = this.state.addPerTagButtonValue;
                        let bonsData = this.state.addPerTagButtonBonsData;
                        if (type && this.state.addPerTagButtonValue) {
                            this.setState({ addPerTagButton: false, addPerTagButtonValue: null }, () => {
                                let data = "lID\foNum\foType\feTagId\feBonsData\r"
                                    + this.state.levelId + "\f"
                                    + this.state.optionNum + "\f"
                                    + type + "\f"
                                    + tagId + "\f"
                                    + (bonsData ? bonsData : "");
                                this.sendAPI(this.api.create_extra_tag, data, this.refreshData, (e) => { message.error(e) });
                            })
                        } else {
                            message.error(this.getText(14074))
                        }
                    }}>{this.getText(14076)}</Button>
                ]}>
                {content}
            </GenericModal>)
        ];
    }

    // extraButtons = (record) => {
    //     let subButton = (text, type, onClick) => {
    //         return (
    //             <Tooltip title={this.getText(text)}>
    //                 <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
    //             </Tooltip>)
    //     }

    //     let content = (<div>
    //         {subButton(12678, "dollar", () => {
    //             this.setState({ hierarchy: this.extras_payment_id, optionNum: record.oOptionNum, optionName: record.oItemName });
    //             this.props.dataActions.genericDataSetFilter(this.extras_payment_id, this.props.user.companyCode, this.props.user.token,
    //                 { oID: record.key, oType: 2 }, this.api.get_extra);
    //         })}
    //         {subButton(12679, "minus-circle", () => {
    //             this.setState({ hierarchy: this.extras_no_payment_id, optionNum: record.oOptionNum, optionName: record.oItemName });
    //             this.props.dataActions.genericDataSetFilter(this.extras_no_payment_id, this.props.user.companyCode, this.props.user.token,
    //                 { oID: record.key, oType: 3 }, this.api.get_extra);
    //         })}
    //         {subButton(12729, "minus-circle", () => {
    //             this.setState({ hierarchy: this.extras_auto_id, optionNum: record.oOptionNum, optionName: record.oItemName });
    //             this.props.dataActions.genericDataSetFilter(this.extras_auto_id, this.props.user.companyCode, this.props.user.token,
    //                 { oID: record.key, oType: 4 }, this.api.get_extra);
    //         })}
    //     </div>);

    //     return (
    //         <Tooltip title={this.getText(12732)}>
    //             <Popover content={content} title={this.getText(12733)} trigger="click">
    //                 <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} />
    //             </Popover>
    //         </Tooltip >)
    // }

    createNewItem = (onSave) => {
        this.props.uiActions.setIsEditFatherItem(false);
        this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { ...this.props.ui.data, itemId: null, onSave: onSave })
    }

    saveBonTags = () => {
        const { selectorBonTags, selectorBonTagsData } = this.state;
        if (selectorBonTags == "forImportTag") {
            this.setState({ addPerTagButtonBonsData: selectorBonTagsData, selectorBonTags: null, selectorBonTagsData: null });
            return;
        }
        let dataSend = 'fID\ffBonTags\r' + selectorBonTags + '\f' + (selectorBonTagsData ? selectorBonTagsData : '');
        this.sendAPI(this.api.edit_bons, dataSend, () => {
            this.setState({ selectorBonTags: null, selectorBonTagsData: null }, () => {
                message.success(this.getText(16280))
                this.refreshData()
            })
        }, e => { console.error(e) })
    }

    bonTagsModal = () => {
        return (<SelectorBonTags
            {...this.props}
            visible={this.state.selectorBonTags}
            onCancel={() => { this.setState({ selectorBonTags: null, selectorBonTagsData: null }) }}
            onOk={this.saveBonTags}
            value={this.state.selectorBonTagsData}
            onChange={(v) => { this.setState({ selectorBonTagsData: v }) }}
        />)
    }


    render() {

        let fName = this.props.ui.data ? this.props.ui.data['fName'] : '';
        let fID = this.props.ui.data ? this.props.ui.data['fID'] : 0;
        let levelId = this.state.levelId;
        let levelName = this.state.levelName;
        let optionNum = this.state.optionNum;
        let optionName = this.state.optionName;
        let extraTextTitle = "";
        let genericActionButtons = [];

        switch (this.state.hierarchy) {
            case this.options_id:
                genericActionButtons = this.addPerTagButton(1);
                break;

            case this.extras_id:
                extraTextTitle = " - " + this.getText(12989);
                genericActionButtons = this.addPerTagButton(2);
                break;



            // case this.extras_payment_id:
            //     extraTextTitle = " - " + this.getText(12681);
            //     genericActionButtons = this.addPerTagButton(2);
            //     break;

            // case this.extras_no_payment_id:
            //     extraTextTitle = " - " + this.getText(12680);
            //     genericActionButtons = this.addPerTagButton(3);
            //     break;

            // case this.extras_auto_id:
            //     extraTextTitle = " - " + this.getText(12728);
            //     genericActionButtons = this.addPerTagButton(4);
            //     break;
        }

        let blueArrow = (<span style={{ paddingRight: 10, paddingLeft: 10, color: '#00FFFF' }}>{">"}</span>)

        let mTitle = (<span style={{ padding: 4 }} onClick={() => {
            this.setState({ hierarchy: this.id, levelId: null, levelName: null, optionNum: null, optionName: null, addPerTagButton: false, addPerTagButtonValue: null }, this.refreshData)
        }}>{this.getText(12662) + " " + fName}</span>);

        if (levelId && levelName) {
            mTitle = (<span>{mTitle}<span style={{ padding: 4 }} onClick={() => {
                this.setState({ hierarchy: this.options_id, optionNum: null, optionName: null, addPerTagButton: false, addPerTagButtonValue: null }, this.refreshData)
            }}>{blueArrow}{levelName}</span></span>)
        }

        if (optionNum && optionName) {
            mTitle = (<span>{mTitle}<span style={{ padding: 4 }}>{blueArrow}{optionName + extraTextTitle}</span></span>)
        }


        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={mTitle}
                    actionButtons={[{
                        buttonType: 'add',
                        buttonFunction: () => {
                            this.props.dataActions.genericDataStartInlineCreate(this.state.hierarchy);
                            this.props.uiActions.showModal(this.state.hierarchy + "Editor", this.props.ui.data);
                        }
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    }]}
                    genericActionButtons={genericActionButtons}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let mobile = window.innerWidth <= 600 ? true : false;

        let columns = null;
        let percentageToMobile = null;
        let data = this.props.data[this.id];
        let value = (field) => data.editedRecord[field];
        let onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.id, { [f]: v }) }


        switch (this.state.hierarchy) {
            case this.id:

                data = this.props.data[this.id];
                value = (field) => data.editedRecord[field];
                onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.id, { [f]: v }) }

                columns = [
                    {
                        title: this.getText(12656),
                        dataIndex: "lName",
                        key: "lName",
                        width: '40%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<Input value={value('lName')} onChange={(v) => { onChange('lName', v.target.value) }} />)
                        },
                    }, {
                        title: this.getText(12657),
                        dataIndex: "lOptions",
                        key: "lOptions",
                        width: '10%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={1} value={value('lOptions')} onChange={(v) => { onChange('lOptions', v) }} />)
                        },
                    }, {
                        title: this.getText(14357),
                        dataIndex: "lMinOptions",
                        key: "lMinOptions",
                        width: '10%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} max={value('lOptions')} value={value('lMinOptions')} onChange={(v) => { onChange('lMinOptions', v) }} />)
                        },
                    }, {
                        title: this.getText(20268),
                        dataIndex: "lPrintGroupTitle",
                        key: "lPrintGroupTitle",
                        width: '10%',
                        render: (text, record) => {
                            let disabled = data.editing !== record.key;
                            let checked = disabled ? text == 1 : value('lPrintGroupTitle') == 1;
                            return (<Checkbox {...{ checked, disabled }} onChange={(v) => { onChange('lPrintGroupTitle', v.target.checked ? '1' : '0') }} />)
                        },
                    }, {
                        title: this.getText(20469),
                        dataIndex: "lShowAsButtons",
                        key: "lShowAsButtons",
                        width: '10%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} max={9} value={value('lShowAsButtons')} onChange={(v) => { onChange('lShowAsButtons', v) }} />)
                        },
                    }, {
                        title: this.getText(12658),
                        width: '15%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ?
                                (<span>
                                    {this.editButton(this.id, record)}
                                    {this.deleteButton([12668, 12669, 12670], this.id, record, this.api.delete, this.api.get)}
                                    {this.tooltipButton(12671, "table", () => {
                                        this.setState({ hierarchy: this.options_id, levelId: record.key, levelName: record.lName });
                                        this.props.dataActions.genericDataSetFilter(this.options_id, this.props.user.companyCode, this.props.user.token,
                                            { lID: record.key }, this.api.get_options);
                                    })}
                                    {this.tooltipButton(19470, "copy", () => {
                                        this.sendAPI("copy_food_level", "lID\r" + record.key, this.refreshData)
                                    })}
                                </span >)
                                : this.checkAndCloseButtons(this.id, this.api.create_edit, this.api.get, { fID: fID })
                        },
                    },
                ];
                break;

            case this.options_id:

                data = this.props.data[this.options_id];
                value = (field) => data.editedRecord[field];
                onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.options_id, { [f]: v }) }
                percentageToMobile = [70, 30];

                columns = [
                    {
                        title: this.getText(12672),
                        dataIndex: "oItemName",
                        key: "oItemName",
                        width: '38%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<span>
                                    <SelectorItems
                                        user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                                        dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                                        ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} itemType={1}
                                        value={value('oItemCode')}
                                        onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName) }}
                                        onChange={(s) => { onChange('oItemCode', s.target.value) }}
                                        onBlur={(s) => { this.checkIfItemExist(value('oItemCode')) }}
                                        onKeyDown={this.keyboardListener.bind(this)}
                                    />
                                    <span style={{ paddingRight: 10 }}><b>{value('oItemName') || this.getText(12667)}</b></span>
                                    <Button type={'primary'} style={{ margin: 5 }} onClick={() => {
                                        this.createNewItem((s) => {
                                            this.onChangeItemCode(s.mCode, s.mName);
                                            onChange('oItemCode', s.mCode);
                                        });
                                    }}>{'+ ' + this.getText(14351)}</Button>
                                </span >)
                        },
                    }, {
                        title: this.getText(12673),
                        dataIndex: "oOptionQty",
                        key: "oOptionQty",
                        width: '10%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} value={value('oOptionQty')} onChange={(v) => { onChange('oOptionQty', v) }} />)
                        },
                    }, {
                        title: this.getText(15693),
                        dataIndex: "oLimitMoreFreeOptions",
                        key: "oLimitMoreFreeOptions",
                        width: '10%',
                        render: (text, record) => {
                            return (<Checkbox
                                disabled={(data.editing !== record.key)}
                                checked={(data.editing !== record.key && text == '1') || value('oLimitMoreFreeOptions') == '1'}
                                onChange={(v) => { onChange('oLimitMoreFreeOptions', v.target.checked ? '1' : '0') }}
                            />)
                        },
                    }, {
                        title: this.getText(12688),
                        dataIndex: "oPrice",
                        key: "oPrice",
                        width: '15%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} step={0.01} value={value('oPrice')} onChange={(v) => {
                                    onChange('oPrice', v);
                                    if (v > 0) onChange('oPercentOff', 0);
                                }} />)
                        },
                    }, {
                        title: this.getText(12689),
                        dataIndex: "oPercentOff",
                        key: "oPercentOff",
                        width: '15%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} value={value('oPercentOff')} onChange={(v) => {
                                    onChange('oPercentOff', v);
                                    if (v > 0) onChange('oPrice', 0);
                                }} />)
                        },
                    }, {
                        title: this.getText(12674),
                        width: '12%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ?
                                (<span>
                                    {this.editButton(this.options_id, record)}
                                    {this.deleteButton([12675, 12676, 12677], this.options_id, record, this.api.delete_options, this.api.get_options)}
                                    {this.tooltipButton(12690, "eye", () => {
                                        this.props.uiActions.showModal(TableIDs.modalPriceListItemView,
                                            { ...this.props.ui.data, itemCode: record.oItemCode, itemName: record.oItemName, })
                                    })}
                                    {/* {this.extraButtons(record)} */}
                                    {this.tooltipButton(12990, "table", () => {
                                        this.setState({ hierarchy: this.extras_id, optionNum: record.oOptionNum, optionName: record.oItemName });
                                        this.props.dataActions.genericDataSetFilter(
                                            this.extras_id, this.props.user.companyCode, this.props.user.token, { oID: record.key }, this.api.get_extra);
                                    })}
                                    {this.tooltipButton(16267, 'block', () => {
                                        this.setState({ selectorBonTags: record.key, selectorBonTagsData: record.oBonTags });
                                    })}
                                </span >)
                                : this.checkAndCloseButtons(this.options_id, this.api.create_edit_options, this.api.get_options, { lID: this.state.levelId })
                        },
                    },
                ]

                break;

            case this.extras_id:
                // case this.extras_payment_id:
                // case this.extras_no_payment_id:
                // case this.extras_auto_id:

                data = this.props.data[this.state.hierarchy];
                value = (field) => data.editedRecord[field];
                onChange = (f, v) => {
                    console.log('onChange', this.state.hierarchy, { [f]: v })
                    this.props.dataActions.genericDataSetEdit(this.state.hierarchy, { [f]: v })
                }
                percentageToMobile = [60, 20, 20];

                columns = [
                    {
                        title: this.getText(12682),
                        dataIndex: "eItemName",
                        key: "eItemName",
                        width: '30%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<span>
                                    <SelectorItems
                                        user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                                        dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                                        ctrlBKeyFlag={null} isExpandMatrixFatherItem={false} itemType={1}
                                        value={value('eItemCode')}
                                        onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName) }}
                                        onChange={(s) => { onChange('eItemCode', s.target.value) }}
                                        onBlur={(s) => { this.checkIfItemExist(value('eItemCode')) }}
                                        onKeyDown={this.keyboardListener.bind(this)}
                                    />
                                    <span style={{ paddingRight: 10 }}><b>{value('eItemName') || this.getText(12667)}</b></span>
                                    <Button type={'primary'} style={{ margin: 5 }} onClick={() => {
                                        this.createNewItem((s) => {
                                            this.onChangeItemCode(s.mCode, s.mName);
                                            onChange('eItemCode', s.mCode);
                                        });
                                    }}>{'+ ' + this.getText(14351)}</Button>
                                </span>)
                        },
                    }, {
                        title: this.getText(13119),
                        dataIndex: "eType",
                        key: "eType",
                        width: '20%',
                        render: (text, record) => {
                            let typeNames = [
                                this.getText(12681),
                                this.getText(12680),
                                this.getText(12728)
                            ];

                            let options = typeNames.map((x, i) => (<Option key={i} value={i + 2}>{x}</Option>));

                            return (data.editing !== record.key) ? typeNames[parseInt(text) - 2] :
                                (<Select
                                    style={{ width: "100%" }}
                                    key={"extraTypes"}
                                    value={value('eType') ? parseInt(value('eType')) : null}
                                    onChange={(v) => { onChange('eType', v) }}
                                >{options}</Select>)
                        },
                    }, {
                        title: this.getText(12683),
                        dataIndex: "ePrice",
                        key: "ePrice",
                        width: '15%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} step={0.01} value={value('ePrice')} onChange={(v) => {
                                    onChange('ePrice', v);
                                    if (v > 0) onChange('ePercentOff', 0);
                                }} />)
                        },
                    }, {
                        title: this.getText(12689),
                        dataIndex: "ePercentOff",
                        key: "ePercentOff",
                        width: '15%',
                        render: (text, record) => {
                            return (data.editing !== record.key) ? text :
                                (<InputNumber min={0} value={value('ePercentOff')} onChange={(v) => {
                                    onChange('ePercentOff', v);
                                    if (v > 0) onChange('ePrice', 0);
                                }} />)
                        },
                    }, {
                        title: this.getText(12684),
                        width: '15%',
                        render: (text, record) => {
                            let h = this.state.hierarchy;
                            // let oType = h === this.extras_payment_id ? 2 : h === this.extras_no_payment_id ? 3 : 4
                            return (data.editing !== record.key) ?
                                (<span>
                                    {this.editButton(h, record)}
                                    {this.deleteButton([12685, 12686, 12687], h, record, this.api.delete_extra, this.api.get_extra)}
                                    {this.tooltipButton(12690, "eye", () => {
                                        this.props.uiActions.showModal(TableIDs.modalPriceListItemView,
                                            { ...this.props.ui.data, itemCode: record.eItemCode, itemName: record.eItemName })
                                    })}
                                    {this.tooltipButton(16267, 'block', () => {
                                        this.setState({ selectorBonTags: record.key, selectorBonTagsData: record.eBonTags });
                                    })}
                                </span >)
                                : this.checkAndCloseButtons(h, this.api.create_edit_extra, this.api.get_extra,
                                    { lID: this.state.levelId, oNum: this.state.optionNum })
                        },
                    },
                ]

                break;
        }

        return (
            <Modal
                destroyOnClose={true} width={mobile || this.state.hierarchy !== this.id ? '95%' : "50%"} visible={false || this.props.toggle}
                title={titleBar} onCancel={this.handleCancel} footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataAction={this.props.dataAction} >
                <ResponsiveTable
                    id={this.state.hierarchy}
                    idTable={this.props.data[this.state.hierarchy]}
                    columns={columns}
                    percentageToMobile={percentageToMobile}
                    tableOnly={true}
                    pagination={false}
                    scroll={{ y: topHeight }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <ModalPriceListItemView
                    id={TableIDs.modalPriceListItemView} user={this.props.user} ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalPriceListItemView)}
                />
                {this.addPerTagButtonModal()}
                {this.bonTagsModal()}
            </Modal >)
    }
}

export default ModalCreateEditFoodLevels;
