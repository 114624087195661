import React, { Component } from "react"
import GenericModal from "../../components/GenericModal"
import GenericTable from "../../components/GenericTable"
import GenericSelector from "../../components/GenericSelector"
import TableLineIcons from "../../components/TableLineIcons"
import LangContext from "../../contextProvider/LangContext"
import { Input } from "antd"


class PizzaSlicesExchange extends Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.id = "PIZZA_SLICES_EXCHANGE"
        this.type_id = "PIZZA_SLICES_TYPES"

        this.api = {
            get: "get_pizza_slices_exchange",
            get_types: "get_pizza_slices_types",
            edit: "edit_pizza_slices_exchange",
            create: "create_pizza_slices_exchange",
            delete: "delete_pizza_slices_exchange",
        }

        this.selectors = [
            { id: "PIZZA_SLICES_TYPES", api: "get_pizza_slices_types" }
        ]

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    typeSelector = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]

        return (<GenericSelector
            {...this.props} {...this.selectors[0]}
            value={editing == record.key ? editedRecord.pTypeId : text}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { pTypeId: e }) }}
            disabled={editing != record.key}
        />)
    }

    textView = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]

        return editing != record.key ? text :
            (<Input
                {...this.props} {...this.selectors[0]}
                value={editedRecord.pText}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { pText: e.target.value }) }}
            />)
    }

    editIcons = (text, record) => {
        const { editing } = this.props.data[this.id];
        if (editing == record.key) {
            if (editing == 0) {
                return (<TableLineIcons
                    onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.create, this.api.get) }}
                    onClose={() => { this.props.dataActions.genericDataCancelInlineCreate(this.id) }}
                />)
            } else {
                return (<TableLineIcons
                    onCheck={() => { this.props.dataActions.genericDataSaveEdit(this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get) }}
                    onClose={() => { this.props.dataActions.genericDataCancelEditing(this.id) }}
                />)
            }
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
                deleteQuetion={{ title: this.getText(20500), okText: this.getText(20501), cancelText: this.getText(20502) }}
            />)
        }
    }


    render() {
        const gt = this.getText

        return (<GenericModal
            {...this.props}
            title={gt(20496)}
            width={600}
            actionButtons={[
                { buttonType: 'add', buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) } },
                { buttonType: 'refresh', buttonFunction: this.refreshData },
            ]}>
            <GenericTable
                {...this.props}
                tableOnly
                id={this.id}
                getApi={this.api.get}
                columns={[
                    { title: gt(20497), key: "pTypeId", dataIndex: "pTypeId", width: "40%", render: this.typeSelector },
                    { title: gt(20498), key: "pText", dataIndex: "pText", width: "40%", render: this.textView },
                    { title: gt(20499), width: "20%", render: this.editIcons },
                ]}
                pagination={{ pageSize: parseInt((window.innerHeight - 250) / 40) ?? 1 }}
            />
        </GenericModal>)
    }
}

export default PizzaSlicesExchange