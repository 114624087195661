/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Upload, Icon, Modal, message, Spin, Progress, Alert, Button } from "antd";
import { Job, User, OutputType, ProcessType /*, JobResponse*/ } from '../JmReact';
import LangContext from "../contextProvider/LangContext";

type formEntPoint = {
  token: String,
  companyCode: String,
  entpoint: String,
  success: String,
  error: String,
}

type Props = {
  url: string,
  videoType: string,
  boxSize: number,
  token: string,
  companyCode: string,
  onUploadComplete: Function,
  formEntPoint: formEntPoint,

  uploadScriptName: string,
  isShared: Boolean,
};

type FileItem = { uid: number, name: string, status: string, url: string };

type State = {
  previewVisible: boolean,
  previewvideo: string,
  fileList: Array<FileItem>
};

export class VideoView extends React.Component<Props, State> {
  state = {
    previewVisible: false,
    previewvideo: '',
    fileList: [],
    fileBlob: {},
    chunkLeft: null,
    chunkCount: null,
    errorToMatch: false,
    errorOther: false,

  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file: Object) => {
    this.setState({
      previewvideo: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = (e) => {
    if (e.file.size > 20971520) {
      message.error(this.getText(18951));
      this.onRemove()
      return;
    }
    this.setState({ fileList: e.fileList }, () => {
      if (e.file.status !== "removed") {
        this.handleUpload(e.file)
      }
    });
  };

  static contextType = LangContext;

  getText = (id) => {
    if (this.props.getText) return this.props.getText(id)
    else return this.context && this.context.get(id) ? this.context.get(id) : '[' + id + ']'
  }

  /*
  getBase64(file: File, callback: Function) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(file);
  }
  
  onUpload(uploadedData: Object) {
      window.console.log(uploadedData);
      this.getBase64(uploadedData.file, this.handleUpload.bind(this));
  }
  */

  getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => { callback(reader.result) });
    reader.readAsDataURL(file);
  }

  getImgWidthAndHeight = (src, isImage, callback) => {
    const img = document.createElement(isImage ? "img" : "video");
    img.src = src;
    let imgData = document.body.appendChild(img);
    setTimeout(() => {
      const { width, height } = imgData.getClientRects()[0];
      callback({ width, height })
      setTimeout(() => {
        imgData.remove();
      }, 250);
    }, 1000);
  }

  handleUpload = (fileBlob) => {
    this.getBase64(fileBlob, (fileUrl) => {
      // const { width, height } = 

      // console.log("test image upload", width, height)

      let videoData = fileUrl.split(',')[1];
      let chunkCount = Math.ceil(videoData.length / 1000002);
      let chunkValue = videoData.slice(0, 1000002);
      videoData = videoData.slice(1000002);
      let chunkLeft = chunkCount - 1;
      let vidSize = fileBlob.size;
      let videoName = fileBlob.name.split('.')[0];
      let videoExt = fileBlob.name.split('.').slice(-1)[0];
      let videoMeta = fileUrl.split(',')[0];
      let isImage = /\.(jpe?g|png|gif|bmp)$/i.test(fileBlob.name)
      this.getImgWidthAndHeight(fileUrl, isImage, (r) => {

        if (!this.props.notLimit && (r.width > 1281 || r.height > 1281 || (r.width > 721 && r.height > 721))) {
          message.error(this.getText(19019))
          setTimeout(() => {
            this.setState({
              chunkLeft: null,
              chunkCount: null,
              errorToMatch: false,
              errorOther: false,
            }, this.onRemove)
          }, 1000);
        } else if (chunkCount > 100) {
          this.setState({ errorToMatch: true })
        } else {
          this.setState({ fileBlob, chunkLeft, chunkCount }, () => {
            this.saveVideo(fileBlob, chunkLeft, chunkCount, vidSize, '0', videoName, videoExt, videoMeta, videoData, chunkValue);
          });
        }
      });
    });
  }

  saveVideo = (fileBlob, chunkLeft, chunkCount, vidSize, vidUUID, videoName, videoExt, videoMeta, videoData, chunkValue) => {
    // let testData = new RegExp(/[-A-Za-z0-9+/]/);
    // chunkValue.split("").forEach((x, i) => {
    //   if (i < 1000)
    //     console.log("saveVideo test ", x, i, testData.test(x))
    // })

    let formEntPoint = this.props.formEntPoint;
    let token = formEntPoint ? formEntPoint.token : this.props.user.token;
    let companyCode = formEntPoint ? formEntPoint.companyCode : this.props.user.companyCode;
    let entpoint = formEntPoint ? formEntPoint.entpoint : "CashOnTab";
    let script = this.props.uploadScriptName || "upload_video";
    let user = new User(token, companyCode);
    let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC, (entpoint === "CashOnTab") ? false : true);

    job.setSection("videoName", videoName);
    job.setSection("videoExt", videoExt);
    job.setSection("videoMeta", videoMeta);
    job.setSection("videoData", chunkValue);
    job.setSection("vidChunk", "vidUUID\fchunkLeft\fvidSize\r" + vidUUID + "\f" + chunkLeft + "\f" + vidSize);

    job.send("/cgi-bin/" + entpoint, (ob) => {
      let res = ob.data ? ob.data.split('\r') : [];
      let keys = res[0] ? res[0].split('\f') : []
      let values = res[1] ? res[1].split('\f') : [];
      let resObj = {}
      keys.forEach((x, i) => { resObj = { ...resObj, [x]: values[i] } });

      console.log('saveVideo open')
      if (!resObj.isLast || !resObj.uuid) {
        this.errorCallback('')
        // console.log('saveVideo 1', ob.data)
      } else if (resObj.isLast == '1' && chunkLeft <= 0) {
        // console.log('saveVideo 2', ob.data)
        this.successCallback({ data: resObj.uuid })
      } else if (resObj.isLast == '0' && chunkLeft > 0) {
        // console.log('saveVideo 3', ob.data)
        chunkLeft--;
        chunkValue = videoData.slice(0, 1000002);
        videoData = videoData.slice(1000002);
        vidUUID = resObj.uuid.split('.')[0];
        this.setState({ chunkLeft }, () => {
          setTimeout(() => {
            this.saveVideo(fileBlob, chunkLeft, chunkCount, vidSize, vidUUID, videoName, videoExt, videoMeta, videoData, chunkValue)
          }, 250);
        })
      } else {
        // console.log('saveVideo 4', ob.data)
        this.errorCallback('')
      }

    }, this.errorCallback);
  }

  successCallback = (ob: Object) => {

    console.log("success: ", ob.data);
    let companyCode = this.props.formEntPoint ? this.props.formEntPoint.companyCode : this.props.user.companyCode;

    // NEW
    companyCode = (this.props.isShared && this.props.isShared === true) ? 'shared' : companyCode;

    // let success = this.props.formEntPoint ? this.props.formEntPoint.success : this.getText(11336);
    let origin = window.location.origin;
    const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
    let baseUrl = isDev ? "http://147.235.163.248" : origin;
    this.setState({
      chunkLeft: null,
      chunkCount: null,
      errorToMatch: false,
      errorOther: false,
      previewvideo: baseUrl + "/uploads/" + companyCode + "/" + ob.data,
      previewVisible: true,
    }, () => {
      if (this.props.onChange) this.props.onChange(ob.data);
      if (this.props.onUploadedSuccess) this.props.onUploadedSuccess(ob.data, baseUrl + "/uploads/" + companyCode + "/" + ob.data);
      message.success('ההעלאה הסתיימה בהצלחה!');
      setTimeout(() => {
        let videoElement = document.getElementById('videoElement');
        let duration = videoElement ? videoElement.duration : 0;
        this.props.onLoadDuration(duration)
      }, 1000);
    })
  }

  errorCallback = (error: any) => {
    this.setState({ errorOther: true }, () => {
      message.error('ההעלאה נכשלה (', error, ')');
      setTimeout(() => {
        this.setState({
          chunkLeft: null,
          chunkCount: null,
          errorToMatch: false,
          errorOther: false,
        }, this.onRemove)
      }, 1000);
    })
  }

  getOldUrl = () => {
    let companyCode = (this.props.isShared && this.props.isShared === true) ? 'shared'
      : this.props.formEntPoint ? this.props.formEntPoint.companyCode : this.props.user.companyCode;

    let origin = window.location.origin;
    const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
    let baseUrl = isDev ? "http://147.235.163.248" : origin;

    let url = this.props.url && this.props.url !== '' ?
      baseUrl + "/uploads/" + companyCode + "/" + this.props.url
      : null;

    this.setState({ fileList: url ? [{ uid: -1, name: this.props.url, status: "done", url: url }] : [] });
  }

  componentDidMount() {
    this.getOldUrl();


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.url !== this.props.url) {
      this.getOldUrl();
    }
  }

  onRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      if (this.props.onRemove) this.props.onRemove();

      return { fileList: newFileList, };
    })
  }

  render() {
    const { previewVisible, previewvideo, fileList } = this.state;
    const uploadButton = (<div><Icon type="plus" /><div className="ant-upload-text">Upload</div></div>);
    let videoElement = document.getElementById('videoElement');
    if (videoElement) console.log('videoElement', videoElement.getRootNode())

    // let videoURL = this.state.fileList[0] ? this.state.fileList[0].url : "";
    let isImage = /\.(jpe?g|png|gif|bmp)$/i.test(previewvideo)

    let companyCode = (this.props.isShared && this.props.isShared === true) ? 'shared'
      : this.props.formEntPoint ? this.props.formEntPoint.companyCode : this.props.user.companyCode;

    let origin = window.location.origin;
    const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
    let baseUrl = isDev ? "http://147.235.163.248" : origin;
    let fullUrlStart = this.props.url ? baseUrl + "/uploads/" + companyCode + "/" + this.props.url : null;

    let percent = parseInt(((this.state.chunkCount - this.state.chunkLeft) / this.state.chunkCount) * 100);
    let status = this.state.errorToMatch || this.state.errorOther ? "error" : this.state.chunkLeft ? "active" : "success";


    return (<div className="clearfix">
      {this.props.readOnly ?
        /\.(jpe?g|png|gif|bmp)$/i.test(this.props.url) ?
          (<img alt="example" style={{ height: this.props.size }} src={fullUrlStart} onClick={() => { this.handlePreview({ url: fullUrlStart }) }} />) :
          (<video style={{ height: this.props.size }} src={fullUrlStart} onClick={() => { this.handlePreview({ url: fullUrlStart }) }}></video>) :
        (<Upload
          listType={"picture"}
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.onRemove}
          beforeUpload={(file) => {
            console.log("file: ", file);
            this.setState(
              state => ({ fileList: [...state.fileList, file], })
            );
            return false;
          }} >
          {!fileList.length ? (
            <Button key={"upButton"}><Icon type="upload" />{'העלה קובץ'}</Button>
          )
            //  : 
            // !(/\.(jpe?g|png|gif|bmp)$/i.test(this.props.url)) ?
            //   (<video style={{ height: 100 }} src={fullUrlStart}></video>)
            : ""}
        </Upload>)
      }
      <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>

        {isImage ?
          (<img alt="example" style={{ width: '100%' }} src={previewvideo} />) :
          (<video style={{ width: '100%' }} controls id={'videoElement'} src={previewvideo}></video>)}
      </Modal>
      <Modal visible={this.state.chunkCount} footer={null}>
        <Spin />
        <Progress percent={percent} status={status} />
        <Alert style={{ marginTop: 20 }} message={status == 'error' ? 'ההעלאה נכשלה' : 'מעלה קובץ'} type={status == 'error' ? "error" : "info"} />
      </Modal>
    </div>
    );
  }

  // TODO: imp more informative error response

}

export default VideoView;
