/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import { Row, Col, Tooltip } from "antd";
import DividedImage from "../../components/DividedImage"
// import baseImg from "../../skins/pizza/base.png";

// type Props {
//     extraData: { eID, eItemCode, eItemName, ePrice, eType, eIsChecked, eLocals, eImage },
//     baseImg: String,
//     areasQty: Number,
//     onChange: Function,
//     getText: Function,
// }

class PizzaButtons extends Component {
    buttonSize = window.innerWidth <= 700 ? 30 : 40;
    buttonGroupWidth = window.innerWidth <= 700 ? 36 : 48;
    buttonLineHeight = window.innerWidth <= 700 ? 0.7 : 1;

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return  baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    getButtonsLine = (list) => {
        let style = { display: 'inline-block', verticalAlign: 'middle', width: this.buttonGroupWidth, lineHeight: this.buttonLineHeight }
        switch (list.length) {
            case 1:
                return (<div style={style}>{list[0]}</div>)
            case 2:
                return (<div style={style}>
                    <Row>
                        <Col span={12}>{list[0]}</Col>
                        <Col span={12}>{list[1]}</Col>
                    </Row>
                </div>)
            case 4:
                return (<div style={style}>
                    <Row style={{marginBottom: 5}}>
                        <Col span={12}>{list[0]}</Col>
                        <Col span={12}>{list[1]}</Col>
                    </Row>
                    <Row>
                        <Col span={12}>{list[2]}</Col>
                        <Col span={12}>{list[3]}</Col>
                    </Row>
                </div>)
        }
    }

    getButton = (title, width, height, rotate, blocals) => {
        let border = null;
        let v = this.props.value;
        let l = this.getPizzaMap(blocals)
        if (v.r1 === l.r1 && v.r2 === l.r2 && v.r3 === l.r3 && v.r4 === l.r4) border = '1px solid red';

        console.log('getButton', v, l, title)

        return (
            <div style={{ width, height, border }}>
                {/* <Tooltip title={title}> */}
                <DividedImage
                    width={width}
                    height={height}
                    rotate={rotate}
                    image={this.imgUrl(this.props.baseImg)}
                    fullWidth={this.buttonSize}
                    title={title}
                    onClick={() => { this.props.onChange({ ...l, title }) }}
                />
                {/* </Tooltip> */}
            </div>)
    }

    getPizzaMap = (localList) => {
        let res = { r1: false, r2: false, r3: false, r4: false }

        if (localList) {
            switch (this.props.areasQty) {
                case 1:
                    if (localList.indexOf(1) > -1) res = { r1: true, r2: true, r3: true, r4: true }
                    break;

                case 2:
                    if (localList.indexOf(1) > -1) res = { ...res, r1: true, r2: true }
                    if (localList.indexOf(2) > -1) res = { ...res, r3: true, r4: true }
                    break;

                case 4:
                    if (localList.indexOf(1) > -1) res = { ...res, r1: true }
                    if (localList.indexOf(2) > -1) res = { ...res, r2: true }
                    if (localList.indexOf(3) > -1) res = { ...res, r3: true }
                    if (localList.indexOf(4) > -1) res = { ...res, r4: true }
                    break;
            }
        }

        return res;
    }

    render() {
        let buttons = [];

        let allText = 'על הכל';
        let helf1 = 'חצי ימני';
        let helf2 = 'חצי שמאלי';
        let r1 = 'רבע ימני עליון';
        let r2 = 'רבע ימני תחתון';
        let r3 = 'רבע שמאלי תחתון';
        let r4 = 'רבע שמאלי עליון';

        let title = this.props.value.title ? this.props.value.title : allText;

        switch (this.props.areasQty) {
            case 1:
                buttons = this.getButtonsLine([
                    this.getButton(allText, this.buttonSize, this.buttonSize, 0, [1])
                ]);
                break;

            case 2:
                buttons = [
                    this.getButtonsLine([
                        this.getButton(allText, this.buttonSize, this.buttonSize, 0, [1, 2])
                    ]),
                    this.getButtonsLine([
                        this.getButton(helf1, this.buttonSize / 2, this.buttonSize, 0, [1]),
                        this.getButton(helf2, this.buttonSize / 2, this.buttonSize, 180, [2]),
                    ]),
                ];
                break;

            case 4:
                buttons = [
                    this.getButtonsLine([
                        this.getButton(allText, this.buttonSize, this.buttonSize, 0, [1, 2, 3, 4])
                    ]),
                    this.getButtonsLine([
                        this.getButton(helf1, this.buttonSize / 2, this.buttonSize, 0, [1, 2]),
                        this.getButton(helf2, this.buttonSize / 2, this.buttonSize, 180, [3, 4]),
                    ]),
                    this.getButtonsLine([
                        this.getButton(r1, this.buttonSize / 2, this.buttonSize / 2, 0, [1]),
                        this.getButton(r4, this.buttonSize / 2, this.buttonSize / 2, 270, [4]),
                        this.getButton(r2, this.buttonSize / 2, this.buttonSize / 2, 90, [2]),
                        this.getButton(r3, this.buttonSize / 2, this.buttonSize / 2, 180, [3]),
                    ]),
                ];
                break;
        }

        return (<div>
            <div className={this.props.getFontStyle2('text_item')} style={{ textAlign: 'center' }}>{title}</div>
            {buttons}
        </div>)
    }
}

export default PizzaButtons;