/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import {
    Row, Col, Button, Modal, Input, InputNumber, message, Card, Select, Spin
} from "antd";
import FoodsFormatCart from "./FoodsFormatCart";
import FoodOrderBenefits from "./FoodOrderBenefits";
import SwiichButton from "../../components/SwiichButton";
import TableIDs from '../../data/TableIDs';
import moment from "moment";

const { Option } = Select;

class FoodOrderPayments extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            successSale: null,
            modalMismatch: false,
            modalMivzaimChange: false,
            cardcomUrl: false,
            lowprofilecode: false,
            DealResponse: false,
            InternalDealNumber: false,
            paymentTypeCheck: false,
            paymentType: null,
            lastPaymentType: null,
            numberOfPay: 1,
            paymentPartial: 0,
            paymentSum: null,
            ownerID: null,
            ownerNum: null,
            paymentBalance: null,
            paymentTotal: null,
            chargeCardInput: '',
            cibusCompany: '0',
            pointsToUse: 0,
            pointsDisable: false,
            swiichView: false,
            swiichWalletId: null,
            couponsWaiting: [],
            sensepayWaiting: 0,
        }

        this.profileCodeUpdated = null;

        this.linkOwnerNumToCust = false;

        this.mobile = window.innerWidth <= 600 ? true : false;

        this.order_id = "FOOD_FORMAT_ORDER_FIELDS";
        this.cart_id = "FOOD_FORMAT_CART";
        this.dQtn_id = "DELIVERY_QTN_TABLE";
        this.payments_id = "FOOD_ORDER_PAYMENTS";
        this.setting_id = "FORMAT_SITE_SETTINGS";
        this.cibus_id = "CIBUS_COMPANIES";
        this.points_id = "FORMAT_SITE_POINTS";
        this.mivzaim_id = "FORMAT_SITE_MIVZAIM";
        this.mivzaim_cart_id = "FORMAT_MIVZAIM_CART";
        this.coupons_id = "FORMAT_COUPONS_CART";

        if (props.data[this.cart_id] === undefined) props.data[this.cart_id] = { data: [] };
        if (props.data[this.mivzaim_id] === undefined) props.data[this.mivzaim_id] = { data: [] };
        if (props.data[this.mivzaim_cart_id] === undefined) props.data[this.mivzaim_cart_id] = { data: [] };
        if (props.data[this.order_id] === undefined) props.data[this.order_id] = { orderType: '0', orderFields: {} };
        if (props.data[this.dQtn_id] === undefined) props.data[this.dQtn_id] = { data: [] };
        if (props.data[this.payments_id] === undefined) props.data[this.payments_id] = { dataset: [] };
        if (props.data[this.setting_id] === undefined) props.data[this.setting_id] = {};
        if (props.data[this.cibus_id] === undefined) props.data[this.cibus_id] = { dataset: [] };
        if (props.data[this.points_id] === undefined) props.data[this.points_id] = {};
        if (props.data[this.coupons_id] === undefined) props.data[this.coupons_id] = { dataset: [] };

        this.api = {
            check_items: "check_prices_items_to_site",
            check_options: "check_prices_options_to_site",
            check_pos_items: "check_owner_items_in_pos",

            save_order: "save_order_from_site",
            save_order_lines: "save_order_lines_from_site",
            save_mivzaim: "create_mivzaim_to_site",
            save_payment: "save_order_payment_from_site",
            get_payments: "get_payments_from_site",
            charge_card: "save_order_charge_card",
            set_points: "save_order_points",
            set_coupon: "save_order_coupon",
            json_multipass: "get_order_lines_to_json_multi_pass",
            use_multipass: "multi_pass_add_transaction",

            sensepay_init: "sensepay_transaction_init",
            sensepay_get: "sensepay_transaction_get",
            sensepay_commit: "sensepay_transaction_commit",

            save_inline_discount: "save_inline_discount_order_from_pos",
        }
    }

    componentDidMount() {
        this.checkPrices(this.saveOrder);
        this.apiCibusCompanies();

        this.props.sendAPI(this.api.get_payments, "", (ob) => {
            let dataset = ob.data.split("\r").map((x) => {
                let y = x.split("\f");
                return { code: y[0], name: y[1] }
            })
            this.props.setJson(this.payments_id, { dataset: dataset });
        }, this.props.errorCallback)
    }

    componentDidUpdate(p) {
        if (p.isView && !this.props.isView && !this.props.ownerSave) {
            this.setState({ ownerID: null, ownerNum: null });
        }

        if (!this.linkOwnerNumToCust &&
            this.props.data[this.setting_id].pLinkToOrder == '1' &&
            this.state.successSale &&
            this.state.ownerID &&
            ((this.state.paymentTypeCheck && this.state.paymentType == '1') || this.state.paymentBalance == '0.00')
        ) {
            this.linkOwnerNumToCust = true;
            let orderFields = this.props.data[this.order_id].orderFields;

            let dataSend = "_type\f_params\f_mobile\f_lang\r5\fownerID=" + this.state.ownerID + "\f" + orderFields.dPhone + "\f" + this.props.lang;
            this.props.sendAPI("create_random_connection_token", dataSend, () => { }, this.props.errorCallback)
        }
    }

    getPayList = () => {
        let payParams = this.props.data[this.setting_id];
        let oData = this.props.data[this.order_id];
        let dataset = this.props.data[this.payments_id].dataset.map((x) => {
            return x.code == '101' ? { code: '101', name: this.props.getText(15801) } : x
        })

        if (payParams.pIs10Bis === '1')
            dataset.push({ code: '10bis', name: this.props.getText(13531) })
        if (payParams.pIsCibus === '1')
            dataset.push({ code: 'cibus', name: this.props.getText(13532) })
        if (payParams.pIsGoodi === '1')
            dataset.push({ code: 'goodi', name: this.props.getText(13533) })
        if (payParams.pIsCustDebit === '1')
            dataset.push({ code: 'debit', name: this.props.getText(13534) })
        if (payParams.pIsCustPoints === '1' && oData.orderFields.cID && this.props.data[this.points_id].TotalPointsForUse > 0 && !this.state.pointsDisable)
            dataset.push({ code: 'points', name: this.props.getText(14196) })

        return dataset;
    }

    onChangePay = (e) => {
        let oData = this.props.data[this.order_id];
        this.setState({ paymentType: e, lastPaymentType: e, chargeCardInput: '' }, () => {
            if (this.state.paymentType === 'points' && oData.orderFields.cID) {
                this.props.apiPoints(oData.orderFields.cID);
                setTimeout(() => {
                    let pointsToUse = this.props.data[this.points_id].pointsForThisSale
                    this.setState({ pointsToUse })
                    console.log({ pointsToUse })
                }, 1000);
            }
        })
    }

    webViewEndOrder = (id, number) => {
        console.log('test webview lazi load 3', moment())
        if (window.Android && window.Android.webViewEndOrder) {
            window.Android.webViewEndOrder(id, number);
        }
        this.setState({ ownerID: null, ownerNum: null }, () => {
            console.log('test webview lazi load 4', moment())
            this.props.webViewChangeMethod();
            this.clearStoreWhenEndOrder();
            this.props.ownerUpdate(false)
            setTimeout(() => {
                this.props.changeScreen(1);
                this.props.setJson(this.order_id + ".orderFields", { futureTime: '', futureDate: '' })
            }, 3000)
        })
    }

    modalMismatch = () => {
        let cData = this.props.data[this.cart_id].data;
        let sData = this.state.modalMismatch;
        if (sData) {
            sData = sData.map((x) => { return { ...x, iName: cData[parseInt(x.iIndex)].item.iName } });

            let buttonsStyle = {
                margin: 10,
                width: 140,
                height: 50,
                ...this.props.buttonStyle
                // background: '#f0b619',
            }

            return (<Modal
                title={this.props.getClassNameStyle("title_block")}
                visible={sData} onCancel={() => this.setState({ modalMismatch: false })} destroyOnClose={true} width={600}
                footer={[
                    <Button style={buttonsStyle} onClick={() => { this.setState({ modalMismatch: false }, () => { this.props.changeScreen(1) }) }}>
                        <span className={"text_item"} style={this.props.buttonStyle}>{this.props.getText(13116)}</span>
                    </Button>
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <div className={"text_item"}>
                    <h1 style={{ textAlign: 'center' }}>{this.props.getText(13117)}</h1>
                    <h2 style={{ textAlign: 'center' }}>{this.props.getText(13118)}:</h2>
                    {sData.map((x) => (<p style={{ textAlign: 'center' }}>{x.iName} - {x.iPrice}</p>))}
                </div>
            </Modal>)
        } else {
            return ""
        }
    }

    modalMivzaimChange = () => {
        let mData = this.props.data[this.mivzaim_id].data;
        let sData = this.state.modalMivzaimChange;
        if (sData) {
            sData = sData.map((x) => {
                let m = mData.find(f => f.mId === x.id);
                return { ...x, name: m ? m.mDesc : "" }
            });

            let buttonsStyle = {
                margin: 10,
                width: 140,
                height: 50,
                ...this.props.buttonStyle
                // background: '#f0b619',
            }

            return (<Modal
                title={this.props.getClassNameStyle("title_block")}
                visible={sData} onCancel={() => this.setState({ modalMivzaimChange: false })} destroyOnClose={true} width={600}
                footer={[
                    <Button style={buttonsStyle} onClick={() => { this.setState({ modalMivzaimChange: false }, () => { this.props.changeScreen(1) }) }}>
                        <span className={"text_item"} style={this.props.buttonStyle}>{this.props.getText(14260)}</span>
                    </Button>
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <div className={"text_item"}>
                    <h1 style={{ textAlign: 'center' }}>{this.props.getText(14261)}</h1>
                    <h2 style={{ textAlign: 'center' }}>{this.props.getText(14262)}:</h2>
                    {sData.map((x) => (<p style={{ textAlign: 'center' }}>{x.name} - {x.value}</p>))}
                </div>
            </Modal>)
        } else {
            return ""
        }
    }

    checkPrices = (next) => {
        let cData = this.props.data[this.cart_id].data;
        console.log("cData", cData)
        let onSuccess = () => { this.setState({ successSale: true, modalMismatch: false }, next ? () => { next(cData) } : () => { }) }
        if (this.props.isFromPos) {
            onSuccess()
        } else {
            let orderFields = this.props.data[this.order_id].orderFields;
            let priceList = orderFields.dPriceList ? orderFields.dPriceList : "";
            let branch = this.props.isFromPos && orderFields.dBranch ? orderFields.dBranch : "0";
            let modalMismatch = [];

            let itemsData = "iCode\fiPrice\fiIndex\fiPriceList";
            let optionsData = "";

            cData.filter(f => !f.item.isDiscountItem).forEach((x, i) => {
                itemsData += "\r" + x.item.iCode + "\f" + x.item.iPrice + "\f" + i + "\f" + priceList;
                if (x.levels) {
                    x.levels.forEach((y) => {
                        y.ownerData.forEach((z) => {
                            let oPrice = parseFloat(z.oPrice ? z.oPrice : 0);
                            let oExtrasList = "";
                            let oFreeExtrasList = "";
                            let oExtrasList05 = "";
                            let oFreeExtrasList05 = "";
                            let oExtrasList025 = "";
                            let oFreeExtrasList025 = "";
                            let oExtrasList075 = "";
                            let oFreeExtrasList075 = "";
                            if (z.oExtraItems) {
                                z.oExtraItems.forEach((e) => { oPrice = parseFloat(e.ePrice) + parseFloat(oPrice) });
                                oExtrasList = z.oExtraItems.filter(f => f.eType === "2" && (!f.eLocals || f.eLocals.length == z.oPizzaPiecesNum)).map((e) => e.eID).join(",");
                                oFreeExtrasList = z.oExtraItems.filter(f => f.eType === "3" && (!f.eLocals || f.eLocals.length == z.oPizzaPiecesNum)).map((e) => e.eID).join(",");
                                oExtrasList05 = z.oExtraItems.filter(f => f.eType === "2" && f.eLocals && f.eLocals.length * 2 == z.oPizzaPiecesNum).map((e) => e.eID).join(",");
                                oFreeExtrasList05 = z.oExtraItems.filter(f => f.eType === "3" && f.eLocals && f.eLocals.length * 2 == z.oPizzaPiecesNum).map((e) => e.eID).join(",");
                                oExtrasList025 = z.oExtraItems.filter(f => f.eType === "2" && f.eLocals && f.eLocals.length == 1 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                                oFreeExtrasList025 = z.oExtraItems.filter(f => f.eType === "3" && f.eLocals && f.eLocals.length == 1 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                                oExtrasList075 = z.oExtraItems.filter(f => f.eType === "2" && f.eLocals && f.eLocals.length == 3 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                                oFreeExtrasList075 = z.oExtraItems.filter(f => f.eType === "3" && f.eLocals && f.eLocals.length == 3 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                            }
                            optionsData += "\r" + z.oID + "\f" +
                                oExtrasList + "\f" +
                                oFreeExtrasList + "\f" +
                                z.oFreeExtras + "\f" +
                                oPrice + "\f" +
                                i + "\f" +
                                priceList + "\f" +
                                branch + "\f" +
                                oExtrasList05 + "\f" +
                                oFreeExtrasList05 + "\f" +
                                oExtrasList025 + "\f" +
                                oFreeExtrasList025 + "\f" +
                                oExtrasList075 + "\f" +
                                oFreeExtrasList075;
                        })
                    })
                }
            });

            optionsData = optionsData ?
                "oID\foExtrasList\foFreeExtrasList\foFreeExtras\foPrice\fiIndex\f_priceList\f_branch" +
                "\foExtrasList05\foFreeExtrasList05\foExtrasList025\foFreeExtrasList025\foExtrasList075\foFreeExtrasList075" +
                optionsData
                : null;

            let isFoodDish = (index) => cData[parseInt(index)].item.iFoodDishId;

            let checkItemsAndOptions = (iData, oData) => {
                modalMismatch = iData.filter(f => !isFoodDish(f.iIndex));

                iData.filter(f => isFoodDish(f.iIndex)).forEach((x) => {
                    let sumFD = parseFloat(x.iPrice);
                    oData.filter(f => f.iIndex === x.iIndex).forEach((y) => {
                        sumFD = parseFloat(sumFD) + parseFloat(y.oPrice);
                    });
                    let sumItemPrice = parseFloat(sumFD).toFixed(2);
                    if (sumItemPrice != cData[x.iIndex].item.iPrice) {
                        modalMismatch = [...modalMismatch, { ...x, iPrice: sumItemPrice }];
                    }
                });

                if (modalMismatch[0]) {
                    this.setState({
                        modalMismatch: modalMismatch,
                        successSale: false,
                        paymentTypeCheck: null,
                    }, () => {
                        let ccData = cData.map((x, i) => {
                            let iChange = modalMismatch.find(f => f.iIndex == i);
                            return iChange ? { ...x, item: { ...x.item, iPrice: iChange.iPrice } } : x;
                        });
                        this.props.setJson(this.cart_id, { data: ccData })
                    });
                } else {
                    onSuccess()
                }
            }

            this.props.sendAPI(this.api.check_items, itemsData, (itemOb) => {
                let iData = itemOb.data ? itemOb.data.split("\r").map((xx) => {
                    let x = xx.split('\f');
                    return { iCode: x[1], iPrice: x[2], iOriginalPrice: x[2], iIndex: x[0] }
                }) : [];

                if (optionsData) {
                    this.props.sendAPI(this.api.check_options, optionsData, (optionsOb) => {
                        let oData = optionsOb.data ? optionsOb.data.split("\r").map((xx) => {
                            let x = xx.split('\f');
                            return { oID: x[1], oPrice: x[2], iIndex: x[0] }
                        }) : [];
                        checkItemsAndOptions(iData, oData);
                    }, (e) => {
                        message.error(this.props.getText(13114))
                        this.props.errorCallback(e)
                        if (this.props.saveScreen) {
                            this.props.changeScreen(1);
                        }
                    })
                } else {
                    checkItemsAndOptions(iData, []);
                }
            }, (e) => {
                message.error(this.props.getText(13114))
                this.props.errorCallback(e)
                if (this.props.saveScreen) {
                    this.props.changeScreen(1);
                }
            })
        }
    }

    getDeliveryRemarks = () => {
        let dqData = this.props.data[this.dQtn_id].data;
        let res = [];
        dqData.forEach((x) => {
            switch (x.mType) {
                case 'Checkbox': if (x.mValue == '1') res.push(x.mDesc); break;
                case 'Text': res.push(x.mDesc + ': ' + x.mValue); break;
                case 'Select': res.push(x.mDesc + ': ' + (x.mOptions[parseInt(x.mValue)] ? x.mOptions[parseInt(x.mValue)] : '')); break;
            }
        });

        return res;
    }

    requestCardcom = (url, params, method, callback) => {
        let pToSend = () => {
            let res = '';
            let keys = Object.keys(params);
            keys.forEach((x, i) => {
                if (i > 0) res += "&";
                res += x + "=" + params[x];
            });
            return res;
        }

        let xmlHttp = new XMLHttpRequest();
        if (callback) {
            xmlHttp.onreadystatechange = () => {
                callback(xmlHttp);
            }
        }
        if (method === 'GET') {
            url = url + '?' + pToSend();
            xmlHttp.open('GET', url, true);
            xmlHttp.send();
        } else {
            xmlHttp.open("POST", url, true);
            xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xmlHttp.send(pToSend());
        }
    }

    getLowProfileCode = (sumPrices, Operation) => {

        let payParams = this.props.data[this.setting_id];
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        if (isDev) origin += '/cash-on-tab/build';

        this.requestCardcom(
            "https://secure.cardcom.solutions/Interface/LowProfile.aspx",
            {
                Operation,
                TerminalNumber: payParams.cTerminal,
                UserName: payParams.cUsername,
                AutoRedirect: true,
                Codepage: 65001,
                ApiLevel: 10,
                SumToBill: sumPrices,
                CoinID: 1,
                Language: 'he',
                SuccessRedirectUrl: origin + '?cardcom=success',
                ErrorRedirectUrl: origin + '?cardcom=error',
                IndicatorUrl: origin + '?cardcom=indicator',
                ProductName: 'סיכום הזמנה',
                CreateTokenJValidateType: '5',
            },
            "POST",
            (res) => {
                let cardcomUrl = res.responseURL;
                // let lowprofilecode = cardcomUrl.split('=')[1];
                let lowprofilecode = cardcomUrl.split('/').slice(-1)[0];
                // if (lowprofilecode?.indexOf("=") > -1) lowprofilecode = lowprofilecode.split('=')[1]
                if (lowprofilecode?.indexOf("?") > -1) {
                    let lpcSplit2 = lowprofilecode.split('?')
                    if (lpcSplit2[1].indexOf("lowprofilecode=") > -1) {
                        lowprofilecode = lpcSplit2[1].split("&").map(x => x.split("=")).find(f => f[0] === 'lowprofilecode')[1]
                    } else {
                        lowprofilecode = lpcSplit2[0]
                    }
                }
                console.log("cardcom run 1", lowprofilecode)
                this.setState({ cardcomUrl: cardcomUrl, lowprofilecode: lowprofilecode })
            }
        )
    }

    getSumPrices = (noTax) => {
        let cartData = this.props.data[this.cart_id].data;
        let couponsData = this.props.data[this.coupons_id].dataset;
        let mivzaimCartData = this.props.data['FORMAT_MIVZAIM_CART'].data;
        let deliveryCost = this.props.data[this.order_id].deliveryCost;
        let sumPrices = 0;
        let sumMivzaim = 0;
        // let sumCoupons = 0;
        let addToPrices = (sp) => { sumPrices = parseFloat(parseFloat(sumPrices) + sp).toFixed(2) }
        cartData.forEach((x) => { addToPrices(parseFloat(x.item.iPrice) * parseFloat(x.item.iQty ? x.item.iQty : 1)) })
        couponsData.forEach((x) => { addToPrices(parseFloat(x.Value) * -1) })
        mivzaimCartData.forEach((x) => { sumMivzaim += Math.ceil(x.value * 100) / 100 });
        if (deliveryCost && deliveryCost.dPrice) addToPrices(parseFloat(deliveryCost.dPrice));
        // sumMivzaim = Math.ceil(sumMivzaim * 100) / 100;
        console.log('sumPrices', sumPrices, sumMivzaim, parseFloat(parseFloat(sumPrices) - sumMivzaim).toFixed(2))
        let res = parseFloat(parseFloat(sumPrices) - sumMivzaim).toFixed(2);
        return noTax ? parseFloat(res / 1.18).toFixed(2) : res
    }

    saveOrder = (cartData) => {

        let sumPrices = this.getSumPrices();

        let orderFields = this.props.data[this.order_id].orderFields;
        let oPos = orderFields.dPosNumber;
        let getField = (field) => orderFields[field] ? orderFields[field] : "";
        let getPrice = (price) => price ? parseFloat(price).toFixed(2) : "0.00";

        let oRequieredDateTime = orderFields['futureDate'] && orderFields['futureTime'] ?
            orderFields['futureDate'] + " " + orderFields['futureTime']
            : "";

        let orderSend =
            "oMethod\f" +
            "oRequieredDateTime\f" +
            "oCustomerId\f" +
            "oTotal\f" +
            "oRemark\f" +
            "oCustomerName\f" +
            "oRemainingPayment\f" +
            "oDeliveryCity\f" +
            "oDeliveryStreet\f" +
            "oDeliveryBuilding\f" +
            "oDeliveryApartement\f" +
            "oDeliveryEntrance\f" +
            "oDeliveryPhone\f" +
            "oDeliveryFloor\f" +
            "oDeliveryLat\f" +
            "oDeliveryLng\f" +
            "oDeliveryRemarks\f" +
            "oIsFinal\f" +
            "oID\f" +
            "oPos\f" +
            "oBranch\f" +
            "oAreaId\f" +
            "oDataJson\r" +
            this.props.data[this.order_id].orderType + "\f" +
            oRequieredDateTime + "\f" +
            (orderFields.custId ? orderFields.custId : '') + "\f" +
            sumPrices + "\f" +
            getField('dRemarks') + "\f" +
            getField('dName') + "\f" +
            sumPrices + "\f" +
            getField('dCity') + "\f" +
            getField('dStreet') + "\f" +
            getField('dBuilding') + "\f" +
            "" + "\f" +
            getField('dEntrance') + "\f" +
            getField('dPhone') + "\f" +
            getField('dFloor') + "\f" +
            getField('dLat') + "\f" +
            getField('dLng') + "\f" +
            this.getDeliveryRemarks().join("|") + "\f" +
            (this.state.paymentType === '1' ? "1" : "0") + "\f" +
            (this.props.ownerSave ? this.props.ownerSave : "") + "\f" +
            oPos + "\f" +
            getField('dBranch') + "\f" +
            getField('dAreaId') + "\f" +
            JSON.stringify(cartData);

        this.props.sendAPI(this.api.save_order, orderSend, (ob) => {
            if (ob.errorMessage) {
                this.props.changeScreen(1);
                message.error(ob.errorMessage);
                console.log('test barashi bug', { ob })
            } else if (ob.data) {

                let obData = ob.data.split('\r');
                let oID = obData[0];

                let linesSend = "lOwnerId\flGroupNumber\flItemCode\flQuantity\flPrice\flParentId\flDescription\flRemark\flJson\flTitle\flBonTags";
                let inlineDiscountSend = '';
                cartData.forEach((x, i) => {

                    let gNum = (i + 1);
                    let iCode = x.item.iCode;
                    let iQty = x.item.iQty;
                    let iPrice = getPrice(x.item.iFoodDishId ? x.item.iOriginalPrice : x.item.iPrice);
                    let iName = x.item.iName;
                    let iRemarks = x.item.custRemark ? x.item.custRemark : "";
                    let lJson = x.levels ? JSON.stringify({ levels: x.levels, foodDishId: x.item.iFoodDishId }) : "";
                    let iBonTags = x.item.iBonTags;

                    linesSend += "\r" + oID + "\f" + gNum + "\f" + iCode + "\f" + iQty + "\f" + iPrice + "\f-1\f" + iName + "\f" + iRemarks + "\f" + lJson + "\f\f" + iBonTags;
                    if (this.props.isFromPos && x.item.inlineDiscount) {
                        inlineDiscountSend += "\r" + oID + "\f" + gNum + "\f" + x.item.inlineDiscount + "\f" + iCode + "\f" + iQty;
                    }

                    if (x.levels) {
                        let oIndex = 0;
                        x.levels.forEach((y) => {
                            y.ownerData.forEach((xx) => {
                                let levelTitle = (x.levels.length > 1 || y.ownerData.length > 1) && y.printGroupTitle == 1 ? y.name : "";
                                let levelPrice = getPrice(xx.oPrice);
                                let pPizzaLogic = this.props.data[this.setting_id].pPizzaLogic;
                                if (xx.oPizzaPiecesNum && xx.oExtraItems && (pPizzaLogic == '2' || pPizzaLogic == '3' || pPizzaLogic == '4')) {
                                    levelPrice = getPrice(parseFloat(levelPrice) + this.props.getSumExtraPrices(xx.oExtraItems, xx.oPizzaPiecesNum))
                                }
                                linesSend += "\r" + oID + "\f" + gNum + "\f" + xx.oItemCode + "\f1\f" + levelPrice + "\f" + oIndex + "\f" + xx.oItemName + "\f\f\f" + levelTitle + '\f' + xx.oBonTags;
                                oIndex++;
                                if (xx.oExtraItems) {
                                    if (xx.oPizzaPiecesNum) {
                                        xx.oExtraItems
                                            .sort((a, b) => a.eLocals[0] - b.eLocals[0])
                                            .sort((a, b) => b.eLocals.length - a.eLocals.length)
                                            .forEach((xxx, iii) => {
                                                let eTitle = this.props.getPiecesText(xxx.eLocals, xx.oPizzaPiecesNum);
                                                if (!eTitle) eTitle = this.props.getText(15570);

                                                let eQty = xxx.eLocals.length / parseInt(xx.oPizzaPiecesNum);
                                                let ePrice = (pPizzaLogic == '2' || pPizzaLogic == '3' || pPizzaLogic == '4') ? '0.00' : getPrice(xxx.ePrice);
                                                linesSend += "\r" + oID + "\f" + gNum + "\f" + xxx.eItemCode + "\f" + eQty + "\f" + ePrice + "\f" + iii + "\f" + xxx.eItemName + "\f\f\f" + eTitle + '\f' + xxx.eBonTags;
                                                oIndex++;
                                            })
                                    } else {
                                        xx.oExtraItems.forEach((xxx, iii) => {
                                            let eTitle = this.props.getText(15569);
                                            linesSend += "\r" + oID + "\f" + gNum + "\f" + xxx.eItemCode + "\f1\f" + getPrice(xxx.ePrice) + "\f" + iii + "\f" + xxx.eItemName + "\f\f\f" + eTitle + '\f' + xxx.eBonTags;
                                            oIndex++;
                                        })
                                    }
                                }
                            })
                        })
                    }
                })
                this.props.sendAPI(this.api.save_order_lines, linesSend, (totalObj) => {
                    let data = totalObj.data ? totalObj.data.split("\f") : ['1', '0'];
                    if (parseFloat(data[0]) != parseFloat(data[1])) {
                        this.props.changeScreen(1);
                        message.error(this.props.getText(17093));
                        return;
                    }
                    let saveMivzaim = () => {
                        this.props.sendAPI(this.api.save_mivzaim, "ownerId\fmCoupon\r" + obData[0] + '\f', (mivzaimRes) => {
                            ///////
                            let changes = [];
                            let clientData = this.props.data[this.mivzaim_cart_id].data;
                            let serverData = mivzaimRes.data ? mivzaimRes.data.split('\r').map((mivza) => {
                                let y = mivza.split('\f');
                                return { id: y[0], value: parseFloat(y[1]) }
                            }) : [];

                            serverData.forEach((mivza) => {
                                let c = clientData.find(f => f.data.mId === mivza.id);
                                let cv = c ? Math.ceil(c.value * 100) / 100 : 0;
                                if (cv - mivza.value > 0.005 || cv - mivza.value < -0.005) changes.push(mivza);
                            });

                            let ontherInClient = clientData.filter(f => !serverData.find(fi => f.data.mId === fi.id) && f.value);

                            ontherInClient.forEach(x => { changes.push({ id: x.data.mId, value: 0 }) });

                            if (changes.length && !this.props.isFromPos) {
                                this.setState({ modalMivzaimChange: changes }, () => {
                                    let newList = [...clientData];
                                    changes.forEach((x) => {
                                        let l = newList.findIndex(f => f.data.mId === x.id);
                                        if (l < 0) {
                                            let mivzaimSource = this.props.data[this.mivzaim_id].data;
                                            let fm = mivzaimSource.find(f => f.mId === x.id);
                                            newList.push({ data: fm, value: x.value })
                                        } else {
                                            newList[l] = { ...newList[l], value: x.value }
                                        }
                                    });
                                    this.props.setJson(this.mivzaim_cart_id, { data: newList })
                                })
                            }

                            ///////
                            this.setState({ ownerID: obData[0], ownerNum: obData[1] }, () => {
                                this.props.ownerUpdate(obData[1]);

                                if (this.props.saveScreen) {
                                    this.props.changeScreen(1);
                                    message.success(this.props.getText(13417));
                                } else {
                                    let couponsWaiting = cartData.map(itemInCart => itemInCart.item.couponData).filter(f => f);
                                    this.setState({ couponsWaiting }, this.sendCouponsWaiting);

                                    if (this.props.isFromPos) {
                                        this.savePayment({
                                            sum: '0.00',
                                            owner: obData[0],
                                            ownerNum: obData[1],
                                            type: '1',
                                            token: '',
                                            payerID: '',
                                            cardNumber: '',
                                            tokef: '',
                                        });
                                    }

                                    let payList = this.getPayList()

                                    if (payList.length === 1) {
                                        this.onChangePay(payList[0].code)
                                    }
                                }
                            });
                        }, this.errorCallbackOnSave)
                    }

                    if (this.props.isFromPos && inlineDiscountSend) {
                        this.props.sendAPI(this.api.save_inline_discount, '_owner\f_group\f_value\f_item\f_qty' + inlineDiscountSend, saveMivzaim, this.errorCallbackOnSave)
                    } else {
                        this.props.sendAPI(this.api.check_pos_items, oID, (notItemsInPos) => {
                            if (notItemsInPos.data) {
                                let modalNotItemsInPos = notItemsInPos.data.split("\r").map(x => {
                                    let y = x.split('\f')
                                    return { code: y[0], name: y[1], isTemp: y[2] }
                                })
                                this.props.setStateRoot({ modalNotItemsInPos }, () => { this.props.changeScreen(1) });
                            } else {
                                saveMivzaim()
                            }
                        }, this.errorCallbackOnSave)
                    }
                }, this.errorCallbackOnSave)

            } else {
                this.props.changeScreen(1);
                message.error(this.props.getText(17093));
            }

        }, this.errorCallbackOnSave)
    }

    errorCallbackOnSave = (e) => {
        this.props.errorCallback(e)
        if (this.props.saveScreen) {
            this.props.changeScreen(1);
            message.error(e);
        }
    }

    savePayment = (d) => {
        let sumOnePay = d.numberOfPay > 1 ? parseInt(parseFloat(d.sum) / d.numberOfPay) : 0;
        let sumFirstPay = d.numberOfPay > 1 ? parseFloat(d.sum) - (sumOnePay * (d.numberOfPay - 1)) : d.sum;

        this.props.sendAPI(
            this.api.save_payment,
            "pSum\foID\fpType\fpToken\fpNumberOfPay\fpSumFirstPay\fpSumOnePay\fpPayerId\fpCardNumber\fpExpCard\fpMetaData\fpConfirmationNumber\r"
            + d.sum + "\f"
            + d.owner + "\f"
            + d.type + "\f"
            + (d.token ? d.token : '') + "\f"
            + (d.numberOfPay ? d.numberOfPay : 1) + "\f"
            + sumFirstPay + "\f"
            + sumOnePay + "\f"
            + d.payerID + "\f"
            + d.cardNumber + "\f"
            + d.tokef + "\f"
            + (d.meta ? d.meta : '') + "\f"
            + (d.confirmation ? d.confirmation : ''),
            (obj) => {
                if (this.props.isFromPos) {
                    this.webViewEndOrder(d.owner, d.ownerNum)
                }
                this.setState({
                    paymentBalance: obj.data,
                    paymentTypeCheck: false,
                    paymentType: null,//'7',
                    numberOfPay: 1,
                    paymentSum: null,
                }, () => {
                    if (obj.data === '0.00') {
                        this.props.ownerUpdate(false)
                        this.clearStoreWhenEndOrder();
                    }
                })
            }, this.props.errorCallback)
    }

    parseXml = (xml, t, arr) => {
        if (arr) {
            let res = xml ? xml.split('<' + t + '>').map(x => x ? x.split('</' + t + '>')[0] : '') : [];
            return res.slice(1);
        } else if (xml) {
            let resX = xml.split('<' + t + '>')[1];
            return resX ? resX.split('</' + t + '>')[0] : '';
        }
    }

    clearStoreWhenEndOrder = () => {
        this.props.setJson(this.cart_id, { data: [] });
        this.props.setJson(this.mivzaim_cart_id, { data: [] });
        this.props.setJson(this.points_id, { pointsForThisSale: 0 });
        this.props.setJson(this.coupons_id, { dataset: [] });
        this.props.nextRegi(1);
    }

    sendChargeCard = () => {
        let ccInput = this.state.chargeCardInput;
        let ccSum = this.getSumPrices();
        let oID = this.state.ownerNum;
        let em = (e) => {
            this.setState({ paymentTypeCheck: false }, () => {
                message.error(this.props.getText(13547));
                this.props.errorCallback(e)
            })
        }

        let successOrder = () => {
            this.setState({
                paymentBalance: '0.00',
                paymentTypeCheck: false,
                paymentType: null,// '7',
                numberOfPay: 1,
                paymentSum: null,
            }, this.clearStoreWhenEndOrder)
        }

        let hebrowReverse = (t) => {
            let res = '';
            let x = t.split(';');
            x.forEach((y) => {
                let z = y.trim();
                let l = z.length;
                for (let n = 1; n <= l; n++) { res += z[l - n] }
                res += " ";
            });
            return res;
        }

        switch (this.state.paymentType) {
            case '10bis':
                this.props.sendAPI("10bis_debit_money_card", "magneticCode\fsumToDebit\r" + ccInput + "\f" + ccSum, (ob) => {

                    let tID = this.parseXml(ob.data, 'Success') === 'true' ? this.parseXml(ob.data, 'TransID') : '';
                    let dataSend = "cType\fcSum\fcTransId\fcOwner\fcLastNamber\r1\f" + ccSum + "\f" + tID + "\f" + oID + "\f" + ccInput.slice(-4);
                    if (tID) this.props.sendAPI(this.api.charge_card, dataSend, successOrder, em);
                    else em();
                }, em);
                break;
            case 'goodi':
                this.props.sendAPI("goodi_execute_transaction", "cardNumber\fprice\r" + ccInput + "\f" + ccSum, (ob) => {
                    let data = ob.data ? ob.data.split('|') : false;

                    if (data) {
                        let tID = data[0] === '2000' ? data[1] : '';
                        let eDesc = data[0] !== '2000' && data[3] ? data[3] === '0000' ? data[4] : hebrowReverse(data[3]) : '';
                        let dataSend = "cType\fcSum\fcTransId\fcOwner\fcLastNamber\r2\f" + ccSum + "\f" + tID + "\f" + oID + "\f" + ccInput.slice(-4);
                        if (tID) {
                            this.props.sendAPI(this.api.charge_card, dataSend, successOrder, em);
                        } else {
                            this.setState({ paymentTypeCheck: false }, () => {
                                message.error(eDesc);
                            })
                        }
                    } else {
                        em();
                    }
                }, em);
                break;
            case 'cibus':
                let cibusSend = "companyCode\fcompanyCard\fprice\r" + this.state.cibusCompany + "\f" + ccInput + "\f" + ccSum
                this.props.sendAPI("cibus_add_order", cibusSend, (ob) => {
                    let tID = this.parseXml(ob.data, 'orderID');
                    let dataSend = "cType\fcSum\fcTransId\fcOwner\fcLastNamber\r3\f" + ccSum + "\f" + tID + "\f" + oID + "\f" + ccInput.slice(-4);
                    console.table({ ccSum, tID, oID, ccInput: ccInput.slice(-4) })
                    if (parseInt(tID)) this.props.sendAPI(this.api.charge_card, dataSend, successOrder, em);
                    else em();
                }, em);
                break;
        }
    }

    apiCibusCompanies = () => {
        this.props.sendAPI("cibus_load_companies", "", (ob) => {
            let dataset = [{ code: '0', name: 'Cibus' }];
            if (ob.data) {
                let intX = ob.data.split('<int>');
                let intY = intX.map(x => x.split('</int>')[0]).filter(f => parseInt(f))
                let strX = ob.data.split('<string>');
                let strY = strX.map((x, i) => i ? x.split('</string>')[0] : false).filter(f => f)

                let count = intY.length
                for (let x = 0; x < count; x++) {
                    let code = intY[x];
                    let name = strY[x];
                    dataset.push({ code: code, name: name });
                }
            }
            this.props.setJson(this.cibus_id, { dataset: dataset });
        }, this.props.errorCallback);
    }

    sendPoints = () => {
        let cId = this.props.data[this.order_id].orderFields.cID;
        let oId = this.state.ownerID;
        let pSum = this.state.pointsToUse;

        this.props.sendAPI(this.api.set_points, "cId\foId\fpSum\r" + cId + "\f" + oId + "\f" + pSum, (ob) => {
            if (ob && ob.data) {
                let newBalance = parseFloat(ob.data);
                this.setState({
                    paymentBalance: newBalance ? newBalance.toFixed(2) : '0.00',
                    paymentTypeCheck: false,
                    paymentType: null,
                    numberOfPay: 1,
                    paymentSum: null,
                    pointsDisable: true,
                }, () => {
                    if (!newBalance) this.clearStoreWhenEndOrder();
                })
            }
        }, this.props.errorCallback)
    }

    sendCoupon = (cId, cRequest, cResponse, cIsMultipass) => {
        let oId = this.state.ownerID;
        let dataSend = 'oId\fcId\fcRequest\fcResponse\fcIsMultipass\r'
            + oId + '\f'
            + cId + '\f'
            + (cRequest ? JSON.stringify(cRequest) : '') + '\f'
            + (cResponse ? JSON.stringify(cResponse) : '') + '\f'
            + (cIsMultipass ? '1' : '0');

        this.props.sendAPI(this.api.set_coupon, dataSend, (ob) => {
            let data = ob.data ? ob.data.split('\r') : []
            if (data[1] && !parseFloat(data[1])) {
                this.savePayment({
                    sum: '0.00',
                    owner: this.state.ownerID,
                    ownerNum: this.state.ownerNum,
                    type: '1',
                    token: '',
                    payerID: '',
                    cardNumber: '',
                    tokef: '',
                });
            } else if (data[0]) {
                let res = JSON.parse(data[0]);
                let couponsWaiting = this.state.couponsWaiting.slice(1);
                this.setState({ couponsWaiting }, () => {
                    let dataset = [...this.props.data[this.coupons_id].dataset, res];
                    this.props.setJson(this.coupons_id, { dataset });
                    this.sendCouponsWaiting()
                })
            } else {
                this.props.genericMessageError(this.props.getText(19898))
                // message.error(this.props.getText(19898))
            }
        }, this.props.errorCallback)

    }

    sendCouponsWaiting = () => {
        if (this.state.couponsWaiting[0]) {
            const { nId, nRequest, nResponse, nIsMultipass, nCode } = this.state.couponsWaiting[0];
            if (nIsMultipass) this.useCouponMultipass(nCode);
            else this.sendCoupon(nId, nRequest, nResponse, false);
        }
    }

    useCouponMultipass = (CardCode) => {
        let oId = this.state.ownerID;
        this.props.sendAPI(this.api.json_multipass, 'oId\r' + oId, (ob) => {
            if (ob.data) {
                let data = ob.data.split('\r');
                let TranValue = parseInt(data[0]);
                let TranSum = parseInt(data[0]);
                let PosTranId = data[1];

                let couponsList = [];

                let CatItemsList = data.slice(2).map((x, i) => {
                    let y = x.split('\f');
                    let cJson = this.parseXml(y[5], 'coupon');
                    if (cJson) couponsList.push({ ...JSON.parse(cJson), index: i });

                    return {
                        PosItemID: parseInt(y[0]),
                        DisplayIndex: 0,
                        CatalogItemId: 0,
                        CatalogItemCode: y[1],
                        Amount: parseInt(y[2]),
                        Price: parseInt(y[3]),
                        PriceSum: parseInt(y[4]),
                        IsActive: 1,
                        Bussiness: '0',
                    }
                });

                couponsList.forEach(x => {
                    if (x.Distributor != 2 && x.Response && x.Response.nItemCode) {
                        let findItem = CatItemsList.findIndex(f => f.CatalogItemCode == x.Response.nItemCode);
                        let PriceSum = CatItemsList[findItem].PriceSum - parseInt(x.Value * 100);
                        CatItemsList[findItem] = {
                            ...CatItemsList[findItem],
                            PriceSum,
                            Price: parseInt(PriceSum / (CatItemsList[findItem].Amount / 100))
                        }
                        CatItemsList[x.index] = null;
                    } else if (x.Distributor == 2 && x.Response && x.Response.EBenefits && x.Response.EBenefits) {
                        x.Response.EBenefits.forEach(y => {
                            if (y.ItemCode) {
                                let findItem = CatItemsList.findIndex(f => f.CatalogItemCode == y.ItemCode);
                                let PriceSum = CatItemsList[findItem].PriceSum - parseInt(y.Value * 100);
                                CatItemsList[findItem] = {
                                    ...CatItemsList[findItem],
                                    PriceSum,
                                    Price: parseInt(PriceSum / (CatItemsList[findItem].Amount / 100))
                                }
                                CatItemsList[x.index] = null;
                            }
                        })

                    }
                });

                CatItemsList = CatItemsList.filter(f => f)

                let dataSend = { CardCode, TranValue, TranSum, CatItemsList, PosTranId }
                this.props.sendAPI(this.api.use_multipass, 'data\r' + JSON.stringify(dataSend), (xmlRes) => {
                    let ResultId = this.parseXml(xmlRes.data, 'ResultId');
                    if (ResultId === '0') {
                        let resObj = {
                            MltpTransactionId: this.parseXml(xmlRes.data, 'MltpTransactionId'),
                            BenefitsDiscount: this.parseXml(xmlRes.data, 'BenefitsDiscount'),
                            CompanyId: this.parseXml(xmlRes.data, 'CompanyId'),
                            ResultMessage: this.parseXml(xmlRes.data, 'ResultMessage'),
                            ResponseFullMessage: this.parseXml(xmlRes.data, 'ResponseFullMessage'),
                            UsedBenefitList: this.parseXml(xmlRes.data, 'BenefitUsage', true).map(x => {
                                return {
                                    BenefitID: this.parseXml(x, 'BenefitID'),
                                    BenefitName: this.parseXml(x, 'BenefitName'),
                                    CouponItemCode: this.parseXml(x, 'string'),
                                }
                            }),
                            TransactionItem: this.parseXml(xmlRes.data, 'TransactionItem', true).map(x => {
                                return {
                                    Id: this.parseXml(x, 'Id'),
                                    Code: this.parseXml(x, 'Code'),
                                    Amount: this.parseXml(x, 'Amount'),
                                    Price: this.parseXml(x, 'Price'),
                                    TotalPrice: this.parseXml(x, 'TotalPrice'),
                                    DiscountType: this.parseXml(x, 'DiscountType'),
                                }
                            }),
                        }
                        this.sendCoupon(CardCode, dataSend, resObj, 1);
                    }
                }, this.props.errorCallback)
            }
        }, this.props.errorCallback)
    }

    clearState = (next) => {
        this.setState({
            successSale: null,
            modalMismatch: false,
            modalMivzaimChange: false,
            cardcomUrl: false,
            lowprofilecode: false,
            DealResponse: false,
            InternalDealNumber: false,
            paymentTypeCheck: false,
            paymentType: null,
            lastPaymentType: null,
            numberOfPay: 1,
            paymentPartial: 0,
            paymentSum: null,
            ownerID: null,
            ownerNum: null,
            paymentBalance: null,
            paymentTotal: null,
            chargeCardInput: '',
            cibusCompany: '0',
            pointsToUse: 0,
            pointsDisable: false,
            swiichView: false,
            swiichWalletId: null,
            couponsWaiting: [],
        }, next)
    }

    initSensepay = () => {

        let dPhone = this.props.data[this.order_id].orderFields.dPhone;
        let dataSend = "timeOut\famount\fmaxInstallments\fisCent\fcurrency\fmethod\fbranchId\freturnURL\fcancelURL\fclientPhoneNumber\freason\fpaymentMethod\fproducts\r"
            + "120\f"
            + this.getSumPrices() + "\f"
            + "3\f"
            + "0\f"
            + "ILS\f"
            + "Credit\f"
            + "0\f"
            + "http://147.235.163.248/JMadmin/Web/index.html\f"
            + "http://147.235.163.248/JMadmin/Web/index.html\f"
            + (dPhone ? dPhone : '') + "\f"
            + "reason test\f"
            + "\f"
            + "[]";

        this.props.sendAPI(this.api.sensepay_init, dataSend, (ob) => {
            let j = ob.data ? JSON.parse(ob.data) : {};
            if (j.status === 0) {
                this.getSensepay(j.TransactionNumber);
            } else {
                this.setState({ paymentTypeCheck: false }, () => { message.error(this.props.getText(15736)) })
            }
        }, this.props.errorCallback)
    }

    getSensepay = (trans) => {
        let mError = (text) => { this.setState({ sensepayWaiting: 0, paymentTypeCheck: false }, () => { message.error(this.props.getText(text)) }) }

        if (this.state.sensepayWaiting >= 180) {
            mError(15737)
        } else {
            this.setState({ sensepayWaiting: this.state.sensepayWaiting + 1 }, () => {
                setTimeout(() => {
                    this.props.sendAPI(this.api.sensepay_get, "transactionID\r" + trans, (ob) => {
                        let j = ob.data ? JSON.parse(ob.data) : {};

                        switch (j.status) {
                            case 0: this.getSensepay(trans); break;
                            case 5: this.saveSensepay(j); break;
                            case 6: mError(15738); break;
                            case 7: mError(15739); break;
                            case 8: mError(15737); break;
                            case 9: mError(15740); break;
                        }
                    }, this.props.errorCallback)
                }, 1000)
            })
        }
    }

    saveSensepay = (res) => {
        this.savePayment({
            sum: res.amount,
            owner: this.state.ownerID,
            ownerNum: this.state.ownerNum,
            type: 101,
            token: res.confirmationNumber,
            payerID: '',
            cardNumber: '',
            tokef: '',
            numberOfPay: res.installments,
            meta: JSON.stringify(res),
        });

        this.props.sendAPI(this.api.sensepay_commit, 'commitLink\f' + res.commitLink, () => {
            this.setState({ sensepayWaiting: 0 })
        }, this.props.errorCallback);
    }

    spinView = (text) => {
        return (<div style={{ paddingTop: window.innerHeight / 3, textAlign: 'center', background: "white" }}>
            <Spin />
            <br />
            <br />
            <h1>{this.props.getText(text)}</h1>
        </div>)
    }

    render() {

        let oData = this.props.data[this.order_id];
        let payParams = this.props.data[this.setting_id];

        let textStyle = (title, value) => (<div style={{ fontSize: 20 }}>{title + (value ? ": " + value : '')}</div>)

        let fieldStyle = (text, field) => {
            let val = oData.orderFields[field] ? oData.orderFields[field] : ""
            let res = field == 'futureDate' && val ?
                moment(val).format("DD/MM/YYYY") :
                field == 'futureDate' && val ?
                    val :
                    val
            return textStyle(this.props.getText(text), res);
        }

        let remarkStyle = this.getDeliveryRemarks().map(x => {
            let y = x.split(":");
            return textStyle(y[0], y[1])
        });

        let ot = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset.find(f => f.code === oData.orderType);

        let viewCart = this.props.data[this.cart_id].data[0] ? true : false;

        const {
            TotalPointsForUse,
            MinForUse,
            PointUsePercent,
            PointValue,
            TagsForNotUsingPoints,
            UseDecimalPoints,
            UseOnDiscount,
            pointsForThisSale
        } = this.props.data[this.points_id];


        let paymentTypeWindow = () => {

            let fPrice = (s) => s ? parseFloat(s).toFixed(2) : '0.00';
            let cPrice = (a, b) => parseFloat(fPrice(a) - fPrice(b)).toFixed(2);

            let sumPrices = this.getSumPrices();
            let sumPricesNoTax = this.getSumPrices(true);
            let sumOnePay = parseInt(parseFloat(sumPrices) / this.state.numberOfPay);
            let sumFirstPay = this.state.numberOfPay > 1 ? cPrice(sumPrices, (sumOnePay * (this.state.numberOfPay - 1))) : 0;


            let payList = this.getPayList()
            // let payList = [...this.props.data[this.payments_id].dataset];
            // /////
            // payList = payList.map(x => x.code == '101' ? { ...x, name: this.props.getText(15801) } : x)

            // /////
            // if (payParams.pIs10Bis === '1')
            //     payList.push({ code: '10bis', name: this.props.getText(13531) })
            // if (payParams.pIsCibus === '1')
            //     payList.push({ code: 'cibus', name: this.props.getText(13532) })
            // if (payParams.pIsGoodi === '1')
            //     payList.push({ code: 'goodi', name: this.props.getText(13533) })
            // if (payParams.pIsCustDebit === '1')
            //     payList.push({ code: 'debit', name: this.props.getText(13534) })
            // if (payParams.pIsCustPoints === '1' && oData.orderFields.cID && !this.state.pointsDisable)
            //     payList.push({ code: 'points', name: this.props.getText(14196) })

            let sumPayment = (<span>
                {this.props.data[this.setting_id].pPricesNoTax ? <h2 style={{ paddingRight: 15 }}>{this.props.getText(17537)}: ₪{sumPricesNoTax}</h2> : ""}
                <h1 style={{ padding: 15 }}>{this.props.getText(13395)}: ₪{sumPrices}</h1>
                {oData.orderFields.cID ? <p style={{float:"left",margin:10}}>{this.props.getText(20471)}: {TotalPointsForUse}</p>:""}
                {this.state.paymentBalance ? [
                    <h1 style={{ paddingRight: 15 }}>{this.props.getText(13397)}: ₪{cPrice(sumPrices, this.state.paymentBalance)}</h1>,
                    <h1 style={{ paddingRight: 15 }}>{this.props.getText(13398)}: ₪{this.state.paymentBalance}</h1>
                ] : ""}
            </span>)

            let paymentSelector = (<div>
                <div className={"text_item"}>{this.props.getText(13387)}: </div>
                <Select
                    className={"text_item"}
                    key="PaymentTypesSelect"
                    value={this.state.paymentType}
                    onChange={this.onChangePay}
                    style={{ width: 250 }}
                >
                    {payList.map((x) => (<Option value={x.code}>{x.name}</Option>))}
                </Select>
            </div>);

            let cibusCompanies = this.state.paymentType === 'cibus' ? (<div>
                <div className={"text_item"}>{this.props.getText(13548)}: </div>
                <Select
                    className={"text_item"}
                    key="cibusCompanies"
                    value={this.state.cibusCompany}
                    onChange={(e) => { this.setState({ cibusCompany: e }) }}
                    style={{ width: 250 }}
                >
                    {this.props.data[this.cibus_id].dataset.map((x) => (<Option value={x.code}>{x.name}</Option>))}
                </Select>
            </div>) : '';

            let nopQty = payParams.pMaxPayNum ? payParams.pMaxPayNum : 0;
            let pMinToPay = payParams.pMinToPay ? payParams.pMinToPay : 0;
            if (nopQty && (sumPrices / nopQty) < pMinToPay) {
                nopQty = parseInt(sumPrices / pMinToPay);
            }

            let nopList = [];
            // for (let a = 1; a <= nopQty; a++) { nopList.push(<Radio value={a}>{a}</Radio>) }
            for (let a = 1; a <= nopQty; a++) { nopList.push(<Option value={a}>{a}</Option>) }
            let numberOfPay = this.state.paymentType === '7' && nopQty > 1 ? (<div>
                <span className={"text_item"}>{this.props.getText(13535)}: </span>
                <Select
                    className={"text_item"}
                    style={{ width: 80, marginRight: 10 }}
                    onChange={(e) => { this.setState({ numberOfPay: e }) }}
                    value={this.state.numberOfPay}>
                    {nopList}
                </Select>
                {this.state.numberOfPay > 1 ? (<span>
                    <h3>{this.props.getText(13536)}: ₪{sumFirstPay}</h3>
                    <h3>{this.props.getText(13537)}: ₪{sumOnePay} </h3>
                </span>) : ''}
            </div>) : '';

            let chargeCardInput =
                ['10bis', 'cibus', 'goodi'].indexOf(this.state.paymentType) > -1 ?
                    (<div>
                        <div className={"text_item"}>{this.props.getText(13546) + payList.find(f => f.code === this.state.paymentType).name}: </div>
                        <Input className={"text_item"} value={this.state.chargeCardInput} onChange={(e) => { this.setState({ chargeCardInput: e.target.value }) }} />
                    </div >) : '';

          
            let pointsInput = this.state.paymentType === "points" &&  oData.orderFields.cID && TotalPointsForUse > 0 ?
                [<Row>
                    <Col span={12}>
                        <div className={"text_item"}>
                            <div style={{ display: "inline-block", width: 150 }}>{this.props.getText(14205)}</div>
                            <InputNumber
                                style={{ height: 45 }}
                                precision={UseDecimalPoints == '1' ? 2 : 0}
                                min={MinForUse ? parseInt(MinForUse) : 0}
                                max={pointsForThisSale}
                                className={"text_item"}
                                value={this.state.pointsToUse}
                                onChange={(e) => {
                                    let minUse = MinForUse ? parseInt(MinForUse) : 0
                                    if (e <= pointsForThisSale && e >= minUse) this.setState({ pointsToUse: e })
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={"text_item"}>
                            <div style={{ display: "inline-block", width: 150 }}>{this.props.getText(14207)}</div>
                            ₪{parseFloat(parseFloat(PointValue) * this.state.pointsToUse).toFixed(2)}
                        </div>
                    </Col>

                </Row>,
                <Row>
                    <Col span={12}>
                        <div className={"text_item"}>
                            <div style={{ display: "inline-block", width: 150 }}>{this.props.getText(14206)}</div>
                            {parseFloat(parseFloat(TotalPointsForUse) - this.state.pointsToUse).toFixed(2)}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={"text_item"}>
                            <div style={{ display: "inline-block", width: 150 }}>{this.props.getText(14208)}</div>
                            ₪{parseFloat(parseFloat(sumPrices) - (parseFloat(PointValue) * this.state.pointsToUse)).toFixed(2)}
                        </div>
                    </Col>
                </Row>]
                : "";

            let siteSettings = this.props.data[this.setting_id];
            let styleType = siteSettings.pDesktopCategoriesView;

            return (<div>
                {sumPayment}
                {styleType == '5' ? "" :
                    <FoodOrderBenefits
                        {...this.props}
                        fontColor={styleType == '4' ? "white" : styleType == '6' ? "black" : siteSettings.rFonts}
                        backgroundColor={styleType == '4' ? "#BE1E2D" : styleType == '6' ? "#ddc268" : siteSettings.rPrimaryButtons}
                        couponsList={this.props.data[this.coupons_id].dataset.map(x => x.CardCode)}
                        onCouponOk={(res) => { this.setState({ couponsWaiting: [res] }, this.sendCouponsWaiting) }}
                    />
                }
                {paymentSelector}
                {numberOfPay}
                {cibusCompanies}
                {chargeCardInput}
                {pointsInput}

                {/* {this.state.paymentType !== '1' ? fullPaymentOptions : ''} */}
                {/* {this.state.paymentPartial ? paymentPartial : ''} */}

                <div style={{ padding: 15 }}>
                    <Button
                        key={'paymentTypeCheck'}
                        style={{
                            margin: 10,
                            width: 140,
                            height: 50,
                            ...this.props.buttonStyle
                            //               background: '#f0b619',
                        }}
                        disabled={!this.state.paymentType}
                        onClick={() => {
                            console.log('paymentType', this.state.paymentType)
                            this.setState({ paymentTypeCheck: true }, () => {
                                const Operation = this.state.paymentType == 3 ? 1 : 3
                                switch (this.state.paymentType) {
                                    case '3': // cardcom - Immediate charge
                                    case '7': // cardcom
                                    case '9': // swiich
                                        if (!this.state.paymentBalance) {
                                            this.checkPrices((data) => {
                                                let sumPrices = this.getSumPrices();
                                                let paySum = this.state.paymentSum;
                                                if (this.state.paymentType == '3' || this.state.paymentType == '7') {
                                                    this.setState({ paymentTotal: sumPrices }, () => {
                                                        this.getLowProfileCode((this.state.paymentPartial) ? paySum : sumPrices, Operation);
                                                    })
                                                } else if (this.state.paymentType == '9') {
                                                    this.setState({ paymentTotal: sumPrices, swiichView: true })
                                                }
                                                // this.saveOrder(data);

                                            });
                                        } else {
                                            this.setState({ DealResponse: null }, () => {
                                                let sumToSend = (this.state.paymentPartial) ? this.state.paymentSum : this.state.paymentBalance;
                                                this.getLowProfileCode(sumToSend, Operation);
                                            })
                                        }
                                        break;

                                    case '101': // sensepay
                                        this.checkPrices(this.initSensepay);
                                        break;

                                    case '10bis': case 'cibus': case 'goodi':
                                        this.sendChargeCard();
                                        break;
                                    case 'points':
                                        this.sendPoints();
                                        break;
                                    case 'debit':
                                        this.savePayment({
                                            sum: '0.00',
                                            owner: this.state.ownerID,
                                            ownerNum: this.state.ownerNum,
                                            type: '1',
                                            token: '',
                                            payerID: '',
                                            cardNumber: '',
                                            tokef: '',
                                        });
                                }
                            })
                        }}>
                        <span className={"text_item"} style={this.props.buttonStyle}>{this.props.getText(13393)}</span>
                    </Button>
                    <Button
                        onClick={() => { this.clearState(() => { this.props.changeScreen(1) }) }}
                        style={{
                            margin: 10,
                            width: 140,
                            height: 50,
                            ...this.props.buttonStyle
                        }}>
                        <span className={"text_item"} style={this.props.buttonStyle}>{this.props.getText(13401)}</span>
                    </Button>
                </div>
            </div >)
        }

        let successOwner = (<div>
            <h1>{this.props.getText(13394)}</h1>
            <h1>{this.props.getText(13396)}: {this.state.ownerNum}</h1>
        </div>);

        let cardcomIframe = () => {
            return (<iframe
                name="myFrame"
                src={this.state.cardcomUrl}
                width={"100%"}
                height={window.innerHeight - 300}
                onLoad={(e) => {
                    console.log('cardcom onLoad: ', this.state.lowprofilecode)
                    if (this.state.lowprofilecode) {
                        this.requestCardcom(
                            'https://secure.cardcom.solutions/Interface/BillGoldGetLowProfileIndicator.aspx',
                            {
                                codepage: 65001,
                                terminalnumber: payParams.cTerminal,
                                UserName: payParams.cUsername,
                                lowprofilecode: this.state.lowprofilecode
                            },
                            'GET',
                            (res) => {
                                let ob = {}
                                res.responseText.split('&').forEach((x) => {
                                    let y = x.split('=');
                                    ob = { ...ob, [y[0]]: y[1] }
                                });
                                console.log('cardcom response: ', res, ob)

                                if (ob.TokenResponse === '0' || (ob.Operation === '1' && ob.OperationResponse === '0')) {
                                    this.setState({
                                        DealResponse: '0',
                                        InternalDealNumber: ob.InternalDealNumber,
                                    }, () => {
                                        if (this.profileCodeUpdated !== ob.lowprofilecode) {

                                            this.profileCodeUpdated = ob.lowprofilecode;
                                            this.savePayment({
                                                sum: parseInt(ob['ExtShvaParams.Sum36']) / 100,
                                                owner: this.state.ownerID,
                                                ownerNum: this.state.ownerNum,
                                                type: this.state.paymentType,
                                                token: ob.Token,
                                                payerID: ob['ExtShvaParams.CardHolderIdentityNumber'],
                                                cardNumber: ob['ExtShvaParams.CardNumber5'],
                                                tokef: ob['ExtShvaParams.Tokef30'],
                                                numberOfPay: this.state.numberOfPay,
                                                meta: JSON.stringify(ob),
                                                confirmation: ob['ExtShvaParams.ApprovalNumber71'],
                                            });
                                        }
                                    })
                                } else {
                                    this.setState({ DealResponse: ob.DealResponse });
                                }
                            })
                    }
                }}>
            </iframe>)
        }

        let branchData = oData.orderFields ?
            this.props.data.BRANCHES_LIST.dataset.find(f => f.code === oData.orderFields.dBranch)
            : null



        let siteSettings = this.props.data[this.setting_id];


        let paymentsView = (<Row style={siteSettings.pDesktopCategoriesView != '6' ? { backgroundColor: '#404041' } : { minHeight: window.innerHeight - 210 }}>
            <Col style={{ padding: 20 }} span={this.mobile ? 24 : 4}>
                {this.props.getClassNameStyle("title_block", { padding: 5 }, this.props.getText(13112))}
                <div className={"text_item"} style={{ backgroundColor: '#eeeeee', padding: 20 }}>
                    <div>
                        {/* <h2 style={{ display: 'inline-block' }}>{this.props.getText(13113)}</h2>: */}
                        {ot ? ot.name : ''}
                    </div>
                    <div>{branchData ? branchData.name : ""}</div>
                    <div>₪{this.getSumPrices()}</div>
                    {/* {textStyle(this.props.getText(13113), ot ? ot.name : '')} */}
                    {oData.futureOrder ?
                        (<div style={{ paddingBottom: 15 }}>
                            {fieldStyle(13582)}
                            {fieldStyle(14334, 'futureDate')}
                            {fieldStyle(14335, 'futureTime')}
                        </div>)
                        : fieldStyle(13583)}
                    {oData.needAddress === '1' ?
                        (<div>
                            {fieldStyle(13091, 'dName')}
                            {fieldStyle(13092, 'dPhone')}
                            {fieldStyle(13093, 'dCity')}
                            {fieldStyle(13094, 'dStreet')}
                            {fieldStyle(13095, 'dBuilding')}
                            {fieldStyle(13096, 'dEntrance')}
                            {fieldStyle(13097, 'dFloor')}
                            {fieldStyle(13098, 'dApartement')}
                            {fieldStyle(13099, 'dRemarks')}
                            {remarkStyle}
                        </div>)
                        : (<div>
                            {fieldStyle(13091, 'dName')}
                            {fieldStyle(13092, 'dPhone')}
                            {fieldStyle(13099, 'dRemarks')}
                            {remarkStyle}
                        </div>)}
                </div>
            </Col>
            <Col style={{ padding: 20 }} span={this.mobile ? 24 : viewCart ? 14 : 20}>
                {this.props.getClassNameStyle("title_block", { padding: 5 }, this.props.getText(13565))}
                {this.state.successSale ?
                    (<div className={"text_item"} style={{ backgroundColor: '#eeeeef', padding: 20 }}>
                        {this.state.cardcomUrl && (!this.state.DealResponse || this.state.DealResponse === '-1') ? cardcomIframe() : ''}
                        {this.state.cardcomUrl && this.state.DealResponse && this.state.DealResponse !== '-1' && this.state.paymentType !== '1' ?
                            this.state.DealResponse === '0' && this.state.InternalDealNumber ?
                                this.state.lastPaymentType == '3' ? "" : (<div><h1>{this.props.getText(13380)}</h1></div>)
                                : (<div>
                                    <h1>{this.props.getText(13382)}</h1>
                                    <h1>{this.props.getText(13383)}: {
                                        this.state.DealResponse === '3' ? this.props.getText(13384) :
                                            this.state.DealResponse === '4' ? this.props.getText(13385) :
                                                this.props.getText(13386)
                                    }
                                    </h1>
                                </div>)
                            : ''}
                        {(this.state.paymentTypeCheck && this.state.paymentType === '1') || this.state.paymentBalance === '0.00' ? [
                            successOwner,
                            <Button
                                onClick={() => { this.clearState(() => { this.props.changeScreen(1) }) }}
                                style={{
                                    margin: 10,
                                    width: 140,
                                    height: 50,
                                    ...this.props.buttonStyle
                                }}>
                                <span className={"text_item"} style={this.props.buttonStyle}>{this.props.getText(13401)}</span>
                            </Button>
                        ] : ''}
                        {!this.state.paymentTypeCheck && this.state.paymentBalance !== '0.00' ? paymentTypeWindow() : ''}
                        {/* {this.state.swiichView ?
                            (<SwiichButton
                                user={this.props.user} ui={this.props.ui} uiActions={this.props.uiActions}
                                data={this.props.data} dataActions={this.props.dataActions} ActionQueue={this.props.ActionQueue}

                                elementId="swiich-element-in-site"
                                width={40} height={40}
                                eventsConfig={{}}
                                customerName={'shooky'}
                                amount={1}
                                orderId={this.state.ownerID}

                                walletList={[1, 2, 3, 4, 5, 6]}
                                value={this.state.swiichWalletId}
                                onChange={(e) => {
                                    console.log(e)
                                    this.setState({ swiichWalletId: e })
                                }}
                            />) : ""} */}
                    </div>) : ''}

            </Col>
            <Col style={{ padding: 20 }} span={!this.mobile && viewCart ? 6 : 0}>
                <FoodsFormatCart
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                    isBackOffice={this.props.isBackOffice}
                    getText={this.props.getText}
                    isLogin={this.props.isLogin}
                    sendAPI={this.props.sendAPI}
                    errorCallback={this.props.errorCallback}
                    changeScreen={this.changeScreen}
                    companyCode={this.props.companyCode}
                    isShop={false}
                    getPiecesText={this.props.getPiecesText}
                    priceNoTax={this.props.data[this.setting_id].pPricesNoTax == '1'}
                />
            </Col>
        </Row>)

        return (<span>
            {this.props.saveScreen || !this.state.ownerID ? this.spinView(13418) : this.state.sensepayWaiting ? this.spinView(15802) : this.props.isFromPos ? this.spinView(16421) : paymentsView}
            {this.modalMismatch()}
            {this.modalMivzaimChange()}
        </span>)

    }

}

export default FoodOrderPayments;