/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import GenericTable from "../components/GenericTable";
import GenericModal from "../components/GenericModal";
import TableLineIcons from "../components/TableLineIcons";
import { Button, Form, Input, Select, Icon } from "antd";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import VideoView from "../components/VideoView";
import TableIDs from "../data/TableIDs";
import GenericSelector from "../components/GenericSelector";

class HelpDocumentations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalCreateEdit: null,
            fileList: [],
        }

        this.id = "HELP_DOCUMENTATIONS_TABLE";

        this.api = {
            get: "get_help_documentations_table",
            delete: "delete_help_documentation",
            save: "save_help_documentation",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorUIComponents, api: "get_ui_component_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16458);
    }

    renderLink = (text, record) => {
        if (this.props.admin) {
            let gt = this.getText;
            return (<TableLineIcons
                onEdit={() => { this.setState({ modalCreateEdit: record }) }}
                onDelete={() => { this.sendAPI(this.api.delete, record.key, this.refreshData) }}
                deleteQuetion={{ title: gt(16465), okText: gt(16466), cancelText: gt(16467) }}
            />)
        } else {
            return (<TableLineIcons
                genericIcons={[{
                    type: 'eye',
                    onClick: () => { this.openLink(text) }
                }]} />)
        }
    }

    openLink = (url) => {
        if (url) {
            let origin = window.location.origin;
            const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
            let baseUrl = isDev ? "http://147.235.163.248" : origin;
            window.open(baseUrl + "/uploads/shared/" + url, '_blank');
        }
    }

    createNew = () => {
        let modalCreateEdit = {}
        this.props.data[this.id].headers.forEach(x => { modalCreateEdit = { ...modalCreateEdit, [x]: '' } })
        this.setState({ modalCreateEdit })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null, FileList: [] })
    }

    handleOk = () => {
        const { headers } = this.props.data[this.id];
        let mData = this.state.modalCreateEdit;
        let dataSend = headers.join('\f') + "\r" + headers.map(x => mData[x] ? mData[x] : "").join('\f');
        this.sendAPI(this.api.save, dataSend, () => {
            this.setState({ modalCreateEdit: null, FileList: [] }, this.refreshData)
        });
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    handleChange = (e, onSuccess) => {
        console.log(e, 'file blob')
        this.setState({ fileList: e.fileList }, () => {
            if (e.file.status !== "removed") {
                let fileBlob = e.file;
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    let fileUrl = reader.result;
                    this.setState({ fileUrl, loading: false, },
                        () => {
                            let user = new User(this.props.user.token, this.props.user.companyCode);
                            let job = new Job(user, "upload_shared_image", OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
                            job.setSection("imageName", fileBlob.name.split('.')[0]);
                            job.setSection("imageExt", fileBlob.name.split('.').slice(-1)[0]);
                            job.setSection("imageType", 'P');
                            job.setSection("imageMeta", fileUrl.split(',')[0]);
                            job.setSection("imageData", fileUrl.split(',')[1]);

                            job.send("/cgi-bin/CashOnTab", onSuccess, e => { console.error(e) });
                        });
                });
                reader.readAsDataURL(e.file);

            } else {
                onSuccess({ data: '' });
            }
        });
    };

    getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => { callback(reader.result) });
        if (file) reader.readAsDataURL(file);
    }

    getModalField = (label, field) => {
        let mData = this.state.modalCreateEdit;
        let value = mData ? mData[field] : "";
        let onChange = e => { this.setState({ modalCreateEdit: { ...mData, [field]: e } }) }
        let content = '';
        switch (field) {
            case 'nDescription':
                content = (<Input value={value} onChange={e => { onChange(e.target.value) }} />);
                break;
            case 'nDocType':
                content = (<Select value={value} onChange={onChange} style={{ width: "100%" }}>
                    <Select.Option value="1">{this.getText(16462)}</Select.Option>
                    <Select.Option value="2">{this.getText(16463)}</Select.Option>
                    <Select.Option value="3">{this.getText(17883)}</Select.Option>
                    <Select.Option value="4">{this.getText(17884)}</Select.Option>
                    <Select.Option value="5">{this.getText(17903)}</Select.Option>
                </Select>);
                break;
            case 'nUiComponentId':
                content = (<GenericSelector {...this.props} {...this.selectors[0]}  {...{ value, onChange }} />);
                break;
            case 'nFileLink':
                content = value ?
                    [<Button key={"upButton"} onClick={() => {
                        this.openLink(value)
                    }}><Icon type="eye" style={{ marginLeft: 5 }} />{this.getText(16474)}</Button>,
                    <Button key={"upButton"} onClick={() => {
                        this.setState({ fileList: [] }, () => { onChange('') })
                    }}><Icon type="delete" style={{ marginLeft: 5 }} />{this.getText(16475)}</Button>
                    ]
                    //     : (<Upload
                    //         fileList={this.state.fileList}
                    //         onChange={e => { this.handleChange(e, (ob) => { onChange(ob.data ? ob.data : "") }) }}
                    //         onRemove={() => { this.setState({ fileList: [] }, () => { onChange("") }) }}
                    //         beforeUpload={(file) => {
                    //             this.setState({ fileList: [file] });
                    //             return false;
                    //         }}
                    //     >
                    //         <Button key={"upButton"}><Icon type="upload" style={{ marginLeft: 5 }} />{this.getText(16473)}</Button>
                    //     </Upload >);
                    : (<VideoView
                        {...this.props}
                        url={value}
                        onChange={onChange}
                        onRemove={() => { onChange("") }}
                        isShared
                        uploadScriptName={"upload_shared_video"}
                        onLoadDuration={() => { }}
                    />)
                break;
        }

        return (<Form.Item label={this.getText(label)} > {content}</Form.Item>)
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            width: '100%'
        };

        const gt = this.getText;
        const render = t => t;
        const renderType = t => {
            switch (t) {
                case '1': return gt(16462)
                case '2': return gt(16463)
                case '3': return gt(17883)
                case '4': return gt(17884)
                case '5': return gt(17903)
            }
        }

        const renderSelector = t => (<GenericSelector {...this.props} {...this.selectors[0]} value={t} disabled />)

        const sorter = true;
        const addButton = this.props.admin ? this.createNew : null;


        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16457)}
                filters={{ _isAsc: 0, _sort_by: 'col_4', _docType: '0' }}
                searchBox
                addButton={addButton}
                columns={[
                    { title: gt(16459), width: "25%", key: "nDescription", dataIndex: "nDescription", sorter, render },
                    { title: gt(16460), width: "15%", key: "nDocType", dataIndex: "nDocType", sorter, render: renderType },
                    { title: gt(16461), width: "25%", key: "nCreationDatetime", dataIndex: "nCreationDatetime", sorter, render },
                    { title: gt(20503), width: "25%", key: "nUiComponentId", dataIndex: "nUiComponentId", sorter, render: renderSelector },
                    { title: gt(16464), width: "10%", key: "nFileLink", dataIndex: "nFileLink", render: this.renderLink },
                ]}
                genericActionButtons={[
                    <Select
                        style={{ width: 250 }}
                        value={this.props.data[this.id].filters._docType ? this.props.data[this.id].filters._docType : "0"}
                        onChange={e => {
                            this.props.dataActions.genericDataSetFilter(
                                this.id, this.props.user.companyCode, this.props.user.token, { _docType: e }, this.api.get);
                        }}>
                        <Select.Option value="0">{this.getText(17885)}</Select.Option>
                        <Select.Option value="1">{this.getText(16462)}</Select.Option>
                        <Select.Option value="2">{this.getText(16463)}</Select.Option>
                        {this.props.user.companyCode === '2f5a' ? (<Select.Option value="3">{this.getText(17883)}</Select.Option>) : null}
                        {this.props.user.companyCode === '2f5a' ? (<Select.Option value="4">{this.getText(17884)}</Select.Option>) : null}
                        <Select.Option value="5">{this.getText(17903)}</Select.Option>
                    </Select>
                ]}
            />
            <GenericModal
                visible={this.props.admin && this.state.modalCreateEdit}
                onCancel={this.handleCancel}
                title={gt(16468)}
                width={500}
                footer={[
                    <Button onClick={this.handleCancel}>{gt(16470)}</Button>,
                    <Button type='primary' onClick={this.handleOk}>{gt(16469)}</Button>
                ]}>
                {this.getModalField(16459, 'nDescription')}
                {this.getModalField(16460, 'nDocType')}
                {this.getModalField(20503, 'nUiComponentId')}
                {this.getModalField(16471, 'nFileLink')}
            </GenericModal>
        </div>)
    }
}
export default HelpDocumentations;