/* @flow */
/*jshint esversion: 6 */
import * as React from "react";
// import { useState } from "react";
// import { Layout, Icon, Tooltip } from "antd";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import "./App.css";
import FormsRoute from "./forms/FormsRoute";
import BackOfficeApp from "./backOffice/BackOfficeApp";
import { Modal } from "antd";
import { isBODev } from "./utils/utils";

const { confirm } = Modal;

//import LangContext from "./contextProvider/LangContext";
// End of pages
// const { Header, Sider, Content, Footer } = Layout;

type Props = {
  User: Object,
  loginUser: Function,
  logoutUser: Function,

  Ui: Object,
  toggleMainMenu: Function,
  savePreviousFormValues: Function,
  UiActions: any,
  dataActions: any,
  data: Object,
  ActionQueue: any
};

//var  6.2.1


const baseUrl = process.env.PUBLIC_URL;
//const baseUrl = "/cash-on-tab/build"; //dev - "homepage": "/cash-on-tab/build",
//const baseUrl = ""; // prod - "homepage": "/",
//const baseUrl = process.env.PUBLIC_URL
//const TextValues = React.createContext();

function App(props: Props) {
  let paramsUrl = window.location.search;
  ///window.store = props;
  console.log("PROCESS: ", process);
  //console.log("BASEURL: ", baseUrl);
  //console.log("PROCESS.ENV: ", process.env);
  return (
    /*<React.StrictMode>*/
    <CacheClearer isLogin={props.User.token.length > 0}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          console.log("CacheClearer: ", loading, isLatestVersion);
          // You can decide how and when you want to force reload
          //let enabled = 0
          //if (enabled === 1) {
          refreshCacheAndReload();
          //}
          return null;
        }

        return (<Router>
          <span>
            {paramsUrl.length > 0 && paramsUrl.indexOf("autoIphone=1") === -1 ?
              <FormsRoute
                user={props.User}
                baseUrl={baseUrl}
                forms={props.forms}
                formsActions={props.formsActions}
                ui={props.Ui}
                uiActions={props.UiActions}
                dataActions={props.dataActions}
                data={props.data}
                ActionQueue={props.ActionQueue}
                adminLogin={props.loginUser} />
              :
              <BackOfficeApp
                user={props.User}
                baseUrl={baseUrl}
                ui={props.Ui}
                uiActions={props.UiActions}
                dataActions={props.dataActions}
                data={props.data}
                ActionQueue={props.ActionQueue}
                login={props.loginUser}
                loginRefresh={props.loginRefresh}
                logoutFunction={props.logoutUser}
                toggleMainMenu={props.toggleMainMenu}
                saveValues={props.savePreviousFormValues}

                renew={props.renewUser}
                getUser={props.getUserForRenew}
                setUser={props.setUserForRenew}
                justLogin={props.justLoginUser}

              />}
          </span>
        </Router>)

      }}
    </CacheClearer>

    /*</React.StrictMode>*/
  );
}
/* <FavoriteBar token={props.User.token} baseUrl={baseUrl} /> */

export default App;

class CacheClearer extends React.Component/*<Props, State>*/ {
  constructor(props/*: Props*/) {
    super(props);


    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: this.refreshCacheAndReload
    };
    ///console.log("TEST_CC CacheClearer contr ");
  }

  componentDidMount() {
    this.cacheClearRun()
  }

  componentDidUpdate(prev) {
    if (!prev.isLogin && this.props.isLogin) {
      this.cacheClearRun()
    }
  }

  refreshCacheAndReload = () => {
    this.indexedDbCheck(() => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        /*
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
        */
        caches.keys().then(async function (names) {
          await Promise.all(names.map(name => caches.delete(name)));
        });
      }

      this.indexedDbCreate()
      // Promise.all()

      // delete browser cache and hard reload
      //window.location.reload(true);
      //window.location.reload();
      //window.location.replace(window.location.href);
      //window.location.href = window.location.href + "";
      //window.location.assign(window.location.href);

      setTimeout(() => {
        let origin = window.location.origin;
        if (isBODev()) origin += '/cash-on-tab/build';
        window.location.assign(origin + "/reload.html" + "?url=" + encodeURIComponent(window.location.href));
      }, 3000)

      console.log('Clearing cache and hard reloading finished')
    }, () => {
      confirm({
        title: "טעינת גירסה חדשה",
        content: "לצורך טעינת הגירסה המעודכנת יש לסגור את הדפדפן ולפתוח מחדש",
        iconType: 'exclamation-circle',
        okText: '',
        cancelText: '',
        onOk() { },
        onCancel() { },
      });
    })
    ///console.log('Clearing cache and hard reloading...')

  }

  indexedDbConnect = (onsuccess) => {
    let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    let open = indexedDB.open("last_cache_clear", 1);
    open.onupgradeneeded = () => {
      let db = open.result;
      let store = db.createObjectStore("last_clear", { keyPath: "field_name" });
      store.createIndex("field_name", "field_name");
      store.createIndex("field_value", "field_value");
    };
    open.onsuccess = () => { onsuccess(open) }
  }

  indexedDbCheck = (onFirst, onNotFirst) => {
    this.indexedDbConnect((open) => {
      let db = open.result;
      let tx = db.transaction("last_clear", "readwrite");
      let store = tx.objectStore("last_clear");
      store.index("field_name");
      let getLastClear = store.get("lastClear");
      getLastClear.onsuccess = () => {
        let prevLast = getLastClear.result ? getLastClear.result.field_value : 0
        let curLast = Date.now();
        if (curLast - prevLast < 10000) onNotFirst()
        else onFirst();

        tx.oncomplete = () => { db.close() };
      };
    })
  }

  indexedDbCreate = () => {
    this.indexedDbConnect((open) => {
      //console.log("test db - create");
      let db = open.result;
      let tx = db.transaction("last_clear", "readwrite");
      let store = tx.objectStore("last_clear");
      store.index("field_name");
      store.put({ field_name: "lastClear", field_value: Date.now() });
      tx.oncomplete = () => { db.close() };
    })
  }

  cacheClearRun = () => {
    ///console.log("TEST_CC CacheClearer cmd");
    const hostname = window.location.hostname;
    if (hostname === "localhost" ||
      hostname === "127.0.0.1" ||
      hostname === "" /* local file */) {
      console.log(`localhost, version check is irrelevant. skip refresh`);
      this.setState({ loading: false, isLatestVersion: true });
    } else {
      fetch(process.env.PUBLIC_URL + '/meta.json', { cache: "no-store" })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          //const currentVersion = global.appVersion;
          const currentVersion = process.env.REACT_APP_VERSION;

          console.log("currentVersion: ", currentVersion);
          console.log("latestVersion: ", latestVersion);
          //const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
          const shouldForceRefresh = (latestVersion === currentVersion) ? 0 : 1;
          if (shouldForceRefresh) {
            console.log(`We have a new version - ${latestVersion}. Should force refresh`);
            this.setState({ loading: false, isLatestVersion: false });
          } else {
            console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
            this.setState({ loading: false, isLatestVersion: true });
          }
        }).catch((error) => {
          console.error('Error:', error);
          this.setState({ loading: false, isLatestVersion: true });
        });
    }

  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    console.log("CacheClearer render");
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }


}