/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, Button, //Form, Select, 
    Checkbox,
    //InputNumber, Input, 
    Icon, Popconfirm,
    //Table, Collapse, 
    Popover, Tooltip, message, Radio
} from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
// moment from "moment";
import PageToolbar from "../../components/PageToolbar";
//import ItemPicker from "../../components/ItemPicker";
import ModalAdvanceSearchReports from "../../components/ModalAdvanceSearchReports";
import LabelPrinter from "../../components/LabelPrinterV2";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { escapeRegExp } from "../../utils/utils";
import moment from "moment";
import ReportShowButton from "../../components/ReportShowButton";


// לתקופה
//const FormItem = Form.Item;
//const { Option, OptGroup } = Select;
//const Panel = Collapse.Panel;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class OrdersFromSupplier extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PaymentsSearch: {
                mDocTypeList: '15',
                mPosList: '',
                mLowerSelectionDate: moment().add(-30, "days").format("YYYY-MM-DD"),
                mLowerSelectionTime: '00:00:00',
                mUpperSelectionDate: moment().format("YYYY-MM-DD"),
                mUpperSelectionTime: '23:59:59',
                mApplyCreditCardSearchQuery: null,
                mIsCustomerActive: 1,
                mCustomer: null,
                mItemCode: '',
                mEmployeeList: '',
                mStorageList: '',
                mDocumentIsClosed: 1,
                mSumFrom: null,
                mSumTo: null,
                mSumAbsolute: 0,
                mSupplierDoc: null,
                mSupplierDocDateFrom: null,
                mSupplierDocDateTo: null,
                mTagList: '',
                mCustTagList: '',
                mItemSerialNumber: '',

            },
            OrdersFromSupplier: {
                DocumentNumber: null,
                DocumentTypeID: null,
                DocumentPOSID: null,
                CompanyCode: this.props.user.companyCode,
                reportName: "reports/DocumentCopy.pdf",
            },
            mWords: '',
            userID: -1,
            isReportDetailed: '0'
        };

        this.timeOut = null;
        this.state.collapeKey = null;
        this.id = "DOC_SEARCH_TABLE";
        this.labelsId = "DOC_ITEMS_TO_LABELS";
        this.state = { ...this.state, defaultItemCode: "", itemCodeSelectionVisible: false };

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[this.labelsId] === undefined) props.data[this.labelsId] = { dataset: [] };


        this.tableApi = {
            get: "get_doc_search_table_list",
            create: "",
            edit: "edit_doc_is_closed",
            //delete: "delete_customer",
            selector: "",
            modalPriceListGet: "",
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorDocumentTypes] === undefined) {
            props.data[TableIDs.genericSelectorDocumentTypes] = { ...props.data.genericSelector };
        }
        // if (props.data[TableIDs.genericSelectorCustomers] === undefined) {
        //     props.data[TableIDs.genericSelectorCustomers] = { ...props.data.genericSelector };
        // }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            selector_payment_types: "get_payment_types_list",

            selector_docTypes: "get_document_types_list",
            selector_customers: "get_customers_list_v3",
            selector_employee: "get_employee_list",
            selector_storages: "get_storage_list",
        }



        this.selectTable = null;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - דו\"חות";
        // this.loadData();
        this.refreshDataSelectors();

        // this.initFilters();
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10163);
        // if (this.props.user.userId !== this.state.userID) {
        //     this.setState({ userID: this.props.user.userId }, () => {
        //         this.refreshDataSelectors();
        //         setTimeout(() => { this.initFilters() }, 600);
        //     })
        // }

        if (prevProps.data[TableIDs.genericSelectorPos].dataset != this.props.data[TableIDs.genericSelectorPos].dataset) {
            clearImmediate(this.selectTable)
            this.selectTable = setTimeout(this.initFilters, 1000);
        }

    }

    initFilters = () => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');

        let request = {
            ...this.state.PaymentsSearch,
            mPosList: this.state.PaymentsSearch.mPosList === '' ? fullPosList : this.state.PaymentsSearch.mPosList,
            searchColFilter: "0",
            mCustWithConnected: "0",
            mCustomerList: "",
            mCustType: "2",

            _id: 0,
            // ui
            page: 1,
            recordsPerPage: 10,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: escapeRegExp(this.state.mWords),
            _page_num: 1,
            _rows_num: 10,
            _isAsc: 0,
            _sort_by: 'col_0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.tableApi.get);

        //  this.refreshDataSelectors();
    }

    refreshData = () => {
        // this.refreshDataFilters()
        // this.refreshDataSelectors()
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.tableApi.get);
    }

    refreshDataSelectors = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);

    }

    // refreshDataFilters = () => {
    //     this.props.dataActions.genericDataSetFilter(
    //         this.id, this.props.user.companyCode, this.props.user.token, this.props.data[this.id].filters, this.tableApi.get);
    // }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 10;
        // fall through
        switch (sorter.field) {
            case "key": col--;  // falls through // 0
            case "DocumentPOSID": col--; // falls through
            case "POSName": col--; // falls through
            case "DocTypeName": col--; // falls through
            case "DocumentNumber": col--; // falls through
            case "DocumentCustomerName": col--; // falls through
            case "DocumentTotalWithTax": col--; // falls through
            case "DocumentTimestamp": col--; // falls through
            case "EmployeeName": col--; // falls through
            case "DocZNum": col--; // falls through
            case "StorageName": break; // 10

            //case "EmployeeName": col--;
            //case "DocumentEmployeeID": col--;
            //case "DocumentTypeId": break; // 10
            //case "DocumentTypeName": break; //10
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: escapeRegExp(this.state.mWords),
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.tableApi.get)
    }


    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    setReportParametersAndDo(report: string, params: Object, func) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState, func);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorDocumentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_docTypes]
        });

        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorCustomers, this.props.user.companyCode, this.props.user.token, this.api.selector_customers, { _words: null }]
        // });

        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.PaymentsSearch.LowerYearSelection;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.PaymentsSearch.UpperYearSelection;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    onChange = (field, value, field2, value2) => {
        console.log("f ", field, "v ", value);
        this.setReportParameters("PaymentsSearch", { [field]: value, [field2]: value2 })
    }

    onStartChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;
        console.log("start date ", arr);
        this.onChange('mLowerSelectionDate', arr[0], 'mLowerSelectionTime', '00:00:00' /*arr[1]*/);
    }

    onEndChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');
        console.log("end date ", arr);
        this.onChange('mUpperSelectionDate', arr[0], 'mUpperSelectionTime', '23:59:59' /*arr[1]*/);
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentsSearch", { [field]: data });
                    else
                        this.setReportParameters("PaymentsSearch", { [field]: '' });
                }} />);
    }

    collapeCallback = (key) => {
        this.setState({ collapeKey: key });
    }
    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }

    // createLabels = (docNum, docType, docPos) => {
    //     this.sendAPI("get_doc_items_to_labels", "mDocNum\fmTypeID\fmPOSID\r" + docNum + "\f" + docType + "\f" + docPos, ob => {
    //         let dataset = []
    //         if (ob.data) {
    //             let headers = []
    //             ob.data.split("\r").forEach((x, i) => {
    //                 let y = x.split('\f')
    //                 if (!i) {
    //                     headers = y
    //                 } else {
    //                     let obj = { index: i - 1, checked: true, _package_qty: 1 }
    //                     headers.forEach((z, zi) => { obj = { ...obj, [z]: y[zi] } })
    //                     dataset.push(obj)
    //                 }
    //             })
    //         }
    //         this.props.dataActions.setJson(this.labelsId, { dataset })
    //     })
       
    // }

    
    reportButton = () => {
        let generateReport = (reportName) => {
            const f = this.props.data[this.id].filters;
            const fs = { ...f, _words: encodeURIComponent(f._words), page: undefined, reportName: "reports/" + reportName, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, fs]
            });
        }

        let subButton = (text, type, reportName) => {
            let onClick = () => { generateReport(reportName) }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }

        let content = (<div>
            <Radio.Group value={this.state.isReportDetailed} onChange={e => { this.setState({ isReportDetailed: e.target.value }) }}>
                <Radio value={'0'}>{this.getText(19338)}</Radio><br />
                <Radio value={'1'}>{this.getText(19339)}</Radio>
            </Radio.Group>
            {this.state.isReportDetailed == "1" ? "" : subButton(12721, "file-pdf", "DocumentsReport.pdf")}
            {subButton(12722, "file-excel", this.state.isReportDetailed == "1" ? "OrdersFromSupplierDetailed.xls" : "DocumentsReportExcel.xls")}
        </div>);

        return (
            <Tooltip title={this.getText(12720)}>
                <Popover content={content} title={this.getText(12723)} trigger="click">
                    <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip >)
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    };


    render() {
        const { PaymentsSearch, endOpen } = this.state;
        //const { mLowerSelectionDate, mLowerSelectionTime, mUpperSelectionDate, mUpperSelectionTime } = PaymentsSearch;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            console.log("yuda y", y)
            return {
                //key: y[0],
                key: y[0] + '#' + y[1] + '#' + y[2],
                DocumentNumber: y[0],
                DocumentTypeId: y[1],
                DocumentPOSID: y[2],
                DocumentTimestamp: y[3],
                DocumentEmployeeID: y[4],
                DocumentTotalWithTax: y[5],
                DocumentCustomerName: y[6],
                POSName: y[7],
                EmployeeName: y[8],
                //DocumentTypeName: y[9],
                DocTypeName: y[10],
                DocZNum: y[11],
                DocIsForInventory: y[12],
                StorageName: y[13],
                docIsMatrix: y[14],
                DocumentIsClosed: y[15],
                index: index
            }
        });

        let columns = [
            {
                title: this.getText(12791),
                dataIndex: "DocumentPOSID",
                key: "DocumentPOSID",
                sorter: true,
                width: '5%',
                render: (text, record) => (<Tooltip title={record.POSName}>{text}</Tooltip>)
            }, {
                title: this.getText(12792),
                dataIndex: "StorageName",
                key: "StorageName",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(12793),
                dataIndex: "DocumentCustomerName",
                key: "DocumentCustomerName",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(12794),
                dataIndex: "DocTypeName",
                key: "DocTypeName",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(12795),
                dataIndex: "DocumentNumber",
                key: "DocumentNumber",
                sorter: true,
                width: '10%',
                render: (text, record) => (<Tooltip title={this.getText(11178) + " : " + record.DocZNum}>{text}</Tooltip>)
            }, {
                title: this.getText(12796),
                dataIndex: "DocumentTotalWithTax",
                key: "DocumentTotalWithTax",
                sorter: true,
                width: '10%',
                render: (text, record) => parseFloat(text).toFixed(2),
            }, {
                title: this.getText(12797),
                dataIndex: "DocumentTimestamp",
                key: "DocumentTimestamp",
                sorter: true,
                width: '12%',
                render: (text, record) => text,
            }, {
                title: this.getText(12798),
                dataIndex: "EmployeeName",
                key: "EmployeeName",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(12799),
                width: '8%',
                render: (text, record) => {
                    const handleClick = (type: String) => {
                        this.setReportParametersAndDo("OrdersFromSupplier", {
                            DocumentNumber: record['DocumentNumber'],
                            DocumentTypeID: record['DocumentTypeId'],
                            DocumentPOSID: record['DocumentPOSID'],
                            userID: this.props.user.userId,
                            userName: encodeURIComponent(this.props.user.displayName),
                            isOriginal: false,
                            reportName: "reports/DocumentCopy." + type
                        }, () => { this.generateReport.apply(this, ["OrdersFromSupplier"]) })
                        //this.setReportParameters("OrdersFromSupplier", { reportName: "reports/paymentsSearch." + this.state.type, type: value });         
                    }
                    const content = (<div>
                        <Tooltip title={this.getText(11179)}>
                            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                                type={"file-pdf"} onClick={() => { handleClick("pdf") }} />
                        </Tooltip>
                        <Tooltip title={this.getText(11180)}>
                            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                                type={"file-excel"} onClick={() => { handleClick("xls") }} />
                        </Tooltip>
                        <Tooltip title={this.getText(11181)}>
                            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                                type={"file-word"} onClick={() => { handleClick("docx") }} />
                        </Tooltip>
                    </div>);

                    let isClosed = record.DocumentIsClosed == 1 ? true : false;

                    return (
                        <span>
                            <Tooltip title={this.getText(13106)}>
                                <Popconfirm title={this.getText(isClosed ? 12801 : 12800)} onConfirm={() => {
                                    let data = "DocumentIsClosed\fDocumentNumber\fDocumentTypeId\fDocumentPOSID\r" +
                                        (isClosed ? "0" : "1") + "\f" + record.DocumentNumber + "\f" + record.DocumentTypeId + "\f" + record.DocumentPOSID;
                                    this.sendAPI(this.tableApi.edit, data, this.refreshData, (e) => { message.error(e) })
                                }} okText={this.getText(12802)} cancelText={this.getText(12803)}>
                                    <Icon style={{ fontSize: 16, color: isClosed ? '#f20e0e' : '#0cd119' }} className={"clickable margin-4"}
                                        type={isClosed ? "lock" : "unlock"} />
                                </Popconfirm>
                            </Tooltip>
                            <Tooltip title={this.getText(13107)}>
                                <Popover content={content} title={this.getText(11183)} trigger="click">
                                    <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="printer" theme="outlined" />
                                </Popover>
                            </Tooltip>
                            <ReportShowButton
                                {...this.props}
                                iconButton
                                validSubmit
                                     params={{
                                    DocumentNumber: record['DocumentNumber'],
                                    DocumentTypeID: record['DocumentTypeId'],
                                    DocumentPOSID: record['DocumentPOSID'],
                                    userID: this.props.user.userId,
                                    userName: encodeURIComponent(this.props.user.displayName),
                                    docIsWithSigns: record.docIsWithSigns,
                                    reportName: "reports/DocumentCopy.pdf"
                                }} />
                            <Tooltip title={this.getText(12787)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"}
                                    type={"share-alt"} onClick={() => {
                                        this.sendAPI("get_document_report_link",
                                            "docNum\fdocType\fdocPos\r"
                                            + record['DocumentNumber'] + "\f"
                                            + record['DocumentTypeId'] + "\f"
                                            + record['DocumentPOSID'],
                                            (ob) => {
                                                console.log("yuda ob.data ", ob.data)
                                                window.open("https://wa.me/?text=" + ob.data, '_blank');
                                            }, (e) => {
                                                message.error(e)
                                            })
                                    }} />
                            </Tooltip>
                         
                        </span>
                    )
                }
            }
        ];

        let actionButtons = [
            {
                buttonType: 'filter',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalAdvanceSearchReports); },
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(12790)} showsearch={false} actionButtons={actionButtons} genericActionButtons={[this.reportButton()]} />)}
                    bordered={true} style={{ width: '100%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>


                    <ResponsiveTable
                        tableOnly={true}
                        percentageToMobile={[30, 35, 35]}

                        dataSource={dataSource}
                        columns={columns}
                        // loading={this.props.ui.workInProgress}
                        //scroll={{ x: 1650 }}
                        bordered
                        pagination={this.props.data[this.id].pagination}
                        onChange={this.handleTableChange}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    // locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                    />

                    <ModalAdvanceSearchReports
                        id={TableIDs.modalAdvanceSearchReports}
                        isFromOrders={true}
                        user={this.props.user}
                        uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                        dataActions={this.props.dataActions} data={this.props.data}
                        toggle={this.props.ui.modalStack.includes(TableIDs.modalAdvanceSearchReports)}
                    // title={this.getText(11185)}
                    />
                </Card>
               
                {/* <LabelPrinter {...this.props}
                    id={TableIDs.labelPrinter}
                    toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
                    title={this.getText(11186)}
                    labels={this.props.data[this.labelsId].dataset}
                    onChangeLabels={dataset => { this.props.dataActions.setJson(this.labelsId, { dataset }) }}
                />, */}
            </div >
        );
    }
}
export default OrdersFromSupplier;
