/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Checkbox, Input, message, Radio, Tooltip } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
//import ItemPicker from "../../components/ItemPicker";
import PageToolbar from "../../components/PageToolbar";
//import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import StockTransferStorages from "../../components/StockTransferStorages";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericModal from "../../components/GenericModal";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import ResponsiveTable from "../../components/ResponsiveTable";
import { disabledDatePicker } from "../../utils/utils";
import CustPicker from "../../components/CustPicker";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";

const FormItem = Form.Item;
//const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class StockItemCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            StockItemCard: {
                //mRequestedReport: 0,
                mItemCode: '',
                mSerialNumber: '',
                mLowerSelectionDate: moment().add(-1, "month").format("YYYY-MM-01"),
                //mLowerSelectionTime: "00:00:00",
                mUpperSelectionDate: moment().format("YYYY-MM-DD"),
                mStorageList: '', // compatibility
                // mShowPricesWithTax: 1,
                mMovinType: "0",
                mCustList: "",
                mSuppList: "",
                mDocTypeList: "",
                mViewCreationDate: "0",
                reportName: "reports/stockItemCard.pdf"
            },
            updateTable: null,

            _customerId: null,
            _customerWithConnected: '1',
            _customerConnectedList: '',
        };

        this.state.type = "pdf";
        this.state.mItemName = null;

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        this.connected_id = "CONNECTED_CUSTOMERS";

        this.modalFirstLoad = true;

        if (props.data[this.connected_id] === undefined) props.data[this.connected_id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        // if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //     props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        // }
        // if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
        //     props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        // }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        // if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
        //     props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        // }
        // if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
        //     props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        // }
        // if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
        //     props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        // }

        if (props.data[TableIDs.genericSelectorDocumentTypes] === undefined) {
            props.data[TableIDs.genericSelectorDocumentTypes] = { ...props.data.genericSelector };
        }


        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            selector_docTypes: "get_document_types_list",

            get_connected: "get_connected_customers",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        // this.props.dataActions.genericMultiSelectorRefreshDataset(
        //     TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);

        if (this.props.refreshData) this.props.refreshData(newState.StockItemCard)
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params, "mPosList", "mStorageList");
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.sendAPI("check_item_is_serialize", params.mItemCode, ob => {
                let reportName = "reports/stockItemCard" + (parseInt(ob.data) ? "Horizontal.pdf" : ".pdf")
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.generateReport,
                    args: [this.props.user.companyCode, this.props.user.token, { ...params, reportName }]
                });
            })
        }
    }

    getFullSelector = (params, posField, storagesField) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params[posField] === '') params = { ...params, [posField]: fullPosList }
        if (params[storagesField] === '') params = { ...params, [storagesField]: fullStoragesList }

        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10187);
        if (this.props.data[TableIDs.genericSelectorPos].dataset[0] &&
            this.props.data[TableIDs.genericSelectorStorages].dataset[0] &&
            !this.state.loading) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("StockItemCard", this.props.ui.favoriteParams)
        }

        if (this.props.isModalFromItems && (!prevProps.modalItemCode || this.modalFirstLoad) && this.props.modalItemCode) {
            this.modalFirstLoad = false;
            this.setState({ _customerId: this.props.filterCust ? this.props.filterCust : "" }, () => {

                this.setReportParameters("StockItemCard", {
                    mItemCode: this.props.modalItemCode,
                    mStorageList: this.props.modalItemStorageList,
                    mCustList: this.props.filterCust ? this.props.filterCust : "",
                    mSuppList: this.props.filterSupp ? this.props.filterSupp : "",
                })
            })
        }

        // if (prevProps.dataSourceTable !== this.props.dataSourceTable) {
        //     this.setState({ updateTable: Date.now() })
        // }


    }

    loadData() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        // });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericMultiSelectorRefreshDataset,
        //     args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        // });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        // });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        // });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        // });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.StockItemCard.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.StockItemCard.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("StockItemCard", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("StockItemCard", { [field]: data });
                else
                    this.setReportParameters("StockItemCard", { [field]: '' });
            }} />);
    }

    isItemValid = () => {
        return (this.state.StockItemCard.mItemCode === ''
            || this.state.StockItemCard.mItemCode === null)
            ? 'error' : 'success';
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemCode = itemsRows[0].split('\f')[1];

                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal/*, modalParamObj*/);
                    } else {

                        this.onChangeItemCode(_ItemCode)
                    }
                },
                (error) => {
                    message.error(this.getText(11958));
                }
            );
        }
    }

    keyboardListener(e) {

        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.onChangeItemCode('');
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default: break;
        }
    }


    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setReportParameters("StockItemCard", { mItemCode: code })
        }
    }


    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { message.error(e) });
    }

    onChangeCustId = (mId) => {
        this.setState({ _customerId: mId }, () => {
            this.sendAPI(this.api.get_connected, "mId\r" + mId, (ob) => {
                this.setState({ _customerConnectedList: ob.data ? ob.data.split("\r").slice(1, -1).map(x => x.split("\f")[1]).join(",") : "" }, this.filterCust)
            })
        })
    }

    filterCust = () => {
        const { _customerId, _customerWithConnected, _customerConnectedList } = this.state;
        let mCustList = "";
        if (_customerId) mCustList = _customerId;
        if (_customerWithConnected == '1' && _customerConnectedList) mCustList = _customerId + "," + _customerConnectedList;
        this.setReportParameters("StockItemCard", { mCustList });
    }

    render() {
        const { modalItemCode, modalItemName } = this.props

        console.log("this.state.modalStockItemCard", { modalItemCode, modalItemName })

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let dateStartField = (<FormItem label={this.getText(11961)} /*LowerSelectionDate - LowerSEleCtionTime*/>
            <DatePicker
                disabledDate={d => disabledDatePicker(d, null, this.state.StockItemCard.mUpperSelectionDate)}
                // disabledDate={this.disabledStartDate}
                // showTime
                format="DD-MM-YYYY"//"YYYY-MM-DD"
                //format="D/M/YY"
                value={this.state.StockItemCard.mLowerSelectionDate ? moment(this.state.StockItemCard.mLowerSelectionDate, "YYYY-MM-DD") : null}
                placeholder={this.getText(11970)}
                onChange={this.onStartChange}
            //onOpenChange={this.handleStartOpenChange}
            />

        </FormItem>)

        let dateEndField = (<FormItem label={this.getText(11962)} /*style={isEndDateShownStyle}*/>
            <DatePicker
                disabledDate={d => disabledDatePicker(d, this.state.StockItemCard.mLowerSelectionDate, null)}
                // disabledDate={this.disabledEndDate}
                // showTime
                format="DD-MM-YYYY"//"YYYY-MM-DD"
                //format="D/M/YY"
                value={this.state.StockItemCard.mUpperSelectionDate ? moment(this.state.StockItemCard.mUpperSelectionDate, "YYYY-MM-DD") : null}
                placeholder={this.getText(11971)}
                onChange={this.onEndChange}
            //open={endOpen}
            //onOpenChange={this.handleEndOpenChange}
            />
        </FormItem>)

        let storageListField = (<StockTransferStorages
            {...this.props}
            fieldName={'mStorageList'}
            onChange={(value) => {
                this.setReportParameters("StockItemCard", { mStorageList: value.join(',') })
            }}
            value={(this.state.StockItemCard.mStorageList === '') ? [] : this.state.StockItemCard.mStorageList.split(',')}
        />)

        let serialNumberField = (<FormItem label={this.getText(18516)}>
            <Input
                onChange={(value) => {
                    this.setReportParameters("StockItemCard", { mSerialNumber: value.target.value })
                }}
                value={this.state.StockItemCard.mSerialNumber}
            />
        </FormItem>)

        let movinTypeField = (<Form.Item>
            <Radio.Group value={this.state.StockItemCard.mMovinType}
                onChange={(e) => {
                    this.setReportParameters("StockItemCard", { mMovinType: e.target.value })
                }}>
                <Radio value="0">{this.getText(18770)}</Radio><br />
                <Radio value="1">{this.getText(18771)}</Radio><br />
                <Radio value="2">{this.getText(18772)}</Radio><br />
            </Radio.Group>
        </Form.Item>)

        let customerField = (<Form.Item label={this.getText(18946)}>
            <CustPicker
                {...this.props}
                disabled={this.state.StockItemCard.mSuppList}
                value={this.state._customerId}
                onChange={this.onChangeCustId}
                defaultView={this.getText(12121)}
            />
            {this.state._customerConnectedList ?
                <Radio.Group value={this.state._customerWithConnected}
                    onChange={(e) => { this.setState({ _customerWithConnected: e.target.value }, this.filterCust) }}>
                    <Radio value="1">{this.getText(18947)}</Radio><br />
                    <Radio value="0">{this.getText(18948)}</Radio><br />
                </Radio.Group>
                : ""}
        </Form.Item>)

        let supplierField = (<Form.Item label={this.getText(19715)}>
            <GenericSelectorSuppliers
                {...this.props}
                disabled={this.state._customerId}
                value={this.state.StockItemCard.mSuppList}
                onChange={e => { this.setReportParameters("StockItemCard", { mSuppList: e }) }}
            />
        </Form.Item>)

        let viewCreationDateField = (<Form.Item>
            <Checkbox
                checked={this.state.StockItemCard.mViewCreationDate == '1'}
                onChange={e => {
                    this.setReportParameters("StockItemCard", { mViewCreationDate: e.target.checked ? "1" : "0" })
                }}>{this.getText(19391)}</Checkbox>
        </Form.Item>)

        let docTypeListField = (<Form.Item label={this.getText(20523)}>
            <GenericSelector
                {...this.props} multi
                id={TableIDs.genericSelectorDocumentTypes}
                api={this.api.selector_docTypes}
                value={this.state.StockItemCard.mDocTypeList ? this.state.StockItemCard.mDocTypeList.split(",") : []}
                onChange={e => { this.setReportParameters("StockItemCard", { mDocTypeList: e?.join(",") }) }}
            />
        </Form.Item>)

        const renderCol = t => (<div style={{ textAlign: "right", paddingRight: 4 }}>{t}</div>)
        const renderColLtr = t => (<div style={{ direction: "ltr", textAlign: "right", paddingRight: 4 }}>{t}</div>)
        const dateFormat = t => t ? moment(t).format("HH:mm DD/MM/YYYY") : null

        const renderTime = (t, r) => {
            return this.state.StockItemCard.mViewCreationDate == '1' && r._docCreationDate ?
                <Tooltip title={this.getText(19392) + " " + dateFormat(r._docCreationDate)}>
                    <div style={{ textAlign: "right", paddingRight: 4, background: "pink" }}>{dateFormat(t)}</div>
                </Tooltip>
                : renderCol(dateFormat(t));
        }
        const renderSum = t => renderCol(parseFloat(t) ? parseFloat(t).toFixed(2) : null);

        if (this.props.isModalFromItems) {
            return (<GenericModal
                visible={this.props.modalItemCode}
                onCancel={this.props.modalItemCodeClose}
                title={this.getText(11959)}
                actionButtons={[
                    { buttonType: 'refresh', buttonFunction: () => { this.props.refreshData(this.state.StockItemCard) } },
                    { buttonType: 'print', buttonFunction: () => { this.generateReport("StockItemCard") } },
                ]}>
                <Row>
                    <Col span={6}>
                        <h3>{this.getText(17290)}: {this.props.modalItemCode}</h3>
                        <h3>{this.getText(17291)}: {this.props.modalItemName}</h3>
                        {serialNumberField}
                        {viewCreationDateField}
                    </Col>
                    <Col span={6}>
                        {dateStartField}
                        {dateEndField}
                    </Col>
                    <Col span={6}>
                        {storageListField}
                        {docTypeListField}
                    </Col>
                    <Col span={6}>
                        {customerField}
                        {supplierField}
                        {movinTypeField}
                    </Col>
                </Row>

                <ResponsiveTable
                    tableOnly
                    dataSource={this.props.dataSourceTable}
                    columns={[
                        { title: this.getText(17292), key: '_time', dataIndex: '_time', width: '10%', render: renderTime },
                        { title: this.getText(17293), key: '_type', dataIndex: '_type', width: '10%', render: renderCol },
                        { title: this.getText(17294), key: '_number', dataIndex: '_number', width: '10%', render: renderCol },
                        { title: this.getText(17295), key: '_sumNoTax', dataIndex: '_sumNoTax', width: '10%', render: renderSum },
                        { title: this.getText(17296), key: '_discount', dataIndex: '_discount', width: '10%', render: renderSum },
                        { title: this.getText(17297), key: '_inQty', dataIndex: '_inQty', width: '10%', render: renderColLtr },
                        { title: this.getText(17298), key: '_outQty', dataIndex: '_outQty', width: '10%', render: renderColLtr },
                        { title: this.getText(17299), key: '_subTotal', dataIndex: '_subTotal', width: '10%', render: renderColLtr },
                        { title: this.getText(17300), key: '_storage', dataIndex: '_storage', width: '10%', render: renderCol },
                        { title: this.getText(17301), key: '_opposite', dataIndex: '_opposite', width: '10%', render: renderCol },
                    ]}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>)
        } else {
            return (<div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(11959)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '90%' : '70%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >

                        <FormItem label={this.getText(11960)}
                            validateStatus={this.isItemValid()}
                            extra={<label style={(this.isItemValid() === 'success')
                                ? { display: 'none' } : { color: '#f5222d' }}>{this.getText(11969)}</label>}
                            required={true}
                        >
                            <SelectorItemClassic {...this.props}
                                valueId={this.state.StockItemCard.mItemCode}
                                onChange={(code) => {
                                    this.setReportParameters("StockItemCard", { mItemCode: code })
                                }}
                                onBlur={() => {
                                    if (!this.state.StockItemCard.mItemCode) {
                                        message.warning(this.getText(11972));
                                    } else {

                                        this.sendAPI("check_item_is_serialize", this.state.StockItemCard.mItemCode, ob => {
                                            let reportName = "reports/stockItemCard" + (parseInt(ob.data) ? "Horizontal.pdf" : ".pdf")
                                            this.setReportParameters("StockItemCard", { reportName })
                                        })
                                    }
                                }}
                                notViewName
                                itemTypeAll
                            />
                        </FormItem>
                        {serialNumberField}
                        {dateStartField}
                        {dateEndField}
                        {storageListField}
                        {docTypeListField}
                        {customerField}
                        {supplierField}
                        {movinTypeField}
                        {viewCreationDateField}
                        <ReportTypeButton
                            {...this.props}
                            params={this.state.StockItemCard}
                            storagesField={'mStorageList'}
                            encodeURIFields={['mItemCode', 'mSerialNumber']}
                            datesToFavorites={[
                                { field: 'mLowerSelectionDate', label: this.getText(11961) },
                                { field: 'mUpperSelectionDate', label: this.getText(11962) },
                            ]}
                            favoriteSave
                            validSubmit={() => this.isItemValid() === 'success'}
                            disabled={this.isItemValid() !== 'success'}
                        />
                    </Form>
                </Card>
            </div>);
        }
    }
}
export default StockItemCard;