/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import TableIDs from '../../data/TableIDs.js';
import {
    Checkbox, InputNumber, Select, Button, Row, Col, Modal, Icon, Input, Tooltip, message, Popconfirm, Popover, Radio, TimePicker
} from "antd";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import DateTimePicker from "../../components/DateTimePicker";
import PictureView from "../../components/PictureView";
import moment from 'moment';

const { Option, OptGroup } = Select;

class VirtualShopMethods extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modelDeliveryQtn: false,
            modalQtnOptions: false,
            modalBranchDelivery: false,
            modalBranchDeliverySearch: '',
            modalBranchDeliveryFilter: 3,
        }

        this.methods_id = "DELIVERY_METHODS_TABLE";
        this.qtn_id = "DELIVERY_QTN_TABLE";
        this.bdm_id = "BRANCH_DELIVERY_METHOD_TABLE"

        this.api = {

            get_methods: "get_delivery_methods_table",
            save_methods: "save_delivery_method",

            get_qtn: "get_delivery_qtn",
            save_qtn: "save_delivery_qtn",
            delete_qtn: "delete_delivery_qtn",

            get_bdm: "get_branch_delivery_method",
            save_bdm: "save_branch_delivery_method",
        }

        this.selectorTags = TableIDs.genericSelectorTagsToSite;
        this.selectorMsg = TableIDs.genericSelectorPreDefinedMsg;

        if (props.data[this.methods_id] === undefined) props.data[this.methods_id] = { ...props.data.genericData };
        if (props.data[this.qtn_id] === undefined) props.data[this.qtn_id] = { ...props.data.genericData };
        if (props.data[this.bdm_id] === undefined) props.data[this.bdm_id] = { ...props.data.genericData };

        this.mobile = window.innerWidth <= 600 ? true : false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.props.dataActions.genericDataSetFilter(this.methods_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_methods);
    }

    getModalTitle = (title, actions, gActions, showsearch) => {
        return (<PageToolbar
            isModal={true}
            title={title}
            showsearch={showsearch}
            actionButtons={actions ? actions : []}
            genericActionButtons={gActions ? gActions : undefined}
            ui={this.props.ui}
            uiActions={this.props.uiActions}
            data={this.props.data}
            dataActions={this.props.dataActions} />)
    }

    modelDeliveryQtn = () => {
        let dDesc = this.state.modelDeliveryQtn ? this.state.modelDeliveryQtn.dDescription : ''
        let editing = this.props.data[this.qtn_id].editing;
        let count = this.props.data[this.qtn_id].count;
        let editedRecord = this.props.data[this.qtn_id].editedRecord;
        let onChange = (k, v) => { this.props.dataActions.genericDataSetEdit(this.qtn_id, { [k]: v }) }
        let isEdit = (r) => r.key === editing && !this.state.modalQtnOptions ? true : false;

        let tInput = (t, r, f) => isEdit(r) ? (<Input value={editedRecord[f]} onChange={(e) => { onChange(f, e.target.value) }} />) : t
        let nInput = (t, r, f) => isEdit(r) ? (<InputNumber min={1} max={count} value={editedRecord[f]} onChange={(e) => { onChange(f, e) }} />) : t
        let cb = (t, r, f) => {
            return (<Checkbox
                checked={(!isEdit(r) && t == '1') || (isEdit(r) && editedRecord[f] == '1')}
                disabled={!isEdit(r)}
                onChange={(e) => { onChange(f, e.target.checked ? '1' : '0') }}
            />);
        }

        let bIcon = (t, oc) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} onClick={oc} />)

        let popIcon = (t, texts, oc) => {
            let gt = (i) => this.getText(texts[i]);
            return (<Popconfirm title={gt(0)} okText={gt(1)} cancelText={gt(2)} onConfirm={oc}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} />
            </Popconfirm>)
        }

        let buttonsEdit = (r) => {
            if (!isEdit(r)) {
                return (<span>
                    {bIcon("edit", () => { this.props.dataActions.genericDataStartEditing(this.qtn_id, r.key, r.index) })}
                    {popIcon("delete", [13484, 13485, 13486], () => {
                        this.props.dataActions.genericDataDeleteRow(
                            this.qtn_id, r.key, this.props.user.companyCode, this.props.user.token, this.api.delete_qtn, this.api.get_qtn);
                    })}
                    {r.qType === 'Select' ? (
                        <Tooltip title={this.getText(13480)}>
                            {bIcon("table", () => {
                                this.setState({ modalQtnOptions: true }, () => {
                                    this.props.dataActions.genericDataStartEditing(this.qtn_id, r.key, r.index)
                                });
                            })}
                        </Tooltip>)
                        : ''}
                </span>)
            } else {
                return (<span>
                    {bIcon("check", () => { this.saveDeliveryQtn() })}
                    {bIcon("close", () => { this.cancelEditInline(this.qtn_id) })}
                </span>)
            }
        }

        let typeSelect = (t, r) => {
            return (
                <Select
                    value={!isEdit(r) ? t : editedRecord.qType}
                    onChange={(e) => { onChange('qType', e) }}
                    disabled={!isEdit(r)}
                    style={{ width: '100%' }}
                >
                    <Option value="Text">{this.getText(13477)}</Option>
                    <Option value="Checkbox">{this.getText(13478)}</Option>
                    <Option value="Select">{this.getText(13479)}</Option>
                </Select>);
        }

        let defaultInput = (t, r) => {
            let typeValue = isEdit(r) ? editedRecord.qType : r.qType;
            switch (typeValue) {
                case 'Text':
                    return tInput(t, r, 'qDefault');
                case 'Checkbox':
                    // return cb(t, r, 'qDefault');
                    return (<Radio.Group
                        value={!isEdit(r) ? t : editedRecord.qDefault}
                        disabled={!isEdit(r)}
                        onChange={(e) => { onChange('qDefault', e.target.value) }}>
                        <Radio value={"1"}>{this.getText(20452)}</Radio>
                        <Radio value={"0"}>{this.getText(20453)}</Radio>
                        <Radio value={""}>{this.getText(20454)}</Radio>
                    </Radio.Group>)
                case 'Select':
                    let defaultValue = isEdit(r) ? editedRecord.qDefault : t;
                    if (defaultValue) defaultValue = parseInt(defaultValue);
                    let optionsValue = isEdit(r) ? editedRecord.qOptions : r.qOptions;
                    optionsValue = optionsValue ? optionsValue.split(',') : [''];

                    return (<Select
                        value={defaultValue}
                        onChange={(e) => { onChange('qDefault', e) }}
                        style={{ width: '100%' }}
                        disabled={!isEdit(r)}
                    >{optionsValue.map((x, i) => (<Option value={i}>{x}</Option>))}</Select>);
            }
        }

        let columns = [
            { title: this.getText(13469), key: 'qOrder', dataIndex: 'qOrder', width: '8%', render: (t, r) => nInput(t, r, 'qOrder') },
            { title: this.getText(13470), key: 'qDesc', dataIndex: 'qDesc', width: '42%', render: (t, r) => tInput(t, r, 'qDesc') },
            { title: this.getText(13471), key: 'qType', dataIndex: 'qType', width: '12%', render: (t, r) => typeSelect(t, r) },
            { title: this.getText(13472), key: 'qIsMandatory', dataIndex: 'qIsMandatory', width: '8%', render: (t, r) => cb(t, r, 'qIsMandatory') },
            { title: this.getText(13473), key: 'qIsActive', dataIndex: 'qIsActive', width: '8%', render: (t, r) => cb(t, r, 'qIsActive') },
            { title: this.getText(13475), key: 'qDefault', dataIndex: 'qDefault', width: '12%', render: (t, r) => defaultInput(t, r) },
            { title: this.getText(13476), width: '10%', render: (t, r) => buttonsEdit(r) },
        ];


        return (<Modal
            visible={this.state.modelDeliveryQtn}
            onCancel={() => { this.setState({ modelDeliveryQtn: false }) }}
            width={'95%'}
            footer={false}
            title={this.getModalTitle(this.getText(13466) + " - " + dDesc, [{
                buttonType: 'add',
                buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.qtn_id) }
            }])}
        >
            <ResponsiveTable
                tableOnly={true}
                idTable={this.props.data[this.qtn_id]}
                columns={columns}
                percentageToMobile={[20, 80]}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }}
            />
            {this.modalQtnOptions()}
        </Modal>)
    }

    modalQtnOptions = () => {
        let editedRecord = this.props.data[this.qtn_id].editedRecord;
        let oData = editedRecord && editedRecord.qOptions ? editedRecord.qOptions.split(',') : [''];
        let onChange = () => { this.props.dataActions.genericDataSetEdit(this.qtn_id, { qOptions: oData.join(",") }) }

        return (<Modal
            visible={this.state.modalQtnOptions}
            onCancel={() => {
                this.setState({ modalQtnOptions: false }, () => { this.cancelEditInline(this.qtn_id) })
            }}
            width={this.mobile ? '95%' : '40%'}
            footer={[
                <Button type="primary" onClick={() => {
                    this.setState({ modalQtnOptions: false }, () => { this.saveDeliveryQtn() })
                }}>{this.getText(13468)}</Button>
            ]}
            title={this.getModalTitle(this.getText(13480), [{
                buttonType: 'add',
                buttonFunction: () => {
                    oData.push('');
                    onChange();
                }
            }])}
        >
            <div>
                {oData.map((x, i) => {
                    return (<div>
                        {oData.length > 1 ?
                            (<Icon
                                style={{ fontSize: 16, color: 'red' }}
                                className={"clickable margin-4"}
                                type={"delete"} onClick={() => {
                                    oData.splice(i, 1);
                                    onChange();
                                }} />)
                            : ''}
                        <Input value={x} style={{ width: '90%' }} onChange={(e) => {
                            oData[i] = e.target.value;
                            onChange();
                        }} />
                    </div>)
                })}
            </div>
        </Modal>)
    }

    weekBlockRender = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.bdm_id];

        let dayText = [
            { day: '1', text: 17208 },
            { day: '2', text: 17209 },
            { day: '3', text: 17210 },
            { day: '4', text: 17211 },
            { day: '5', text: 17212 },
            { day: '6', text: 17213 },
            { day: '7', text: 17214 },
        ];

        if (record.key === editing) {
            return (<div>
                <Select style={{ width: '50%' }} value={editedRecord.mWeeklyBlockDay} onChange={e => {
                    this.props.dataActions.genericDataSetEdit(this.bdm_id, { mWeeklyBlockDay: e })
                }}>
                    <Select.Option key={'null'} value={''}> - </Select.Option>
                    {dayText.map(x => (<Select.Option key={x.day} value={x.day}>{this.getText(x.text)}</Select.Option>))}
                </Select>
                <DateTimePicker format="HH:mm:ss" style={{ width: '50%' }} value={editedRecord.mWeeklyBlockHour} onChange={e => {
                    this.props.dataActions.genericDataSetEdit(this.bdm_id, { mWeeklyBlockHour: e })
                }} />
            </div>)
        } else {
            let d = dayText.find(f => f.day === text);
            return d ? this.getText(d.text) + " " + record.mWeeklyBlockHour : ""
        }
    }

    blockedCurWeek = (record) => {
        const { mWeeklyBlockDay, mWeeklyBlockHour } = record;
        if (mWeeklyBlockDay) {
            let curDate = new Date();
            let curDay = curDate.getDay() + 1;
            let curTime = moment().format("HH:mm:ss");
            if (curDay > mWeeklyBlockDay || (curDay == mWeeklyBlockDay && curTime >= mWeeklyBlockHour)) return true
        }
    }

    modalBranchDelivery = () => {
        let dDesc = this.state.modalBranchDelivery ? this.state.modalBranchDelivery.dDescription : ''
        let editing = this.props.data[this.bdm_id].editing;
        let editedRecord = this.props.data[this.bdm_id].editedRecord;
        const { mSuspendUntil, mAllowFutureOrder, mId, mAcceptFutureDateOrder, mAcceptFutureHourOrder } = editedRecord;
        const { modalBranchDeliverySearch, modalBranchDeliveryFilter } = this.state;
        let isEdit = (r) => r.key === editing ? true : false;

        let defaultFuture = (date) => (mAcceptFutureDateOrder == '1' || (mAcceptFutureHourOrder == '1' && (!date || date.split(' ')[0] === moment().format('YYYY-MM-DD'))))

        let cb = (t, r) => {
            return (<Checkbox
                checked={(!isEdit(r) && t == '1') || (isEdit(r) && mAllowFutureOrder == '1')}
                disabled={!isEdit(r)}
                onChange={(e) => {
                    if (defaultFuture(mSuspendUntil)) this.props.dataActions.genericDataSetEdit(this.bdm_id, { mAllowFutureOrder: e.target.checked ? '1' : '0' })
                    else message.error(this.getText(15575))
                }}
            />);
        }

        let dp = (t, r) => {
            return !isEdit(r) ? t : (<DateTimePicker format="DD/MM/YYYY HH:mm:ss" showTime value={mSuspendUntil} onChange={(e) => {
                this.props.dataActions.genericDataSetEdit(this.bdm_id, { mSuspendUntil: e, mAllowFutureOrder: !defaultFuture(e) ? '0' : mAllowFutureOrder })
            }} />);
        }

        let bIcon = (t, oc) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} onClick={oc} />)

        let buttonsEdit = (t, r) => {
            if (!isEdit(r)) {
                return bIcon(!r.mSuspendUntil ? "unlock" : "lock", () => {
                    this.props.dataActions.genericDataStartEditing(this.bdm_id, r.key, r.index)
                    if (r.mSuspendUntil) this.props.dataActions.genericDataSetEdit(this.bdm_id, { mSuspendUntil: '' })
                })
            } else {

                return (<span>
                    {bIcon("check", () => { this.saveEdit(this.bdm_id, this.api.save_bdm, this.api.get_bdm) })}
                    {bIcon("close", () => { this.cancelEditInline(this.bdm_id) })}
                </span>)
            }
        }

        let columns = [
            { title: this.getText(15698), key: 'mBranchId', dataIndex: 'mBranchId', width: '10%', render: t => t },
            { title: this.getText(15572), key: 'mBranchName', dataIndex: 'mBranchName', width: '30%', render: t => t },
            { title: this.getText(15573), key: 'mSuspendUntil', dataIndex: 'mSuspendUntil', width: '25%', render: dp },
            { title: this.getText(17207), key: 'mWeeklyBlockDay', dataIndex: 'mWeeklyBlockDay', width: '25%', render: this.weekBlockRender },
            { title: this.getText(15574), key: 'mAllowFutureOrder', dataIndex: 'mAllowFutureOrder', width: '10%', render: cb },
            { title: '', width: '5%', render: buttonsEdit },
        ];



        let dataSource = this.props.data[this.bdm_id].data
            .map((x, i) => {
                let y = x.split('\f');
                let obj = { key: y[0], index: i }
                this.props.data[this.bdm_id].headers.forEach((z, ii) => { obj = { ...obj, [z]: y[ii] } })
                return obj;
            })
            .filter(f => !modalBranchDeliverySearch || f.mBranchName.indexOf(modalBranchDeliverySearch) > -1)
            .filter(f => modalBranchDeliveryFilter == 3 || (modalBranchDeliveryFilter == 1 && !f.mSuspendUntil) || (modalBranchDeliveryFilter == 2 && f.mSuspendUntil))

        let actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: () => {
                    this.props.dataActions.genericDataGetRequested(
                        this.bdm_id, this.props.user.companyCode, this.props.user.token, this.api.get_bdm);
                }
            }
        ];

        let gActionButtons = [
            <Popover
                content={(<Radio.Group
                    value={modalBranchDeliveryFilter}
                    onChange={(e) => { this.setState({ modalBranchDeliveryFilter: e.target.value }) }}>
                    <Radio value={1}>{this.getText(15700)}</Radio>
                    <Radio value={2}>{this.getText(15701)}</Radio>
                    <Radio value={3}>{this.getText(15702)}</Radio>
                </Radio.Group>)}
                title={this.getText(15699)}
                placement="bottomLeft"
                trigger="click">
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                    <Icon type={'filter'} style={{ fontSize: "16px", }} />
                </Button>
            </Popover>
        ];

        let showsearch = (value) => { this.setState({ modalBranchDeliverySearch: value }) }

        return (<Modal
            visible={this.state.modalBranchDelivery}
            onCancel={() => { this.setState({ modalBranchDelivery: false }) }}
            width={'95%'}
            footer={false}
            title={this.getModalTitle(this.getText(15571) + " - " + dDesc, actionButtons, gActionButtons, showsearch)}
        >
            <ResponsiveTable
                tableOnly={true}
                dataSource={dataSource}
                columns={columns}
                rowClassName={(r) => !r.mSuspendUntil && !this.blockedCurWeek(r) ? 'row_greenyellow' : 'row_pink'}
                // percentageToMobile={[20, 80]}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }}
            />
        </Modal>)
    }

    saveDeliveryQtn = () => {
        let dID = this.state.modelDeliveryQtn ? this.state.modelDeliveryQtn.dID : '';
        let er = this.props.data[this.qtn_id].editedRecord;
        if (!er.qDesc) {
            message.error(this.getText(13482))
        } else if (!er.qType) {
            message.error(this.getText(13483))
        } else {
            this.saveEdit(this.qtn_id, this.api.save_qtn, this.api.get_qtn, { dID: dID });
        }
    }

    cancelEditInline = (id) => {
        if (this.props.data[id].editedRecord.key == 0)
            this.props.dataActions.genericDataCancelInlineCreate(id);
        else
            this.props.dataActions.genericDataCancelEditing(id);
    }

    saveEdit = (id, saveScript, getScript, params) => {
        if (params) {
            this.props.dataActions.genericDataSaveEditWithParams(
                id, this.props.user.companyCode, this.props.user.token, saveScript, getScript, params);
        } else if (getScript) {
            this.props.dataActions.genericDataSaveEdit(
                id, this.props.user.companyCode, this.props.user.token, saveScript, getScript);
        } else {
            this.props.dataActions.genericDataJustSaveEdit(
                id, this.props.user.companyCode, this.props.user.token, saveScript);
        }
    }

    render() {
        let editing = this.props.data[this.methods_id].editing;
        let editedRecord = this.props.data[this.methods_id].editedRecord;
        let onChange = (k, v) => { this.props.dataActions.genericDataSetEdit(this.methods_id, { [k]: v }) }

        let tInput = (t, r, f) => r.key === editing ? (<Input value={editedRecord[f]} onChange={(e) => { onChange(f, e.target.value) }} />) : t
        let cb = (t, r, f) => {
            return (<Checkbox
                checked={(r.key !== editing && t == '1') || (r.key === editing && editedRecord[f] == '1')}
                disabled={r.key !== editing}
                onChange={(e) => { onChange(f, e.target.checked ? '1' : '0') }}
            />);
        }

        let tImg = (t, r) => {
            let origin = window.location.origin;
            const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
            const baseUrl = isDev ? "http://147.235.163.248" : origin
            let bUrl = baseUrl + '/uploads/' + this.props.user.companyCode + '/';

            return r.key !== editing ? t ? (<img src={bUrl + t} height={50} />) : ''
                : (<PictureView
                    {...this.props}
                    imageType={'V'} aspect={1} url={editedRecord.dImage}
                    onRemove={() => { onChange('dImage', '') }}
                    onChange={(v) => { onChange('dImage', v) }}
                    onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                />);
        }

        let bIcon = (t, oc) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} onClick={oc} />)

        let buttonsEdit = (t, r) => {
            if (r.key !== editing) {
                return (<span>
                    {bIcon("edit", () => {
                        this.props.dataActions.genericDataStartEditing(this.methods_id, r.key, r.index)
                    })}
                    <Tooltip title={this.getText(13466)}>
                        {bIcon("ordered-list", () => {
                            this.setState({ modelDeliveryQtn: r }, () => {
                                this.props.dataActions.genericDataSetFilter(
                                    this.qtn_id,
                                    this.props.user.companyCode,
                                    this.props.user.token,
                                    { dID: r.dID },
                                    this.api.get_qtn);
                            })
                        })}
                    </Tooltip>
                    <Tooltip title={this.getText(15571)}>
                        {bIcon("tag", () => {
                            this.setState({ modalBranchDelivery: r }, () => {
                                this.props.dataActions.genericDataSetFilter(
                                    this.bdm_id,
                                    this.props.user.companyCode,
                                    this.props.user.token,
                                    { mID: r.dID },
                                    this.api.get_bdm);
                            })
                        })}
                    </Tooltip>
                </span>)
            } else {
                return (<span>
                    {bIcon("check", () => { this.saveEdit(this.methods_id, this.api.save_methods, this.api.get_methods, 0) })}
                    {bIcon("close", () => { this.cancelEditInline(this.methods_id) })}
                </span>)
            }
        }

        let columns = [
            { title: this.getText(13461), key: 'dID', dataIndex: 'dID', width: '10%', render: (t) => t },
            { title: this.getText(13462), key: 'dDescription', dataIndex: 'dDescription', width: '40%', render: (t, r) => tInput(t, r, 'dDescription') },
            { title: this.getText(13463), key: 'dIsActive', dataIndex: 'dIsActive', width: '10%', render: (t, r) => cb(t, r, 'dIsActive') },
            { title: this.getText(13464), key: 'dNeedAddress', dataIndex: 'dNeedAddress', width: '10%', render: (t, r) => cb(t, r, 'dNeedAddress') },
            { title: this.getText(16019), key: 'dImage', dataIndex: 'dImage', width: '15%', render: tImg },
            { title: this.getText(13465), width: '15%', render: buttonsEdit },
        ];

        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={false}
                width={this.mobile ? '95%' : '60%'}
                title={this.getModalTitle(this.getText(13460), [{
                    buttonType: 'add',
                    buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.methods_id) }
                }])}
            >
                <ResponsiveTable
                    tableOnly={true}
                    idTable={this.props.data[this.methods_id]}
                    columns={columns}
                    percentageToMobile={[20, 80]}
                    ui={this.props.ui}
                    pagination={{ pageSize: 10 }}
                />
                {this.modelDeliveryQtn()}
                {this.modalBranchDelivery()}
            </Modal>)
    }

}
export default VirtualShopMethods;