/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Popover, Tooltip, Button, message, Modal, Row, Col, Input, Form, Select, Switch, Upload, Progress, Checkbox } from "antd";
//import TableIDs from "../../data/TableIDs";
import ResponsiveTable from "../../components/ResponsiveTable";
import PageToolbar from "../../components/PageToolbar";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import GenericModal from "../../components/GenericModal";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";

const { Option } = Select;
const { Item } = Form;

class ImportExcel extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            height: window.innerHeight,
            modalCreateEdit: false,
            modalUpload: false,
            modalConsts: false,
            modalConstTemplate: null,
            modalErrorConsts: null,
            oldExcel: 0,
            filterFields: false,

            uploadOption: 0,
            uploading: false,
            fileList: [],
            fileLinesNum: 0,
            filePackSum: 0,
            defID: null,

            firstTitle: false,
            errorsInTable: null,
            errorsList: {},
            fixesList: [],
            sliceStrRight: '0',
            deleteDuplicate: false,
            deleteInProcess: false,
            fixesLine: null,
            fixAllLines: false,

            popABC: false,
        }

        this.api = {
            get: "get_import_excel_table",
            get_fields: "get_import_fields_table",
            get_consts: "get_import_excel_consts",

            create_edit: "create_edit_import_excel_table",
            delete: "delete_import_excel_table",
            copy: "copy_import_excel_table",

            selector_tables: "import_tables_list",
            selector_errors: "import_errors_list",

            c_old_excel: "check_if_exist_old_excel",
            save_excel: "save_import_excel_temp",
            get_excel: "get_get_excel",
            delete_excel: "delete_get_excel",

            sql_on_excel: "sql_on_excel_run",
            type_check: "health_check_get_excel",
            slice_str: "slice_str_get_excel",
            delete_duplicate: "delete_duplicate_get_excel",
            mark_to_update: "mark_to_update_get_excel",
            to_tables: "update_get_excel_in_tables",

            delete_all_duplicate: "delete_all_duplicate_get_excel"
        }

        this.id = 'IMPORT_EXCEL_TABLE';
        this.tables_id = 'IMPORT_TABLES';
        this.fields_id = 'IMPORT_FIELDS_TABLE';
        this.view_id = "IMPORT_EXCEL_VIEW";
        this.errors_id = "IMPORT_ERRORS";
        this.duplicate_id = "IMPORT_ERRORS";
        this.consts_id = "IMPORT_EXCEL_CONSTS"

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.tables_id] === undefined) props.data[this.tables_id] = { ...props.data.genericSelector };
        if (props.data[this.fields_id] === undefined) props.data[this.fields_id] = { ...props.data.genericData };
        if (props.data[this.view_id] === undefined) props.data[this.view_id] = { ...props.data.genericData };
        if (props.data[this.errors_id] === undefined) props.data[this.errors_id] = { ...props.data.genericSelector };
        if (props.data[this.duplicate_id] === undefined) props.data[this.duplicate_id] = { ...props.data.genericData };
        if (props.data[this.consts_id] === undefined) props.data[this.consts_id] = { ...props.data.genericData };

        this.doOnResize = null;
        this.sqlOnExcelRun = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']';
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });

        this.props.dataActions.genericSelectorRefreshDataset(
            this.tables_id,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.selector_tables);

        this.props.dataActions.genericSelectorRefreshDataset(
            this.errors_id,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.selector_errors);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    componentDidUpdate(p) {
        document.title = "Cash On Tab - " + this.getText(13122);

        let curTemp = this.props.data[this.view_id].count;
        let prevTemp = p.data[this.view_id].count;

        if (!curTemp && prevTemp && this.sqlOnExcelRun) {
            message.success(this.getText(13868))
            this.closeModal()
        }

    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    initFilters = () => {

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: 10, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: 10,
            _isAsc: 1,

        };
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {

        let request = {
            _id: 0,
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: 10,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: 1,

            _page_num: pagination.current,
            _rows_num: 10,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    createNewDef = () => {
        this.setState({ modalCreateEdit: true, filterFields: false }, () => {
            this.props.dataActions.genericDataStartInlineCreate(this.id);
            this.props.dataActions.setJson(this.fields_id, { data: [] });
        });
    }

    apiFields = (mType, mKey) => {
        this.props.dataActions.genericDataSetFilter(
            this.fields_id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                mKey: mKey,
                mTypeId: mType
            },
            this.api.get_fields);
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: false }, () => {
            if (this.props.data[this.id].editing == 0)
                this.props.dataActions.genericDataCancelInlineCreate(this.id);
            else
                this.props.dataActions.genericDataCancelEditing(this.id);
        });
    }

    handleOk = () => {
        let mData = this.props.data[this.fields_id].data;
        let isValid = true;
        mData.forEach((x) => {
            let y = x.split('\f');
            if (!y[9] && y[10] !== '1') isValid = false;
        });

        if (isValid) {
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create_edit,
                [
                    { name: this.id },
                    { name: this.fields_id, sendDataTable: true },
                ],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.get]
                }
            )
            this.handleCancel();
        } else {
            message.error(this.getText(13870))
        }
    }

    getFullExcel = (ob) => {
        if (ob && ob.data && this.state.modalConsts) {
            let d = ob.data.split("\f");
            if (d[0] == '1') {
                this.setState({ modalErrorConsts: { title: d[1], data: d[2] ? d[2].split(",") : [] } })
            }
        }
        let _page_num = this.props.data[this.view_id].filters._page_num;
        if (!_page_num) _page_num = 1;
        this.props.dataActions.genericDataSetFilter(
            this.view_id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                _page_num,
                _rows_num: 10,
                _errors: 0,
                _titles: this.state.firstTitle ? 1 : 0,
                _duplicate: 0,
            },
            this.api.get_excel)
    }

    saveExcel = (fullDataSend, colStart, colEnd, first) => {
        // console.log('importexcel saveexcel', fullDataSend, colStart, colEnd, first)
        if (this.state.uploading) {
            let spliceData = fullDataSend.splice(0, 1000);
            if (!first && spliceData[0][0] == '1') spliceData = spliceData.map(x => '0' + x.slice(1));

            let dataSend = "deleteOld";
            let a_z = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            a_z.map((x) => { dataSend += '\fcol' + x });
            dataSend = [dataSend, ...spliceData];

            const saveXls = () => {
                this.sendAPI(this.api.save_excel, dataSend.join('\r'), () => {
                    this.getFullExcel();
                    if (fullDataSend.length < 1) {
                        this.setState({ uploadOption: 3, fileList: [], fileLinesNum: 0, uploading: false }, this.sqlOnExcel);
                    } else {
                        this.setState({ fileLinesNum: this.state.fileLinesNum - 1000 }, () => {
                            this.saveExcel(fullDataSend, colStart, colEnd, false);
                        });
                    }
                }, (e) => {
                    message.error(e)
                })
            }

            if (this.state.uploadOption < 2 && first) {
                this.sendAPI("truncate_get_excel", "", saveXls, (e) => { console.error(e) })
            } else {
                saveXls()
            }
        }
    }

    sqlOnExcel = () => {
        this.sqlOnExcelRun = true;
        let isConst = this.state.modalConsts ? '1' : '0'
        this.sendAPI(this.api.sql_on_excel, '_def\f_isConst\r' + this.state.defID + '\f' + isConst, this.getFullExcel, (e) => { console.error(e) });
    }

    getExcelChange = (pagination) => {
        let request = {
            ...this.props.data[this.view_id].filters,
            _id: 0,
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: 10,
            sortType: 0,
            sortColumn: 1,

            _page_num: pagination.current,
            _rows_num: 10,
        };

        this.props.dataActions.genericDataSetFilter(
            this.view_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_excel)
    }

    checkTypesExcel = (next) => {
        let defID = this.state.defID;
        let firstTitle = this.state.firstTitle ? '1' : '0';

        this.sendAPI(this.api.type_check, '_defId\f_titles\r' + defID + '\f' + firstTitle, (ob) => {
            if (ob.data) {
                let eList = {}
                let fList = []
                let eSum = 0;
                ob.data.split('\f').forEach((x, i) => {
                    let intX = parseInt(x);
                    eList = { ...eList, ['error' + (i + 1)]: intX }
                    eSum += intX;
                    if (intX && [2, 7, 8].find(f => f == (i + 1))) {
                        fList.push(i + 1);
                    }
                });

                if (eSum <= 0) {
                    this.sendAPI(this.api.to_tables, '_def\r' + this.state.defID, (ob) => {
                        if (ob.data == '0\r1') {
                            message.success(this.getText(13868))
                            this.closeModal()
                        } else {
                            message.error(this.getText(13869))
                        }
                    }, (e) => { console.error(e) })
                } else {
                    this.setState({
                        errorsInTable: eSum,
                        errorsList: eList,
                        fixesList: fList
                    }, () => {
                        this.props.dataActions.genericDataSetFilter(
                            this.view_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            {
                                _page_num: 1,
                                _rows_num: 10,
                                _errors: 1,
                                _titles: firstTitle,
                                _duplicate: 0,
                            },
                            this.api.get_excel);
                    })
                }

                if (next) next();
            }
        }, (e) => { console.error(e) })
    }

    modalUpload1 = () => {
        let cOption = (n) => {
            this.setState({
                uploadOption: n,
                oldExcel: 0,
                fileList: [],
                filePackSum: 0
            })
        }
        return (<div style={{ textAlign: 'center' }}>
            <h1>{this.getText(13343) + " " + this.state.oldExcel + " " + this.getText(13344)}</h1>
            <h2>{this.getText(13345)}:</h2>
            <Button key={'deleteOld'} style={{ margin: 5, width: '75%' }} onClick={() => { cOption(1) }}>{this.getText(13346)}</Button>
            <Button key={'noDelete'} style={{ margin: 5, width: '75%' }} onClick={() => { cOption(2) }}>{this.getText(13347)}</Button>
            <Button key={'noImport'} style={{ margin: 5, width: '75%' }} onClick={() => { cOption(3) }}>{this.getText(13348)}</Button>
        </div>)
    }

    modalUpload2 = () => {

        const { fileList, uploading } = this.state;

        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                // console.log("file: ", file);
                this.setState(
                    state => ({ fileList: [...state.fileList, file], })
                );
                return false;
            },
            fileList,
        };

        let GetTableFromExcel = (data) => {
            //Read the Excel File data in binary
            let workbook = XLSX.read(data, {
                type: 'binary'
            });

            console.log("xlsx test ", { workbook })

            //get the name of First Sheet.
            let Sheet = workbook.SheetNames[0];
            let fullData = workbook.Sheets[Sheet];
            let ref = fullData['!ref'].split(':');
            let refStart = parseInt(ref[0].replace(/[A-Z]+/, ''));
            let refEnd = parseInt(ref[1].replace(/[A-Z]+/, ''));
            let colStart = ref[0].replace(/[0-9]/, '');
            let colEnd = ref[1].replace(/[0-9]/, '');
            /////

            //////

            // console.table({
            //     fullData: fullData,
            //     ref: ref,
            //     refStart: refStart,
            //     refEnd: refEnd,
            // })

            let a_z = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            let dataSend = [];
            for (let n = refStart; n <= refEnd; n++) {
                let rowData = this.state.uploadOption < 2 &&
                    n === refStart
                    ? "1" : "0";
                a_z.map((x) => {
                    let fd = fullData[x + n] && fullData[x + n].w ? fullData[x + n].w : '';
                    rowData += '\f' + fd
                        .split("")
                        .filter(f => {
                            let decimalCode = f.charCodeAt(0) // utf-16
                            if (decimalCode >= 55296) console.log("smaili in excel", n, f)
                            return (decimalCode < 8064 || decimalCode > 8319) && decimalCode < 55296
                        })
                        .join("")
                        .replace(/[\x00-\x1F\x80-\xFF]/, '')
                        .replace(/\r/, '')
                        .replace(/\n/, '')
                        .trim();
                });
                dataSend.push(rowData);
            }

            this.setState({
                fileLinesNum: dataSend.length,
                filePackSum: Math.ceil(dataSend.length / 1000),
                uploading: true,
            }, () => {
                this.saveExcel(dataSend, colStart, colEnd, true);
            });
        };

        let UploadProcess = () => {
            let fileUpload = this.state.fileList[0];
            let reader = new FileReader();

            //For Browsers other than IE.
            if (reader.readAsBinaryString) {
                reader.onload = function (e) {
                    GetTableFromExcel(e.target.result);
                };
                reader.readAsBinaryString(fileUpload);
            } else {
                //For IE Browser.
                reader.onload = function (e) {
                    let data = "";
                    let bytes = new Uint8Array(e.target.result);
                    for (let i = 0; i < bytes.byteLength; i++) {
                        data += String.fromCharCode(bytes[i]);
                    }
                    GetTableFromExcel(data);
                };
                reader.readAsArrayBuffer(fileUpload);
            }
        };

        return (<div>
            <Upload id={"fileUpload"} {...props}>
                <Button key={"upButton"} disabled={fileList.length !== 0}>
                    <Icon type="upload" />{this.getText(13349)}
                </Button>
            </Upload>
            <Button
                key={"importButton"}
                type="primary"
                onClick={UploadProcess}
                disabled={fileList.length === 0}
                style={{ margin: 16 }}
            >
                {this.getText(13350)}
            </Button>
            <Button
                key={"cancelButton"}
                onClick={this.closeModal}
                style={{ margin: 16 }}
            >
                {this.getText(13351)}
            </Button>

        </div>)
    }

    modalUpload3 = () => {
        let colData = this.props.data[this.view_id].headers;
        let vColumns = [
            {
                key: "deleteRow",
                dataIndex: "ID",
                width: 100,
                render: (text, record) => {
                    return (<TableLineIcons
                        deleteQuetion={{ title: this.getText(19390), okText: this.getText(17399), cancelText: this.getText(17400) }}
                        onDelete={() => {
                            this.sendAPI(this.api.delete_excel, record.key, () => { this.checkTypesExcel() }, e => { console.error(e) })
                            // this.props.dataActions.genericDataDeleteRow(
                            //     this.view_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_excel, this.api.get_excel);

                        }}
                    />)
                }
            },
            ...colData.map((x) => {
                let title = x.split('\n');
                return {
                    title: (<div>{title.map(item => (<div>{item}</div>))}</div>),
                    key: x,
                    dataIndex: x,
                    render: (text, record) => {
                        if (x === 'Error') {
                            let textView = text.split('\n').map((l) => {
                                let find = this.props.data[this.errors_id].dataset.find(f => f.code == l[0])
                                let res = find ? find.name : '';
                                res += ' ' + this.getText(13605) + ' ' + l.slice(1);
                                return l ? (<div>{res}</div>) : ''
                            });
                            return (<div style={{ maxHeight: 50, overflowY: 'scroll' }}>{textView}</div>)
                        } else {
                            return record.Error && record.Error.indexOf(title[0]) > -1 ?
                                (<div
                                    onClick={() => {
                                        let errorCol = record.Error.split('\n').find(f => f.indexOf(title[0]) > -1);
                                        if (errorCol[0] == '7') {
                                            this.setState({ deleteDuplicate: true }, () => { this.getDuplicateApi(record.key) });
                                        } else {
                                            this.setState({
                                                fixesLine: {
                                                    _key: record.key,
                                                    _col: errorCol.slice(1),
                                                    _error: errorCol[0]
                                                }
                                            })
                                        }
                                    }}
                                    style={{ backgroundColor: '#FFFF00', cursor: 'pointer' }}
                                >{text}</div>)
                                : text
                        }
                    }
                }
            })
        ]

        let errorsGrid = this.props.data[this.errors_id].dataset.map((x) => {
            return this.state.errorsList['error' + x.code] ? (<div>
                {this.state.errorsList['error' + x.code] + ' ' + this.getText(13861) + ' ' + x.name}
            </div>) : ''
        })

        let percent = parseInt(100 - (this.state.fileLinesNum / this.state.filePackSum / 10))
        return (<div>
            {!this.state.errorsInTable ? (<Progress percent={percent} />) : ''}
            <ResponsiveTable
                idTable={this.props.data[this.view_id]}
                tableOnly={true}
                actionButtons={[]}
                columns={vColumns}
                onChange={this.getExcelChange}
                scroll={{ x: colData.length * 200 }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
            {this.state.errorsInTable ?
                (<div style={{ display: 'inline-block', margin: '1%', width: '48%' }}>
                    <div style={{ fontSize: 30, color: 'red' }}>
                        {this.getText(13603) + ' ' + this.state.errorsInTable + ' ' + this.getText(13604)}
                    </div>
                    {errorsGrid}
                </div>)
                : this.state.modalConsts ? "" : (<Button
                    key={"updateInTable"}
                    disabled={this.state.uploading}
                    style={{ margin: '1%', width: '48%' }}
                    onClick={() => { this.checkTypesExcel(false) }}
                    type={"primary"}
                >{this.getText(13353)}</Button>)}
            <Button
                key={"updateCancle"}
                style={{ margin: '1%', width: '48%' }}
                onClick={this.closeModal}
            >{this.getText(13352)}</Button>
        </div>)
    }


    fixesLine = () => {
        let res = '';
        let onClick = false;
        let onCancel = () => { this.setState({ fixesLine: null }) }
        let s = this.state.fixesLine ? this.state.fixesLine : {};

        switch (s._error) {
            case '2':
                res = (<div>
                    <h1>{this.getText(13855)}</h1>
                    <Switch
                        checkedChildren={this.getText(13856)}
                        unCheckedChildren={this.getText(13857)}
                        checked={this.state.sliceStrRight == '1' ? true : false}
                        onChange={(e) => { this.setState({ sliceStrRight: e ? '1' : '0' }) }}
                    />
                </div>);
                onClick = () => {
                    this.sendAPI(
                        this.api.slice_str,
                        "_col\f_right\f_key\f_def\r"
                        + s._col + "\f"
                        + this.state.sliceStrRight + "\f"
                        + (this.state.fixAllLines ? '0' : s._key) + "\f"
                        + this.state.defID,
                        () => {
                            this.checkTypesExcel(onCancel);
                        },
                        (e) => {
                            console.error(e);
                        });
                }
                break;
            case '8':
                res = (<h1>{this.getText(13867)}</h1>);
                onClick = () => {
                    this.sendAPI(
                        this.api.mark_to_update,
                        "_col\f_key\r"
                        + s._col + "\f"
                        + (this.state.fixAllLines ? '0' : s._key),
                        () => {
                            this.checkTypesExcel(onCancel);
                        },
                        (e) => {
                            console.error(e);
                        });
                }
                break;
        }

        return (<Modal
            destroyOnClose={true} width={500} visible={this.state.fixesLine !== null} onCancel={onCancel}
            footer={onClick ? [<Button key={"fixOn"} style={{ margin: 5 }} onClick={onClick} type={"primary"}>{this.getText(13859)}</Button>] : false}
        >
            {res ?
                (<span>
                    {res}
                    <Checkbox
                        style={{ marginTop: 20 }}
                        checked={this.state.fixAllLines == '1' ? true : false}
                        onChange={(e) => { this.setState({ fixAllLines: e.target.checked ? '1' : '0' }) }}
                    >{this.getText(13864)}</Checkbox>
                </span>)
                : (<div>
                    <h1>{this.getText(13865)}</h1>
                    <h3>{this.getText(13866)}</h3>
                </div>)}
        </Modal>);
    }

    closeModal = () => {
        this.setState({
            height: window.innerHeight,
            modalCreateEdit: false,
            modalUpload: false,
            oldExcel: 0,
            filterFields: false,
            uploadOption: 0,
            uploading: false,
            fileList: [],
            fileLinesNum: 0,
            filePackSum: 0,
            defID: null,
            firstTitle: false,
            errorsInTable: null,
            errorsList: {},
            fixesList: [],
            sliceStrRight: '0',
            deleteDuplicate: false,
            deleteInProcess: false,
            fixesLine: null,
            fixAllLines: false,
        })
    }

    deleteDuplicateModal = () => {
        let colData = this.props.data[this.duplicate_id].headers;
        let vColumns = colData ? colData.map((x) => {
            let title = x.split('\n');
            return {
                title: (<div>{title.map(item => (<div>{item}</div>))}</div>),
                key: x,
                dataIndex: x,
                render: (text, record) => {
                    if (x === 'Error') {
                        let textView = text.split('\n').map((l) => {
                            let find = this.props.data[this.errors_id].dataset.find(f => f.code == l[0])
                            let res = find ? find.name : '';
                            res += ' ' + this.getText(13605) + ' ' + l.slice(1);
                            return l ? (<div>{res}</div>) : ''
                        });
                        return (<div style={{ maxHeight: 50, overflowY: 'scroll' }}>{textView}</div>)
                    } else {
                        return (<div style={{
                            backgroundColor: record.Error && record.Error.indexOf(title[0]) > -1 ? '#FFFF00' : '#FFFFFF',
                            cursor: this.state.deleteInProcess ? 'progress' : 'pointer'
                        }}>{text}</div>)
                    }
                }
            }
        }) : [];

        return (<Modal
            destroyOnClose={true}
            width={window.innerWidth <= 600 ? '95%' : '70%'}
            visible={this.state.deleteDuplicate}
            onCancel={() => { this.setState({ deleteDuplicate: false }) }}
            footer={false}
        >
            <Button
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={this.deleteAllDuplicateApi}
            >{this.getText(19353)}</Button>
            <ResponsiveTable
                idTable={this.props.data[this.duplicate_id]}
                title={this.getText(13863)}
                onRowClick={this.state.deleteInProcess ? false : this.deleteDuplicateApi}
                actionButtons={[]}
                columns={vColumns}
                pagination={{ pageSize: 10 }}
                scroll={colData ? { x: colData.length * 200 } : false}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
        </Modal>)
    }

    deleteDuplicateApi = (record) => {
        this.setState({ deleteInProcess: true }, () => {

            // let keys = Object.keys(record);
            // let _col = record.Error.split('\n').find(f => f[0] == '7').slice(1);
            // let _value = record[keys.find(f => f && f.split('\n')[0] == _col)];
            // let _key = record.key;

            const { data } = this.props.data[this.duplicate_id]

            let deleteIds = data.map(x => x.split("\f")[0]).filter(id => id != record.key)
            this.sendAPI(this.api.delete_excel, "\r" + deleteIds.join("\r"), () => {
                this.checkTypesExcel(() => {
                    this.setState({
                        deleteInProcess: false,
                        deleteDuplicate: false
                    })
                });
            }, (e) => { console.error(e) })


            // this.sendAPI(this.api.delete_duplicate, "_col\f_value\f_key\r" + _col + "\f" + _value + "\f" + _key, () => {
            //     this.checkTypesExcel(() => {
            //         this.setState({
            //             deleteInProcess: false,
            //             deleteDuplicate: false
            //         })
            //     });
            // }, (e) => {
            //     message.error(e);
            //     this.setState({ deleteInProcess: false });
            // })
        })
    }

    deleteAllDuplicateApi = () => {
        this.setState({ deleteInProcess: true }, () => {
            this.sendAPI(this.api.delete_all_duplicate, "", () => {
                this.checkTypesExcel(() => {
                    this.setState({
                        deleteInProcess: false,
                        deleteDuplicate: false
                    })
                });
            }, (e) => {
                message.error(e);
                this.setState({ deleteInProcess: false });
            })
        })
    }

    getDuplicateApi = (_key) => {
        this.props.dataActions.genericDataSetFilter(
            this.duplicate_id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                _page_num: 1,
                _rows_num: 9999,
                _errors: 0,
                _titles: this.state.firstTitle ? 1 : 0,
                _duplicate: _key,
            },
            this.api.get_excel);
    }

    fieldsDataSource = () => {
        let data = this.props.data[this.fields_id];

        let dataSource = data && data.data && data.data[0] ?
            data.data.map((x, indexData) => {
                let y = x.split("\f");
                let ob = { key: y[0], index: indexData }
                data.headers.map((z, index) => { ob = { ...ob, [z]: y[index] } });
                return ob;
            }) : [];

        if (this.state.filterFields == '1') {
            dataSource = dataSource.filter(f => f.mExcelColumn)
        }

        return dataSource;
    }

    uploadButtonConstsTable = (_id) => {
        return (<Tooltip title={this.getText(13335)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"cloud-upload"} onClick={() => {
                this.modalUploadOpen(_id, true)
            }} />
        </Tooltip>)
    }

    modalUploadOpen = (defID, firstTitle) => {
        this.setState({ modalUpload: true, defID, firstTitle }, () => {
            this.sendAPI(this.api.c_old_excel, "", (ob) => {
                if (parseInt(ob.data) > 0) {
                    this.setState({ oldExcel: parseInt(ob.data) }, () => {
                        this.props.dataActions.genericDataSetFilter(
                            this.view_id,
                            this.props.user.companyCode,
                            this.props.user.token,
                            {
                                _id: 0, // for testing. TODO: "read" from selector
                                _fieldId: false,
                                page: 1,
                                recordsPerPage: 10, //5,
                                sortType: 1,
                                sortColumn: 1,
                                // api
                                _words: '',
                                _page_num: 1,
                                _rows_num: 10,
                                _isAsc: 1,
                                _errors: 0,
                                _titles: firstTitle ? 1 : 0,
                                _duplicate: 0,
                            },
                            this.api.get_excel);
                    });
                }
            })
        });
    }

    renderConstRemark = (text, record) => {
        let obj = {}
        if (text) {
            text.split('\n').forEach(x => {
                let y = x.split('=');
                if (y[0] && y[1]) obj = { ...obj, [y[0]]: y[1] }
            })
        }
        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"setting"} onClick={() => {
            this.setState({ modalConstTemplate: { ...record, dataTemplate: obj } })
        }} />)
        // return <div style={{ height: 50, overflowY: "scroll" }}>{text.split('\n').map(x => (<p>{x}</p>))}</div>
    }

    printTemplateConst = () => {
        const { dataTemplate, _desc } = this.state.modalConstTemplate;
        // let data = dataTemplate ? '"' + Object.keys(dataTemplate).map(x => dataTemplate[x].split('"').join('""')).join('","') + '"' : ""
        // let blob = new Blob(["\ufeff", data], { type: "text/csv;charset=utf8" });
        // let a = document.createElement("a");
        // a.href = URL.createObjectURL(blob);
        // a.download = _desc + '.csv';
        // a.click();

        let data = {}

        if (dataTemplate) {
            Object.keys(dataTemplate).forEach(x => {
                data = { ...data, [dataTemplate[x]]: "" }
            })
        }

        this.printExcelFile([data], _desc)
    }

    printExcelFile = (data, name) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: fileType });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = name + '.xlsx';
        a.click();
    }

    render() {
        console.log("deleteAllDuplicateApi", this.props.data[this.view_id])

        const mobile = window.innerWidth <= 600 ? true : false;

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            margin: 'auto',
            width: mobile ? '100%' : 1000,
        };

        let pData = this.props.data[this.id];

        let lDesc = (n) => this.getText(parseInt(n) ? parseInt(n) : n)

        let columns = [
            {
                title: this.getText(13123),
                dataIndex: "mDesc",
                key: "mDesc",
                width: '35%',
                render: (text, record) => text
            }, {
                title: this.getText(13124),
                dataIndex: "mType",
                key: "mType",
                width: '25%',
                render: (text, record) => lDesc(text)
            }, {
                title: this.getText(13125),
                dataIndex: "mIsActive",
                key: "mIsActive",
                width: '10%',
                render: (text, record) => (<Icon type={text == 1 ? "check-circle" : "close-circle"} style={{ color: text == 1 ? 'green' : 'red' }} />)
            }, {
                title: this.getText(13601),
                dataIndex: "mFirstTitle",
                key: "mFirstTitle",
                width: '10%',
                render: (text, record) => (<Icon type={text == 1 ? "check-circle" : "close-circle"} style={{ color: text == 1 ? 'green' : 'red' }} />)
            }, {
                title: this.getText(this.props.isAdmin ? 12812 : 16836),
                width: '20%',
                render: (text, record) => {
                    let deleteRow = () => { this.sendAPI(this.api.delete, record.mID, this.refreshData, (e) => { message.error(e) }) }
                    let copyRow = () => { this.sendAPI(this.api.copy, record.mID, this.refreshData, (e) => { message.error(e) }) }

                    let adminActions = this.props.isAdmin ? [
                        <Tooltip title={this.getText(13336)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                this.setState({ modalCreateEdit: true, filterFields: true }, () => {
                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                    this.apiFields(record.mTypeID, record.mID);
                                });
                            }} />
                        </Tooltip>,
                        <Tooltip title={this.getText(13337)}>
                            <Popconfirm title={this.getText(13339)} onConfirm={deleteRow} okText={this.getText(13340)} cancelText={this.getText(13341)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </Tooltip>,
                        <Tooltip title={this.getText(13338)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"copy"} onClick={copyRow} />
                        </Tooltip>,
                        <Tooltip title={this.getText(13876)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"setting"} onClick={() => { this.props.onClickSqlOnExcel(record) }} />
                        </Tooltip>
                    ] : ""

                    return (<span>
                        <Tooltip title={this.getText(13335)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"cloud-upload"} onClick={() => {
                                this.sendAPI(this.api.get_fields, 'mTypeId\fmKey\r' + record.mTypeID + '\f' + record.mID, (ob) => {
                                    if (ob.data) {
                                        let mData = ob.data.split('\r').slice(1, -1);
                                        let isValid = true;
                                        mData.forEach((x) => {
                                            let y = x.split('\f');
                                            if (!y[9] && y[10] !== '1') isValid = false;
                                        });

                                        if (isValid) {
                                            this.modalUploadOpen(record.mID, record.mFirstTitle == '1' ? true : false)
                                        } else {
                                            message.error(this.getText(13871));
                                            setTimeout(() => {
                                                this.setState({ modalCreateEdit: true, filterFields: false }, () => {
                                                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                                    this.apiFields(record.mTypeID, record.mID);
                                                });
                                            }, 3000);
                                        }
                                    }

                                }, (e) => { console.error(e) });

                            }} />
                        </Tooltip>
                        <Tooltip title={this.getText(17289)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"printer"} onClick={() => {
                                this.sendAPI(this.api.get_fields, 'mTypeId\fmKey\r' + record.mTypeID + '\f' + record.mID, (ob) => {
                                    if (ob.data) {
                                        let mData = ob.data.split('\r').slice(0, -1);
                                        let mHeaders = mData.splice(0, 1)[0].split("\f");
                                        let fullData = mData.map((r) => {
                                            let obj = {}
                                            let y = r.split("\f");
                                            mHeaders.forEach((h, i) => { obj = { ...obj, [h]: y[i] } });
                                            return obj;
                                        })

                                        // let printData = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map(x => {
                                        //     return '"' + fullData.filter(f => f.mExcelColumn == x).map(y => lDesc(y.mFieldDesc)).join(" / ").split('"').join('""') + '"'
                                        // }).join(',');

                                        // let blob = new Blob(["\ufeff", printData], { type: "text/csv;charset=utf8" });
                                        // let a = document.createElement("a");
                                        // a.href = URL.createObjectURL(blob);
                                        // a.download = record.mDesc + '.csv';
                                        // a.click();

                                        let objData = {}
                                        let blankCol = "";
                                        "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").forEach(x => {
                                            blankCol += " ";
                                            let findCol = fullData.find(f => f.mExcelColumn == x);
                                            objData = { ...objData, [findCol ? lDesc(findCol.mFieldDesc) : blankCol]: "" }
                                        });


                                        this.printExcelFile([objData], record.mDesc)

                                    }
                                }, (e) => { console.error(e) });

                            }} />
                        </Tooltip>
                        {adminActions}
                    </span>)
                }
            },
        ];

        let modalInput = (record, pos) => {
            let oData = this.props.data[this.fields_id].data;
            let oRow = oData[record.index].split("\f");
            let updateIn = (v) => {
                let nRow = [...oRow];
                nRow[pos] = v;
                let nData = [...oData];
                nData[record.index] = nRow.join("\f");
                this.props.dataActions.setJson(this.fields_id, { data: nData })
            }

            if (pos === 9) {
                let isRequired = record.mIsNullable !== '1';

                let content = [<Button onClick={() => { this.setState({ popABC: false }, () => { updateIn(null) }) }}><Icon type={"delete"} /></Button>];

                "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").forEach((x, i) => {
                    if (i == 8 || i == 17) content.push(<br />)
                    content.push(<Button onClick={() => { this.setState({ popABC: false }, () => { updateIn(x) }) }}>{x}</Button>)
                });

                // let content = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((x) => {
                //     return (<Button onClick={() => { this.setState({ popABC: false }, () => { updateIn(x) }) }}>{x}</Button>)
                // });

                return (<Form.Item style={{ marginBottom: 0 }} required={isRequired} validateStatus={(isRequired && !oRow[pos]) ? "error" : "success"}>
                    <Popover placement="right" content={content} title={this.getText(14614)} trigger="click" visible={this.state.popABC === record.key}>
                        <Input value={oRow[pos]} onClick={() => { this.setState({ popABC: record.key }) }} style={{ width: '100%' }} />
                    </Popover>
                </Form.Item>)
            } else {
                return (<Form.Item style={{ marginBottom: 0 }}>
                    <Input value={oRow[pos]} onChange={(v) => { updateIn(v.target.value) }} style={{ width: '100%' }} />
                </Form.Item>)
            }
        }

        let modalColumns = [
            { title: this.getText(13134), key: 'mTableDesc', dataIndex: 'mTableDesc', width: '20%', render: (text, record) => lDesc(text) },
            { title: this.getText(13135), key: 'mFieldDesc', dataIndex: 'mFieldDesc', width: '20%', render: (text, record) => lDesc(text) },
            { title: this.getText(13136), key: 'mExcelDesc', dataIndex: 'mExcelDesc', width: '20%', render: (text, record) => modalInput(record, 8) },
            { title: this.getText(13137), key: 'mExcelColumn', dataIndex: 'mExcelColumn', width: '20%', render: (text, record) => modalInput(record, 9) },
            { title: this.getText(13138), key: 'mFieldDefault', dataIndex: 'mFieldDefault', width: '20%', render: (text, record) => modalInput(record, 7) },
        ];

        let modalTitle = (text) => (<PageToolbar title={this.getText(text)} showsearch={false} actionButtons={[]} />);

        let actionButtons = [{ buttonType: 'refresh', buttonFunction: this.refreshData }];
        if (this.props.isAdmin) actionButtons = [{ buttonType: 'add', buttonFunction: this.createNewDef }, ...actionButtons]

        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    title={this.getText(13126)}
                    actionButtons={actionButtons}
                    genericActionButtons={(<Tooltip title={this.getText(16832)}>
                        <Button style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => {
                            this.setState({ modalConsts: true })
                        }}><Icon style={{ fontSize: 16 }} type="build" /></Button>
                    </Tooltip>)}
                    columns={columns}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <GenericModal
                    visible={this.state.modalConsts}
                    onCancel={() => { this.setState({ modalConsts: false }) }}
                    title={this.getText(16833)}
                    width={600}
                >
                    <GenericTable
                        {...this.props}
                        tableOnly
                        id={this.consts_id}
                        getApi={this.api.get_consts}
                        columns={[
                            { title: this.getText(16834), key: "_desc", dataIndex: "_desc", width: "70%", render: t => t },
                            { title: this.getText(16835), key: "_remarks", dataIndex: "_remarks", width: "15%", render: this.renderConstRemark },
                            { title: this.getText(16836), key: "_id", dataIndex: "_id", width: "15%", render: this.uploadButtonConstsTable },
                        ]}
                    />
                </GenericModal>
                <GenericModal
                    visible={this.state.modalConstTemplate !== null}
                    onCancel={() => { this.setState({ modalConstTemplate: null }) }}
                    title={this.state.modalConstTemplate ? (this.getText(16887) + " - " + this.state.modalConstTemplate._desc) : ""}
                    actionButtons={[{ buttonType: 'print', buttonFunction: this.printTemplateConst }]}>
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalConstTemplate && this.state.modalConstTemplate.dataTemplate ?
                            [this.state.modalConstTemplate.dataTemplate]
                            : []}
                        columns={this.state.modalConstTemplate && this.state.modalConstTemplate.dataTemplate ?
                            Object.keys(this.state.modalConstTemplate.dataTemplate).map(x => {
                                return { title: x, key: x, dataIndex: x, render: t => t }
                            })
                            : []}
                        pagination={false}
                        scroll={{ x: window.innerWidth - 150 }}
                    />
                </GenericModal>
                <GenericModal
                    visible={this.state.modalErrorConsts}
                    onCancel={() => { this.setState({ modalErrorConsts: null }) }}
                    title={this.state.modalErrorConsts ? this.state.modalErrorConsts.title : ""}
                    width={500}>
                    {this.state.modalErrorConsts ? this.state.modalErrorConsts.data.map(x => (<h4>{x}</h4>)) : ""}
                </GenericModal>
                <Modal
                    destroyOnClose={true} width={'95%'} visible={this.state.modalCreateEdit}
                    title={modalTitle(pData.editing == 0 ? 13127 : 13128)} onOk={this.handleOk} onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel} style={{ marginLeft: 10 }}>{this.getText(12820)}</Button>,
                        <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(12822)}</Button>,
                    ]}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataAction={this.props.dataAction} >
                    <Row>
                        <Col span={mobile ? 24 : 10} style={{ padding: 10 }}>
                            <Item label={this.getText(13129)}>
                                <Input style={{ width: '98%', textAlign: 'center' }} value={pData.editedRecord['mDesc']} onChange={(value) => {
                                    this.props.dataActions.genericDataSetEdit(this.id, { mDesc: value.target.value })
                                }} />
                            </Item>
                        </Col>
                        <Col span={mobile ? 24 : 10} style={{ padding: 10 }}>
                            <Item label={this.getText(13130)}>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch filterOption={true}
                                    optionFilterProp="valuefilter"
                                    value={pData.editedRecord['mTypeID']}
                                    disabled={pData.editedRecord['mID']}
                                    onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mTypeID: value });
                                        this.apiFields(value, 0);
                                    }}>
                                    {this.props.data[this.tables_id].dataset.map((obj, index) => {
                                        return (<Option key={obj['code']} value={obj['code']} valuefilter={lDesc(obj['name'])}>
                                            {lDesc(obj['name'])}
                                        </Option>)
                                    })}
                                </Select>
                            </Item>
                        </Col>
                        <Col span={mobile ? 24 : 2} style={{ padding: 10 }}>
                            <Item label={this.getText(13131)}>
                                <Switch checkedChildren={this.getText(13132)} unCheckedChildren={this.getText(13133)}
                                    checked={pData.editedRecord['mIsActive'] == 1 ? true : false} onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mIsActive: value ? '1' : '0' })
                                    }} />
                            </Item>
                        </Col>
                        <Col span={mobile ? 24 : 2} style={{ padding: 10 }}>
                            <Item label={this.getText(13601)}>
                                <Switch checkedChildren={this.getText(13132)} unCheckedChildren={this.getText(13133)}
                                    checked={pData.editedRecord['mFirstTitle'] == 1 ? true : false} onChange={(value) => {
                                        this.props.dataActions.genericDataSetEdit(this.id, { mFirstTitle: value ? '1' : '0' })
                                    }} />
                            </Item>
                        </Col>
                    </Row>
                    {this.props.data[this.fields_id].data.length > 0 ?
                        (<ResponsiveTable
                            cardStyle={true}
                            dataSource={this.fieldsDataSource()}
                            actionButtons={[]}
                            genericActionButtons={[<Switch
                                checkedChildren={this.getText(13872)}
                                unCheckedChildren={this.getText(13873)}
                                checked={this.state.filterFields == '1' ? true : false}
                                onChange={(e) => { this.setState({ filterFields: e ? '1' : '0' }) }}
                            />]}
                            columns={modalColumns}
                            scroll={{ y: window.innerHeight / 2.5 }}
                            pagination={false}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                        />) : ""}
                </Modal>
                <Modal
                    destroyOnClose={true}
                    width={mobile ? '95%' : '70%'}
                    visible={this.state.modalUpload}
                    title={modalTitle(13342)}
                    footer={false}
                    onCancel={this.closeModal}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataAction={this.props.dataAction} >
                    {this.state.oldExcel ?
                        this.modalUpload1()
                        : this.state.uploadOption < 3 ?
                            this.state.filePackSum > 0 ?
                                this.modalUpload3()
                                : this.modalUpload2()
                            :
                            this.modalUpload3()
                    }
                </Modal>
                {this.fixesLine()}
                {this.deleteDuplicateModal()}
            </div>
        )

    }
}

export default ImportExcel;