/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import {
    Carousel, message, Button, Card, Modal, Collapse, Icon, InputNumber, Select, Row, Col, Tooltip, Input, Form
} from 'antd';
import "../../App.css";
import TableIDs from '../../data/TableIDs';
import FoodsFormatCart from "./FoodsFormatCart";
import PizzaView from "./PizzaView";
import PizzaButtons from "./PizzaButtons";
import SearchBox from "./SearchBox";
import FoodFormatMenu from "./FoodFormatMenu";
import FoodFormatMessage from "./FoodFormatMessage";
import FoodOrderBenefits from "./FoodOrderBenefits";

const { Panel } = Collapse;
const { Option } = Select;

type Props = {
    data: Object,
    dataActions: Object,
    isBackOffice: Boolean,
    getText: Function,
    sendAPI: Function,
    changeScreen: Function,
    companyCode: String,
}

class FoodsFormatMain extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            mobileCartView: false,
            tag: "",
            tagName: "",
            secondTag: '',
            secondTagName: '',

            itemModal: null,
            extraModal: null,
            itemQty: 1,
            gridView: true,
            searchTagInput: '',

            itemMouseOver: '',
            scrollExtraModal: null,

            modalImage: null,

            messagesView1: null,
            messagesView2: null,
            messagesView3: null,

            searchExtraName: "",
        };

        this.carousel = React.createRef();

        this.mobile = window.innerWidth <= 700 ? true : false;
        this.getText = this.props.getText;
        this.activeKey = null;

        this.id = "FOOD_FORMAT_LIST";
        this.fields_id = "FOOD_FORMAT_ORDER_FIELDS";
        this.slider_id = "FOOD_FORMAT_SLIDER";
        this.matrix_id = "FOOD_FORMAT_MATRIX";
        this.levels_id = "FOOD_FORMAT_LEVELS";
        this.extras_id = "FOOD_FORMAT_EXTRAS";
        this.cart_id = "FOOD_FORMAT_CART";
        this.setting_id = "FORMAT_SITE_SETTINGS";
        this.mivzaim_id = "FORMAT_SITE_MIVZAIM";
        this.mivzaim_cart_id = "FORMAT_MIVZAIM_CART";
        this.points_id = "FORMAT_SITE_POINTS";
        this.tags_id = TableIDs.genericSelectorTagsToSite;
        this.second_id = "SECONDARY_TAGS_TO_SITE";
        this.itemProps_id = "VIRTUAL_SHOP_ITEM_PROPERTIES";


        if (props.data[this.id] === undefined) props.data[this.id] = { data: [] };
        if (props.data[this.fields_id] === undefined) props.data[this.fields_id] = { orderType: '0', orderFields: {} };
        if (props.data[this.slider_id] === undefined) props.data[this.slider_id] = { data: [] };
        if (props.data[this.matrix_id] === undefined) props.data[this.matrix_id] = { data: [] };
        if (props.data[this.levels_id] === undefined) props.data[this.levels_id] = { data: [], levels: [] };
        if (props.data[this.extras_id] === undefined) props.data[this.extras_id] = { data2: [], data3: [], data4: [] };
        if (props.data[this.cart_id] === undefined) props.data[this.cart_id] = { data: [] };
        if (props.data[this.setting_id] === undefined) props.data[this.setting_id] = {};
        if (props.data[this.mivzaim_id] === undefined) props.data[this.mivzaim_id] = { data: [] };
        if (props.data[this.mivzaim_cart_id] === undefined) props.data[this.mivzaim_cart_id] = { data: [] };
        if (props.data[this.points_id] === undefined) props.data[this.points_id] = {};

        if (props.data[this.tags_id] === undefined) { props.data[this.tags_id] = { dataset: [] } }
        if (props.data[this.second_id] === undefined) { props.data[this.second_id] = { dataset: [] } }
        if (props.data[this.itemProps_id] === undefined) props.data[this.itemProps_id] = { dataset: [] };

        this.api = {
            get_items: "get_items_to_site",
            get_matrix: "get_item_matrix_to_site",
            get_levels: "get_food_levels_to_site",
            get_extras: "get_food_extras_to_site",
            get_tags: "get_tags_to_site",
        }

        this.sliderTag = 0;

        this.mivzaValue = 0;
        this.mivzaGroupValue = 0;
        this.mivzaGroupSkipSum = 0;
        this.mivzaGroupZhutSum = 0;
        this.miTableFilter = [];

        this.errorCallback = this.props.errorCallback;

        this.gridViewDefaultUpdated = false;
        this.openExtras = false;
    }

    componentDidMount() {
        this.apiTags();
        this.replaceTopBanners('messagesView1', 0)
        this.replaceTopBanners('messagesView2', 0)
        this.replaceTopBanners('messagesView3', 0)
    }

    componentDidUpdate(p) {
        let settingData = this.props.data[this.setting_id];
        let orderFields = this.props.data[this.fields_id].orderFields;
        let pOrderFields = p.data[this.fields_id].orderFields;

        if (this.sliderTag == 0 && settingData.pSliderTag) {
            this.apiTags();
        }

        if (
            orderFields && pOrderFields && orderFields.dPriceList &&
            orderFields.dPriceList !== pOrderFields.dPriceList
        ) {
            this.apiItems(this.state.tag, this.id, false);
            this.apiItems(this.sliderTag, this.slider_id, false);
        }

        if (p.messagesView1 !== this.props.messagesView1) this.replaceTopBanners('messagesView1', 0);
        if (p.messagesView2 !== this.props.messagesView2) this.replaceTopBanners('messagesView2', 0);
        if (p.messagesView3 !== this.props.messagesView3) this.replaceTopBanners('messagesView3', 0);


        let ivDefault = this.props.isFromPos ? 'pDefaultItemViewPos' : 'pDefaultItemViewDesktop';
        if (settingData[ivDefault] === '2' && !this.gridViewDefaultUpdated) {
            this.gridViewDefaultUpdated = true;
            this.setState({ gridView: false })
        }

        if (p.isMivzaimPage !== this.props.isMivzaimPage) {
            this.apiItems(this.state.tag, this.id, this.props.isMivzaimPage);
        }

    }

    apiTags = (tParnetId) => {

        let dataSend = "tParnetId\r" + (tParnetId ? tParnetId : "");
        let settingData = this.props.data[this.setting_id];
        this.sliderTag = settingData.pSliderTag ? settingData.pSliderTag : 0;

        this.props.sendAPI(this.api.get_tags, dataSend, (ob) => {
            let data = ob.data ? ob.data.split("\r") : []
            let dBranch = this.props.data[this.fields_id].orderFields.dBranch;
            data = data.map((y) => {
                let z = y.split("\f");
                return { code: z[0], name: z[1], color: z[2], image: z[3], hide: z[4], branchList: z[5] }
            })
                .filter(f => f.code !== this.sliderTag && f.hide != 1)
                .filter(f => !this.props.isFromPos || !f.branchList || f.branchList.split(',').indexOf(dBranch) > -1);

            let autoLoadTag = data[0] && (!this.mobile || settingData.pMobileCategoriesView == '2')

            if (tParnetId && !this.state.secondTag) {
                if (autoLoadTag) {
                    this.setState({
                        secondTag: data[0].code,
                        secondTagName: data[0].name,
                    }, () => { this.apiItems(data[0].code, this.id, false) })
                }
                this.props.setJson(this.second_id, { dataset: data })
            } else if (!tParnetId) {
                if (autoLoadTag) {
                    this.setState({
                        tag: data[0].code,
                        tagName: data[0].name,
                    }, () => { this.apiItems(data[0].code, this.id, false) })
                }
                this.props.setJson(this.tags_id, { dataset: data })
                this.apiItems(this.sliderTag, this.slider_id, false);
            }
        }, this.errorCallback);
    }

    apiItems = (tag, store, search) => {
        const { orderFields } = this.props.data[this.fields_id];
        let _words = search ? search : '';
        let _priceList = orderFields.dPriceList ? orderFields.dPriceList : "";
        let dataSend = "_tag\f_words\f_priceList\f_couponItem\f_mivzaim\r";

        if (this.props.isMivzaimPage && this.props.isStyle3) dataSend += "\f\f" + _priceList + "\f\f1";
        else if (this.props.isMivzaimPage && this.props.isStyle6) dataSend += this.sliderTag + "\f\f" + _priceList + "\f\f";
        else dataSend += tag + "\f" + _words + "\f" + _priceList + "\f\f";

        this.props.sendAPI(this.api.get_items, dataSend, (ob) => {
            const { dBranch } = this.props.data[this.fields_id].orderFields;
            let x = ob.data.split("\r");
            let headers = x[0].split("\f");
            let data = x.slice(1);
            data = data.map((a) => {
                let b = a.split("\f");
                let obj = {};
                headers.map((c, i) => { obj = { ...obj, [c]: b[i] } });
                return obj;
            }).filter(f => !dBranch || !f.iExcBranches || !f.iExcBranches.split(',').find(fi => fi == dBranch));
            this.props.setJson(store, { data: data });
        }, this.errorCallback);

        setTimeout(() => {
            if (!search && tag) {

                this.apiTags(tag)
            } else {
                this.setState({ secondTag: "", secondTagName: "" }, () => {
                    this.props.setJson(this.second_id, { dataset: [] })
                })
            }
        }, 1000);
    }

    apiMatrix = (fiCode) => {
        const { orderFields } = this.props.data[this.fields_id];
        let _priceList = orderFields.dPriceList ? orderFields.dPriceList : "";

        this.props.sendAPI(this.api.get_matrix, "fiCode\f_priceList\r" + fiCode + "\f" + _priceList, (ob) => {
            let x = ob.data.split("\r");
            let headers = x[0].split("\f");
            let data = x.slice(1);
            data = data.map((a) => {
                let b = a.split("\f");
                let obj = {};
                headers.map((c, i) => { obj = { ...obj, [c]: b[i] } });
                return obj;
            });
            this.props.setJson(this.matrix_id, { data: data })
        }, this.errorCallback);
    }

    apiLevels = (fID) => {
        if (fID) {
            const { orderFields } = this.props.data[this.fields_id];
            let _priceList = orderFields.dPriceList ? orderFields.dPriceList : "";

            this.props.sendAPI(this.api.get_levels, "fID\f_priceList\r" + fID + "\f" + _priceList, (ob) => {
                let x = ob.data.split("\r");
                let headers = x[0].split("\f");
                let data = x.slice(1);
                let dData = [];
                let levels = [];
                const addLevel = (obj) => {
                    dData.push([obj]);
                    levels.push({
                        code: obj.lNum,
                        name: obj.lName,
                        options: obj.lOptions,
                        minOptions: obj.lMinOptions,
                        printGroupTitle: obj.lPrintGroupTitle,
                        ownerData: []
                    });
                }
                data.forEach((a, index) => {
                    let b = a.split("\f");
                    let obj = {};
                    headers.map((c, i) => { obj = { ...obj, [c]: b[i] } });

                    if (index === 0) {
                        addLevel(obj)
                    } else {
                        let lastLevel = dData[dData.length - 1][0].lNum;
                        if (lastLevel === obj.lNum) {
                            dData[dData.length - 1].push(obj);
                        } else {
                            addLevel(obj)
                        }
                    }
                })
                dData = dData.map(dataLevel => {
                    return dataLevel.filter(f => !orderFields.dBranch || !f.oExcBranches || !f.oExcBranches.split(',').find(fi => fi == orderFields.dBranch));
                })
                if (dData && dData.length == 1 && dData[0].length == 1 && dData[0][0].lMinOptions == "1" && dData[0][0].lOptions == "1") {
                    if (!parseInt(dData[0][0].oExtras) && !parseInt(dData[0][0].oFreeExtras)) {
                        levels = levels.map(x => { return { ...x, ownerData: [dData[0][0].oNum] } });
                        setTimeout(() => {
                            this.addToCart({ ...this.state.itemModal, singleItem: true })
                        }, 300);
                    } else {
                        this.setState({
                            extraModal: dData[0][0],
                            itemModal: { ...this.state.itemModal, singleItemWithExtra: true }
                        }, () => { this.apiExtras(dData[0][0].oID, null, dData[0][0].oPizzaPiecesNum) })
                    }
                }
                this.props.setJson(this.levels_id, { data: dData, levels: levels })

            }, this.errorCallback)
        }
    }

    editItemCart = (x, i) => {
        let cartData = this.props.data[this.cart_id].data;
        if (x.iFoodDishId) {
            this.setState({ itemModal: x }, () => {
                this.apiLevels(x.iFoodDishId);
                setTimeout(() => {
                    if (x.singleItemWithExtra) {
                        let ownerData = cartData[i].levels && cartData[i].levels[0] ? cartData[i].levels[0].ownerData : [];
                        let oExtraItems = ownerData && ownerData[0] ? ownerData[0].oExtraItems : null;
                        if (oExtraItems) {
                            this.setState({ itemModal: { ...x, iPrice: x.iOriginalPrice } }, () => {
                                this.apiExtras(ownerData[0].oID, oExtraItems, ownerData[0].oPizzaPiecesNum)
                            });
                        }
                    } else {
                        let updateLevels = cartData[i].levels ? cartData[i].levels.map((ul) => {
                            let updateOD = ul.ownerData.map((uod) => {
                                if (uod.oExtraItems) {
                                    return { item: uod.oNum, extras: uod.oExtraItems }
                                } else {
                                    return uod.oNum
                                }
                            })
                            return { ...ul, ownerData: updateOD }
                        }) : null;
                        if (updateLevels) this.props.setJson(this.levels_id, { levels: updateLevels });
                    }
                }, 1000);
            });

        } else if (x.iColorID || x.iSize1ID) {
            this.setState({ itemModal: { ...x, index: i + 1, sizeSelect: x.iSize1ID, colorSelect: x.iColorID } }, () => {
                this.apiMatrix(x.iFatherCode);
            })
        }
    }

    apiExtras = (oID, editData, oPizzaPiecesNum) => {
        if (oID) {
            const { orderFields } = this.props.data[this.fields_id];
            let _priceList = orderFields.dPriceList ? orderFields.dPriceList : "";

            this.props.sendAPI(this.api.get_extras, "oID\f_priceList\r" + oID + "\f" + _priceList, (ob) => {
                let x = ob.data.split("\r");
                let headers = x[0].split("\f");
                let data = x.slice(1).map((a) => {
                    let b = a.split("\f");
                    let obj = {};
                    headers.map((c, i) => { obj = { ...obj, [c]: b[i] } });
                    obj = { ...obj, eIsChecked: obj.eType == 4 ? 1 : 0 }
                    return obj;
                }).filter(f => !orderFields.dBranch || !f.eExcBranches || !f.eExcBranches.split(',').find(fi => fi == orderFields.dBranch))


                if (editData && editData.length) {
                    data = data.map(ext => {
                        let editedExt = editData.find(f => f.eID === ext.eID);
                        return editedExt ? editedExt : ext
                    })
                }

                let eLocals = oPizzaPiecesNum ? oPizzaPiecesNum == 2 ? [1, 2] : oPizzaPiecesNum == 4 ? [1, 2, 3, 4] : [1] : null

                let data2 = data.filter(e => e.eType == 2);
                let data3 = data.filter(e => e.eType == 3);
                let data4 = data.filter(e => e.eType == 4).map(ee => eLocals ? { ...ee, eLocals } : ee);

                this.props.setJson(this.extras_id, { data2, data3, data4 })
            }, this.errorCallback)
        }
    }

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    nisFormat = (price, iIsByWeight, isExtraPizza) => {
        let styleType = this.props.data[this.setting_id].pDesktopCategoriesView;
        let intNis = price ? parseInt(price) : '0';
        let floatNis = price && parseFloat(price) ? parseFloat(price).toFixed(2).split('.')[1] : '';
        if (floatNis === '00') floatNis = '';
        return floatNis || intNis ? (<div className={"price_item"} style={{ color: styleType == '3' || styleType == '4' ? 'black' : styleType == '6' ? "#000000" : styleType == '5' ? '#443325' : styleType == '2' && !isExtraPizza ? '#060707' : "auto" }}>
            ₪<b>{intNis}</b><sup>{floatNis}</sup>
            {iIsByWeight ? " " + this.getText(14318) : ""}
        </div>) : ''
    }

    getPriceItem = (itemObj) => {
        let pPricesNoTax = this.props.data[this.setting_id].pPricesNoTax;
        return pPricesNoTax == '1' ? itemObj.iPriceNoVat : itemObj.iPrice
    }

    getSlider = () => {
        const { pDesktopCategoriesView } = this.props.data[this.setting_id];
        let items = this.props.data[this.slider_id].data;

        const onClick = (x) => {
            if (x.iFoodDishId) {
                this.setState({ itemModal: { ...x, iOriginalPrice: x.iPrice } }, () => { this.apiLevels(x.iFoodDishId) })
            } else if (x.iIsFather == 1) {
                this.setState({ itemModal: x }, () => { this.apiMatrix(x.iCode) })
            } else {
                this.addToCart(x);
            }
        }

        if (pDesktopCategoriesView == '4' || pDesktopCategoriesView == '6') {
            let itemStyle = {
                width: 250,
                height: 250,
                display: "inline-block",
                verticalAlign: "text-top",
                cursor: "pointer",
                textAlign: "center",
                marginTop: 10,
                marginRight: 10,
                paddingTop: 200,
            }

            let priceStyle = {
                background: "red",
                borderRadius: "50%",
                padding: 10,
                fontSize: 22,
                fontWeight: "bold",
                color: "white",
            }

            return items.map(x => {
                return x.iPictureFile ?
                    (<div style={{ ...itemStyle, background: "url(" + this.imgUrl(x.iPictureFile) + ")", backgroundSize: "cover" }} onClick={() => { onClick(x) }}>
                        <span style={priceStyle}>₪{x.iPrice}</span>
                    </div>)
                    : ""
            })
        }

        let heightSlider = window.innerHeight / 4;
        let widthSlider = window.innerWidth * 0.6;
        if (pDesktopCategoriesView == '2' && !this.mobile) {
            widthSlider = widthSlider - 365;
        }
        let cardQty = this.mobile ? 1 : parseInt(widthSlider / heightSlider);
        let arrowStyle = { fontSize: 30, marginTop: heightSlider / 2 }
        let sData = [];
        for (let x = 0; x < items.length; x += cardQty) {
            let toPush = [];
            for (let y = 0; y < cardQty; y++) { toPush.push(items[x + y]) }
            sData.push(toPush);
        }
        return sData.length ? (<div style={{ backgroundColor: '#404041' }}>
            <Row style={{ width: this.mobile ? '100%' : pDesktopCategoriesView == '2' ? (window.innerWidth * 0.72) - 365 : "72%" }}>
                <Col span={2} style={arrowStyle}>
                    <div id="arrow_right" onClick={() => { this.carousel.next() }} ></div>
                </Col>
                <Col span={20} >
                    <Carousel autoplay={true} ref={(e) => this.carousel = e}>
                        {sData.map((y) => {
                            return (<div>
                                {y.map((x) => {
                                    let i = items.findIndex(f => f && x && f.iCode === x.iCode)
                                    return x ?
                                        this.getStyleCard(
                                            heightSlider / 110,
                                            x.iPictureFile,
                                            x.iCode,
                                            x.iName,
                                            this.getPriceItem(x),// x.iPrice,
                                            x.iRemarks,
                                            0,
                                            () => { onClick(x) },
                                            x.mivzaList,
                                            (x.iFoodDishId || !parseFloat(x.stepQty)) ? '' : this.qtyInput(this.slider_id, i),
                                            x.iIsByWeight == '1'
                                        ) : ""
                                })}</div>)
                        })}
                    </Carousel>
                </Col>
                <Col span={2} style={arrowStyle}>
                    <div id="arrow_left" onClick={() => { this.carousel.prev() }} ></div>
                </Col>
            </Row>
        </div>)
            : ""
    }

    getPriceStyle = (price, iIsByWeight) => {
        if (typeof price === "string" && price.indexOf("-") > -1) {
            let p = price.split("-");
            return (p[0] !== p[1]) ?
                (<span style={{ fontSize: 10 }}>
                    {this.nisFormat(p[0], iIsByWeight)}
                    <div className={"price_item"}>{" - "}</div>
                    {this.nisFormat(p[1], iIsByWeight)}
                </span>) :
                this.nisFormat(p[0], iIsByWeight)
        } else {
            return this.nisFormat(price, iIsByWeight)
        }
    }

    getSizeColorStyle = (size, color) => {
        let sDiv = size ? (<div><b>{this.getText(13008)}:</b> {size}</div>) : ""
        let cDiv = color ? (<div><b>{this.getText(13009)}:</b> {color}</div>) : ""
        return (<span>{sDiv}{cDiv}</span>)
    }

    getStyleCard = (size, pFile, code, name, price, remarks, extras, onClick, mivzaim, qtyInput, iIsByWeight, checkboxChecked, modifier1Text, onMousModal) => {

        let styleType = this.props.data[this.setting_id].pDesktopCategoriesView;

        let itemStyle = {
            width: this.mobile ? "95%" : size * 100,
            height: styleType == '2' ? this.mobile ? 240 : 410 : this.mobile || styleType == '3' ? 'auto' : 450, //size * 160,
            backgroundColor: styleType == '2' ? '#febd27' : '#ffffff',
            color: styleType == '2' ? "#060707" : "#404041",
            display: "inline-block",
            verticalAlign: 'text-top',
            cursor: 'pointer',
            margin: size * (styleType == '3' ? 3 : 5),
            marginBottom: size * (styleType == '3' ? 10 : 5),
            paddingBottom: size * 5,
            borderBottomRightRadius: styleType == '3' ? 20 : 0,
            borderBottomLeftRadius: styleType == '3' ? 20 : 0,
            textAlign: 'center',
            direction: 'rtl',
            padding: styleType == '2' || styleType == '3' ? 0 : 8
        }

        let imgStyle = (w) => {
            return this.mobile ? {
                width: w + "%",
                display: "inline-block",
                verticalAlign: "text-top",
            } : {}
        }

        let extrasIcon = (<Icon
            type="plus"
            style={{
                backgroundColor: styleType == '6' ? '#ddc268' : "green",
                color: styleType == '6' ? '#000000' : "white",
                padding: 10,
                margin: 10,
                borderRadius: "50%",
                float: "left",
                fontSize: styleType == '6' ? 10 : 'auto',
            }}
            onClick={() => {
                this.openExtras = true;
            }} />)

        let checkboxStyle = checkboxChecked || checkboxChecked === 0 ?
            checkboxChecked == 1 ? this.props.getClassNameStyle("extra_checkbox_checked") : (<div className="extra_checkbox" />)
            : "";

        let mivzaimSplit = mivzaim ? mivzaim.split(',') : [];
        let mivzaimFilter = this.props.data[this.mivzaim_id].data.filter(f => mivzaimSplit.indexOf(f.mId) > -1).map(x => { return { code: x.mId, name: x.mDesc } })

        let mivzaimIcon = mivzaimFilter.length > 0 ? (
            <Tooltip title={(<div><p>{this.getText(14109)}:</p>{mivzaimFilter.map(x => (<p>{x.name}</p>))}</div>)}>
                <span className="item_sale_bargains" />
            </Tooltip>) : "";

        let buttonAddToCart = styleType == '3' || (styleType == '2' && this.state.gridView) ? '' : (<div style={{ marginBottom: 0, height: 40, fontSize: 20, direction: 'rtl', paddingTop: this.mobile ? 20 : 0 }}>
            <Button><Icon type={"shopping-cart"} style={{ marginLeft: 5, fontSize: 20 }} />{this.getText(15455)}</Button>
        </div>)

        let priceItemDiv = (<span>
            <div style={{ marginBottom: 0, height: 45, direction: 'rtl' }}>{parseFloat(price) ? this.getPriceStyle(price, iIsByWeight) : ""}</div>
            <div style={{ marginBottom: 0, fontSize: 12, height: 20 }}>{modifier1Text ? modifier1Text : ""}</div>
        </span>)

        return (<div
            style={itemStyle}
            onClick={() => {
                if (extras && this.props.data[this.setting_id].pNotOpenExtraByDefault != '1') this.openExtras = true;
                onClick()
            }}>
            <div style={{ height: this.mobile ? 'auto' : styleType == '3' ? 250 : 200, ...imgStyle(40) }}>
                {pFile ?
                    // (<div style={{ cursor: 'zoom-in' }} onClick={(e) => {
                    //     e.stopPropagation();
                    //     this.setState({ modalImage: (<img style={{ maxHeight: '100%', width: '100%' }} src={this.imgUrl(pFile)} />) })
                    // }}>
                    //     <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={this.imgUrl(pFile)} />
                    // </div>)
                    (<div style={{
                        backgroundImage: 'url(' + this.imgUrl(pFile) + ')',
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        height: this.mobile ? 'auto' : styleType == '3' ? 250 : 200, ...imgStyle(40),
                        padding: 10
                    }}>
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            background: "white",
                            cursor: "zoom-in"
                        }} onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ modalImage: (<img style={{ maxHeight: '100%', width: '100%' }} src={this.imgUrl(pFile)} />) })
                        }}>
                            <Icon type="eye" />
                        </div>
                    </div>)
                    : ''}

                {this.mobile && typeof extras !== "object" ? priceItemDiv : ""}
            </div>
            <div className={this.props.getFontStyle2("text_item")} style={{ ...imgStyle(60), fontFamily: styleType == '3' ? "FbTipograf" : "auto", }}>
                {mivzaimIcon}
                {typeof extras === "object" ?
                    <Row>
                        <Col span={12}>{name}<br />{this.nisFormat(price, iIsByWeight)}</Col>
                        <Col span={12}>{this.getSizeColorStyle(extras.iSizeDesc, extras.iColorDesc)}</Col>
                    </Row>
                    : (<div
                        style={{
                            textAlign: 'center',
                            background: styleType == '3' ? "orange" : "auto",
                            lineHeight: styleType == '3' ? 1 : "auto",
                            height: styleType == '3' ? 75 : "auto",
                        }}>
                        <h2 style={{
                            marginBottom: 0,
                            fontSize: styleType == '3' ? 24 : 20,
                            fontWeight: styleType == '3' ? "normal" : "auto",
                            height: 30,
                            overflow: styleType == '3' ? 'hidden' : 'auto'
                        }}>{name}</h2>
                        {styleType == '3' ? "" :
                            <h5 style={{ marginBottom: 0, fontSize: 15, height: 45, overflow: 'hidden' }}>{remarks}</h5>
                        }
                        {this.mobile ? "" : priceItemDiv}
                        {extras ? extrasIcon : ''}
                    </div>)}
            </div>
            <div style={{ marginBottom: 0, height: this.mobile || styleType == '2' || styleType == '3' ? 40 : 60, ...imgStyle(40) }}>{qtyInput ? qtyInput : ""}</div>
            <div style={{ ...imgStyle(60) }}>{checkboxStyle ? checkboxStyle : buttonAddToCart}</div>
        </div >);
    }

    qtyInput = (idStore, indexItem) => {
        let styleType = this.props.data[this.setting_id].pDesktopCategoriesView;

        let sData = [...this.props.data[idStore].data]
        let x = sData[indexItem];
        let stepQty = parseFloat(x.stepQty) ? parseFloat(x.stepQty) : 1;
        let minQty = parseFloat(x.minQty) ? parseFloat(x.minQty) : 1;
        let maxQty = parseFloat(x.maxQty) ? parseFloat(x.maxQty) : 999999;
        let iQty = parseFloat(x.iQty) ? parseFloat(x.iQty) : minQty;

        let precision = stepQty.toString().split('.')[1] ? stepQty.toString().split('.')[1].length : 0;

        let onChange = (e) => {
            sData[indexItem] = { ...sData[indexItem], iQty: parseFloat(e).toFixed(precision) }
            this.props.dataActions.setJson(idStore, { data: sData });
        }

        return styleType == '2' || styleType == '3'/*&& this.state.gridView*/ ?
            (<Row style={{
                background: '#231f20',
                color: '#ffffff',
                height: this.state.gridView ? 47 : 32,
                fontSize: 27,
                borderBottomRightRadius: styleType == '3' && this.state.gridView ? 20 : 0,
                borderBottomLeftRadius: styleType == '3' && this.state.gridView ? 20 : 0,
            }}>
                <Col span={12}>{this.getText(16020)}</Col>
                <Col span={12}>
                    <div
                        style={{ display: "inline-block", verticalAlign: "text-top", cursor: "pointer" }}
                        onClick={(e) => {
                            e.stopPropagation()
                            let newQty = parseFloat(iQty) + stepQty;
                            onChange((newQty <= maxQty ? newQty : maxQty).toFixed(precision))
                        }}>+</div>
                    <div
                        style={{
                            display: "inline-block", verticalAlign: "text-top", paddingRight: 10, paddingLeft: 10
                        }}>{parseFloat(iQty).toFixed(precision)}</div>
                    <div
                        style={{
                            display: "inline-block", verticalAlign: "text-top", cursor: "pointer", marginTop: -2
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            let newQty = parseFloat(iQty) - stepQty;
                            onChange((newQty >= minQty ? newQty : minQty).toFixed(precision))
                        }}>-</div>
                </Col>
            </Row>)
            : (<div onClick={(e) => { e.stopPropagation() }} >
                {this.state.gridView ? (<div>{this.getText(15469)}</div>) : ""}

                <InputNumber
                    step={stepQty}
                    min={minQty}
                    max={maxQty}
                    precision={precision}
                    value={iQty}
                    onChange={onChange} />
            </div>)
    }

    getItemModal = () => {
        let mData = this.state.itemModal ?
            this.state.itemModal :
            {
                iCode: "",
                iFoodDishId: "",
                iIsFather: "",
                iName: "",
                iPictureFile: "",
                iPrice: "",
                iRemarks: "",
            }

        let siteSettings = this.props.data[this.setting_id];
        let optionListView = siteSettings.pLevelOptionsView == '2'

        let allLevels = this.props.data[this.levels_id].levels;
        let mandatoryLevels = mData.iFoodDishId ?
            allLevels.map((x, i) => { return { ...x, index: i } })
                .filter(x => x.minOptions && parseInt(x.minOptions) > x.ownerData.length)
            : [];


        let mContent = () => {
            if (mData.iFoodDishId) {
                let activeLevels = allLevels.filter(x => (parseInt(x.options) - x.ownerData.length > 0 && !x.skip) || x.open);
                this.activeKey = activeLevels[0] ? activeLevels[0].code : null

                let optionName = (num, options) => {
                    let numItem = (typeof num === "object") ? num.item : num;
                    let resOp = options.find(z => z.oNum === numItem);
                    return resOp.oItemName;
                }

                let iPrice = this.state.itemModal.iPrice ? parseFloat(this.state.itemModal.iPrice) : 0;


                let headerClick = (x, i) => {
                    let ageLevelsSkip = mandatoryLevels.filter(f => f.index < i).length < 1
                    let activeKeyIndex = allLevels.findIndex(f => f.code === this.activeKey)
                    if (ageLevelsSkip) {
                        allLevels[activeKeyIndex] = { ...allLevels[activeKeyIndex],  skip: true, open: false }
                    }
                    allLevels[i] = { ...allLevels[i], /*ownerData: [],*/ skip: false, open: true }
                    this.setState({ itemModal: { ...this.state.itemModal, iPrice: iPrice } })
                    this.props.setJson(this.levels_id, { levels: allLevels })
                }

                let itemClick = (x, i, y, isChecked) => {
                    let ownerData = allLevels[i].ownerData;
                    if (isChecked) {
                        let oToSplice = ownerData.splice(ownerData.findIndex(f => f === y.oNum || y.oNum === f.item), 1)[0]
                        if (oToSplice.extras) {
                            iPrice = parseFloat(iPrice - this.props.getSumExtraPrices(oToSplice.extras, parseInt(y.oPizzaPiecesNum))).toFixed(2);
                        }
                        if (parseFloat(y.oPrice)) {
                            iPrice = parseFloat(iPrice - parseFloat(y.oPrice)).toFixed(2);
                        }

                        allLevels[i] = { ...allLevels[i], ownerData: ownerData }
                        this.props.setJson(this.levels_id, { levels: allLevels });
                        this.setState({ itemModal: { ...this.state.itemModal, iPrice: iPrice } });
                    } else {
                        if (ownerData.length < x.options) {
                            if (parseInt(y.oExtras) && (y.oPizzaPiecesNum || this.openExtras)) {
                                this.setState({ extraModal: y }, () => {
                                    this.apiExtras(y.oID, null, y.oPizzaPiecesNum)
                                    this.openExtras = false;
                                })
                            } else {
                                ownerData.push(y.oNum);
                                allLevels[i] = { ...allLevels[i], ownerData: ownerData, open: false }
                                this.props.setJson(this.levels_id, { levels: allLevels });
                                if (parseFloat(y.oPrice)) {
                                    iPrice = parseFloat(iPrice + parseFloat(y.oPrice)).toFixed(2);
                                    this.setState({ itemModal: { ...this.state.itemModal, iPrice: iPrice } });
                                }
                            }
                        } else {
                            this.props.genericMessageError(this.getText(14423))
                            // message.error(this.getText(14423))
                        }
                    }
                }



                return (<span>
                    <Collapse expandIcon={() => (<div />)} activeKey={'level' + this.activeKey}>
                        {allLevels.map((x, i) => {
                            let options = this.props.data[this.levels_id].data[i];

                            let titleHeader = (<div className={this.props.getFontStyle2("text_item")} onClick={() => { headerClick(x, i) }}>
                                <h2>{x.name}</h2>
                                {parseInt(x.options) - x.ownerData.length > 0 ? this.getText(12986) + ": " + x.options : ""}
                                {parseInt(x.minOptions) - x.ownerData.length > 0 ? ", " + this.getText(14358) + ": " + x.minOptions : ""}

                                {x.code === this.activeKey && (!parseInt(x.minOptions) || parseInt(x.minOptions) - x.ownerData.length < 1) ?
                                    (<Button style={{ float: 'left', marginTop: -20, ...this.props.buttonStyle }} onClick={(e) => {
                                        e.stopPropagation();
                                        let updateLevels = [...this.props.data[this.levels_id].levels];
                                        updateLevels[i] = { ...updateLevels[i], skip: true, open: false }
                                        this.props.setJson(this.levels_id, { levels: updateLevels })
                                    }}>
                                        <span className={this.props.getFontStyle2("text_item")} style={{ ...this.props.buttonStyle, fontSize: 17, height: 30 }}>{this.getText(14359)}</span>
                                    </Button>)
                                    : (<div className={x.code === this.activeKey ? 'collapse_icon_active' : 'collapse_icon'} />)}

                                <div>{x.ownerData.map((w) => optionName(w, options)).join(', ')}</div>
                            </div>)

                            return (<Panel header={titleHeader} key={'level' + x.code}>
                                {options.map((y) => {
                                    let extras = parseInt(y.oExtras);
                                    let isChecked = x.ownerData.find(f => f === y.oNum || (typeof f === 'object' && f.item === y.oNum)) ? 1 : 0
                                    return optionListView ?
                                        this.getCheckboxStyle(
                                            isChecked, y.oItemName, y.oPrice, () => { itemClick(x, i, y, isChecked) }, extras)
                                        : this.getStyleCard(
                                            2.5, y.oImage, y.oItemCode, y.oItemName, y.oPrice, null, extras,
                                            () => { itemClick(x, i, y, isChecked) },
                                            null, null, null, isChecked);
                                })}
                            </Panel>)
                        })}
                    </Collapse>
                    {this.getRemarkModalItem()}
                </span>)
            } else if (mData.iIsFather == 1 || mData.iFatherCode) {
                let siItems = this.props.data[this.matrix_id].data;
                let sizesSData = new Map();
                let colorSData = new Map();
                siItems.map((x) => {
                    if (x.iSize1ID && x.iSizeDesc) sizesSData.set(x.iSize1ID, x.iSizeDesc)
                    if (x.iColorID && x.iColorDesc) colorSData.set(x.iColorID, x.iColorDesc)
                });
                let gSelect = (key, data, text) => {
                    return data.length > 1 ?
                        (<div className={"choose_container"}>
                            <Select
                                suffixIcon={(<span><div className={"collapse_icon_active"} style={{ marginTop: -5 }} /></span>)}
                                key={key} style={{ width: 340, height: '50px !important', fontSize: 22 }} showSearch filterOption={true} optionFilterProp="valuefilter"
                                onChange={(value) => this.setState({ itemModal: { ...this.state.itemModal, [key]: value } })}
                                value={this.state.itemModal[key] ? this.state.itemModal[key] : ""}>
                                <Option key={0} value={""}>{this.getText(text)}</Option>
                                {data.map((x) => (<Option key={x[0]} value={x[0]} valuefilter={x[1]}>{x[1]}</Option>))}
                            </Select>
                        </div>)
                        : ""
                }
                return (<div>
                    {gSelect("sizeSelect", [...sizesSData], 13026)}
                    {gSelect("colorSelect", [...colorSData], 13027)}
                    <div>{siItems
                        .filter(f => f.iSize1ID === this.state.itemModal.sizeSelect || !this.state.itemModal.sizeSelect)
                        .filter(f => f.iColorID === this.state.itemModal.colorSelect || !this.state.itemModal.colorSelect)
                        .map((y) => this.getStyleCard(2.5, y.iPictureFile, y.iCode, y.iName, this.getPriceItem(y)/*y.iPrice*/, y.iRemarks, {
                            iSizeDesc: y.iSizeDesc,
                            iColorDesc: y.iColorDesc
                        }, () => {
                            let addItem = { ...y, iFatherCode: mData.iCode };
                            if (mData.iFatherCode) addItem = { ...addItem, iFatherCode: mData.iFatherCode, index: mData.index }
                            this.addToCart(addItem);
                        }))}</div>
                </div>)
            }
        }


        let footerButton = () => {

            if (mData.iIsFather != 1 && mData.iFoodDishId && !mandatoryLevels.length && (mData.iRemarkRequierd != '1' || mData.custRemark)) {
                return [
                    <Button
                        id="add_to_cart_but"
                        style={this.props.buttonStyle}
                        onClick={() => { this.addToCart(this.state.itemModal) }}>
                        <div className={"cart_but"}></div>
                        {this.getText(13000)}
                    </Button>,
                    <Button
                        style={{ ...this.props.buttonStyle, height: 54, marginRight: 10, marginLeft: 0, width: 184 }}
                        onClick={() => { this.addToCart(this.state.itemModal, true) }}>
                        {this.getText(16503)}
                    </Button>
                ]
            } else {
                return []
            }
        }

        let dataLevels = this.props.data[this.levels_id].data
        let singleItem = dataLevels.length === 1 && dataLevels[0].length === 1;
        let notItems = !dataLevels.length;
        let visible = (!singleItem && !notItems && !this.state.extraModal) || !mData.iFoodDishId;

        return (<Modal
            zIndex={10000}
            visible={this.state.itemModal && visible}
            onCancel={() => { this.setState({ itemModal: null }) }}
            destroyOnClose={true}
            width={window.innerWidth > 1600 ? 1460 : "95%"}
            title={this.props.getClassNameStyle("title_block", {}, mData.iName)}
            footer={footerButton()}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            {this.getHeaderModalItem(mContent())}
        </Modal>)
    }

    getCheckboxStyle = (checked, name, price, onChange, extras, pizzaBtn) => {
        let styleType = this.props.data[this.setting_id].pDesktopCategoriesView;

        let extrasIcon = (<Icon
            type="plus"
            style={{
                backgroundColor: styleType == '6' ? '#ddc268' : "green",
                color: styleType == '6' ? '#000000' : "white",
                padding: 10,
                marginRight: 10,
                borderRadius: "50%",
                fontSize: styleType == '6' ? 10 : 'auto'
            }}
            onClick={() => {
                this.openExtras = true;
            }} />);

        let res = (<div
            className={this.props.getFontStyle2("text_item")}
            style={{ textAlign: 'right', cursor: 'pointer' }}
            onClick={() => {
                if (extras && this.props.data[this.setting_id].pNotOpenExtraByDefault != '1') this.openExtras = true;
                onChange(checked == 1 ? 0 : 1);
            }}
        >
            {checked == 1 ? this.props.getClassNameStyle("extra_checkbox_checked", {}, '') : (<div className={"extra_checkbox"} />)}
            {" " + name}
            {price && parseFloat(price) ? (<b>{" - ₪" + parseFloat(price).toFixed(2)}</b>) : ""}
            {extras ? extrasIcon : ""}
        </div>)

        return pizzaBtn ? (<Row>
            <Col span={16}>{res}</Col>
            <Col span={8}>{pizzaBtn}</Col>
        </Row>) : res
    }


    getExtraModal = () => {
        let mData = { ...this.props.data[this.extras_id] }
        let mDataOrigin = { ...this.props.data[this.extras_id] }
        if (this.state.searchExtraName) {
            mData = {
                data2: mData.data2?.filter(f => f.eItemName?.indexOf(this.state.searchExtraName) > -1) ?? [],
                data3: mData.data3?.filter(f => f.eItemName?.indexOf(this.state.searchExtraName) > -1) ?? [],
                data4: mData.data4?.filter(f => f.eItemName?.indexOf(this.state.searchExtraName) > -1) ?? [],
            }
        }
        let iData = this.state.extraModal;
        let siteSettings = this.props.data[this.setting_id];

        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        let isStyle6 = siteSettings.pDesktopCategoriesView == '6';

        let divStyle = {
            display: isStyle6 ? "block" : 'inline-block',
            width: isStyle6 ? "auto" : 230,
            margin: 20,
            verticalAlign: 'text-top',
            border: '#606161 solid 1px',
        }

        let pizzaStyle = {
            margin: 20,
            border: '#606161 solid 1px',
        }

        let sTitleStyle = {
            backgroundColor: '#606161',
            color: '#ffffff',
            textAlign: 'center',
        }

        let contentStyle = {
            padding: 10,

        }


        let titleText = iData ? iData.oPizzaImg ? iData.oItemName : this.getText(12996) + iData.oItemName : "";
        let titleStyle = this.props.getClassNameStyle("title_block", { background: isStyle4 || isStyle5 ? "#BE1E2D" : isStyle6 ? "#ddc268" : "auto" }, (<h2 style={{ color: '#ffffff', fontSize: this.mobile ? 25 : 'auto' }}>{titleText}</h2>))

        let areasQty = iData ? parseInt(iData.oPizzaPiecesNum) : 0;

        let cardExtraStyle = (num, isPizza) => {
            let titleCard = (<div className={this.props.getFontStyle2("text_item")} style={sTitleStyle}>
                {num == 4 ? this.getText(12993) :
                    num == 2 || !iData || !parseInt(iData.oFreeExtras) ? this.getText(12994) :
                        (<span>
                            {this.getText(12995)}
                            <div style={{ fontSize: 16, marginTop: -10 }}>{this.getText(12998) + " " + iData.oFreeExtras + " " + this.getText(12997)}</div>
                        </span>)}
            </div>);

            let checkIfFree = (index) => {
                let checkArray = mData.data3.map((xx, i) => i === index ? { ...xx, checkIfFree: true } : xx);
                let oFreeExtras = iData && iData.oFreeExtras ? parseInt(iData.oFreeExtras) : 0;
                checkArray = checkArray.filter(f => f.eIsChecked == 1)
                    .sort((a, b) => parseFloat(b.ePrice) - parseFloat(a.ePrice))
                    .slice(0, oFreeExtras)
                    .find(f => f.checkIfFree === true);
                return !checkArray;
            }

            let extraCheck = (newObj) => {
                let nmData = mDataOrigin['data' + num].map(x => (x.eID == newObj.eID) ? newObj : x)

                if (iData && iData.oLimitMoreFreeOptions == '1' && num == '3' && nmData.filter(f => f.eIsChecked).length > parseInt(iData.oFreeExtras)) {
                    this.props.genericMessageError(this.getText(14423))
                    // message.error(this.getText(14423))
                } else {
                    this.props.setJson(this.extras_id, { ['data' + num]: nmData });
                }
            }

            return (mData["data" + num][0]) ? (<div style={isPizza ? pizzaStyle : divStyle}>
                {titleCard}
                <div className={this.props.getFontStyle2("text_item")} style={contentStyle}>
                    {mData["data" + num].map((x, index) => {
                        let ePrice = num == 2 || (x.eIsChecked == '1' && checkIfFree(index)) ? x.ePrice : null;
                        let eAllLocals = null;
                        if (areasQty) {
                            eAllLocals = []
                            for (let x = 1; x <= areasQty; x++) {
                                eAllLocals.push(x)
                            }
                        }
                        return (<div style={{
                            display: !isStyle6 ? "block" : 'inline-block',
                            width: !isStyle6 ? "auto" : 300,

                        }}>
                            {this.getCheckboxStyle(
                                x.eIsChecked,
                                x.eItemName,
                                ePrice,
                                (c) => { extraCheck({ ...x, eIsChecked: c, eLocals: eAllLocals ? c ? eAllLocals : [] : undefined }) },
                                null,
                                // iData && iData.oPizzaImg ? (
                                //     <PizzaButtons
                                //         extraData={x}
                                //         baseImg={iData.oPizzaImg}
                                //         areasQty={areasQty}
                                //         onChange={(newObj) => { extraCheck( newObj) }}
                                //         getText={this.props.getText}
                                //         companyCode={this.props.companyCode}
                                //     />
                                // ) : ""
                            )}
                        </div>)
                    })}

                </div>
            </div>) : ""
        }

        let getExtraData = () => {
            let dd2 = mData.data2.filter(f => f.eIsChecked == 1);
            let dd3 = mData.data3.filter(f => f.eIsChecked == 1)
                .sort((a, b) => parseFloat(b.ePrice) - parseFloat(a.ePrice))
                .map((e, i) => {
                    return (iData && i < parseInt(iData.oFreeExtras)) ? { ...e, ePrice: 0 } : e
                });
            let dd4 = mData.data4.filter(f => f.eIsChecked == 1).map((e) => { return { ...e, ePrice: 0 } });

            return {
                extras: [...dd2, ...dd3, ...dd4],
                sum: this.props.getSumExtraPrices([...dd2, ...dd3], areasQty)
            }
        }

        let handleSave = () => {

            let iPrice = parseFloat(this.state.itemModal.iPrice) ? parseFloat(this.state.itemModal.iPrice) : 0;
            if (parseFloat(iData.oPrice)) {
                iPrice = parseFloat(parseFloat(iPrice) + parseFloat(iData.oPrice)).toFixed(2);
            }

            let eData = getExtraData();
            iPrice = parseFloat(parseFloat(iPrice) + (eData.sum ? eData.sum : 0)).toFixed(2);

            let allLevels = this.props.data[this.levels_id].levels.map((x) => {
                if (x.code === iData.lNum) {
                    return { ...x, ownerData: [...x.ownerData, { item: iData.oNum, extras: eData.extras }], open: false }
                } else {
                    return x;
                }
            });

            this.props.setJson(this.levels_id, { levels: allLevels });

            this.setState({ extraModal: null, searchExtraName: "", itemModal: { ...this.state.itemModal, iPrice: iPrice } }, () => {
                let dataLevels = this.props.data[this.levels_id].data
                let singleItem = dataLevels.length === 1 && dataLevels[0].length === 1;
                if (singleItem) {
                    this.addToCart(this.state.itemModal);
                    setTimeout(() => {
                        this.props.setJson(this.levels_id, { data: [], levels: [] });
                        this.props.setJson(this.extras_id, { data2: [], data3: [], data4: [] });
                    }, 1000);
                }
            })
        }

        let buttonStyle = this.props.buttonStyle;
        if (isStyle4 || isStyle5 || isStyle6) {
            buttonStyle = { ...buttonStyle, background: isStyle6 ? "#ddc268" : "#BE1E2D", color: isStyle6 ? "black" : "white" }
        }


        let bStyle = {
            margin: 10,
            width: 140,
            height: 50,
            ...buttonStyle,
        }


        // let pizzaExtrasStyle = {
        //     display: 'inline-block',
        //     width: 150,
        //     // height: 150,
        //     padding: 10,
        //     textAlign: 'center',
        // }

        // let pizzaExtrasImgStyle = {
        //     width: 83,
        //     height: 83,
        //     borderRadius: '50%',
        // }

        let contentModal = iData && iData.oPizzaImg ? (
            // <Row>
            //     <Col span={this.mobile ? 24 : 14}>
            //         {pizzaExtras.map(x => {
            //             return (<div style={pizzaExtrasStyle}>
            //                 <img style={pizzaExtrasImgStyle} src={this.imgUrl(x.eImg)} />
            //                 <div className={this.props.getFontStyle2("text_item")} style={{ textAlign: 'center' }}>{x.eItemName}</div>
            //                 {this.nisFormat(x.ePrice, 0, true)}
            //             </div>)
            //         })}
            //     </Col>
            //     <Col span={this.mobile ? 24 : 10}>
            <PizzaView
                visible={this.state.extraModal !== null}
                extraList={mData}
                baseImg={iData.oPizzaImg}
                freeExtras={iData.oFreeExtras}
                areasQty={parseInt(iData.oPizzaPiecesNum)}
                btnColor={siteSettings.rSubTitles}
                companyCode={this.props.companyCode}
                getFontStyle2={this.props.getFontStyle2}
                getText={this.props.getText}
                nisFormat={this.nisFormat}
                itemName={this.state.itemModal ? this.state.itemModal.iName : ""}
                isStyle4={siteSettings.pDesktopCategoriesView == '4'}
                isStyle5={siteSettings.pDesktopCategoriesView == '5'}
                isStyle6={siteSettings.pDesktopCategoriesView == '6'}
                onChange={(newData) => {
                    let nmData = [...this.props.data[this.extras_id]['data' + newData.typeNum]]
                    nmData[newData.typeIndex] = newData;
                    this.props.setJson(this.extras_id, { ['data' + newData.typeNum]: nmData });
                }}
            />
            //     </Col>

            // </Row>
        ) : (
            <div style={{ textAlign: 'center' }}>
                {this.searchBoxExtra()}
                {cardExtraStyle(4)}
                {cardExtraStyle(2)}
                {cardExtraStyle(3)}
            </div>);

        contentModal = [
            contentModal,
            <div className={this.props.getFontStyle2("text_item")} style={{ textAlign: 'left', paddingLeft: isStyle4 || isStyle5 || isStyle6 ? 50 : 0 }}>
                {this.getText(15672)}:
                <br />
                <span style={{ paddingRight: 10 }}>{this.getPriceStyle(getExtraData().sum)} </span>
            </div>
        ];

        const { itemModal } = this.state;

        if (itemModal && itemModal.singleItemWithExtra) {
            contentModal = [...contentModal, this.getRemarkModalItem()];
            if (iData && !iData.oPizzaImg) contentModal = this.getHeaderModalItem(contentModal)
        }

        let disabledButton = itemModal && itemModal.singleItemWithExtra && itemModal.iRemarkRequierd == '1' && !itemModal.custRemark

        return (<Modal
            zIndex={10000}
            className={"padding_0"}
            visible={this.state.extraModal !== null}
            onCancel={() => { this.setState({ extraModal: null, searchExtraName: "" }) }}
            destroyOnClose={true}
            width={window.innerWidth > 1200 ? 1100 : "95%"}
            title={titleStyle}
            footer={[<Button key="saveExtras" style={bStyle} onClick={handleSave} disabled={disabledButton}>
                <span className={this.props.getFontStyle2("text_item")} style={buttonStyle}>{this.getText(12999)}</span>
            </Button >]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}>
            <div style={this.props.isFromPos ? { height: window.innerHeight - 250, overflow: 'scroll' } : { padding: isStyle4 || isStyle5 || isStyle6 ? 20 : 0 }}>
                {contentModal}
            </div>
        </Modal>)
    }

    getHeaderModalItem = (content) => {
        let mData = this.state.itemModal;

        return mData ? (<div style={this.props.isFromPos ? { height: window.innerHeight - 250, overflow: 'scroll' } : {}}>
            <div className={"img_item"}>
                {mData.iPictureFile ? (
                    <div style={window.innerWidth > 600 ? { height: 500 } : {}}>
                        <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={this.imgUrl(mData.iPictureFile)} />
                    </div>
                ) : ""}
                <h2>{mData.iName}</h2>
                {mData.iRemarks}
                {mData.iIsFather == 1 ? "" :
                    (<h2 style={{ marginBottom: 50 }}>{this.getText(12985)}: <br />{this.getPriceStyle(mData.iPrice, mData.iIsByWeight == '1')}</h2>)}

            </div>
            <div className={this.props.getFontStyle2("content_item text_item")}>
                {content}
            </div>
        </div>) : ""
    }

    getRemarkModalItem = () => {
        let mData = this.state.itemModal;

        return mData ? (<span>
            <div className={this.props.getFontStyle2("text_item")} style={{ marginTop: 20 }}>
                {mData.iRemarkRequierd == '1' ? (<span style={{ color: 'red' }}>{' * '}</span>) : ''}
                {this.props.getText(13361)}
            </div>
            <Form.Item validateStatus={mData.iRemarkRequierd == '1' && !mData.custRemark ? 'error' : 'success'}>
                <Input
                    className={this.props.getFontStyle2("text_item")}
                    style={{ width: '100%' }}
                    value={mData.custRemark ? mData.custRemark : ""}
                    onChange={(e) => { this.setState({ itemModal: { ...this.state.itemModal, custRemark: e.target.value } }) }}
                />
            </Form.Item>

        </span>) : ""
    }

    getTitleCart = (sm) => {
        let cartData = this.props.data[this.cart_id].data;
        let sumPrices = 0;
        let addToPrices = (sp) => { sumPrices = parseFloat(parseFloat(sumPrices) + sp).toFixed(2) }
        cartData.map((x) => { addToPrices(parseFloat(this.getPriceItem(x.item)/* x.item.iPrice*/) * parseFloat(x.item.iQty ? x.item.iQty : 1)) })

        return (
            <div style={{ fontSize: sm ? 16 : 20, color: 'white' }}>
                <div style={{ textAlign: 'center' }}>
                    <Icon type="shopping-cart" />
                    <b>{" " + this.getText(13003)}</b>
                </div>
                <div>
                    <span style={{ float: 'right' }}>{cartData.length} {this.getText(13002)}</span>
                    <span style={{ float: 'left' }}>{this.getPriceStyle(sumPrices)}</span>
                </div>
                {this.props.data[this.setting_id].pPricesNoTax == '1' ? (<div>{'* ' + this.getText(17536)}</div>) : ""}
            </div>)
    }

    addToCart = (mData, finishOrder) => {
        let cData = this.props.data[this.cart_id].data;
        let dataToCart = null;

        if (mData.index) cData.splice(mData.index - 1, 1);

        if (!mData.iPrice) mData = { ...mData, iPrice: '0.00' }
        if (!mData.iPriceNoVat) mData = { ...mData, iPriceNoVat: '0.00' }

        if (mData.iFoodDishId) {
            let lData = this.props.data[this.levels_id];
            let lvlData = lData.levels.map((x, i) => {
                let options = lData.data[i];
                let owData = [];
                x.ownerData.map((y) => {
                    if (typeof y === "string") {
                        owData.push(options.find(z => z.oNum === y))
                    } else {
                        owData.push({ ...options.find(z => z.oNum === y.item), oExtraItems: y.extras })
                    }
                })
                return { ...x, ownerData: owData };
            })
            dataToCart = {
                item: mData,
                levels: lvlData
            }
        } else {
            dataToCart = { item: mData }
        }

        let toAdd = [...Array(this.state.itemQty).keys()].map(() => dataToCart);
        let dataToAdd = [...cData, ...toAdd]
        this.props.setJson(this.cart_id, { data: dataToAdd })
        this.setState({ itemModal: null, itemQty: 1 }, () => {
            message.success(this.getText(13368));
            let contentCart = document.getElementById('contentCart');
            if (contentCart && !this.mobile) {
                contentCart.scrollTop = contentCart.scrollHeight;
            }
            setTimeout(() => {
                this.props.mivzaimLogic(this.cart_id, finishOrder);
            }, 500);
        })
    }

    getTagsMenuMobile = (vertical, tagName) => {
        const siteSettings = this.props.data[this.setting_id];
        const typeStyle = siteSettings.pDesktopCategoriesView;
        const topDiv = typeStyle == 4 ? [<br />, <div>{this.searchBox()}</div>] : [<div>{this.searchBox()}</div>, this.getBenefitsStyle(typeStyle)]

        if (vertical) {
            return (<div>
                {topDiv}
                <FoodFormatMenu
                    {...this.props}
                    tagActive={this.state.tag}
                    secondTagActive={this.state.secondTag}
                    apiItems={this.apiItems}
                    onChangeState={(state, next) => { this.setState(state, next) }}
                    vertical
                    titleText={tagName}
                    isStyle4={typeStyle == 4}
                    isStyle5={typeStyle == 5}
                    isStyle6={typeStyle == 6}
                />
            </div>)
        } else {
            return (<div>
                {topDiv}
                <div>
                    <FoodFormatMenu
                        {...this.props}
                        tagActive={this.state.tag}
                        // secondTagActive={this.state.secondTag}
                        apiItems={this.apiItems}
                        onChangeState={(state, next) => { this.setState(state, next) }}
                        isStyle4={typeStyle == 4}
                        isStyle5={typeStyle == 5}
                        isStyle6={typeStyle == 6}
                    />
                </div>
                <div>
                    <FoodFormatMenu
                        {...this.props}
                        tagActive={this.state.secondTag}
                        // tagActive={this.state.tag}
                        // secondTagActive={this.state.secondTag}
                        apiItems={this.apiItems}
                        onChangeState={(state, next) => { this.setState(state, next) }}
                        secondTags
                        isStyle4={typeStyle == 4}
                        isStyle5={typeStyle == 5}
                        isStyle6={typeStyle == 6}
                    />
                </div>
            </div>)
        }
    }

    getTagsMenu = (second) => {
        const siteSettings = this.props.data[this.setting_id];
        const typeStyle = siteSettings.pDesktopCategoriesView;
        return (<div>
            {second ? '' : this.searchBox()}

            <FoodFormatMenu
                {...this.props}
                tagActive={second ? this.state.secondTag : this.state.tag}
                // tagActive={this.state.tag}
                // secondTagActive={this.state.secondTag}
                apiItems={this.apiItems}
                onChangeState={(state, next) => { this.setState(state, next) }}
                secondTags={second}
                isStyle4={typeStyle == 4}
                isStyle5={typeStyle == 5}
                isStyle6={typeStyle == 6}
            />
        </div>)
    }

    getBenefitsStyle = (typeStyle) => {
        return typeStyle == 2 || typeStyle == 4 ?
            (<FoodOrderBenefits
                {...this.props}
                typeStyle={typeStyle}
                editItemCart={this.editItemCart}
                isShop={true}
                addToCart={this.onClickItem}
                getStyleCard={this.getStyleCard}
                fontColor={'#ffffff'}
                backgroundColor={'#be1e2d'}
                inCart
                couponsList={this.props.data[this.cart_id].data.filter(f => f.item.couponData).map(x => x.item.couponData.nCode)}
            />)
            : ""
    }

    getTagsMenuVertical = (styleType) => {
        const messagesView = (type, staticElement) => {
            let source = this.state["messagesView" + type];
            if (source && source.content) {
                return (<FoodFormatMessage  {...this.props} visible={source.id} idStaticElement={staticElement} content={source.content} />)
            }
        }
        let messagesView2 = messagesView('2', 'message-view-top-menu')
        let messagesView3 = messagesView('3', 'message-view-bottom-menu')

        return (<div>
            {this.searchBox()}
            {messagesView2 ? (<div style={{ textAlign: 'center', marginLeft: '10%' }}>{messagesView2}</div>) : ''}
            {styleType == '2' ? this.getBenefitsStyle('2') : ""}
            <FoodFormatMenu
                {...this.props}
                tagActive={this.state.tag}
                secondTagActive={this.state.secondTag}
                apiItems={this.apiItems}
                onChangeState={(state, next) => { this.setState(state, next) }}
                vertical
                isStyle3={styleType == 3}
                isStyle4={styleType == 4}
                isStyle5={styleType == 5}
                isStyle6={styleType == 6}
            />
            {styleType == '4' ? this.getBenefitsStyle('4') : ""}
            {messagesView3 ? (<div style={{ textAlign: 'center', marginLeft: '10%' }} onClick={() => {
                this.setState({ modalImage: messagesView('3', "message-view-bottom-menu-modal") }, () => {
                    setTimeout(() => {
                        let e = document.getElementById("message-view-bottom-menu-modal");
                        let img = e ? e.getElementsByTagName("img") : null;
                        if (img[0]) img[0].style.width = "100%";
                    }, 100);
                })
            }}>{messagesView3}</div>) : ''}
        </div>)
    }

    searchBox = () => {
        return this.getSearchBox(
            this.getText(13373),
            () => {
                let v = this.state.searchTagInput ? this.state.searchTagInput.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") : false;
                if (v) {
                    this.setState({ tag: '0', tagName: v }, () => { this.apiItems("0", this.id, v) });
                } else {
                    let firstTag = this.props.data[this.tags_id].dataset[0];
                    this.setState({ tag: firstTag.code, tagName: firstTag.name }, () => { this.apiItems(firstTag.code, this.id, false) });
                }
            },
            (e) => { this.setState({ searchTagInput: e }) })
    }

    searchBoxExtra = () => {
        return this.getSearchBox(
            this.getText(20416),
            () => { },
            (e) => { this.setState({ searchExtraName: e }) })
    }

    getSearchBox = (placeholder, onSearch, onChange) => {
        return (<SearchBox
            btnText={this.getText(15583)}
            placeholder={placeholder}
            onSearch={onSearch}
            onChange={onChange}
            searchTime={500}
            style={{ fontSize: 25, fontWeight: 800, color: 'black' }}
            isStyle3={this.props.data[this.setting_id].pDesktopCategoriesView == '3'}
            isStyle4={this.props.data[this.setting_id].pDesktopCategoriesView == '4'}
            isStyle5={this.props.data[this.setting_id].pDesktopCategoriesView == '5'}
            isStyle6={this.props.data[this.setting_id].pDesktopCategoriesView == '6'}
        />)
    }

    onClickItem = (x) => {
        if (x.iFoodDishId) {
            this.setState({ itemModal: { ...x, iOriginalPrice: x.iPrice } }, () => { this.apiLevels(x.iFoodDishId) })
        } else if (x.iIsFather == 1) {
            this.setState({ itemModal: x }, () => { this.apiMatrix(x.iCode) })
        } else {
            this.addToCart(x);
        }
    }

    replaceTopBanners = (type, index) => {
        let bannersList = this.props[type];
        if (bannersList.length) {
            this.setState({ [type]: bannersList[index] }, () => {
                let nextIndex = bannersList[index + 1] ? index + 1 : 0;
                setTimeout(() => { this.replaceTopBanners(type, nextIndex) }, 10000);
            });
        }
    }

    render() {
        let siteSettings = this.props.data[this.setting_id];
        let isStyle2 = siteSettings.pDesktopCategoriesView == '2';
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        let isStyle6 = siteSettings.pDesktopCategoriesView == '6';

        let divItemsStyle = {
            width: this.mobile || isStyle3 || isStyle4 || isStyle5 || isStyle6 ? '100%' : isStyle2 ? (window.innerWidth * 0.7) - 365 : "72%",
            marginTop: isStyle2 && !this.mobile ? 50 : isStyle3 ? 0 : "1%",
            display: "inline-block",
            verticalAlign: "text-top",
            textAlign: "center",
        }
        let divCartStyle = {
            width: this.mobile || this.props.cartFullScreen ? "100%" : "25%",
            margin: "1%",
            display: "inline-block",
            verticalAlign: "text-top",
        }

        let listRowStyle = {
            background: '#ffffff',
            border: '1px solid #404041',
            borderRadius: 5,
            height: isStyle2 ? 70 : 50,
            color: "#404041",
            cursor: 'pointer',
            margin: 2,
            marginRight: isStyle2 ? 0 : 50,
            padding: 1,
            lineHeight: 1,
        }

        let listRowImgStyle = {
            maxHeight: isStyle2 ? 65 : 45,
            maxWidth: '100%'
        }



        let itemsDiv = (<div style={{ backgroundColor: isStyle2 ? '#f2f2f2' : isStyle3 ? "#e6e7e8" : isStyle4 || isStyle5 || isStyle6 ? 'white' : '#404041' }}>

            <div style={divItemsStyle}>
                {this.state.messagesView1 && this.state.messagesView1.content ? (
                    <div style={{ border: '2px solid #febd27', padding: 5, width: '98%' }}>
                        <FoodFormatMessage
                            {...this.props}
                            visible={this.state.messagesView1.id}
                            content={this.state.messagesView1.content}
                            idStaticElement={'message-view-top'}
                        />
                    </div>
                ) : ""}
                <div style={{ paddingBottom: this.mobile ? 50 : 0 }}>
                    {this.props.data[this.id].data.map((x, i) => {
                        return isStyle4 || isStyle5 || isStyle6 ?
                            <Row
                                style={{ borderBottom: "1px solid #aaaaaa", padding: isStyle6 ? 0 : 5, margin: 5, textAlign: "right", }}
                                onClick={() => { this.onClickItem(x) }}>
                                <Col span={8} style={{ padding: isStyle6 ? 0 : 8, textAlign: 'center' }}>
                                    <img
                                        src={this.imgUrl(x.iPictureFile)}
                                        style={{
                                            height: this.mobile ? null : isStyle6 ? 120 : 180,
                                            width: this.mobile ? "80%" : null,
                                            borderRadius: isStyle6 ? 5 : "50%"
                                        }} />
                                </Col>
                                <Col span={12} style={{ paddingTop: 10 }}>
                                    <div
                                        // className={isStyle6 ? "border_text_block" : ""}
                                        style={{ fontSize: this.mobile ? 18 : 25, fontWeight: "bold", color: isStyle4 ? "#be1e2d" : isStyle6 ? "#ddc268" : "#443325" }}>
                                        {x.iName}
                                    </div>
                                    <div style={{ fontSize: this.mobile ? 15 : 20 }}>{x.iRemarks}</div>
                                    {this.props.data[this.itemProps_id].dataset
                                        .filter(f => x.iCode == f.itemCode)
                                        .map(iProp => {
                                            return (<div style={{
                                                display: "inline-block",
                                                verticalAlign: "text-top",
                                                width: 50,
                                                marginTop: 8,
                                                marginLeft: 8,
                                                textAlign: "center",
                                                fontSize: 12
                                            }}>
                                                <img src={this.imgUrl(iProp.image)} style={{ width: 30 }} />
                                                {iProp.desc}
                                            </div>)
                                        })
                                    }
                                </Col>
                                <Col span={4}
                                    // className={isStyle6 ? "border_text_block" : ""}
                                    style={{ paddingTop: this.mobile ? 70 : isStyle6 ? 80 : 140, textAlign: "center" }}>
                                    {this.getPriceStyle(this.getPriceItem(x)/*x.iPrice*/, x.iIsByWeight == '1')}
                                </Col>
                            </Row>
                            : this.state.gridView || this.mobile ?
                                this.getStyleCard(
                                    2.5,
                                    x.iPictureFile,
                                    x.iCode,
                                    x.iName,
                                    this.getPriceItem(x),// x.iPrice,
                                    x.iRemarks,
                                    0,
                                    () => { this.onClickItem(x) },
                                    x.mivzaList,
                                    (x.iFoodDishId || !parseFloat(x.stepQty) || x.iIsFather == 1) ? '' : this.qtyInput(this.id, i),
                                    x.iIsByWeight == '1',
                                    null,
                                    x.modifier1Text,
                                    true
                                ) :
                                this.props.isFromPos && isStyle2 ?
                                    (<Tooltip title={x.iRemarks} placement="right">
                                        <Row style={{ ...listRowStyle, height: 100 }} onClick={() => { this.onClickItem(x) }}>
                                            <Col span={24}><div className={this.props.getFontStyle2("text_item")}>{x.iName}</div></Col>
                                            <Col span={6}>{this.getPriceStyle(this.getPriceItem(x)/*x.iPrice*/, x.iIsByWeight == '1')}</Col>
                                            <Col span={6} style={{ paddingTop: 10 }}>{x.modifier1Text}</Col>
                                            <Col span={12} style={{ paddingTop: 10 }}>{(x.iFoodDishId || !parseFloat(x.stepQty) || x.iIsFather == 1) ? '' : this.qtyInput(this.id, i)}</Col>
                                        </Row>
                                    </Tooltip>)
                                    :
                                    (<Tooltip title={x.iRemarks} placement="right">
                                        <Row style={listRowStyle} onClick={() => { this.onClickItem(x) }}>
                                            <Col span={3}>{x.iPictureFile ? (<img style={listRowImgStyle} src={this.imgUrl(x.iPictureFile)} />) : ''}</Col>
                                            <Col span={9}><div className={this.props.getFontStyle2("text_item")}>{x.iName}</div></Col>
                                            <Col span={3}>{this.getPriceStyle(this.getPriceItem(x)/*x.iPrice*/, x.iIsByWeight == '1')}</Col>
                                            <Col span={3} style={{ paddingTop: 10 }}>{x.modifier1Text}</Col>
                                            <Col span={6} style={{ paddingTop: 10 }}>{(x.iFoodDishId || !parseFloat(x.stepQty) || x.iIsFather == 1) ? '' : this.qtyInput(this.id, i)}</Col>
                                        </Row>
                                    </Tooltip>);
                    })}
                </div>
            </div>
        </div>)

        let sliderDiv = this.props.data[this.slider_id].data.length ? [
            isStyle6 ? "" :
                this.props.getClassNameStyle("sale_title",
                    // isStyle6 ? { background: null, color: "#ddc268", fontWeight: "bold", fontSize: 40 } :
                    { marginTop: 0 },
                    isStyle4 || isStyle5// || isStyle6 
                        ? this.getText(18482) : ''),
            this.getSlider()
        ] : ""

        let cartComponent = (<div style={divCartStyle}>
            <FoodsFormatCart
                {...this.props}
                typeStyle={siteSettings.pDesktopCategoriesView}
                priceNoTax={siteSettings.pPricesNoTax == '1'}
                editItemCart={this.editItemCart}
                isShop={true}
                addToCart={this.onClickItem}
                getStyleCard={this.getStyleCard}
                openModalCouponCustList={this.props.openModalCouponCustList}
            />
        </div>)

        let slider_pic = this.props.isFromPos ?
            (<div style={{ height: 1, background: 'black' }} />)
            : isStyle2 ? ''
                : (<div id="slider_pic">
                    <img alt="slider_pic" src={this.imgUrl(siteSettings.pImageTop)} width="100%" />
                </div>)

        let tagName = this.state.tagName + (this.state.secondTagName ? ' > ' + this.state.secondTagName : '')

        let mobileShopStyle = (<div>
            {isStyle4 || isStyle5 || isStyle6 ? "" : slider_pic}
            {siteSettings.pMobileCategoriesView == '2' ?
                [
                    this.getTagsMenuMobile(),
                    this.props.getClassNameStyle("sale_title", { marginTop: 0 }, tagName)
                ]
                : this.getTagsMenuMobile(true, tagName)}
            {(isStyle4 || isStyle5) && this.props.isMivzaimPage ? "" : itemsDiv}
            {(isStyle4 || isStyle5) && !this.props.isMivzaimPage ? "" : sliderDiv}
        </div>);

        let desctopTitleItems = this.props.getClassNameStyle("sale_title",
            isStyle2 && !this.mobile && !this.props.isFromPos ? {
                position: 'fixed',
                top: 195,
                left: '29%',
                width: (window.innerWidth * 0.72) - 400,
                zIndex: this.props.isModalOpen ? 0 : 2500,
            } : {},
            [
                <div style={{ float: 'right', marginTop: -8, marginRight: 20 }}>
                    <Tooltip title={this.state.gridView ? this.getText(13362) : this.getText(13363)}>
                        <Button style={{ fontSize: 16, color: siteSettings.rSubTitles }}
                            onClick={() => { this.setState({ gridView: this.state.gridView ? false : true }) }} >
                            <Icon type={this.state.gridView ? 'menu' : 'table'} />
                        </Button>
                    </Tooltip>
                </div>,
                // <span style={isStyle2 && !this.mobile ? { paddingRight: 100 } : {}}>{
                this.props.isMivzaimPage ? this.getText(18482) : tagName
                // }</span>
            ]);

        let desctopStyle1 = (<div>
            {this.getTagsMenu()}
            {slider_pic}
            {desctopTitleItems}
            {this.getTagsMenu(true)}
            {itemsDiv}
            {sliderDiv}
            {this.props.minimizeCart ? '' : cartComponent}
        </div>);

        let desctopStyle2 = (<div>
            <div style={{
                position: 'absolute',
                top: this.props.isBackOffice ? 300 : this.props.isFromPos ? 130 : 200,
                right: this.props.isFromPos ? 0 : this.props.isBackOffice ? 95 : 15,
                width: 365
            }}>
                {this.getTagsMenuVertical()}
            </div>
            <div style={{ paddingRight: 364, width: '72%', paddingTop: this.props.isBackOffice ? 0 : this.props.isFromPos ? 124 : 194 }}>
                {slider_pic}
                {desctopTitleItems}
                {itemsDiv}
                {sliderDiv}
            </div>
            {this.props.minimizeCart ? '' : cartComponent}
        </div >)

        let mStyle = { display: "inline-block", verticalAlign: "text-top" }

        let desctopStyle3 = (<div>
            <div style={{ ...mStyle, width: "18%" }}>
                {this.getTagsMenuVertical(3)}
            </div>
            <div style={{ ...mStyle, width: (this.props.minimizeCart ? "82%" : "60%") }}>
                {desctopTitleItems}
                {itemsDiv}
                {sliderDiv}
            </div>
            {this.props.minimizeCart ? '' : (<div style={{ ...mStyle, width: "22%" }}>{cartComponent}</div>)}
        </div >)

        let desctopStyle4 = (<div style={{ minHeight: isStyle6 ? window.innerHeight - 210 : "auto" }}>
            <div style={{
                ...mStyle,
                width: "20%",
                padding: isStyle6 ? 0 : "1%",
                paddingRight: "1%",
                paddingLeft: "1%",
                height: isStyle6 ? window.innerHeight - 230 : null,
                position: isStyle6 ? 'fixed' : null,
                overflowY: isStyle6 ? 'scroll' : null,
            }}>
                {this.getTagsMenuVertical(siteSettings.pDesktopCategoriesView < 4 ? 4 : siteSettings.pDesktopCategoriesView)}
            </div>
            <div style={{
                ...mStyle,
                width: (this.props.minimizeCart ? "80%" : "53%"),
                height: isStyle6 ? window.innerHeight - 230 : null,
                position: isStyle6 ? 'fixed' : null,
                overflowY: isStyle6 ? 'scroll' : null,
                left: isStyle6 ? '26%' : null,
            }}>
                {/* {this.props.isMivzaimPage ? "" : desctopTitleItems} */}
                {this.props.isMivzaimPage && !isStyle6 ? sliderDiv : itemsDiv}
            </div>
            {this.props.minimizeCart ? '' : (<div style={{ ...mStyle, width: "22%" }}>{cartComponent}</div>)}
        </div >)


        let modals = [
            this.getItemModal(),
            this.getExtraModal(),
            <Modal
                className="padding_0"
                zIndex={10000}
                visible={this.state.modalImage !== null}
                onCancel={() => { this.setState({ modalImage: null }) }}
                footer={false}
                width={window.innerWidth > 700 ? 700 : '100%'}
            >{this.state.modalImage}</Modal>
        ];

        let mainStyle = this.mobile ?
            this.props.mobileCartView ? cartComponent : mobileShopStyle
            : isStyle2 ? desctopStyle2
                : isStyle3 ? desctopStyle3
                    : (isStyle4 || isStyle5 || isStyle6) ? desctopStyle4
                        : desctopStyle1;

        return (<span>{mainStyle}{modals}</span>)

    }
}

export default FoodsFormatMain;